import React, { useMemo, lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Box from "@mui/material/Box";
import Appbar from "./../Dashboard/Layout/Appbar";
import Sidebar from "./../Dashboard/Layout/Sidebar";
import Error from "./../components/Error";
import localstorageData from "../helpers/localstorageData";
import { useState, useEffect } from "react";
import { getServiceProviderActivePackage, accessRoleList } from "../api";
import axios from "axios";
import { SuperAdminRoutes } from "./AllRoutesArray";
import { getCreatedBy } from "../helpers/commonHelper";
import Loader from "../../../frontend/src/pages/loader/Loader";

export default function OtherRole() {
  const MemoizedAppbar = useMemo(() => <Appbar />, []);
  const MemoizedSidebar = useMemo(() => <Sidebar />, []);
  const [menuList, setMenuList] = useState([]);
  const [routes, setroutes] = useState([]);
  const user = localstorageData();
  const createdBy = getCreatedBy();
  const [loading, setLoading] = useState(true); // Loader state

  const getServiceProviderActivePackageData = () => {
    axios
      .get(
        `${getServiceProviderActivePackage}?id=${user.createdBy}&userTypeId=${user.userTypeId}`
      )
      .then(function (res) {
        if (res) {
          const out = [];

          res.data.data.map((item) => {
            if (item?.isSubMenu === false) {
              out.push(item?.mainMenu?.url);
            } else {
              item?.subMenu?.map((sub) => {
                out.push(sub.url);
              });
            }
          });

          console.log(out);
          setMenuList(out);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getServiceProviderRouteAccess = () => {
    axios
      .get(
        `${accessRoleList}?roleId=${user.userTypeId}&createdBy=${user.createdBy
        }&role=${user.role}&userId=${user._id}&userType=${user?.createdByUserType
        }&bookAdemo=${user?.isBookDemoUser}&isSc=${user?.isSoleTrader}&child=${user?.createdByUserType
        }&isABNHolderHc=${user?.isSoleTrader}&isABNHolder=${user?.isSoleTrader
        }&evlClient=${user?.packageMenuId == false && user?.role == "client"
          ? "true"
          : "false"
        }`
      )
      .then(function (res) {
        if (res) {
          console.log(res.data.data);
          setroutes((prevData) => [...prevData, ...res.data.data]);
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    // getServiceProviderActivePackageData();
    getServiceProviderRouteAccess();
  }, []);

  function isPathInRoutes(path) {
    return routes.includes(path);
  }

  const changeRoutePath = (path) => {
    if (user.role === "admin") {
      return path.replace("/super-admin", "/admin");
    } else if (user.role === "serviceProvider") {
      return path.replace("/super-admin", "/service-provider");
    } else {
      return path;
    }
  };

  if (loading) {
    return <Loader />; // Display loader while loading
  }
  return (
    <Routes>
      <Route>
        {SuperAdminRoutes?.map(
          (routeData, index) =>
            isPathInRoutes(routeData.path) ? (
              <Route
                key={index}
                //path={routeData.path}
                path={changeRoutePath(routeData.path)}
                element={
                  <Suspense
                    fallback={
                      <div>
                        <Loader />
                      </div>
                    }
                  >
                    <routeData.component />
                  </Suspense>
                }
              />
            ) : null // You can also use an empty fragment <> </> instead of null
        )}

        {SuperAdminRoutes?.map(
          (routeData, index) =>
            isPathInRoutes(routeData.path) ? (
              <Route
                key={index}
                path={routeData.path}
                element={
                  <Suspense fallback={<div>Loading...y</div>}>
                    <routeData.component />
                  </Suspense>
                }
              />
            ) : null // You can also use an empty fragment <> </> instead of null
        )}

        <Route path="*" element={<Error />} />
      </Route>
    </Routes>
  );
}
