import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import BannerSlider from "../../components/ui/BannerSlider";
import { useState, useEffect } from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { forgotpassword, getUserTypeList, passwordReset } from "../../api";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import { red } from "@material-ui/core/colors";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";

const useStyles = makeStyles((theme) => ({
  text: {
    margin: theme.spacing(2, 0),
    color: blue[500],
  },
  text1: {
    margin: theme.spacing(2, 0),
    color: red[800],
    fontWeight: "bold",
  },
}));

export default function SignInSide() {
  const [userEmail, setUserEmail] = useState([]);
  const [disableButton, setDisableButton] = useState(false);

  const [passwordTypeNew, setPasswordTypeNew] = useState("password");
  const [passwordTypeConfirm, setPasswordTypeConfirm] = useState("password");

  const [errorMsg, setErrorMessage] = useState(
    "Sorry, looks like there are some errors detected, please try again."
  );
  const [hasErrors, setHasErrors] = useState(undefined);
  const classes = useStyles();
  const navigate = useNavigate();

  const initalValues = {
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    // password: Yup.string()
    //   .min(8, "Password must be at least 8 characters")
    //   .required("Password is required"),
    // confirmPassword: Yup.string()
    //   .oneOf([Yup.ref("password")], "Passwords do not match")
    //   .required("Confirm Password is required"),

    password: Yup.string()
      .matches(
        //  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!\"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])[A-Za-z\d!\"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]{8,}$/,
        "Password must be 8 characters and contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      )
      .required("Required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Required"),
  });

  const fieldLogout = () => {
    localStorage.removeItem("userData");
    navigate("/sign-in");
  };

  const togglePasswordNew = () => {
    if (passwordTypeNew === "password") {
      setPasswordTypeNew("text");
      return;
    }
    setPasswordTypeNew("password");
  };

  const togglePasswordConfirm = () => {
    if (passwordTypeConfirm === "password") {
      setPasswordTypeConfirm("text");
      return;
    }
    setPasswordTypeConfirm("password");
  };
  return (
    <Box id="personal-info" width="100%" height="100%">
      <Grid container>
        <Grid item xs={12} sm={12} md={7}>
          <Box>
            <BannerSlider />
          </Box>
        </Grid>

        <Grid item xs={12} md={5}>
          <Box className="w-100 h-100 d-flex align-items-center justify-content-center">
            <Box className="form-main-box">
              <Box>
                <h2 mb={3} className="sign-title">
                  Reset Password?
                </h2>
              </Box>
              <Formik
                initialValues={initalValues}
                validationSchema={validationSchema}
                onSubmit={(values, formikHelpers) => {
                  setDisableButton(true);
                  const token =
                    window.location.pathname.split("/reset-password/")[1];
                  setHasErrors(undefined);
                  axios
                    .post(`${passwordReset}/${token}`, values)
                    .then((res) => {
                      if (res.data.success === false) {
                        setHasErrors(true);
                        setErrorMessage(res.data.message);
                        toast.error(res.data.message);
                        setDisableButton(false);
                      }
                      if (res.data.success === true) {
                        setHasErrors(false);
                        toast.success(res.data.message);
                        setDisableButton(false);
                        setTimeout(() => {
                          navigate("/sign-in");
                        }, 3000);
                      }
                    })
                    .catch((error) => {
                      setHasErrors(true);
                    });
                  // formikHelpers.resetForm();
                }}
              >
                {({ errors, isValid, touched, dirty }) => (
                  <Form>
                    {/* <Field
                      name="password"
                      type={passwordTypeNew}
                      as={TextField}
                      sx={{ mt: 2, mb: 2, width: "100%" }}
                      className="Form-1-text-field"
                      id="outlined-required"
                      label="New Password"
                      error={
                        Boolean(errors.password) && Boolean(touched.password)
                      }
                      helperText={Boolean(touched.password) && errors.password}
                    />
                    <Field
                      name="confirmPassword"
                      type={passwordTypeConfirm}
                      as={TextField}
                      sx={{ mt: 2, mb: 2, width: "100%" }}
                      className="Form-1-text-field"
                      id="outlined-required"
                      label="Confirm Password"
                      error={
                        Boolean(errors.confirmPassword) &&
                        Boolean(touched.confirmPassword)
                      }
                      helperText={
                        Boolean(touched.confirmPassword) &&
                        errors.confirmPassword
                      }
                    /> */}

                    <Field
                      name="password"
                      type={passwordTypeNew}
                      as={TextField}
                      sx={{ mt: 2, mb: 2, width: "100%" }}
                      className="Form-1-text-field"
                      InputProps={{
                        endAdornment: (
                          <endAdornment
                            className="input-icon"
                            position="end"
                            onClick={togglePasswordNew}
                            style={{ cursor: "pointer" }}
                          >
                            {passwordTypeNew === "password" ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </endAdornment>
                        ),
                      }}
                      placeholder="New Password"
                      error={
                        Boolean(errors.password) && Boolean(touched.password)
                      }
                      helperText={Boolean(touched.password) && errors.password}
                    />

                    <Field
                      name="confirmPassword"
                      type={passwordTypeConfirm}
                      as={TextField}
                      sx={{ mt: 2, mb: 2, width: "100%" }}
                      className="Form-1-text-field"
                      InputProps={{
                        endAdornment: (
                          <endAdornment
                            className="input-icon"
                            position="end"
                            onClick={togglePasswordConfirm}
                            style={{ cursor: "pointer" }}
                          >
                            {passwordTypeConfirm === "password" ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </endAdornment>
                        ),
                      }}
                      placeholder="Confirm Password"
                      error={
                        Boolean(errors.confirmPassword) &&
                        Boolean(touched.confirmPassword)
                      }
                      helperText={
                        Boolean(touched.confirmPassword) &&
                        errors.confirmPassword
                      }
                    />

                    <Button
                      className="signupbutton"
                      type="submit"
                      fullWidth
                      sx={{ mt: 3, mb: 2 }}
                      disabled={disableButton}
                    >
                      {disableButton === true ? "Processing..." : "Submit"}
                    </Button>

                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {
                        <>
                          <Typography color="#929495">
                            Back to{" "}
                            <Link
                              onClick={fieldLogout}
                              variant="body2"
                              style={{ cursor: "pointer" }}
                            >
                              Login
                            </Link>
                          </Typography>
                        </>
                      }
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
