import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import WebHeader from "./Web-Header";
import WebFooter from "./Web-footer";
import { Link } from "react-router-dom";
import pickUpBanner from "../assets/images/pick-up-banner.jpg";
import ProviderCards from "./Provider-cards";
import "../assets/css/dashboard.css";
import "../assets/css/web-css.css";

const PickupShifts = () => {
  return (
    <>
      <Box id="getSupport">
        <Box>
          <Box className="web-topBanner-bg  feature-main-banner">
            <WebHeader />

            <Box className="feature-titleBox">
              <Box className="webContainer">
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    className="home-content-main "
                  >
                    <Box>
                      <h1>Pick Up Shifts</h1>
                      <Typography>
                        Lorem ipsum dolor sit amet. Lorem ipsum dolor sit <br />{" "}
                        amet. Lorem ipsum dolor sit amet. Lorem ipsum <br />{" "}
                        dolor sit amet.
                      </Typography>

                      <Box className="d-flex align-items-center">
                        <Link
                          to="/book-demo"
                          className="home-section-button web-primary-btnLarge cta1"
                        >
                          Book A Demo
                        </Link>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} className="featureBannerImg">
                    <Box className="manageCarer-cardImg">
                      <img src={pickUpBanner} />
                    </Box>

                    <Box className="blurBg blurRed"> </Box>
                    <Box className="blurBg blurPurple"> </Box>
                    <Box className="blurBg blurYellow"></Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>

          <Box className="webContainer" sx={{ mt: 5, mb: 5 }}>
            <ProviderCards props={{ page: "home" }} />
          </Box>

          <WebFooter />
        </Box>
      </Box>
    </>
  );
};

export default PickupShifts;
