import * as React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import "../assets/css/dashboard.css";
import "../App.css";

export default function Error() {
  return (
    <Box class="error-main ">
      <Box className="text-center">
        {/* <Typography className="error-404"> 401! </Typography> */}
        {/* <Typography className="error-text">
          Opps! Unauthorized Access
        </Typography> */}
        <span>
          Sorry, you don't have permission to access Shift Note. Please Contact with Admin.{" "}
        </span>

        <Box className="error-btn">
          <a href="/family-member/home">Go to Dashboard</a>
        </Box>
      </Box>
    </Box>
  );
}
