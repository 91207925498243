import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Loader from "../../../frontend/src/pages/loader/Loader";
//404 page
import Error from "../components/Error";

export default function Supervisor() {
  const Home = lazy(() => import("../pages/Supervisor/supervisor-dashboard"));
  const MyAccount = lazy(() => import("../pages/Supervisor/form/MyAccount"));

  const Appointments = lazy(() =>
    import("../pages/Supervisor/appointment/list")
  );

  const AppointmentView = lazy(() =>
    import("../components/calendar-view/Appointment-View")
  );

  const PendingApproval = lazy(() =>
    import("../pages/superAdmin/pending-approval")
  );

  const SignedDocument = lazy(() =>
    import("../components/SignedDocument/ListSignedDocument")
  );

  const ViewSignedDocument = lazy(() =>
    import("../components/SignedDocument/ViewSignedDocument")
  );
  const ListHealthcarer = lazy(() =>
    import("../pages/Supervisor/users/healthCarer")
  );
  const ListSupportcoordinator = lazy(() =>
    import("../pages/Supervisor/users/supportCoordinator")
  );
  const ListClient = lazy(() => import("../pages/Supervisor/users/client"));
  const ListIncident = lazy(() => import("../pages/Supervisor/incident/list"));
  const Viewincident = lazy(() => import("../pages/Supervisor/incident/view"));
  const Pdfincident = lazy(() => import("../pages/healthCarer/incident/pdf"));
  const Closureincident = lazy(() =>
    import("../pages/Supervisor/incident/incidentClosure")
  );

  //leave management new changes

  const ListLeaveCategory = lazy(() =>
    import("../pages/superAdmin/leave-category/list")
  );

  const ListLeaveManagementOld = lazy(() =>
    import("../pages/superAdmin/leaveManagement/list")
  );

  const ListLeaveManagement = lazy(() =>
    import("../pages/superAdmin/leaveManagement/list-tab")
  );

  const EditLeaveManagement = lazy(() =>
    import("../pages/superAdmin/leaveManagement/edit")
  );

  const ViewLeaveManagement = lazy(() =>
    import("../pages/superAdmin/leaveManagement/view")
  );

  const ListSetLeaves = lazy(() => import("../pages/superAdmin/setLeave/list"));
  const ListMedicalHistory = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/listMedicalHistory")
  );
  const MedicalHistory = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/Medical-History")
  );
  const MedicationChart = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/Medication-chart")
  );
  const ObservationChart = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/Observation-chart")
  );
  const EditMedicalHistory = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/Add-Medical-History")
  );
  //ViewMedicalHistory
  const ViewMedicalHistory = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/View-Medical-History")
  );

  const EditMedicationChart = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/Add-Medication")
  );

  //ViewMedicationChart

  const ViewMedicationChart = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/View-Medication")
  );

  const ProgressNote = lazy(() =>
    import("../pages/superAdmin/progressNote/list")
  );

  const Setting = lazy(() =>
    import("../pages/serviceProvider/Settings/setting.js")
  );
  const ListShiftNotes = lazy(() =>
    import("../pages/superAdmin/shiftNotes/list")
  );
  const ViewShiftNotes = lazy(() =>
    import("../pages/superAdmin/shiftNotes/view")
  );

  const ProgressNoteAdd = lazy(() =>
    import("../pages/superAdmin/progressNote/edit")
  );

  const Attendance = lazy(() =>
    import("../pages/CommanComponant/Attendance/Attendance")
  );

  const Addbank = lazy(() =>
    import("../pages/Supervisor/form/Myaccounttab/Addbank")
  );
  const Editbank = lazy(() =>
    import("../pages/Supervisor/form/Myaccounttab/Editbank")
  );

  const ListCommonCalander = lazy(() =>
    import("../pages/superAdmin/schedule-common-meeting/client/list-tab")
  );

  const ViewMeetingDetails = lazy(() =>
    import("../components/meeting-view/details")
  );

  const ViewInterviewDetails = lazy(() =>
    import("../components/interview-view/details")
  );

  const ViewOtherMeetingDetails = lazy(() =>
    import("../components/meeting-view-other/details")
  );

  const ScheduleOtherMeeting = lazy(() =>
    import("../pages/superAdmin/schedule-other-meeting/meeting")
  );

  const ShiftNoteList = lazy(() =>
    import("../pages/superAdmin/shift-notes/shiftNoteclientlist.js")
  );

  return (
    <Routes>
      <Route>
        <Route
          path="/supervisor/home"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Home />
            </Suspense>
          }
        />
        <Route
          path="/supervisor/add-progressnote"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ProgressNoteAdd />
            </Suspense>
          }
        />
        <Route
          path="/supervisor/pending-approval"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <PendingApproval />
            </Suspense>
          }
        />

        <Route
          path="/supervisor/appointments-view"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <AppointmentView />
            </Suspense>
          }
        />

        <Route
          path="/supervisor/list-appointments"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Appointments />
            </Suspense>
          }
        />

        <Route
          path="/supervisor/myaccount"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <MyAccount />
            </Suspense>
          }
        />

        <Route
          path="/supervisor/list-signed-document"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <SignedDocument />
            </Suspense>
          }
        />
        <Route
          path="/supervisor/view-signed-document"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ViewSignedDocument />
            </Suspense>
          }
        />
        <Route
          path="/supervisor/list-health-carers"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListHealthcarer />
            </Suspense>
          }
        />
        <Route
          path="/supervisor/list-support-coordinators"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListSupportcoordinator />
            </Suspense>
          }
        />
        <Route
          path="/supervisor/list-clients"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListClient />
            </Suspense>
          }
        />
        <Route
          path="/supervisor/list-incident"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListIncident />
            </Suspense>
          }
        />
        <Route
          path="/supervisor/view-incident"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Viewincident />
            </Suspense>
          }
        />
        <Route
          path="/supervisor/pdf-incident"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Pdfincident />
            </Suspense>
          }
        />

        {/* leave management new changes */}
        <Route
          path="/supervisor/list-leave-categories"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListLeaveCategory />
            </Suspense>
          }
        />

        <Route
          path="/supervisor/list-leave-management-old"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListLeaveManagementOld />
            </Suspense>
          }
        />

        <Route
          path="/supervisor/list-leave-management"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListLeaveManagement />
            </Suspense>
          }
        />

        <Route
          path="/supervisor/add-leave"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <EditLeaveManagement />
            </Suspense>
          }
        />

        <Route
          path="/supervisor/edit-leave"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <EditLeaveManagement />
            </Suspense>
          }
        />

        <Route
          path="/supervisor/view-leave"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ViewLeaveManagement />
            </Suspense>
          }
        />

        <Route
          path="/supervisor/list-set-leave"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListSetLeaves />
            </Suspense>
          }
        />
        <Route
          path="/supervisor/closure-incident"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Closureincident />
            </Suspense>
          }
        />
        <Route
          path="/supervisor/list-medical-history"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListMedicalHistory />
            </Suspense>
          }
        />
        <Route
          path="/supervisor/list-medication-chart"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListMedicalHistory />
            </Suspense>
          }
        />
        <Route
          path="/supervisor/list-observation"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListMedicalHistory />
            </Suspense>
          }
        />
        <Route
          path="/supervisor/list-prn-chart"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListMedicalHistory />
            </Suspense>
          }
        />
        <Route
          path="/supervisor/medical-history-dev"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <MedicalHistory />
            </Suspense>
          }
        />
        <Route
          path="/supervisor/medication-chart-dev"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <MedicationChart />
            </Suspense>
          }
        />

        <Route
          path="/supervisor/observation-chart-dev"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ObservationChart />
            </Suspense>
          }
        />
        <Route
          path="/supervisor/prn-chart-dev"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <MedicationChart />
            </Suspense>
          }
        />
        <Route
          path="/supervisor/add-medical-history-dev"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <EditMedicalHistory />
            </Suspense>
          }
        />
        <Route
          path="/supervisor/edit-medical-history-dev"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <EditMedicalHistory />
            </Suspense>
          }
        />

        <Route
          path="/supervisor/view-medical-history-dev"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ViewMedicalHistory />
            </Suspense>
          }
        />
        <Route
          path="/supervisor/add-medication-dev"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <EditMedicationChart />
            </Suspense>
          }
        />
        <Route
          path="/supervisor/edit-medication-dev"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <EditMedicationChart />
            </Suspense>
          }
        />
        <Route
          path="/supervisor/view-medication-dev"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ViewMedicationChart />
            </Suspense>
          }
        />
        <Route
          path="/supervisor/settings"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Setting />
            </Suspense>
          }
        />
        <Route
          path="/supervisor/list-shift-notes"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListShiftNotes />
            </Suspense>
          }
        />
        <Route
          path="/supervisor/view-shift-notes"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ViewShiftNotes />
            </Suspense>
          }
        />
        <Route
          path="/supervisor/list-progressnote"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ProgressNote />
            </Suspense>
          }
        />

        <Route
          path="/supervisor/attendance"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Attendance />
            </Suspense>
          }
        />

        <Route
          path="/supervisor/add-bank-details"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Addbank />
            </Suspense>
          }
        />

        <Route
          path="/supervisor/edit-bank-details"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Editbank />
            </Suspense>
          }
        />

        <Route
          path="/supervisor/list-calendar"
          element={
            <Suspense>
              <ListCommonCalander />
            </Suspense>
          }
        />
        <Route
          path="/supervisor/list-view-schedule-meeting"
          element={
            <Suspense>
              <ViewMeetingDetails />
            </Suspense>
          }
        />
        <Route
          path="/supervisor/list-view-schedule-interview"
          element={
            <Suspense>
              <ViewInterviewDetails />
            </Suspense>
          }
        />
        <Route
          path="/supervisor/list-view-other-schedule-meeting"
          element={
            <Suspense>
              <ViewOtherMeetingDetails />
            </Suspense>
          }
        />

        <Route
          path="/supervisor/schedule-other-meeting"
          element={
            <Suspense>
              <ScheduleOtherMeeting />
            </Suspense>
          }
        />
        <Route
          path="/supervisor/edit-other-meeting"
          element={
            <Suspense>
              <ScheduleOtherMeeting />
            </Suspense>
          }
        />

        <Route
          path="/supervisor/shift-notes-list"
          element={
            <Suspense>
              <ShiftNoteList />
            </Suspense>
          }
        />

        <Route path="*" element={<Error />} />
      </Route>
    </Routes>
  );
}
