import * as React from 'react';
import dayjs from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Stack from '@mui/material/Stack';

// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import TextField from "@mui/material/TextField";


export default function DatePickerValue() {
    const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const handleChangeDate = (event, newValueDate) => {
    setValue(newValueDate);
  };

  const [datevalue, setDateValue] = React.useState(dayjs('2022-04-17'));

  const [datevalue2, setDateValue2] = React.useState(dayjs('2022-08-15'));



  const [open, setOpen] = React.useState(false);
  const [openDate, setOpenDate] = React.useState(false);

  const [day, setAge] = React.useState('');

  const handleSelect = (event) => {
    setAge(event.target.value);
  };

    return (
           <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3} className="d-flex align-items flex-column-none justify-content-between">
                      <DesktopDatePicker
                        className='form-input date-input-filed'
                        format="DD-MM-YYYY"
                        open={open}
                        onOpen={() => setOpen(true)}
                        onClose={() => {setOpen(false)}}
                        value={datevalue}
                        disableOpenPicker
                        onChange={(newValue) => setDateValue(newValue)}
                        PopperProps={{
                          placement: "bottom-end"

                        }}
                        renderInput={(params) => <TextField size="small" onClick={() => setOpen(true)} sx={{ mt: 2, mb: 2 }}   {...params} inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MM-YY"
                        }} />}
                      />
                      <DesktopDatePicker
                        className='form-input date-input-filed'
                        format="DD-MM-YYYY"
                        open={openDate}
                        onOpen={() => setOpenDate(true)}
                        onClose={() => setOpenDate(false)}
                        value={datevalue2}
                        onChange={(newValueDate) => setDateValue2(newValueDate)}
                        PopperProps={{
                          placement: "bottom"

                        }}
                        disableOpenPicker
                        renderInput={(params) => <TextField size="small" onClick={() => setOpenDate(true)} sx={{ mt: 2, mb: 2 }}   {...params} inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MM-YY"
                        }} />}
                      />

                    </Stack>
                  </LocalizationProvider> 
    );
}