import { getRefreshTokan } from "../api";
import axios from "axios";

// Function to refresh access token using refresh token
const refreshAccessToken = async () => {
  const refreshToken = JSON.parse(
    localStorage.getItem("userData")
  )?.refreshToken;
  try {
    const res = await axios.post(getRefreshTokan, {
      refreshToken: refreshToken,
    });
    if (res.data.success == true) {
      let data = res.data.userData;
      data["token"] = res.data.token;
      data["refreshToken"] = res.data.refreshToken;

      localStorage.setItem("userData", JSON.stringify(data));
      return true; // New access token received from the server
    } else {
      // Handle failed refresh token request
      console.error("Failed to refresh access token");
      return false;
    }
  } catch (error) {
    console.error("Token refresh request failed:", error);
    localStorage.removeItem("userData");

    return false;
  }
};

export default refreshAccessToken;
