import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import WebHeader from "./Web-Header";
import WebFooter from "./Web-footer";
import { Link } from "react-router-dom";
import bannerImage from "../assets/images/get-support-banner.jpg";
import WrokerCards from "./Worker-Cards";
import "../assets/css/dashboard.css";
import "../assets/css/web-css.css";
import FeatureTitleBanner from "../assets/images/get-support-heroimg.jpg";

const GetSupport = () => {
  return (
    <>
      <Box id="getSupport">
        <Box className="web-topBanner-bg  feature-main-banner">
          <WebHeader />

          <Box className="feature-titleBox">
            <Box className="webContainer">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} className="home-content-main ">
                  <Box>
                    <h1>Get Support</h1>
                    <Typography>
                      Lorem ipsum dolor sit amet. Lorem ipsum dolor sit <br />{" "}
                      amet. Lorem ipsum dolor sit amet. Lorem ipsum <br /> dolor
                      sit amet.
                    </Typography>

                    <Box className="d-flex align-items-center">
                      <Link
                        to="/book-demo"
                        className="home-section-button web-primary-btnLarge cta1"
                      >
                        Book A Demo
                      </Link>
                      {/* <Link className="home-section-button cta2">CTA 2</Link> */}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className="featureBannerImg">
                  <Box className="manageCarer-cardImg">
                    <img src={FeatureTitleBanner} />
                  </Box>

                  <Box className="blurBg blurRed"> </Box>
                  <Box className="blurBg blurPurple"> </Box>
                  <Box className="blurBg blurYellow"></Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box>
          {/* <WebHeader /> */}

          {/* <Box className="webContainer">
            <Box className="main-home-section main-banner">
              <Grid container>
                <Grid item xs={12} sm={6} md={6} className="home-content-main">
                  <h1>
                    Find Support <br /> Near You
                  </h1>
                  <Typography>
                    Lorem ipsum dolor sit amet, Lorem ipsum dolor sit ametLorem
                    ipsum <br /> dolor sit ametLorem ipsum dolor sit ametLorem
                    ipsum dolor sit <br /> ametLorem ipsum dolor sit ametLorem
                    ipsum
                  </Typography>

                  <Box className="d-flex align-items-center">
                    <Link className="home-section-button cta1">CTA 1</Link>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  className="d-flex align-items-center justify-content-end"
                >
                  <Box className="home-banner getSupport-banner">
                    <img src={bannerImage} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box> */}

          <Box className="webContainer" sx={{ mt: 3, mb: 5 }}>
            <WrokerCards />
          </Box>

          <WebFooter />
        </Box>
      </Box>
    </>
  );
};

export default GetSupport;
