import React, { useState, useEffect } from "react";
import googlemaps from "../../assets/images/google-maps-svg.png";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

const AutocompleteWrapper = ({
  cl,
  clpredictions,
  predictions1,
  placeId,
  handlePlaceSelect,
  handleCityChange,
  clear,
  setClear,
}) => {
  const [key, setKey] = useState(0);

  useEffect(() => {
    // Update the key when 'cl' changes
    setKey((prevKey) => prevKey + 1);
  }, [cl]);
  console.log("check clear from inside", clear);

  useEffect(() => {
    if (clear) {
      // console.log("check clear placeId", placeId);
      // console.log("check clear clpredictions", clpredictions);
      // console.log("check clear predictions1", predictions1);
      // Update the key when 'clear' is triggered
      setKey((prevKey) => prevKey + 1);
      setClear(false);
    }
  }, [clear]);
  // console.log("check cl", cl);
  // console.log("check clpredictions", clpredictions);
  // console.log("check predictions1", predictions1);
  // console.log("check placeId", placeId);

  return (
    <Autocomplete
      key={key} // Setting a unique key will force a remount when 'key' changes
      disablePortal
      id={`combo-box-demo-kk`}
      size="small"
      limitTags={2}
      options={cl ? clpredictions : predictions1}
      getOptionLabel={(option) => option.description}
      value={
        cl
          ? clpredictions.length > 0
            ? clpredictions.find((option) => option.place_id === placeId)
            : null
          : predictions1.length > 0
          ? predictions1.find((option) => option.place_id === placeId)
          : console.log("check going in else")
      }
      onChange={(event, value) => handlePlaceSelect(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ mt: 2 }}
          onChange={handleCityChange}
          placeholder="Brisbane Airport QLD, Australia"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <img src={googlemaps} alt="googlemaps" />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default AutocompleteWrapper;
