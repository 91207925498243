import React from "react";
import { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Box } from "@mui/material";
import CalenderData from "./CalenderData";
import axios from "../../../api/axios";
import { useEffect } from "react";
import {
  getAllCalendarAvailability,
  getAllLocation,
  SetAttendance,
  UpdateTimeTracking,
} from "../../../api";
import FilterStore from "./store/FilterStore";
// import TopFilter from "./TopFilter";
// import ShiftTypeFilter from "./ShiftTypeFilter";
import { FieldsListLoading } from "../../../pages/components/loading/FieldsListLoading";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// import TextField from "@mui/material/TextField";
import "../../../assets/css/dashboard.css";
import "../../../assets/css/Appointment.css";
import InputAdornment from "@mui/material/InputAdornment";
import Grid from "@mui/material/Grid";
import SearchIcon from "@mui/icons-material/Search";
// import WeeklyTable from "./WeeklyTable";
import generateUrl from "../../../helpers/urlHelper";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Typography } from "@mui/material";
import TimeCardTracking from "./TimeCardTracking";
// import UpdateAvailability from "./UpdateAvailability";
import MyStore from "../../../pages/healthCarer/availability/MyStore";
import noImage from "../../../assets/images/noImage.png";
import Dropdown from "react-bootstrap/Dropdown";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Avatar, Stack } from "@mui/material";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import "./calendarcss.css";
import "./Attendance.css";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const Calendar = (props) => {
  const [events, setEventData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [isAttendanceSetforToday, setIsAttendanceSetforToday] = useState(false);

  const {
    selectedCity,
    ApType2,
    month,
    startDate1,
    endDate1,
    slotType,
    searchTerm,
    selectedShift,
    cityList,
    showMenuItem,
  } = FilterStore();

  //zustand code
  const {
    resetFields,
    setField,
    Weekly,
    NextWeekly,
    Monthly,
    defaultCheck,
    altype,
    selectedDays,
    selectAll,
  } = MyStore();

  const ref = React.createRef();

  let userData = JSON.parse(localStorage.getItem("userData"));
  let userId = userData?._id;
  const urlLink = generateUrl();

  let currentDate = new Date();
  let currentDayOfMonth = currentDate.getDate();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen1, setModalOpen1] = useState(false);
  const [selectedDay, setselectedDay] = useState();
  const [selectedDate, setselectedDate] = useState();
  const [currentActiveDay, setcurrentDay] = useState(currentDayOfMonth);
  const [dayData, setDays] = useState([]);
  const [isActive, setActive] = useState(false);
  const [startTimeSelected, setstartTimeSelected] = useState();
  const [endTimeSelected, setendTimeSelected] = useState();

  const [editlogInTime, seteditlogInTime] = useState();
  const [editlogOutTime, seteditlogOutTime] = useState();
  const [editBreakIn, seteditBreakIn] = useState();
  const [editBreakOut, seteditBreakOut] = useState();
  const [updateAvailabilityFlag, setupdateAvailability] = useState(false);
  const [selectedDateCopy, setselectedDateCopy] = useState();

  useEffect(() => {
    getAllData();
  }, [
    endDate1,
    startDate1,
    selectedStatus,
    ApType2,
    selectedCity,
    searchTerm,
    selectedShift,
  ]);

  const getAllData = () => {
    setIsLoading(true);
    axios
      .get(
        `${getAllCalendarAvailability}?start=${startDate1}&role=${userData?.role}&userId=${userId}&end=${endDate1}&searchTerm=${searchTerm}&city=${selectedCity}`
      )
      .then((res) => {
        setEventData(res?.data?.data);

        const outArr = [];

        res?.data?.data.length > 0 &&
          res?.data?.data.map((val) => {
            let currentDate = new Date(val.extendedProps.date);
            let currentDayOfMonth = currentDate.getDate();

            outArr.push({
              date: val.extendedProps.date,
              day: currentDayOfMonth,
              startTime: val?.extendedProps?.startTime,
              endTime: val?.extendedProps?.endTime,
            });
          });

        setField("Monthly", Monthly);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const goToNextDay = async () => {
    let calendarApi = ref.current.getApi();
    calendarApi.next();
    setField(
      "startDate1",
      calendarApi.currentData.dateProfile.currentRange.start
    );
    setField("endDate1", calendarApi.currentData.dateProfile.currentRange.end);
  };

  const goToPreviousDay = async () => {
    let calendarApi = ref.current.getApi();
    calendarApi.prev();
  };

  const goToDay = async () => {
    let calendarApi = ref.current.getApi();
    calendarApi.today();
    setField(
      "startDate1",
      calendarApi.currentData.dateProfile.currentRange.start
    );
    setField("endDate1", calendarApi.currentData.dateProfile.currentRange.end);
  };

  useEffect(() => {
    const days = getDaysFromCurrentMonth(new Date());
    setDays(days);
  }, []);

  const addAvailabilityPopop = (cellInfo, day, date) => {
    setselectedDay(day);
    setselectedDate(date);
    setModalOpen1(true);
    setupdateAvailability(false);
    SetAttendanceMethod();
    // dateArrayDates
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Adding 1 to month since it's zero-based
    const day1 = ("0" + date.getDate()).slice(-2);

    const dateString = `${year}-${month}-${day1}`;
    const newDateObject = { date: dateString };
    props.attendedDates.push(newDateObject);
    // const lastElement = props.attendedDates[props.attendedDates.length - 1];
    // if (lastElement) {
    //   lastElement.push(dateString);
    // } else {
    //   console.error("Unable to push date string.");
    // }
    // props.attendedDates.date.push(dateString);
  };
  const addPopup = (cellInfo, day, date) => {
    setselectedDay(day);
    setselectedDate(date);
    setModalOpen1(true);
    setupdateAvailability(false);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Adding 1 to month since it's zero-based
    const day1 = ("0" + date.getDate()).slice(-2);

    const dateString = `${year}-${month}-${day1}`; //

    const existingDate = props?.attendedDates.find((d) => {
      const dDate = d.date?.split("T")[0];
      // Compare the extracted dates
      return dDate === dateString;
    });
    if (existingDate) {
      seteditlogInTime(existingDate.logInTime);
      seteditlogOutTime(existingDate.logOutTime);
      seteditBreakIn(existingDate.breakInTime);
      seteditBreakOut(existingDate.breakOutTime);
    }
  };
  const editAvailabilityPopop = (cellInfo, day, date, startTime, endTime) => {
    let currentDate = new Date(date);
    let currentDayOfMonth = currentDate.getDate();
    setselectedDay(currentDayOfMonth);
    setselectedDate(date);
    setModalOpen1(true);
    seteditlogInTime(editlogInTime);
    seteditlogOutTime(endTime);
    seteditBreakIn(startTime);
    seteditBreakOut(endTime);
    setupdateAvailability(true);
  };

  const removeAvailability = (cellInfo, day, date, startTime, endTime) => {
    Swal.fire({
      icon: "warning",
      title: "Delete Availability",
      showCancelButton: true,
      text: `Are you sure you want to delete the Availability?`,
      confirmButtonText: "Yes, proceed it!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        // Add your code to handle availability removal here
        // You can call a function or perform any action you need
        handleAvailabilityRemoval(cellInfo, day, date, startTime, endTime);
        Swal.fire("Deleted!", "Availability has been deleted.", "success");
      } else if (result.isDismissed) {
        Swal.fire("Cancelled", "Availability deletion cancelled.", "info");
      }
    });
  };

  const handleAvailabilityRemoval = (
    cellInfo,
    day,
    date,
    startTime,
    endTime
  ) => {
    let selectedDays = Monthly[props.id]["selectedDays"];

    const selectedDayData = {
      date: date,
      day: day,
      startTime: startTime,
      endTime: endTime,
    };

    if (selectedDays.length > 0) {
      selectedDays = selectedDays.filter((val) => {
        if (val.date === selectedDayData.date) {
          return false;
        } else {
          return true;
        }
      });
    }

    Monthly[props.id]["selectedDays"] = selectedDays;

    setField("Monthly", Monthly);

    const eventData = events.filter((val) => {
      if (val.extendedProps.date === date) {
        return false;
      } else {
        return true;
      }
    });

    setEventData(eventData);

    props?.setAvalabilitys();
  };

  const handleCloseModalAvailability = (e) => {
    e.preventDefault();
    setModalOpen1(false);
  };

  const handleClose = () => {
    setModalOpen1(false);
  };

  const handlePlusButtonClick = (cellInfo, startTime, endTime, date) => {
    setModalOpen(true);
    setstartTimeSelected(startTime);
    setselectedDateCopy(date);
    setendTimeSelected(endTime);
  };

  const handleCloseModal = (e) => {
    e.preventDefault();
    setModalOpen(false);
  };

  function getDaysFromCurrentMonth() {
    const currentDate = new Date();
    const lastDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );

    const daysArray = [];
    for (let day = 1; day <= lastDayOfMonth.getDate(); day++) {
      const currentDay = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        day
      );
      const dayInfo = {
        date: currentDay,
        day: day,
        startTime: props?.selectedShift?.startTime,
        endTime: props?.selectedShift?.endTime,
      };
      daysArray.push(dayInfo);
    }
    return daysArray;
  }

  function getDaysFromCurrentMonth() {
    const currentDate = new Date();
    const lastDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );

    const daysArray = [];
    for (let day = 1; day <= lastDayOfMonth.getDate(); day++) {
      const currentDay = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        day
      );
      const dayInfo = {
        date: currentDay,
        day: day,
        startTime: props?.selectedShift?.startTime,
        endTime: props?.selectedShift?.endTime,
      };
      daysArray.push(dayInfo);
    }
    return daysArray;
  }

  const handleToggleMenuItem = (e, id) => {
    for (const key in showMenuItem) {
      if (key !== id) {
        showMenuItem[key] = false;
      }
    }
    setField("showMenuItem", {
      ...showMenuItem,
      [id]: !showMenuItem[id],
    });
  };

  const toggleClass = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    if (props.defaultCheck === true) {
      const arr = [];

      dayData.map((val) => {
        if (val.day >= currentActiveDay) {
          arr.push({
            start: val.date,
            end: val.date,
            title: "Shiva",
            extendedProps: {
              address: "",
              date: val.date,
              dob: "",
              employeeId: "",
              endTime: props?.selectedShift?.endTime,
              fullName: "",
              gender: "",
              healthCarerId: "",
              jobtitles: "",
              jobtypes: "",
              shiftId: props.id,
              shiftName: "",
              slotId: "",
              day: val.day,
              startTime: props?.selectedShift?.startTime,
              status: "",
              userType: "",
              _id: "",
            },
          });
        }
      });

      setEventData((prevData) => [...prevData, ...arr]);
      // Monthly[props.id]["selectedDays"] = dayData;
      // setField("Monthly", Monthly);
    } else {
      getAllData();
      // Monthly[props.id]["selectedDays"] = [];
      // setField("Monthly", Monthly);
      // setField("selectedDays", []);
    }
  }, [Monthly, props.defaultCheck]);

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckBoxClick = (e) => {
    setIsChecked(!isChecked);
    // Additional logic if needed
  };
  const checkIsAttendance = (date, cellInfo) => {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Adding 1 to month since it's zero-based
    const day = ("0" + date.getDate()).slice(-2);

    const dateString = `${year}-${month}-${day}`; //

    // const dateString = date.toISOString().split("T")[0];

    const dateArrayDates = props?.attendedDates?.map(
      (dateTimeString) => dateTimeString?.date.split("T")[0]
    );
    if (dateArrayDates) {
      const isInDateArray = dateArrayDates.includes(dateString);

      setIsAttendanceSetforToday(isInDateArray);
      return isInDateArray;
    } else return null;
  };
  const renderDayCellContent = (cellInfo) => {
    return (
      <>
        <div>
          {cellInfo.dayNumberText}
          {!cellInfo.isToday ? (
            cellInfo.isFuture ? (
              ""
            ) : checkIsAttendance(cellInfo.date, cellInfo) ? (
              <CheckBoxIcon
                className="calender-add-icon-newcolor"
                onClick={(e) => {
                  // addPopup(cellInfo, cellInfo?.dayNumberText, cellInfo?.date);
                }}
              />
            ) : (
              <CheckBoxOutlineBlankIcon
                className="calender-add-icon-newcolor"
                onClick={(e) => {
                  // old dates
                  // addAvailabilityPopop(
                  //   cellInfo,
                  //   cellInfo?.dayNumberText,
                  //   cellInfo?.date
                  // );
                }}
              />
            )
          ) : checkIsAttendance(cellInfo.date, cellInfo) ? (
            <CheckBoxIcon
              className="calender-add-icon-newcolor"
              onClick={(e) => {
                addPopup(cellInfo, cellInfo?.dayNumberText, cellInfo?.date);
              }}
            />
          ) : (
            <CheckBoxOutlineBlankIcon
              className="calender-add-icon-newcolor"
              onClick={(e) => {
                addAvailabilityPopop(
                  cellInfo,
                  cellInfo?.dayNumberText,
                  cellInfo?.date
                );
              }}
            />
          )}
          &nbsp;
        </div>
      </>
    );
  };

  const renderEventContent = (eventInfo) => {
    const eventDate = new Date(eventInfo?.event?.extendedProps?.date);
    const currentDate = new Date();
    eventDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    return (
      <>
        <div>
          <div
            id="#health-carer-calender"
            className="d-flex align-items-start calender-view-box justify-content-between"
          >
            <Dropdown
              autoClose="outside"
              show={showMenuItem[eventInfo?.event?.extendedProps?.slotId]}
            >
              <Dropdown.Toggle
                id="dropdown-autoclose-outside"
                className="calender-view"
                onClick={(e) =>
                  handleToggleMenuItem(
                    e,
                    eventInfo?.event?.extendedProps?.slotId
                  )
                }
              >
                <div
                  onClick={toggleClass}
                  className={`calender-client-cell calendar-top-title calender-view-gray`}
                >
                  <div>
                    <Box>
                      <Stack direction="row" spacing={1}>
                        <Avatar
                          alt="Remy Sharp"
                          src={
                            eventInfo?.event?.extendedProps?.image?.location !==
                              undefined
                              ? eventInfo?.event?.extendedProps?.image?.location
                              : noImage
                          }
                          sx={{ width: 24, height: 24 }}
                        />

                        <ContentCopyIcon
                          className="calender-add-icon-newcolor"
                          onClick={(cellInfo) =>
                            handlePlusButtonClick(
                              cellInfo,
                              eventInfo?.event?.extendedProps?.startTime,
                              eventInfo?.event?.extendedProps?.endTime,
                              eventInfo?.event?.extendedProps?.date
                            )
                          }
                        />

                        {eventDate >= currentDate ? (
                          <>
                            <EditIcon
                              className="calender-add-icon-newcolor"
                              onClick={(cellInfo) =>
                                editAvailabilityPopop(
                                  cellInfo,
                                  eventInfo?.event?.extendedProps?.day,
                                  eventInfo?.event?.extendedProps?.date,
                                  eventInfo?.event?.extendedProps?.startTime,
                                  eventInfo?.event?.extendedProps?.endTime
                                )
                              }
                            />

                            <DeleteIcon
                              className="calender-add-icon-newcolor"
                              onClick={(cellInfo) =>
                                removeAvailability(
                                  cellInfo,
                                  eventInfo?.event?.extendedProps?.day,
                                  eventInfo?.event?.extendedProps?.date,
                                  eventInfo?.event?.extendedProps?.startTime,
                                  eventInfo?.event?.extendedProps?.endTime
                                )
                              }
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </Stack>
                    </Box>
                  </div>

                  <div className="d-flex align-items-center justify-content-between">
                    <span className="d-flex align-items-center">
                      {eventInfo?.event?.extendedProps?.shiftName ===
                        "Sleepover"
                        ? eventInfo?.event?.extendedProps?.shiftName
                        : eventInfo?.event?.extendedProps?.startTime +
                        "-" +
                        eventInfo?.event?.extendedProps?.endTime}
                    </span>
                  </div>
                </div>
              </Dropdown.Toggle>
            </Dropdown>
          </div>
        </div>
      </>
    );
  };
  const addTracking = (
    inputText,
    logInTime,
    breakInTime,
    breakOutTime,
    logOutTime
  ) => {
    const Data = {
      userId: userId,
      date: new Date(),
      logInTime,
      breakInTime,
      breakOutTime,
      logOutTime,
    };

    axios
      .put(UpdateTimeTracking, Data)
      .then((res) => {
        Swal.fire(
          "Time Card!",
          "Today's time card has been successfully saved!",
          "success"
        );
        setModalOpen1(false);
        setIsAttendanceSetforToday(true);
        seteditlogInTime(logInTime);
        seteditBreakIn(breakInTime);
        seteditBreakOut(breakOutTime);
        seteditlogOutTime(logOutTime);
        props.getaList()

        const data = res?.data?.data?.map((val) => {
          return val;
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const SetAttendanceMethod = async () => {
    const postData = {
      userId: userId,
      date: new Date(),
    };

    axios
      .post(SetAttendance, postData)
      .then((res) => {
        // Swal.fire("Attendance Marked!", "Today Attendance marked", "success");
        setIsAttendanceSetforToday(true);

        const data = res?.data?.data?.map((val) => {
          return val;
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Box
        id="attendance"
        className={isAttendanceSetforToday ? "attended" : ""}
      >
        <div id="calenderal" className="padding-right-20 padding-left-20 mt-3">
          <div className="demo-app-main">
            <FullCalendar
              className="attendanceb"
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: "next",
                center: "title",
                right: "prev",
              }}
              ref={ref}
              // events={events}
              initialView="dayGridMonth"
              weekends={true}
              initialDate={
                startDate1 === null ||
                  startDate1 === "" ||
                  startDate1 === undefined
                  ? new Date()
                  : new Date(startDate1)
              }
              // events={events}
              dayMaxEvents={1}
              customButtons={{
                prev: { click: () => goToPreviousDay() },
                next: { click: () => goToNextDay() },
                today: { text: "today", click: () => goToDay() },
              }}
              dayCellContent={renderDayCellContent}
              eventContent={renderEventContent} // custom render function
            />
          </div>

          {/* {isLoading && <FieldsListLoading />} */}

          <Dialog
            open={modalOpen1}
            onClose={handleCloseModalAvailability}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                className="modal-content"
              >
                <Typography>Clock-In, Clock-Out, and Break Tracking</Typography>
                <Typography>
                  {new Date().toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })}
                </Typography>

                <Box className="item">
                  <TimeCardTracking
                    handleClose={handleCloseModalAvailability}
                    addTracking={addTracking}
                    editlogInTime={editlogInTime}
                    editlogOutTime={editlogOutTime}
                    editBreakIn={editBreakIn}
                    editBreakOut={editBreakOut}
                    selectedDate={selectedDate}

                  />
                </Box>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </div>
      </Box>
    </>
  );
};

export default Calendar;
