// store.js
import { configureStore } from '@reduxjs/toolkit';
// import counterReducer from './reducer/counterSlice';
import counterReducer from "./reducer/CounterSlice"
import statusChangeReducer  from './reducer/StatusChangeSlice';

export const store = configureStore({
  reducer: {
    status: statusChangeReducer,
    // Add your reducers here
  },
});
