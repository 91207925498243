import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, Grid } from "@mui/material";
import pricingIcon from "../../assets/images/pricing-tick-icon.png";
import standardImg from "../../assets/images/pricing-card-topchip.png";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function PricingTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box id="PricingTab" sx={{ width: "100%" }} className="position-relative">
      <Grid container>
        <Grid item xs={12} sm={10} md={10} className="m-auto">
          <Box className="home-tab-wrapper" >
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              
            >
              <Tab
                className="home-tabs-item"
                label="Monthly"
                {...a11yProps(0)}
              />
              <Tab
                className="home-tabs-item"
                label="Half Yearly"
                {...a11yProps(1)}
              />
              <Tab
                className="home-tabs-item"
                label="Annually"
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>
        </Grid>
      </Grid>
      <Box className="space-pt-60">
        <CustomTabPanel value={value} index={0}>
          <Box className="Pricing-Card-main">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box className="pricing-card">
                  <Box className="text-center space-mb-24">
                    <Typography>7-Day Trial</Typography>

                    <h1>Free</h1>
                  </Box>
                  <Box className="pricing-points">
                    <ul>
                      <li>
                        <img src={pricingIcon} />
                        Dashboard
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Meetings
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Notifications
                      </li>
                    </ul>
                  </Box>

                  <Box className="text-center d-flex justify-content-center">
                    <Button className="pricing-card-btn">
                      Start your Free Trial
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box className="pricing-card">
                  <Box className="text-center space-mb-24">
                    <Typography>Basic</Typography>

                    <Box className="d-flex justify-content-center align-items-center">
                      <h1>$29</h1>
                      <span>/ Month</span>
                    </Box>
                  </Box>
                  <Box className="pricing-points">
                    <ul>
                      <li>
                        <img src={pricingIcon} />
                        Invoice Generation
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Bulk upload
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Payment reconciliation
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Monitor NDIS budgets
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Notifications
                      </li>
                    </ul>
                  </Box>

                  <Box className="text-center  d-flex justify-content-center">
                    <Button className="pricing-card-btn">Get Started</Button>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box className="pricing-card position-relative">
                  <Box className="standard-chip">
                    <img src={standardImg} />
                    <span>Most Popular</span>
                  </Box>
                  <Box className="text-center space-mb-24">
                    <Typography>Standard</Typography>

                    <Box className="d-flex justify-content-center align-items-center">
                      <h1>$45</h1>
                      <span>/ Month</span>
                    </Box>
                  </Box>
                  <Box className="pricing-points">
                    <ul>
                      <li>
                        <img src={pricingIcon} />
                        Invoice Generation
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Bulk upload
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Payment reconciliation
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Monitor NDIS budgets
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Utilisation tracking & alerts
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Notifications
                      </li>
                    </ul>
                  </Box>

                  <Box className="text-center d-flex justify-content-center">
                    <Button className="pricing-card-btn">Get Started</Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box className="pricing-card">
                  <Box className="text-center space-mb-24">
                    <Typography>Advanced</Typography>

                    <Box className="d-flex justify-content-center align-items-center">
                      <h1>$90</h1>
                      <span>/ Month</span>
                    </Box>
                  </Box>
                  <Box className="pricing-points">
                    <ul>
                      <li>
                        <img src={pricingIcon} />
                        Invoice Generation
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Bulk upload
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Payment reconciliation
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Monitor NDIS budgets
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Utilisation tracking & alerts
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Notifications
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Invoicing, Payroll
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Import + export data
                      </li>
                    </ul>
                  </Box>

                  <Box className="text-center d-flex justify-content-center">
                    <Button className="pricing-card-btn">Get Started</Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Box className="Pricing-Card-main">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box className="pricing-card">
                  <Box className="text-center space-mb-24">
                    <Typography>7-Day Trial</Typography>

                    <h1>Free</h1>
                  </Box>
                  <Box className="pricing-points">
                    <ul>
                      <li>
                        <img src={pricingIcon} />
                        Dashboard
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Meetings
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Notifications
                      </li>
                    </ul>
                  </Box>

                  <Box className="text-center  d-flex justify-content-center">
                    <Button className="pricing-card-btn">
                      Start your Free Trial
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box className="pricing-card">
                  <Box className="text-center space-mb-24">
                    <Typography>Basic</Typography>

                    <Box className="d-flex justify-content-center align-items-center">
                      <h1>$29</h1>
                      <span>/ Month</span>
                    </Box>
                  </Box>
                  <Box className="pricing-points">
                    <ul>
                      <li>
                        <img src={pricingIcon} />
                        Invoice Generation
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Bulk upload
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Payment reconciliation
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Monitor NDIS budgets
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Notifications
                      </li>
                    </ul>
                  </Box>

                  <Box className="text-center d-flex justify-content-center">
                    <Button className="pricing-card-btn">Get Started</Button>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box className="pricing-card position-relative">
                  <Box className="standard-chip">
                    <img src={standardImg} />
                    <span>Most Popular</span>
                  </Box>
                  <Box className="text-center space-mb-24">
                    <Typography>Standard</Typography>

                    <Box className="d-flex justify-content-center align-items-center">
                      <h1>$45</h1>
                      <span>/ Month</span>
                    </Box>
                  </Box>
                  <Box className="pricing-points">
                    <ul>
                      <li>
                        <img src={pricingIcon} />
                        Invoice Generation
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Bulk upload
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Payment reconciliation
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Monitor NDIS budgets
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Utilisation tracking & alerts
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Notifications
                      </li>
                    </ul>
                  </Box>

                  <Box className="text-center d-flex justify-content-center">
                    <Button className="pricing-card-btn">Get Started</Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box className="pricing-card">
                  <Box className="text-center space-mb-24">
                    <Typography>Advanced</Typography>

                    <Box className="d-flex justify-content-center align-items-center">
                      <h1>$90</h1>
                      <span>/ Month</span>
                    </Box>
                  </Box>
                  <Box className="pricing-points">
                    <ul>
                      <li>
                        <img src={pricingIcon} />
                        Invoice Generation
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Bulk upload
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Payment reconciliation
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Monitor NDIS budgets
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Utilisation tracking & alerts
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Notifications
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Invoicing, Payroll
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Import + export data
                      </li>
                    </ul>
                  </Box>

                  <Box className="text-center d-flex justify-content-center">
                    <Button className="pricing-card-btn">Get Started</Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Box className="Pricing-Card-main">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box className="pricing-card">
                  <Box className="text-center space-mb-24">
                    <Typography>7-Day Trial</Typography>

                    <h1>Free</h1>
                  </Box>
                  <Box className="pricing-points">
                    <ul>
                      <li>
                        <img src={pricingIcon} />
                        Dashboard
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Meetings
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Notifications
                      </li>
                    </ul>
                  </Box>

                  <Box className="text-center">
                    <Button className="pricing-card-btn">
                      Start your Free Trial
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box className="pricing-card">
                  <Box className="text-center space-mb-24">
                    <Typography>Basic</Typography>

                    <Box className="d-flex justify-content-center align-items-center">
                      <h1>$29</h1>
                      <span>/ Month</span>
                    </Box>
                  </Box>
                  <Box className="pricing-points">
                    <ul>
                      <li>
                        <img src={pricingIcon} />
                        Invoice Generation
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Bulk upload
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Payment reconciliation
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Monitor NDIS budgets
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Notifications
                      </li>
                    </ul>
                  </Box>

                  <Box className="text-center d-flex justify-content-center">
                    <Button className="pricing-card-btn">Get Started</Button>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box className="pricing-card position-relative">
                  <Box className="standard-chip">
                    <img src={standardImg} />
                    <span>Most Popular</span>
                  </Box>
                  <Box className="text-center space-mb-24">
                    <Typography>Standard</Typography>

                    <Box className="d-flex justify-content-center align-items-center">
                      <h1>$45</h1>
                      <span>/ Month</span>
                    </Box>
                  </Box>
                  <Box className="pricing-points">
                    <ul>
                      <li>
                        <img src={pricingIcon} />
                        Invoice Generation
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Bulk upload
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Payment reconciliation
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Monitor NDIS budgets
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Utilisation tracking & alerts
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Notifications
                      </li>
                    </ul>
                  </Box>

                  <Box className="text-center d-flex justify-content-center align-items-center">
                    <Button className="pricing-card-btn">Get Started</Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box className="pricing-card">
                  <Box className="text-center space-mb-24">
                    <Typography>Advanced</Typography>

                    <Box className="d-flex justify-content-center align-items-center">
                      <h1>$90</h1>
                      <span>/ Month</span>
                    </Box>
                  </Box>
                  <Box className="pricing-points">
                    <ul>
                      <li>
                        <img src={pricingIcon} />
                        Invoice Generation
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Bulk upload
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Payment reconciliation
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Monitor NDIS budgets
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Utilisation tracking & alerts
                      </li>
                      <li>  
                        <img src={pricingIcon} />
                        Notifications
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Invoicing, Payroll
                      </li>
                      <li>
                        <img src={pricingIcon} />
                        Import + export data
                      </li>
                    </ul>
                  </Box>

                  <Box className="text-center d-flex justify-content-center align-items-center">
                    <Button className="pricing-card-btn">Get Started</Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CustomTabPanel>
      </Box>
    </Box>
  );
}
