import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import { Button, Stack, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import { FieldsListLoading } from "../pages/components/loading/FieldsListLoading";
import { toast } from "react-toastify";
import "../App.css";
import "../assets/css/dashboard.css";
import "../assets/css/custom.css";
import googlemaps from "../assets/images/google-maps-svg.png";
import Card from "@mui/material/Card";
import noImage from "../assets/images/noImage.png";
import Autocomplete from "@mui/material/Autocomplete";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import { useLocation, useNavigate } from "react-router-dom";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import GradeIcon from "@mui/icons-material/Grade";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Request from "../assets/images/request.png";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import "../assets/css/web-css.css";
import mapLocationIcon from "../assets/images/google-maps-svg.png";
import sleepoverImage from "../assets/images/sleep-icon.png";
import downArrow from "../assets/images/down-arrow.png";
import Filters from "../components/TopFilter/Filters";
import FilterStore from "../components/TopFilter/FilterStore";
import SearchIcon from "@mui/icons-material/Search";
import {
  Emlimit,
  listAllHCExploreMarket,
  getState,
  addToFavorites,
  removeToFavorites,
  addSendRequest,
  cancelsendRequest,
  addClientToFavorites,
  removeClientToFavorites,
  AustraliaId,
} from "../api";
import AvailabilityPopup from "../components/Explore-Market/Availability-popup";
import { Rating } from "@mui/material";

export default function WorkerCard() {
  const {
    state,
    city,
    maxDistance,
    isCleared,
    searchTerm,
    setField,
    resetFields,
    searchFlag,
    showFavorites,
  } = FilterStore();
  const [statusItem, setStatusItem] = useState(null);

  // const [chip, setChip] = useState(false);
  const [expanded, setExpanded] = useState(false);
  // const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [listData, setListData] = useState([]);
  const [count, setCount] = useState("");
  const [pageCount, setpageCount] = useState(0);
  const [currentPageVal, setCurrentPageVal] = useState(1);
  const [stateListData, setStateListData] = useState([]);
  const [favourite, setFavourite] = useState([]);
  const [requestSent, setRequestSent] = useState([]);
  const [sendRequestData, setSendRequestData] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [stateId, setStateId] = useState("");
  const navigate = useNavigate();

  let userData = JSON.parse(localStorage.getItem("userData"));
  let userId = userData?._id;
  let role = userData?.role;
  let exploreMarketDetails = JSON.parse(localStorage.getItem("exploreMarket"));
  const location = useLocation();

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const searchItems = (searchValue) => {
    setField("searchTerm", searchValue);
  };

  useEffect(() => {
    // Update currentLocation to null when isCleared becomes true
    if (isCleared === true) {
      setCurrentLocation(null);
    }
  }, [isCleared]);

  const InitialStateList = async (apiUrl) => {
    let result = [];
    const response = await axios.get(apiUrl);
    if (response?.status == 200 && response?.statusText == "OK") {
      const res = response?.data;
      if (res?.success) {
        result.push({
          label: `Select`,
          id: "",
        });
        res?.data?.map((d) => {
          result.push({
            label: `${d?.StateName}`,
            id: d?._id,
          });
        });
      }
    }

    setStateListData(result);
  };

  useEffect(() => {
    //  getUserLocation();
  }, []);

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log("latitude", latitude, "longitude", longitude);
          setCurrentLocation({ latitude, longitude });
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by your browser.");
    }
  };

  useEffect(() => {
    if (AustraliaId) {
      const apiUrl = `${getState}/${AustraliaId}`;
      InitialStateList(apiUrl);
    }
  }, [AustraliaId]);

  const getAllFilterData = () => {
    getaList();
  };

  const getAllFilterDataClear = () => {
    setLoading(true);
    setField("city", "");
    setField("maxDistance", "");

    if (resetFields()) {
      getaList1();
    }
  };

  useEffect(() => {
    getaList();
  }, [
    role,
    Emlimit,
    searchTerm,
    // state,
    // city,
    // maxDistance,
    isCleared,
    currentLocation,
  ]);

  const getaList = async () => {
    setLoading(true);
    try {
      let apiUrl;
      if (!city) {
        apiUrl = `${listAllHCExploreMarket}?searchTerm=${encodeURIComponent(
          searchTerm
        )}&page=1&items_per_page=${Emlimit}&lat=${
          currentLocation?.latitude
        }&lng=${currentLocation?.longitude}&maxDistance=${maxDistance}`;
      } else {
        apiUrl = `${listAllHCExploreMarket}?searchTerm=${encodeURIComponent(
          searchTerm
        )}&page=1&items_per_page=${Emlimit}&lat=${city.latitude}&lng=${
          city.longitude
        }&maxDistance=${maxDistance}`;
      }

      const res = await axios.get(apiUrl);
      setListData(res?.data?.data?.docs);
      let dataval = res?.data?.data;
      let total = res?.data?.data?.totalDocs;
      setpageCount(Math.ceil(total / Emlimit));
      setCount(total);
      setLoading(false);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getaList1 = async () => {
    setLoading(true);
    try {
      let apiUrl;
      if (!city) {
        apiUrl = `${listAllHCExploreMarket}?page=1&items_per_page=${Emlimit}`;
      } else {
        apiUrl = `${listAllHCExploreMarket}?page=1&items_per_page=${Emlimit}`;
      }

      const res = await axios.get(apiUrl);
      setListData(res?.data?.data?.docs);
      let dataval = res?.data?.data;
      let total = res?.data?.data?.totalDocs;
      setpageCount(Math.ceil(total / Emlimit));
      setCount(total);
      setLoading(false);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchPaginationList = async (currentPage) => {
    setLoading(true);
    try {
      let apiUrl;
      if (!city) {
        apiUrl = `${listAllHCExploreMarket}?searchTerm=${encodeURIComponent(
          searchTerm
        )}&page=${currentPage}&items_per_page=${Emlimit}&lat=${
          currentLocation?.latitude
        }&lng=${currentLocation?.longitude}&maxDistance=${maxDistance}`;
      } else {
        apiUrl = `${listAllHCExploreMarket}?searchTerm=${encodeURIComponent(
          searchTerm
        )}&page=${currentPage}&items_per_page=${Emlimit}&lat=${
          city.latitude
        }&lng=${city.longitude}&maxDistance=${maxDistance}`;
      }

      const res = await axios.get(apiUrl);
      const op = res?.data?.data?.docs;
      return op;
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    const commentsFormServer = await fetchPaginationList(currentPage);
    setListData(commentsFormServer);
    setCurrentPageVal(currentPage);
    setLoading(false);
  };

  const itemsPerRow = 3;
  const setAddressStateFunction = async (state) => {
    if (state) {
      setStateId(state?.id);
    }
  };

  const addFavoritesHC = async (hcId) => {
    try {
      if (role === "client") {
        let payload = {
          favoriteHc: hcId,
        };
        console.log("payload", payload);

        axios
          .put(`${addToFavorites}/${userId}`, payload)
          .then((res) => {
            console.log("res", res);
            if (res.data.status) {
              toast.success(res.data.message);
              // getClientDetails();
            }
          })
          .catch((error) => {
            toast.error("Something went wrong");
          });
      } else if (role === "healthCarer") {
        let payload = {
          favoriteClient: hcId,
        };
        console.log("payload", payload);
        axios
          .put(`${addClientToFavorites}/${userId}`, payload)
          .then((res) => {
            console.log("res", res);
            toast.success(res.data.message);
            // getHCDetails();
          })
          .catch((error) => {
            toast.error("Something went wrong");
          });
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const removeFavoritesHC = async (hcId) => {
    try {
      if (role === "client") {
        console.log("removeFavoritesHC hello");
        console.log("hcId", hcId);
        let payload = {
          favoriteHc: hcId,
        };
        console.log("payload", payload);

        axios
          .put(`${removeToFavorites}/${userId}`, payload)
          .then((res) => {
            console.log("res", res);
            toast.success(res.data.message);
            // getClientDetails();
          })
          .catch((error) => {
            toast.error("Something went wrong");
          });
      } else if (role == "healthCarer") {
        console.log("removeFavoritesHC hello");
        console.log("hcId", hcId);
        let payload = {
          favoriteClient: hcId,
        };
        console.log("payload", payload);
        axios
          .put(`${removeClientToFavorites}/${userId}`, payload)
          .then((res) => {
            console.log("res", res);
            toast.success(res.data.message);
            // getHCDetails();
          })
          .catch((error) => {
            toast.error("Something went wrong");
          });
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const addRequestFunction = async (hcId) => {
    try {
      let payload = {
        sendBy: userData?._id,
        sendTo: hcId,
        createdBy: userData?._id,
        updatedBy: userData?._id,
      };
      console.log("payload", payload);
      axios
        .post(`${addSendRequest}`, payload)
        .then((res) => {
          toast.success(res.data.message);
          // getSendRequestDetails();
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const removeRequestFunction = async (hcId) => {
    try {
      axios
        .put(`${cancelsendRequest}/${hcId}`)
        .then((res) => {
          toast.success(res.data.message);
          // getSendRequestDetails();
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const RedirectDirectFunction = async (hcId) => {
    try {
      setTimeout(() => {
        navigateAgreement("1", hcId);
      }, 1000);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const navigateAgreement = (event, newValue) => {
    let exploreMarket = JSON.parse(localStorage.getItem("exploreMarket")) || {};
    exploreMarket.hcId = newValue;
    localStorage.setItem("exploreMarket", JSON.stringify(exploreMarket));

    navigate("/existing-user", {
      state: newValue,
    });
  };

  let totalCompleted = 0;
  let recentCompleted = 0;

  function isRecent(startTime) {
    // Assuming a recent startTime is within the last 7 days
    const startTimeDate = new Date(startTime);
    const currentDate = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(currentDate.getDate() - 7);

    return startTimeDate >= sevenDaysAgo;
  }

  const [showMore, setShowMore] = useState({});
  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpen = (id) => {
    setShowMore((prevTaskShow) => ({
      ...prevTaskShow,
      [id]: true,
    }));
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const calculateAverageRating = (ratings) => {
    console.log(ratings, "check 452");
    if (!ratings || ratings.length === 0) return 0;

    const sum = ratings.reduce((acc, rating) => acc + rating.ratingPoint, 0);
    console.log(sum, "check 456");
    return sum / ratings.length;
  };

  return (
    <Box className="w-100 worker-card">
      <Box>
        <Grid container className="d-flex header-topsection">
          <Grid item xs={6} sm={6} md={6}>
            <Box id="breadcrumb" className="p-0">
              <Box className="breadcrumb-inr">
                <Box className="breadcrumbs">
                  <span className="breadcrumbs-link">Marketplace</span>{" "}
                  <span className="breadcrumbs-icon">{">"}</span>
                  <Link className="breadcrumbs-link breadcrumbs-link-active">
                    Get Support
                  </Link>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={2}>
        <Grid item md={9} sm={12} xs={12}>
          <Box
            // container
            // spacing={2}
            className="get-support-filter"
            sx={{ mt: 2, ml: 0.5 }}
            style={{ paddingLeft: "1%" }}
          >
            <Filters
              getAllFilterData={getAllFilterData}
              getAllFilterDataClear={getAllFilterDataClear}
            />
          </Box>
        </Grid>

        <Grid item md={3} sm={6} xs={12}>
          <Box className="agency-searchbar get-support-search searchbox-margin-new">
            {/* <Grid
  container
  spacing={2}
  className="align-items-center justify-content-between"
> */}
            {/* <Grid item xs={6} sm={4} md={4} style={{ paddingLeft: "0px" }}> */}

            <TextField
              className="export-market-filters search-appointment "
              type="search"
              size="small"
              //name="email"
              fullWidth
              placeholder="Search Here"
              value={searchTerm}
              onChange={(e) => searchItems(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />

            {/* </Grid> */}

            {/* </Grid> */}
          </Box>
        </Grid>
      </Grid>

      {searchFlag && (
        <>
          {city !== undefined && city !== null && city !== "" ? (
            <Box style={{ paddingLeft: "2%" }}>
              <Typography className="worker-cards-main-heading">
                {`${count} ${"Health Carer"} found in ${city?.description}`}
              </Typography>
            </Box>
          ) : null}
        </>
      )}

      {listData?.length !== 0 ? (
        <Grid container mt={5}>
          <Grid container spacing={2} className="explore-market-cards">
            {listData?.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} className="input pt-0 mt-4">
                <Card style={{ width: "100%" }} className="worker-card-main">
                  <CardHeader
                    className="woker-card-header"
                    avatar={
                      <Avatar aria-label="recipe" className="explore-icon">
                        {item?.isAvtarActivate === true ? (
                          <img
                            src={
                              item?.avtarImage !== null &&
                              item?.avtarImage !== undefined
                                ? item?.avtarImage?.location
                                : noImage
                            }
                            alt="explore.png"
                          />
                        ) : (
                          <img
                            src={
                              item?.profileImage !== null &&
                              item?.profileImage !== undefined
                                ? item?.profileImage?.location
                                : noImage
                            }
                            alt="explore.png"
                          />
                        )}
                      </Avatar>
                    }
                    title={
                      <>
                        <Box className="d-flex align-items-start justify-content-between">
                          <Stack direction="column">
                            <Typography
                              component="div"
                              className="worker-card-name"
                            >
                              {" "}
                              {item?.fullName !== undefined &&
                              item?.fullName !== null
                                ? item?.fullName
                                : "NA"}
                            </Typography>
                            <Tooltip title="Rating">
                              <Box
                                // className="worker-card-rating"
                                display="flex"
                                // alignItems="baseline" // Align baseline for proper alignment
                              >
                                <Rating
                                  value={calculateAverageRating(item?.hcRating)}
                                  precision={0.5}
                                  readOnly
                                  sx={{ fontSize: "1rem" }} // Adjust the font size of the stars
                                />
                                <Typography
                                  component="div"
                                  className="rating-text wp-no"
                                  sx={{ marginLeft: "0.5rem" }}
                                >
                                  {`Rated By ${item?.hcRating.length} Users`}
                                </Typography>
                              </Box>
                            </Tooltip>
                          </Stack>

                          <Stack direction="row">
                            <Tooltip title="Location">
                              <img
                                src={mapLocationIcon}
                                className="worker-map-icon"
                              />
                            </Tooltip>
                            <Typography
                              variant="body2"
                              className="card-location-text"
                              component="div"
                            >
                              {" "}
                              {item?.city !== undefined && item?.city !== null
                                ? item?.city
                                : "NA"}
                              {","}
                              {item?.state !== undefined && item?.state !== null
                                ? item?.state
                                : "NA"}
                            </Typography>
                          </Stack>
                        </Box>
                      </>
                    }
                    subheader={
                      <>
                        <Typography component="div">
                          {" "}
                          {item?.jobTitles !== undefined &&
                          item?.jobTitles !== null
                            ? item?.jobTitles
                            : "NA"}{" "}
                        </Typography>

                        <Box mt={1}>
                          <Stack
                            spacing={2}
                            direction={{
                              xs: "column",
                              sm: "column",
                              lg: "row",
                            }}
                          >
                            <Stack direction="row">
                              <Tooltip title="Experience">
                                <BusinessCenterIcon
                                  className="worker-card-explore-icon"
                                  sx={{ marginTop: "-3px" }}
                                />
                              </Tooltip>
                              <Typography variant="body2" component="div">
                                {" "}
                                {item?.experience !== undefined &&
                                item?.experience !== null &&
                                item?.experience !== "undefined"
                                  ? item?.experience
                                  : "NA"}{" "}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Box>
                      </>
                    }
                  />

                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item md={12}>
                        <div className="d-flex flex-wrap">
                          {item?.skills
                            ?.slice(
                              0,
                              expanded ? item?.skills?.length : itemsPerRow
                            )
                            .map((data, index) => (
                              <Typography
                                key={index}
                                variant="body2"
                                component="div"
                                className="marketcard-content"
                                style={{
                                  marginRight: "16px",
                                  marginBottom: "10px",
                                }}
                              >
                                {data}
                              </Typography>
                            ))}
                          {item?.skills?.length > itemsPerRow && (
                            <Button
                              onClick={toggleExpand}
                              variant="text"
                              className="more-btn"
                            >
                              {expanded ? "Show Less" : "More..."}
                            </Button>
                          )}
                        </div>
                      </Grid>
                      {userData !== undefined && userData !== null ? (
                        <>
                          {/* <Grid item md={12}>
                          <Typography variant="body2" component="div">
                            {" "}
                            Available For:{" "}
                            <span>
                              {" "}
                              Sat, Sun, Mon -{" "}
                              <img
                                className="card-availability-icon"
                                src={sleepoverImage}
                              />{" "}
                              12:00 to 6:00{" "}
                            </span>{" "}
                          </Typography>
                        </Grid> */}
                          <Grid item md={12}>
                            {item?.Weekly?.length > 0 ||
                            item?.Fortnightly?.length > 0 ? (
                              <>
                                {item?.Weekly?.length > 0 && (
                                  <Typography variant="body2" component="div">
                                    {item?.Weekly?.map((week, index) => {
                                      console.log(
                                        item,
                                        "Item for availability"
                                      );
                                      const weekData = week.week;

                                      const availableDays = Object.keys(
                                        weekData
                                      )
                                        .filter((dayOfWeek) => {
                                          return (
                                            dayOfWeek !==
                                              "sleepoverCheckedDays" &&
                                            Array.isArray(
                                              weekData[dayOfWeek]
                                            ) &&
                                            weekData[dayOfWeek].length > 0 &&
                                            weekData[dayOfWeek][
                                              weekData[dayOfWeek].length - 1
                                            ]?.startTime &&
                                            weekData[dayOfWeek][
                                              weekData[dayOfWeek].length - 1
                                            ]?.endTime
                                          );
                                        })
                                        .map((dayOfWeek) => {
                                          const dayData = weekData[dayOfWeek];

                                          if (dayData.length > 0) {
                                            const day =
                                              dayData[dayData.length - 1];
                                            totalCompleted++; // Increment total completed count
                                            if (isRecent(day.startTime)) {
                                              recentCompleted++; // Increment recent completed count
                                            }
                                            return `${dayOfWeek}`;
                                          }
                                          return null;
                                        })
                                        .filter((dayInfo) => dayInfo !== null)
                                        .join(", ");
                                      return (
                                        <Box>
                                          <span key={index}>
                                            {availableDays && (
                                              <>
                                                {index > 0 && ", "}
                                                {showMore[week?._id]
                                                  ? `Availability: ${availableDays}`
                                                  : `Availability: ${availableDays.slice(
                                                      0,
                                                      15
                                                    )}`}
                                                {availableDays.length > 15 && (
                                                  <span
                                                    onClick={() =>
                                                      handleClickOpen(week?._id)
                                                    }
                                                    // onClick={(e) =>
                                                    //   toggleShowMore(
                                                    //     e,
                                                    //     week?._id,
                                                    //     availableDays
                                                    //   )
                                                    // }
                                                    style={{
                                                      color: "blue",
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {/* {showMore[week?._id]
                                                  ? "...Less"
                                                  : "More..."} */}
                                                    More..
                                                  </span>
                                                )}
                                                {openDialog && (
                                                  <AvailabilityPopup
                                                    open={openDialog}
                                                    content={availableDays.slice(
                                                      15
                                                    )}
                                                    hcId={item?._id}
                                                    handleClose={handleClose}
                                                  />
                                                )}
                                              </>
                                            )}
                                          </span>
                                        </Box>
                                      );
                                    })}
                                  </Typography>
                                )}
                              </>
                            ) : (
                              <Typography variant="body2" component="div">
                                Availability: NA
                              </Typography>
                            )}
                          </Grid>

                          <Grid item md={6}>
                            <Typography variant="body2" component="div">
                              {" "}
                              Completed Shifts:{" "}
                              <span>
                                {" "}
                                {item?.totalCompleted
                                  ? item?.totalCompleted
                                  : 0}{" "}
                              </span>{" "}
                            </Typography>
                          </Grid>

                          <Grid item md={6} className="padding-left-0">
                            <Typography variant="body2" component="div">
                              {" "}
                              Previously Worked:{" "}
                              <span>
                                {" "}
                                {item?.preWorked ? item?.preWorked : 0} Shifts
                              </span>{" "}
                            </Typography>
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </CardContent>

                  <CardActions
                    className="justify-content-between d-flex"
                    sx={{ pb: 2, pl: 2, pr: 2 }}
                  >
                    {userData?._id !== undefined && userData?._id !== null ? (
                      <>
                        {sendRequestData?.map((sendToItem) => {
                          if (sendToItem?.sendTo === item?._id) {
                            return (
                              <div key={sendToItem._id}>
                                <label style={{ color: "orange" }}>
                                  {sendToItem?.status}
                                </label>
                              </div>
                            );
                          }
                          return null; // Return null for items where the condition is not met
                        })}

                        {/* Render the button only if the condition is not met */}
                        {!sendRequestData?.some(
                          (sendToItem) => sendToItem?.sendTo === item?._id
                        ) && (
                          <Button
                            variant="contained"
                            className="res-iconbtn"
                            onClick={() => {
                              addRequestFunction(item?._id);
                            }}
                          >
                            <img src={Request} className="card-send-icon" />
                            Request
                          </Button>
                        )}
                      </>
                    ) : (
                      <>
                        <Link to="" className="explore-view-link">
                          <div
                            onClick={() => RedirectDirectFunction(item?._id)}
                          >
                            <Button variant="contained" className="res-iconbtn">
                              <img src={Request} className="card-send-icon" />
                              Request
                            </Button>
                          </div>
                        </Link>
                      </>
                    )}

                    <Box className="d-flex align-items-center">
                      {" "}
                      {userData?._id !== undefined &&
                      userData?._id !== null &&
                      exploreMarketDetails?.hcId !== undefined &&
                      exploreMarketDetails !== null ? (
                        <Tooltip title="View Details">
                          <Link
                            // to="/client/view-details"
                            to="/view-details"
                            className="explore-view-link"
                            // state={{ hcId: item?._id }}
                            state={{
                              hcId: item?._id,
                              createdBy: item?.createdBy,
                            }}
                          >
                            <RemoveRedEyeIcon className="explore-view-icon" />
                          </Link>
                        </Tooltip>
                      ) : (
                        <Tooltip title="View Details">
                          <Link
                            to="/view-details"
                            className="explore-view-link"
                            state={{
                              hcId: item?._id,
                              createdBy: item?.createdBy,
                            }}
                          >
                            <RemoveRedEyeIcon className="explore-view-icon" />
                          </Link>
                        </Tooltip>
                      )}
                      <>
                        {userData?._id !== undefined &&
                        userData?._id !== null ? (
                          <Tooltip title="Favorite">
                            <Button
                              variant="outlined"
                              className="fav-iconbtn"
                              startIcon={
                                favourite?.includes(item?._id) ? (
                                  <FavoriteIcon style={{ color: "#15A07D" }} />
                                ) : (
                                  <FavoriteBorderOutlinedIcon />
                                )
                              }
                              onClick={() => {
                                if (favourite?.includes(item?._id)) {
                                  removeFavoritesHC(item?._id);
                                } else {
                                  addFavoritesHC(item?._id);
                                }
                              }}
                            >
                              {/* You can include any additional content here */}
                            </Button>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Favorite">
                            <Link to="" className="explore-view-link">
                              <div
                                onClick={() =>
                                  RedirectDirectFunction(item?._id)
                                }
                              >
                                <Button
                                  variant="outlined"
                                  className="fav-iconbtn"
                                  startIcon={<FavoriteBorderOutlinedIcon />}
                                />
                              </div>
                            </Link>
                          </Tooltip>
                        )}
                      </>
                    </Box>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>

          <Grid
            mt={3}
            item
            xs={12}
            sm={12}
            md={12}
            className="input d-flex align-items-center pt-0"
          >
            {/* <Box className="show-result-box d-flex align-items-center ">
            <span>6</span>
            <img src={downArrow} className="" />
          </Box> */}
            <Grid mt={2} item xs={12} sm={12} md={12} className="input pt-0">
              <div className="record-page">
                <Typography component="div" className="totalcount">
                  Total Records: {count}
                </Typography>
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-right"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item previous-page-link"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
                {isLoading && <FieldsListLoading />}
              </div>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <>
          <Typography sx={{ paddingLeft: 2 }}>
            No exact matches found.
          </Typography>
        </>
      )}
    </Box>
  );
}
