import { create } from "zustand";

const zustandStore = create((set) => ({
  menuListCheck: [],
  setField: (fieldName, fieldValue) =>
    set((state) => ({ ...state, [fieldName]: fieldValue })),

  resetFields: () =>
    set(() => ({
      menuListCheck: [],
    })),
}));

export default zustandStore;
