import { lazy, Suspense, useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import Box from "@mui/material/Box";

import Appbar from "./../Dashboard/Layout/Appbar";
import Sidebar from "./../Dashboard/Layout/Sidebar";
import localstorageData from "../helpers/localstorageData";
import { useState, useEffect } from "react";
import { accessRoleList } from "../api";
import axios from "axios";
import { DelayedError } from "./DelayedError";
import { getCreatedBy } from "../helpers/commonHelper";
import Loader from "../../../frontend/src/pages/loader/Loader";
//404 page
import Error from "./../components/Error";
import FindSupport from "../pages/client/Exploremarket/find-support.js";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function Client() {
  let MemoizedAppbar, MemoizedSidebar;

  const createdBy = getCreatedBy();

  MemoizedAppbar = useMemo(() => <Appbar />, []);
  MemoizedSidebar = useMemo(() => <Sidebar />, []);
  const user = localstorageData();

  const createdByUserType = user?.createdByUserType;
  const userTypeName = user?.userTypeName;

  const SetupAccountClient = lazy(() =>
    import("../pages/client/Account-Setup")
  );

  const Riskassessment = lazy(() =>
    import("../pages/client/risk-assesment/list")
  );
  const MyAccount = lazy(() => import("../pages/client/form/MyAccount"));

  // const Home = lazy(() => import("../pages/client/form/home"));

  const Home = lazy(() => import("../pages/client/Dashboard/Client-Dashboard"));

  const PendingApproval = lazy(() =>
    import("../pages/superAdmin/pending-approval")
  );
  const Listtextspeechkit = lazy(() =>
    import("../pages/superAdmin/textspeechkit/list")
  );
  const Edittextspeechkit = lazy(() =>
    import("../pages/superAdmin/textspeechkit/edit")
  );
  const PdfGoal = lazy(() => import("../components/goals/pdf"));
  const PdfGoalImage = lazy(() => import("../components/goals/pdfWithImage"));
  const Viewtextspeechkit = lazy(() =>
    import("../pages/superAdmin/textspeechkit/details")
  );

  const AppointmentList = lazy(() =>
    import("../pages/client/appointment/list")
  );

  const AppointmentView1 = lazy(() =>
    import("../components/calendar-view/Appointment-View")
  );

  const MeetingList = lazy(() => import("../pages/client/meeting/list"));
  const MeetingDetails = lazy(() => import("../pages/client/meeting/details"));

  const ListClientScheduleMeetingNew = lazy(() =>
    import("../pages/superAdmin/schedule-meeting/client/list-tab")
  );

  const ListCommonCalander = lazy(() =>
    import("../pages/superAdmin/schedule-common-meeting/client/list-tab")
  );

  const EditCommonCalander = lazy(() =>
    import("../pages/superAdmin/schedule-other-meeting/meeting")
  );

  const ViewCommonCalander = lazy(() =>
    import("../components/meeting-view-other/details")
  );

  const ClientMeetingDetailsNew = lazy(() =>
    import("../components/meeting-view/details")
  );

  const Editemergencycontact = lazy(() =>
    import("../pages/healthCarer/Emergency-Contact/edit")
  );
  const Listemergencycontact = lazy(() =>
    import("../pages/healthCarer/Emergency-Contact/list")
  );

  const MedicalHistoryPrint = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/print")
  );
  const Exploremarket = lazy(() =>
    import("../pages/client/Exploremarket/Exploremarket")
  );
  // const Viewexploremarket = lazy(() =>
  //   import("../pages/client/Exploremarket/view")
  // );

  const Viewexploremarket = lazy(() =>
    import("../components/emavailability/ViewDetail")
  );

  const RoutineTaskDashboard = lazy(() =>
    import("../components/routine-task-dashboard/AppointmentDashboard")
  );

  const RoutineTaskPdf = lazy(() =>
    import("../components/routine-task-dashboard/pdf")
  );
  const RoutineTaskPrint = lazy(() =>
    import("../components/routine-task-dashboard/print")
  );
  const ListGoal = lazy(() => import("../components/goals/list"));

  const EditGoal = lazy(() => import("../components/goals/edit"));
  const ViewGoal = lazy(() => import("../components/goals/view"));

  const Editincident = lazy(() => import("../pages/healthCarer/incident/edit"));
  const Listincident = lazy(() => import("../pages/healthCarer/incident/list"));
  const Viewincident = lazy(() => import("../pages/healthCarer/incident/view"));
  const Pdfincident = lazy(() => import("../pages/healthCarer/incident/pdf"));
  const Listcommunityaccrs = lazy(() =>
    import("../pages/superAdmin/community-accessprovider/list")
  );

  const Addcommunityaccrs = lazy(() =>
    import("../pages/superAdmin/community-accessprovider/add")
  );

  const EditRoutineGoal = lazy(() =>
    import("../components/goals/addRoutineTask/edit")
  );

  const InProgressPage = lazy(() => import("../pages/client/in-progress"));
  const PackageMenu = lazy(() => import("../components/packageMenu/list"));

  const RequestConnections = lazy(() =>
    import("../components/Explore-Market/Connection-request")
  );

  const ConnectionAvailability = lazy(() =>
    import("../components/Explore-Market/Availability-connection-request")
  );

  const ShiftNotes = lazy(() =>
    import("../pages/superAdmin/shift-notes/shiftNoteclientlist.js")
  );

  const ViewDetails = lazy(() =>
    import("../pages/client/View-Details/View-Details")
  );

  const ExploreMarketViewDetails = lazy(() =>
    import("../components/Explore-Market/View-Details/View-Details")
  );

  const ExploremarketMyfavorites = lazy(() =>
    import("../pages/client/Exploremarket/Myfavorites")
  );
  const SubscriptionPlan = lazy(() =>
    import("../pages/serviceProvider/subscription-plan/list")
  );
  const PaymentGateway = lazy(() =>
    import("../pages/serviceProvider/subscription-plan/paymentGateway")
  );

  const ViewRequest = lazy(() =>
    import("../pages/client/Exploremarket/View-Request/View-Details")
  );

  const ObservationChart = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/Observation-chart")
  );
  const SentRequestViewDetails = lazy(() =>
    import("../pages/client/Sent Request View/sentViewDetails")
  );

  const RecievedRequestViewDetails = lazy(() =>
    import("../pages/client/RecievedRequestView/RecievedRequestDetails")
  );
  const RequestDocument = lazy(() =>
    import("../components/Explore-Market/Client-document-req")
  );
  const TaskList = lazy(() => import("../pages/client/task/Tasklist"));
  const TaskAdd = lazy(() => import("../pages/client/task/edit"));
  const GoalChart = lazy(() => import("../components/goals/barChart"));

  const ViewTasklist = lazy(() => import("../pages/client/task/viewTasklist"));

  // const ListConflict = lazy(() => import("../pages/superAdmin/conflict/list"));
  // const EditConflict = lazy(() => import("../pages/superAdmin/conflict/edit"));

  const ListRiskAssessment = lazy(() =>
    import("../pages/superAdmin/risk-assessment/list")
  );

  const AddRiskAssessment = lazy(() =>
    import("../pages/superAdmin/risk-assessment/edit.js")
  );

  const EditRiskAssessment = lazy(() =>
    import("../pages/superAdmin/risk-assessment/edit.js")
  );

  const ListBSP = lazy(() => import("../pages/superAdmin/Bsp/list"));

  const AddBSP = lazy(() => import("../pages/superAdmin/Bsp/edit"));

  const EditBSP = lazy(() => import("../pages/superAdmin/Bsp/edit"));


  const ListConflict = lazy(() =>
    import("../pages/superAdmin/conflictClient/list")
  );
  const EditConflict = lazy(() =>
    import("../pages/superAdmin/conflictClient/edit")
  );

  const ListComplaint = lazy(() =>
    // import("../pages/superAdmin/complaint/list")
    import("../pages/superAdmin/complaintClient/list")
  );
  const EditComplaint = lazy(() =>
    //  import("../pages/superAdmin/complaint/edit")
    import("../pages/superAdmin/complaintClient/edit")
  );

  const ListFundManagement = lazy(() =>
    import("../pages/superAdmin/FundManagement/Fund-Management")
  );

  const ListMedicalHistory = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/listMedicalHistory")
  );

  const MedicalHistoryPdf = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/medicalHistoryPdf")
  );

  const MedicalHistory = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/Medical-History")
  );

  const EditMedicalHistory = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/Add-Medical-History")
  );

  // /
  const QuickTask = lazy(() =>
    import("../pages/healthCarer/appointment/dashboard/QuickTask")
  );

  const ViewMedicalHistory = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/View-Medical-History")
  );

  const MedicationChart = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/Medication-chart")
  );

  const EditMedicationChart = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/Add-Medication")
  );

  //
  const Settings = lazy(() =>
    import("../pages/serviceProvider/Settings/setting")
  );
  const ViewMedicationChart = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/View-Medication")
  );

  const ListFamilyMember = lazy(() =>
    import("../pages/superAdmin/family-member-client/list")
  );

  const EditFamilyMember = lazy(() =>
    import("../pages/superAdmin/family-member-client/edit")
  );

  const ViewFamilyMember = lazy(() =>
    import("../pages/superAdmin/family-member-client/view")
  );
  const Silsdalocationlist = lazy(() =>
    import("../pages/client/silsdalocation/list")
  );
  const Viewsilsdalocation = lazy(() =>
    import("../pages/client/silsdalocation/view")
  );
  const SettingPayment = lazy(() =>
    import("../pages/serviceProvider/Settings/payment")
  );
  const ServiceTaskList = lazy(() =>
    import("../pages/superAdmin/service-task/list")
  );

  const ServiceTaskView = lazy(() =>
    import("../pages/superAdmin/service-task/view")
  );

  const InvoicesServiceTask = lazy(() =>
    import("../components/invoice-service-task/Invoice")
  );
  const Invoice = lazy(() => import("../components/invoice/Invoice"));

  const CommonInvoice = lazy(() =>
    import("../components/invoice/CommonInvoice")
  );

  const ProgressNoteAdd = lazy(() =>
    import("../pages/superAdmin/progressNote/edit")
  );
  const InvoicesPdf = lazy(() => import("../components/invoice/InvoicePdf"));

  const ServiceTaskInvoicePdf = lazy(() =>
    import("../components/invoice-service-task/InvoicePdf")
  );

  const PlanManagerlist = lazy(() =>
    import("../pages/client/plamManager/list")
  );
  const ViewShiftNotes = lazy(() =>
    import("../pages/superAdmin/shiftNotes/view")
  );
  const PdfShiftNotes = lazy(() =>
    import("../pages/superAdmin/shiftNotes/pdf")
  );
  const ProgressNotelist = lazy(() =>
    import("../pages/client/progress-notes/list")
  );
  const ProgressNotepdf = lazy(() =>
    import("../pages/client/progress-notes/pdf")
  );
  const ProgressNoteprint = lazy(() =>
    import("../pages/client/progress-notes/print")
  );
  const ShiftNoteprint = lazy(() =>
    import("../pages/superAdmin/shiftNotes/print")
  );

  const SmtpSetup = lazy(() =>
    import("../pages/serviceProvider/Settings/smtpSetup.js")
  );

  const Teamlist = lazy(() => import("../pages/client/Teamdetails/list"));
  const TeamHclist = lazy(() => import("../pages/client/Teamdetails/Hclist"));
  const ViewProgressNotes = lazy(() =>
    import("../pages/client/progress-notes/view")
  );

  const TaskandNotes = lazy(() => import("../pages/client/TaskandNote/list"));
  const ViewTaskandNotes = lazy(() =>
    import("../pages/client/TaskandNote/view")
  );
  const TeamSplist = lazy(() => import("../pages/client/Teamdetails/Splist"));
  const Email = lazy(() => import("../components/email/list"));
  const ChatAiemail = lazy(() => import("../components/email/chatAi"));
  const Viewemail = lazy(() => import("../components/email/view"));
  const Editemail = lazy(() => import("../components/email/newEmail/edit"));

  const ListConnectingUsers = lazy(() =>
    import("../pages/superAdmin/connectingUsers/list")
  );

  const ViewBloodObservation = lazy(() =>
    import("../pages/superAdmin/client-list/Viewtab/Observation/Blood/view")
  );

  const ViewBowelObservation = lazy(() =>
    import("../pages/superAdmin/client-list/Viewtab/Observation/Bowel/view")
  );
  const ViewMestruationObservation = lazy(() =>
    import(
      "../pages/superAdmin/client-list/Viewtab/Observation/Mestruation/view"
    )
  );
  const ViewFoodObservation = lazy(() =>
    import("../pages/superAdmin/client-list/Viewtab/Observation/Food/view.js")
  );

  const ViewPersonalCareObservation = lazy(() =>
    import(
      "../pages/superAdmin/client-list/Viewtab/Observation/PersonalCare/view"
    )
  );

  const ViewSeizureObservation = lazy(() =>
    import(
      "../pages/superAdmin/Medication-management-dev/Observation/Seizure/view"
    )
  );

  const ViewWeightObservation = lazy(() =>
    import(
      "../pages/superAdmin/Medication-management-dev/Observation/weight/view"
    )
  );

  const SettingSuperannuationList = lazy(() =>
    import("../pages/serviceProvider/Settings/superAnnuation/list")
  );
  const SettingSuperannuationAdd = lazy(() =>
    import("../pages/serviceProvider/Settings/superAnnuation/edit.js")
  );
  const Closerincident = lazy(() =>
    import("../pages/Supervisor/incident/incidentClosure")
  );

  const ViewDataBackup = lazy(() => import("../components/dataBackup/list"));
  const MenuMaster = lazy(() => import("../pages/superAdmin/menuMaster/list"));
  const AddMenuMaster = lazy(() =>
    import("../pages/superAdmin/menuMaster/edit")
  );
  const ListSeverity = lazy(() => import("../pages/superAdmin/severity/list"));
  const AddSeverity = lazy(() => import("../pages/superAdmin/severity/edit"));
  const EditSeverity = lazy(() => import("../pages/superAdmin/severity/edit"));

  const ListSignedDocument = lazy(() =>
    import("../components/SignedDocument/ListSignedDocument")
  );
  const ViewSignedDocument = lazy(() =>
    import("../components/SignedDocument/ViewSignedDocument")
  );
  const ListHCRating = lazy(() =>
    import("../pages/superAdmin/Ratings/healthCarer")
  );
  const ShiftNotesPrint = lazy(() =>
    import("../pages/superAdmin/shiftNotes/print.js")
  );

  const clientRoutes = [
    {
      path: "/client/home",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Home />
        </Suspense>
      ),
    },
    {
      path: "/client/list-severity",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListSeverity />
        </Suspense>
      ),
    },
    {
      path: "/client/add-severity",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddSeverity />
        </Suspense>
      ),
    },
    {
      path: "/client/edit-severity",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditSeverity />
        </Suspense>
      ),
    },
    {
      path: "/client/list-superannuation-setting",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SettingSuperannuationList />
        </Suspense>
      ),
    },
    {
      path: "/client/list-menu-masters",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <MenuMaster />
        </Suspense>
      ),
    },

    {
      path: "/client/list-risk-assessment",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListRiskAssessment />
        </Suspense>
      ),
    },

    {
      path: "/client/add-risk-assessment",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddRiskAssessment />
        </Suspense>
      ),
    },

    {
      path: "/client/edit-risk-assessment",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditRiskAssessment />
        </Suspense>
      ),
    },
    {
      path: "/client/list-bsp-question",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListBSP />
        </Suspense>
      ),
    },

    {
      path: "/client/add-bsp-question",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddBSP />
        </Suspense>
      ),
    },
    {
      path: "/client/edit-bsp-question",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditBSP />
        </Suspense>
      ),
    },
    {
      path: "/client/smtp-setup",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SmtpSetup />
        </Suspense>
      ),
    },
    {
      path: "/client/add-menu-masters",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddMenuMaster />
        </Suspense>
      ),
    },
    {
      path: "/client/add-superannuation-setting",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SettingSuperannuationAdd />
        </Suspense>
      ),
    },
    // {
    //   path: "/client/pending-approval",
    //   element: (
    //     <Suspense fallback={<div><Loader /></div>}>
    //       <PendingApproval />
    //     </Suspense>
    //   ),
    // },
    {
      path: "/client/list-package-menu",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <PackageMenu />
        </Suspense>
      ),
    },

    // view details route

    {
      path: "/client/view-details",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewDetails />
        </Suspense>
      ),
    },

    {
      path: "/client/find-support",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <FindSupport />
        </Suspense>
      ),
    },

    // Commented out route
    {
      path: "/client/onboard",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SetupAccountClient />
        </Suspense>
      ),
    },
    {
      path: "/client/risk-assesment",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Riskassessment />
        </Suspense>
      ),
    },
    {
      path: "/client/closure-incident",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Closerincident />
        </Suspense>
      ),
    },

    {
      path: "/client/shift-notes-list",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ShiftNotes />
        </Suspense>
      ),
    },
    {
      path: "/client/shift-notes-print",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ShiftNoteprint />
        </Suspense>
      ),
    },
    {
      path: "/client/list-shift-notes",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ShiftNotes />
        </Suspense>
      ),
    },
    {
      path: "/client/view-shift-notes",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewShiftNotes />
        </Suspense>
      ),
    },
    {
      path: "/client/pdf-shift-task-notes",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <PdfShiftNotes />
        </Suspense>
      ),
    },
    {
      path: "/client/myaccount",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <MyAccount />
        </Suspense>
      ),
    },
    {
      path: "/client/add-textspeechkit",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Edittextspeechkit />
        </Suspense>
      ),
    },
    {
      path: "/client/list-textspeechkit",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Listtextspeechkit />
        </Suspense>
      ),
    },
    {
      path: "/client/view-textspeechkit",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Viewtextspeechkit />
        </Suspense>
      ),
    },
    {
      path: "/client/appointment-list",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AppointmentList />
        </Suspense>
      ),
    },
    {
      path: "/client/list-appointments",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AppointmentList />
        </Suspense>
      ),
    },
    {
      path: "/client/appointments-view",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AppointmentView1 />
        </Suspense>
      ),
    },
    {
      path: "/client/meeting-list-old",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <MeetingList />
        </Suspense>
      ),
    },
    {
      path: "/client/view-meeting-details-old",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <MeetingDetails />
        </Suspense>
      ),
    },
    {
      path: "/client/meeting-list",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListClientScheduleMeetingNew />
        </Suspense>
      ),
    },

    {
      path: "/client/list-calendar",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListCommonCalander />
        </Suspense>
      ),
    },

    {
      path: "/client/view-blood-observation",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewBloodObservation />
        </Suspense>
      ),
    },

    {
      path: "/client/view-seizure-observation",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewSeizureObservation />
        </Suspense>
      ),
    },

    {
      path: "/client/view-bowel-observation",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewBowelObservation />
        </Suspense>
      ),
    },

    {
      path: "/client/view-mestruation-observation",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewMestruationObservation />
        </Suspense>
      ),
    },

    {
      path: "/client/view-food-observation",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewFoodObservation />
        </Suspense>
      ),
    },

    {
      path: "/client/view-personalcare-observation",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewPersonalCareObservation />
        </Suspense>
      ),
    },

    {
      path: "/client/view-weight-observation",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewWeightObservation />
        </Suspense>
      ),
    },

    {
      path: "/client/schedule-other-meeting",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditCommonCalander />
        </Suspense>
      ),
    },

    {
      path: "/client/edit-other-meeting",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditCommonCalander />
        </Suspense>
      ),
    },

    {
      path: "/client/list-view-other-schedule-meeting",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewCommonCalander />
        </Suspense>
      ),
    },

    {
      path: "/client/view-meeting-details",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ClientMeetingDetailsNew />
        </Suspense>
      ),
    },
    {
      path: "/client/add-emergency-contact",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Editemergencycontact />
        </Suspense>
      ),
    },
    {
      path: "/client/edit-emergency-contact",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Editemergencycontact />
        </Suspense>
      ),
    },
    {
      path: "/client/list-emergency-contact",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Listemergencycontact />
        </Suspense>
      ),
    },

    {
      path: "/client/exploremarket",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Exploremarket />
        </Suspense>
      ),
    },
    {
      path: "/client/viewexploremarket",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Viewexploremarket />
        </Suspense>
      ),
    },
    {
      path: "/client/list-community-access-provider",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <InProgressPage />
        </Suspense>
      ),
    },
    {
      path: "/client/list-complaint-or-feedback-management",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <InProgressPage />
        </Suspense>
      ),
    },
    {
      path: "/client/list-conflict-of-interest-register",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <InProgressPage />
        </Suspense>
      ),
    },
    {
      path: "/client/list-funding",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <InProgressPage />
        </Suspense>
      ),
    },
    {
      path: "/client/list-goals",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListGoal />
        </Suspense>
      ),
    },
    {
      path: "/client/invoice",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          {/* {createdByUserType === "serviceProvider" ||
          createdByUserType === "superAdmin" ? (
            <Invoice />
          ) : (
            <InvoicesServiceTask />
          )} */}
          <CommonInvoice />
        </Suspense>
      ),
    },

    {
      path: "/client/list-notes",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <InProgressPage />
        </Suspense>
      ),
    },
    {
      path: "/client/list-plan-manager",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <PlanManagerlist />
        </Suspense>
      ),
    },
    {
      path: "/client/list-progress-notes",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ProgressNotelist />
        </Suspense>
      ),
    },
    {
      path: "/client/pdf-progress-notes",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ProgressNotepdf />
        </Suspense>
      ),
    },
    {
      path: "/client/print-progress-notes",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ProgressNoteprint />
        </Suspense>
      ),
    },
    {
      path: "/client/list-reports",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <InProgressPage />
        </Suspense>
      ),
    },
    {
      path: "/client/list-routine-and-tasks",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <RoutineTaskDashboard />
        </Suspense>
      ),
    },
    {
      path: "/client/list-routine-and-tasks-pdf",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <RoutineTaskPdf />
        </Suspense>
      ),
    },
    {
      path: "/client/list-routine-and-tasks-print",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <RoutineTaskPrint />
        </Suspense>
      ),
    },
    {
      path: "/client/list-search-support",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <InProgressPage />
        </Suspense>
      ),
    },
    {
      path: "/client/list-tasks",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <InProgressPage />
        </Suspense>
      ),
    },
    {
      path: "/client/add-goal",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditGoal />
        </Suspense>
      ),
    },
    {
      path: "/client/edit-goal",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditGoal />
        </Suspense>
      ),
    },
    {
      path: "/client/view-task",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewGoal />
        </Suspense>
      ),
    },
    {
      path: "/client/add-goal-routine",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditRoutineGoal />
        </Suspense>
      ),
    },
    {
      path: "/client/edit-goal-routine",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditRoutineGoal />
        </Suspense>
      ),
    },

    {
      path: "/client/request-connection-list",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <RequestConnections />
        </Suspense>
      ),
    },
    {
      path: "/client/availability-connection-list",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ConnectionAvailability />
        </Suspense>
      ),
    },
    {
      path: "/client/add-incident",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Editincident />
        </Suspense>
      ),
    },
    {
      path: "/client/list-incident",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Listincident />
        </Suspense>
      ),
    },
    {
      path: "/client/edit-incident",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Editincident />
        </Suspense>
      ),
    },
    {
      path: "/client/view-incident",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Viewincident />
        </Suspense>
      ),
    },
    {
      path: "/client/pdf-incident",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Pdfincident />
        </Suspense>
      ),
    },

    {
      path: "/client/view-details",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewDetails />
        </Suspense>
      ),
    },

    {
      path: "/client/subscription-plans-list",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SubscriptionPlan />
        </Suspense>
      ),
    },
    {
      path: "/client/payment-gateway",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <PaymentGateway />
        </Suspense>
      ),
    },

    {
      path: "/client/explore-market-view-details",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ExploreMarketViewDetails />
        </Suspense>
      ),
    },

    {
      path: "/client/explore-market-favorites",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ExploremarketMyfavorites />
        </Suspense>
      ),
    },

    {
      path: "/client/view-request",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewRequest />
        </Suspense>
      ),
    },

    {
      path: "/client/sent-request-details",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SentRequestViewDetails />
        </Suspense>
      ),
    },

    {
      path: "/client/received-request-detail",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <RecievedRequestViewDetails />
        </Suspense>
      ),
    },
    {
      path: "/client/request-document-list",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <RequestDocument />
        </Suspense>
      ),
    },

    {
      path: "/client/bar-chart-goal",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <GoalChart />
        </Suspense>
      ),
    },
    {
      path: "/client/task-list",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <TaskList />
        </Suspense>
      ),
    },

    {
      path: "/client/task-add",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <TaskAdd />
        </Suspense>
      ),
    },
    {
      path: "/client/view-task-list",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewTasklist />
        </Suspense>
      ),
    },

    {
      path: "/client/task-edit",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <TaskAdd />
        </Suspense>
      ),
    },
    {
      path: "/client/community-access-provider-add",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Addcommunityaccrs />
        </Suspense>
      ),
    },
    {
      path: "/client/community-access-provider-list",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Listcommunityaccrs />
        </Suspense>
      ),
    },
    //

    //ViewConflict EditConflict

    {
      path: "/client/list-conflict",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListConflict />
        </Suspense>
      ),
    },

    {
      path: "/client/add-conflict",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditConflict />
        </Suspense>
      ),
    },

    {
      path: "/client/edit-conflict",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditConflict />
        </Suspense>
      ),
    },

    {
      path: "/client/list-complaint",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListComplaint />
        </Suspense>
      ),
    },

    {
      path: "/client/add-complaint",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditComplaint />
        </Suspense>
      ),
    },

    {
      path: "/client/edit-complaint",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditComplaint />
        </Suspense>
      ),
    },

    {
      path: "/client/list-funds-management",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListFundManagement />
        </Suspense>
      ),
    },

    {
      path: "/client/medical-history-pdf",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <MedicalHistoryPdf />
        </Suspense>
      ),
    },

    {
      path: "/client/medical-history-print",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <MedicalHistoryPrint />
        </Suspense>
      ),
    },

    {
      path: "/client/list-medical-history",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListMedicalHistory />
        </Suspense>
      ),
    },

    {
      path: "/client/list-medication-chart",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListMedicalHistory />
        </Suspense>
      ),
    },

    {
      path: "/client/list-prn-chart",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListMedicalHistory />
        </Suspense>
      ),
    },

    {
      path: "/client/medical-history-dev",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <MedicalHistory />
        </Suspense>
      ),
    },
    {
      path: "/client/add-medical-history-dev",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditMedicalHistory />
        </Suspense>
      ),
    },

    {
      path: "/client/observation-chart-dev",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ObservationChart />
        </Suspense>
      ),
    },

    {
      path: "/client/edit-medical-history-dev",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditMedicalHistory />
        </Suspense>
      ),
    },

    {
      path: "/client/view-medical-history-dev",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewMedicalHistory />
        </Suspense>
      ),
    },
    //
    {
      path: "/client/medication-chart-dev",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <MedicationChart />
        </Suspense>
      ),
    },

    {
      path: "/client/add-medication-dev",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditMedicationChart />
        </Suspense>
      ),
    },

    {
      path: "/client/edit-medication-dev",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditMedicationChart />
        </Suspense>
      ),
    },

    {
      path: "/client/view-medication-dev",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewMedicationChart />
        </Suspense>
      ),
    },

    {
      path: "/client/prn-chart-dev",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <MedicationChart />
        </Suspense>
      ),
    },

    //

    {
      path: "/client/list-family-member",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListFamilyMember />
        </Suspense>
      ),
    },

    {
      path: "/client/view-family-member",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewFamilyMember />
        </Suspense>
      ),
    },

    {
      path: "/client/edit-family-member",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditFamilyMember />
        </Suspense>
      ),
    },

    {
      path: "/client/add-family-member",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditFamilyMember />
        </Suspense>
      ),
    },

    {
      path: "/client/sil-sda-location-list",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Silsdalocationlist />
        </Suspense>
      ),
    },
    {
      path: "/client/view-sil-sda-location",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Viewsilsdalocation />
        </Suspense>
      ),
    },

    {
      path: "/client/list-service-task",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ServiceTaskList />
        </Suspense>
      ),
    },

    {
      path: "/client/view-service-task",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ServiceTaskView />
        </Suspense>
      ),
    },

    {
      path: "/client/invoice-pdf",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          {createdByUserType === "serviceProvider" ||
          createdByUserType === "superAdmin" ? (
            <InvoicesPdf />
          ) : (
            <ServiceTaskInvoicePdf />
          )}
        </Suspense>
      ),
    },
    {
      path: "/client/support-coordinator-list",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Teamlist />
        </Suspense>
      ),
    },
    {
      path: "/client/health-carer-list",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <TeamHclist />
        </Suspense>
      ),
    },
    {
      path: "/client/view-progressnote",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewProgressNotes />
        </Suspense>
      ),
    },
    {
      path: "/client/list-task-notes",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <TaskandNotes />
        </Suspense>
      ),
    },
    {
      path: "/client/view-task-notes",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewTaskandNotes />
        </Suspense>
      ),
    },
    {
      path: "/client/service-provider-list",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <TeamSplist />
        </Suspense>
      ),
    },
    {
      path: "/client/email",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Email />
        </Suspense>
      ),
    },
    {
      path: "/client/email/chatopenai",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ChatAiemail />
        </Suspense>
      ),
    },
    {
      path: "/client/view-email",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Viewemail />
        </Suspense>
      ),
    },
    {
      path: "/client/edit-email",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Editemail />
        </Suspense>
      ),
    },
    {
      path: "/client/settings",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Settings />
        </Suspense>
      ),
    },
    {
      path: "/client/export-pdf-with-image",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <PdfGoalImage />
        </Suspense>
      ),
    },
    {
      path: "/client/export-pdf",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <PdfGoal />
        </Suspense>
      ),
    },
    {
      path: "/client/add-progressnote",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ProgressNoteAdd />
        </Suspense>
      ),
    },
    {
      path: "/client/medical-history-pdf",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <MedicalHistoryPdf />
        </Suspense>
      ),
    },
    {
      path: "/client/medical-history-print",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <MedicalHistoryPrint />
        </Suspense>
      ),
    },

    {
      path: "/client/quick-task",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <QuickTask />
        </Suspense>
      ),
    },
    {
      path: "/client/list-observation",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListMedicalHistory />
        </Suspense>
      ),
    },
    {
      path: "/client/list-connecting-users",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListConnectingUsers />
        </Suspense>
      ),
    },
    {
      path: "/client/view-backup-options",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewDataBackup />
        </Suspense>
      ),
    },
    {
      path: "/client/payments",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SettingPayment />
        </Suspense>
      ),
    },
    {
      path: "/client/list-signed-document",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListSignedDocument />
        </Suspense>
      ),
    },

    {
      path: "/client/view-signed-document",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewSignedDocument />
        </Suspense>
      ),
    },
    {
      path: "/client/list-health-carer-rating",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListHCRating />
        </Suspense>
      ),
    },
    {
      path: "/client/shift-notes-print",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ShiftNotesPrint />
        </Suspense>
      ),
    },
    {
      path: "*",
      element: <Error />,
    },
  ];

  const [menuList, setMenuList] = useState([]);

  const getClientRouteAccess = () => {
    axios
      .get(
        `${accessRoleList}?roleId=${
          user.userTypeId
        }&createdBy=${createdBy}&role=${user.role}&userId=${
          user._id
        }&userType=${user?.createdByUserType}&child=${
          user?.createdByUserType
        }&isABNHolder=${user?.isSoleTrader}&isABNHolderHc=${
          user?.isSoleTrader
        }&bookAdemo=${user?.isBookDemoUser}&isSc=${
          user?.isSoleTrader
        }&evlClient=${
          (user?.packageMenuId == false || user?.packageMenuId == undefined) &&
          user?.role == "client"
            ? "true"
            : "false"
        } `
      )
      .then(function (res) {
        if (res.data.data.length > 0) {
          setMenuList((prevData) => [
            ...prevData,
            ...res.data.data,
            ...[
              "/client/view-meeting-details",
              "/client/list-package-menu",
              "/client/settings",
              "/client/myaccount",
              "/client/payments",
              "/client/settings-xero-myob-list",
              "/client/list-payrun",
              "/client/list-superannuation-setting",
              "/client/financial-setting-list",
              "/client/list-menu-masters",
              "/client/smtp-setup",
              "/client/add-menu-masters",
              "/client/add-superannuation-setting",
            ],
          ]);
        } else {
          setMenuList((prevData) => [
            ...prevData,
            ...[
              "/client/view-meeting-details",
              "/client/list-package-menu",
              "/client/myaccount",
              "/client/settings",
              "/client/payments",
              "/client/settings-xero-myob-list",
              "/client/list-payrun",
              "/client/list-superannuation-setting",
              "/client/financial-setting-list",
              "/client/list-menu-masters",
              "/client/smtp-setup",
              "/client/add-menu-masters",
              "/client/add-superannuation-setting",
            ],
          ]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getClientRouteAccess();
  }, []);

  function isPathInRoutes(path) {
    return menuList.includes(path);
  }

  return (
    <Box className="dashboard-form" id="agency">
      <Box sx={{ width: "100%" }} className="appbar-border">
        <Box className="appbar-main">{MemoizedAppbar}</Box>
      </Box>
      <Box className="d-flex dashboard-main-box">
        {MemoizedSidebar}
        <Routes>
          <Route>
            {clientRoutes?.map(
              (route, index) =>
                isPathInRoutes(route.path) ? (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                  />
                ) : null // You can also use an empty fragment <> </> instead of null
            )}
          </Route>
          <Route path="*" element={<DelayedError />} />
        </Routes>
      </Box>
    </Box>
  );
}
