import React from "react";
import {
  Box,
  DialogContentText,
  CardHeader,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import WebHeader from "../../../Website/Web-Header";
import Header from "../../../Website/header";
import WebFooter from "../../../Website/Web-footer";
import { Link } from "@mui/material";
import bannerImage from "../../../assets/images/home-banner.png";
import LogoSlider from "../../../Website/HomeUI/Home-logo-slider";
import careManage1 from "../../../assets/images/care-manage1.jpg";
import careManage2 from "../../../assets/images/care-manage2.jpg";
import careManage3 from "../../../assets/images/care-manage3.jpg";
import careManage4 from "../../../assets/images/care-manage4.jpg";
import featureTick from "../../../assets/images/feature-tick.png";
import orangeIcon from "../../../assets/images/orange-star.png";
import yellowIcon from "../../../assets/images/yellow-star.png";
import transformingImg from "../../../assets/images/transforming-image.jpg";
import HomeTab from "../../../Website/HomeUI/Home-tab";
import Homeclientslider from "../../../Website/HomeUI/Home-client-slider";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DiscoverSlider from "../../../Website/HomeUI/Discover-slider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import LandingBookDemo from "./Landing-Book-Demo";
import TextField from "@mui/material/TextField";
import { useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import LandingFeature from "./Landing-Feature";
import Autocomplete from "@mui/material/Autocomplete";
import { addInterestedIn, getUserTypeList } from "../../../api";
import { useState } from "react";
import axios from "../../../api/axios";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import * as Yup from "yup";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export default function Home() {
  const [expanded, setExpanded] = React.useState("panel1");
  const [userTypeVal, setUserTypeVal] = useState([]);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    window.location.reload();
  };
  //
  const [activeButton, setActiveButton] = useState(null);

  const handleButtonClick = (buttonId) => {
    setActiveButton(activeButton === buttonId ? null : buttonId);
  };
  //

  const [isActive, setActive] = React.useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };

  const [isActive2, setActive2] = React.useState(false);

  const toggleClass2 = () => {
    setActive2(!isActive2);
  };

  const [isActive3, setActive3] = React.useState(false);

  const toggleClass3 = () => {
    setActive3(!isActive3);
  };

  const [age, setAge] = React.useState("");

  const handleSelect = (event) => {
    setAge(event.target.value);
  };

  const [select, setSelect] = React.useState("");

  const handleSelect2 = (event) => {
    setSelect(event.target.value);
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const [disableButton, setDisableButton] = useState(false);

  let interestedInData = [
    { label: "Demo", id: "1" },
    { label: "Register", id: "2" },
    { label: "Meeting", id: "3" },
  ];
  const setStatusFunction = async (state, setFieldValue) => {
    if (state) {
      await setFieldValue("interestedIn", state.label);
    }
  };

  let userTypeData = [
    { label: "Client", id: "1" },
    { label: "Health Carer", id: "2" },
    { label: "Service Provider", id: "3" },
    { label: "Support Coordinator", id: "3" },
  ];
  const setUserTypeFunction = async (state, setFieldValue) => {
    if (state) {
      await setFieldValue("userType", state.label);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .test("name", "Space is not accept", (value) => {
        return value && value.trim() !== "";
      })
      .required("Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    phone: Yup.string()
      .matches(/^[0-9]+$/, "Phone number must contain only digits")
      .min(10, "Phone number must be at least 10 digits")
      .max(13, "Phone number must not exceed 15 digits")
      .required("Phone number is required"),
    userType: Yup.string().required("User Type is required"),
    // interestedIn: Yup.string().required("Interested In is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      userType: "",
      interestedIn: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      console.log("Submitting form with values:", values);
      axios
        .post(`${addInterestedIn}`, values)
        .then((res) => {
          if (res.data.success === false) {
            setDisableButton(false);
            toast.error(res.data.message);
          }

          if (res.data.success === true) {
            // toast.success(res.data.message);
            handleClickOpen();
            setTimeout(() => {
              // window.location.reload();
            }, 1000);
          }
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
    },
  });

  useEffect(() => {
    axios
      .get(getUserTypeList)
      .then((res) => {
        let userTypeData = res?.data?.data;

        let data = [];
        userTypeData &&
          userTypeData.map((d) => {
            data.push({
              label: `${d.userTypeName}`,
              format: d.userTypeFormat,
            });
          });
        setUserTypeVal(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <Box id="landingpage">
        <Box className="mainHome ">
          <Header />
          <Box className="landing-banner main-banner">
            <Box>
              <form noValidate onSubmit={formik.handleSubmit}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    className="landing-banner-none"
                  ></Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <Box className="landing-form-wrapper ">
                      <Box className="space-pb-60">
                        <h1>
                          Connecting Care <br /> for the Community
                        </h1>
                      </Box>
                      <Box className="landing-form">
                        <Box className="text-center">
                          <h2 className="registration-first-heading">
                            Hubshift - NDIS CRM launching soon
                          </h2>
                        </Box>

                        <Typography className="Register-head-text">
                          Register your interest:
                        </Typography>

                        <Box className="landing-form-inputsFields custom-landing-form-fields">
                          <Grid>
                            <Box className="landing-form-input space-mb-24">
                              <TextField
                                size="small"
                                className="w-100 custom-input"
                                required
                                id="standard-basic"
                                variant="standard"
                                type="text"
                                name="name"
                                placeholder="Full Name*"
                                onChange={formik?.handleChange}
                                onBlur={formik?.handleBlur}
                                value={formik?.values?.name}
                                error={
                                  formik?.touched?.name &&
                                  Boolean(formik?.errors?.name)
                                }
                                helperText={
                                  <div
                                    style={{
                                      marginBottom: "-18px",
                                      color: "#F02D3A", // Change color as needed
                                    }}
                                  >
                                    {formik?.touched?.name &&
                                      formik?.errors?.name}
                                  </div>
                                }
                              />
                            </Box>

                            <Box className="landing-form-input space-mb-24">
                              <TextField
                                size="small"
                                className="w-100 custom-input"
                                required
                                id="standard-basic"
                                variant="standard"
                                type="text"
                                name="email"
                                // inputProps={{
                                //   sx: {
                                //     "&::placeholder": {
                                //       color: "#A8A8A8",
                                //     },
                                //   },
                                // }}
                                placeholder="Email Address*"
                                onChange={formik?.handleChange}
                                onBlur={formik?.handleBlur}
                                value={formik?.values?.email}
                                error={
                                  formik?.touched?.email &&
                                  Boolean(formik?.errors?.email)
                                }
                                helperText={
                                  <div
                                    style={{
                                      marginBottom: "-18px",
                                      color: "#F02D3A", // Change color as needed
                                    }}
                                  >
                                    {formik?.touched?.email &&
                                      formik?.errors?.email}
                                  </div>
                                }
                              />
                            </Box>

                            <Box className="landing-form-input">
                              <TextField
                                size="small"
                                className="w-100 custom-input"
                                required
                                id="standard-basic"
                                variant="standard"
                                type="text"
                                name="phone"
                                placeholder="Mobile Number*"
                                onChange={formik?.handleChange}
                                onBlur={formik?.handleBlur}
                                value={formik?.values?.phone}
                                error={
                                  formik?.touched?.phone &&
                                  Boolean(formik?.errors?.phone)
                                }
                                helperText={
                                  <div
                                    style={{
                                      marginBottom: "-18px",
                                      color: "#F02D3A", // Change color as needed
                                    }}
                                  >
                                    {formik?.touched?.phone &&
                                      formik?.errors?.phone}
                                  </div>
                                }
                              />
                            </Box>

                            {/* <Box className="landing-form-input space-mb-24">
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                className="landing-form-autoComplete cutom-field"
                                name="userType"


                                options={userTypeData}
                                // size="small"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    // defaultValue="User Type"
                                    sx={{
                                      input: {
                                        color: 'red',
                                        "&::placeholder": {    // <----- Add this.
                                          opacity: 1,
                                        },
                                      },
                                      label: { color: 'blue' }
                                    }}
                                    placeholder="User Type"
                                    error={
                                      formik?.touched?.userType &&
                                      Boolean(formik?.errors?.userType)
                                    }
                                    helperText={
                                      formik?.touched?.userType &&
                                      formik?.errors?.userType
                                    }
                                  />
                                )}

                                onChange={(event, newInputValue) => {
                                  setUserTypeFunction(newInputValue, formik.setFieldValue);
                                }}
                                value={
                                  formik?.values?.userType !== undefined &&
                                    formik?.values?.userType !== ""
                                    ? userTypeData.find(
                                      (option) =>
                                        option?.label === formik?.values?.userType
                                    )
                                    : ""
                                }

                              />
                            </Box> */}
                            {/* <Box className="landing-form-input space-mb-24">
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                className="landing-form-autoComplete cutom-field"
                                name="interestedIn"
                                // size="small"
                                options={interestedInData}
                                // defaultValue={{ title: "The Godfather", year: 1972 }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    // defaultValue="Interested In"
                                    sx={{
                                      input: {
                                        color: 'red',
                                        "&::placeholder": {    // <----- Add this.
                                          opacity: 1,
                                        },
                                      },
                                      label: { color: 'blue' }
                                    }}
                                    placeholder="Interested In"
                                    error={
                                      formik?.touched?.interestedIn &&
                                      Boolean(formik?.errors?.interestedIn)
                                    }
                                    helperText={
                                      formik?.touched?.interestedIn &&
                                      formik?.errors?.interestedIn
                                    }
                                  />
                                )}

                                onChange={(event, newInputValue) => {
                                  setStatusFunction(newInputValue, formik.setFieldValue);
                                }}
                                value={
                                  formik?.values?.interestedIn !== undefined &&
                                    formik?.values?.interestedIn !== ""
                                    ? interestedInData.find(
                                      (option) =>
                                        option?.label === formik?.values?.interestedIn
                                    )
                                    : ""
                                }

                              />
                            </Box> */}
                            <Typography className="Register-head-text">
                              I am a<span className="red-color">*</span>
                            </Typography>
                            <Box
                              className="align-items-center space-mb-24"
                              style={{ marginTop: "-12px" }}
                            >
                              <Button
                                variant={
                                  activeButton === "btn1"
                                    ? "contained"
                                    : "outlined"
                                }
                                className={
                                  activeButton === "btn1"
                                    ? "contained-button"
                                    : "outlined-button"
                                }
                                onClick={() => {
                                  formik.setFieldValue(
                                    "userType",
                                    "Health Carer"
                                  );
                                  handleButtonClick("btn1");
                                }}
                              >
                                Health Carer
                              </Button>
                              <Button
                                variant={
                                  activeButton === "btn2"
                                    ? "contained"
                                    : "outlined"
                                }
                                className={
                                  activeButton === "btn2"
                                    ? "contained-button"
                                    : "outlined-button"
                                }
                                onClick={() => {
                                  formik.setFieldValue(
                                    "userType",
                                    "Service Provider"
                                  );
                                  handleButtonClick("btn2");
                                }}
                              >
                                Service Provider
                              </Button>
                              <Button
                                variant={
                                  activeButton === "btn3"
                                    ? "contained"
                                    : "outlined"
                                }
                                className={
                                  activeButton === "btn3"
                                    ? "contained-button"
                                    : "outlined-button"
                                }
                                onClick={() => {
                                  formik.setFieldValue("userType", "Client");
                                  handleButtonClick("btn3");
                                }}
                              >
                                Client
                              </Button>
                              <Button
                                variant={
                                  activeButton === "btn4"
                                    ? "contained"
                                    : "outlined"
                                }
                                className={
                                  activeButton === "btn4"
                                    ? "contained-button"
                                    : "outlined-button"
                                }
                                onClick={() => {
                                  formik.setFieldValue(
                                    "userType",
                                    "Support Coordinator"
                                  );
                                  handleButtonClick("btn4");
                                }}
                              >
                                Support Coordinator
                              </Button>

                              {formik?.touched.userType &&
                                formik?.errors.userType && (
                                  <Typography
                                    variant="caption"
                                    className="val-msg"
                                  >
                                    {formik?.errors.userType}
                                  </Typography>
                                )}
                            </Box>
                          </Grid>
                          <Box>
                            <button
                              type="submit"
                              className="home-section-button w-100 web-primary-btnLarge cta1"
                              style={{ marginLeft: "3px" }}
                              disabled={disableButton}
                              // onClick={handleClickOpen}
                            >
                              {disableButton === true
                                ? "Processing..."
                                : "Submit"}
                            </button>
                          </Box>
                          <Box>
                            <>
                              <Grid>
                                <Box>
                                  <Dialog
                                    onClose={handleClose}
                                    aria-labelledby="customized-dialog-title"
                                    open={open}
                                    maxWidth="xs"
                                  >
                                    <Box>
                                      <DialogTitle>
                                        <Box className="d-flex align-items-center justify-content-end">
                                          <CloseIcon
                                            aria-label="close"
                                            style={{ color: "gray" }}
                                            onClick={handleClose}
                                          />
                                        </Box>
                                        <Box className="thankyou-box">
                                          <CardHeader
                                            action={
                                              <IconButton aria-label="settings">
                                                <CheckCircleIcon className="check-circle-icon fdasfdfsa" />
                                              </IconButton>
                                            }
                                          />
                                        </Box>
                                      </DialogTitle>
                                      <DialogContent>
                                        <DialogContentText>
                                          <Typography
                                            mb={2}
                                            textAlign="center"
                                            className="popup-head1"
                                          >
                                            Thank you for registering your
                                            interest!
                                          </Typography>
                                          <Typography
                                            mb={3}
                                            className="popup-head2"
                                            textAlign="center"
                                          >
                                            We'll reach out to you shortly after
                                            our launch.
                                          </Typography>
                                        </DialogContentText>
                                      </DialogContent>
                                    </Box>
                                  </Dialog>
                                </Box>
                              </Grid>
                            </>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>
        </Box>
        {/* home banner end here  */}

        {/* <Box className="home-logo-slider">
          <LogoSlider />
        </Box> */}

        {/* home management section  */}

        {/* <Box className="home-manage-section">
          <Box className="discover-head-text">
            <h1 className="text-center w-100">
              Discover Hubshift's Cutting-Edge Features <br /> Revolutionizing
              Care Management
            </h1>
          </Box>

          <LandingFeature />
        </Box> */}

        {/* <Box className="webContainer">
          <Box className="home-transforming-section space-80">
            <Box className="text-center space-pb-60">
              <h1>Transforming Care:</h1>
              <h1> Where Efficiency Meets Savings</h1>
            </Box>
            <Grid container>
              <Grid item xs={12} sm={12} md={5}>
                <Box className="pRight-80">
                  <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                    className="transform-accordion "
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      onClick={toggleClass}
                    >
                      <Box
                        sx={{ pl: 1, pe: 2 }}
                        className="d-flex  align-items-center "
                      >
                        <img className="feature-tickIcon" src={featureTick} />
                        <h3>Streamline Efficiency</h3>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails className="position-relative transform-box-text" >
                      <Typography className="transforming-text">
                        Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet.
                        Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.
                        Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet.
                        Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                    className="transform-accordion"
                  >
                    <AccordionSummary
                      aria-controls="panel2d-content"
                      id="panel2d-header"
                      onClick={toggleClass2}
                    >
                      <Box
                        sx={{ pl: 1, pe: 2 }}
                        className="d-flex  align-items-center "
                      >
                        <img className="feature-tickIcon" src={featureTick} />
                        <h3>Optimize Resources and Cut Costs</h3>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails className="position-relative transform-box-text" >
                      <Typography className="transforming-text">
                        Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet.
                        Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.
                        Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet.
                        Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    expanded={expanded === "panel3"}
                    onChange={handleChange("panel3")}
                    className="transform-accordion"
                  >
                    <AccordionSummary
                      aria-controls="panel3d-content"
                      id="panel3d-header"
                      onClick={toggleClass3}
                    >
                      <Box
                        sx={{ pl: 1, pe: 2 }}
                        className="d-flex  align-items-center "
                      >
                        <img className="feature-tickIcon" src={featureTick} />
                        <h3>Streamline Efficiency</h3>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails className="position-relative transform-box-text" >
                      <Typography className="transforming-text">
                        Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet.
                        Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.
                        Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet.
                        Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={7}>
                <Box className="transform-img-wrapper position-relative">
                  <Box className="transforming-img">
                    <img src={transformingImg} />
                  </Box>


                  <Box
                    className={
                      isActive
                        ? "transforming-none transformingImage1"
                        : "image-none"
                    }
                  >
                    <Box className="transforming-img">
                      <img src={transformingImg} />
                    </Box>
                  </Box>


                  <Box
                    className={
                      isActive2
                        ? "transforming-none transformingImage2"
                        : "image-none"
                    }
                  >
                    <Box className="transforming-img">
                      <img src={transformingImg} />
                    </Box>
                  </Box>


                  <Box
                    className={
                      isActive3
                        ? "transforming-none transformingImage3"
                        : "image-none"
                    }
                  >
                    <Box className="transforming-img">
                      <img src={transformingImg} />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box> */}

        {/* Home Tabs section  */}

        {/* <Box className="home-module-tabMain success-bg space-pt-80 ">
          <Box className="webContainer">
            <Box className="w-100">
              <HomeTab />
            </Box>
          </Box>
        </Box> */}

        <Box className="blank-box"></Box>
        {/* Home testimonial  */}

        {/* <Box className="home-client-slider space-m-xl ">
          <Homeclientslider />
        </Box> */}

        {/* <Box className="blank-box"></Box> */}

        {/* book a demo section here  */}
        {/* <Box className="webContainer">
          <LandingBookDemo />
        </Box>
        <Box className="space-mt-80">
          <WebFooter />
        </Box> */}
      </Box>
    </>
  );
}
