import { create } from "zustand";

const MyStore = create((set) => ({
  isSubmitting: false,
  shifttypes: [],
  defaultCheck: false,
  selectedShift: "",
  initialstate: "",
  state: "",
  copyToDays: [],
  copyToWeeklyDays: [],
  copyToNextWeeklyDays: [],
  latestObject: [],
  copyFromDayNextWeekly: "",
  copyFromDay: "",
  month: "",
  Weekly: [],
  NextWeekly: [],
  Monthly: [
    {
      shiftTypeId: "",
      selectedQuarter: "",
      defaultCheck: false,
      selectedDays: [],
      QlSelectedDays: {},
      CopyFromDate: "",
      MlCopyFromDate: "",
      CopyQlSelectedDays: {},
      CopyMlSelectedDays: [],
      selectAll: false,
      days: [],
    },
  ],
  altype: "Weekly",
  setField: (fieldName, fieldValue) =>
    set((state) => ({ ...state, [fieldName]: fieldValue })),

  resetFields: () =>
    set(() => ({
      isSubmitting: false,
      shifttypes: [],
    })),
}));

export default MyStore;
