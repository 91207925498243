import React, { useEffect, useMemo } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  BrowserRouter,
} from "react-router-dom";
import "./App.css";
// import { messaging } from "./firbase.js";
// import { getToken } from "firebase/messaging";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { lazy, Suspense } from "react";

import SuperAdmin from "./routing/SuperAdmin";
import HealthCarer from "./routing/HealthCarer";
import SupportCoordinator from "./routing/SupportCoordinator";
import Client from "./routing/Client";
import ServiceProvider from "./routing/ServiceProvider";
import Stakeholder from "./routing/Stakeholder";
import ITManager from "./routing/ITManager";
import PlanManager from "./routing/PlanManager";
import Accountant from "./routing/Accountant";
import SeniorAccountant from "./routing/CommanRouting.js";

import Supervisor from "./routing/Supervisor";
import FamilyMember from "./routing/FamilyMember";
import GeneralManager from "./routing/GeneralManager";
import Hr from "./routing/Hr";
//import OtherRole from "./routing/OtherRole";
import CommanRouting from "./routing/CommanRouting";

// BeforeLogin pages
import AccountSetup from "./pages/AccountSetup/Account-Setup";
import Signin from "./pages/Login/SigninForm";
import Signup from "./pages/Signup/SignupForm";
import HcSignup from "./pages/Signup/HcSignupForm";
import Forgotpassword from "./pages/Login/Forgotpassword";
import ResetPassword from "./pages/Login/ResetPassword";
import VerifyOtp from "./pages/Login/Verifyotp";
import localstorageData from "./helpers/localstorageData";
import Availibility from "./pages/superAdmin/availability/Availibility";
import VerifyEmailSuccess from "./pages/Login/VerifyEmailSuccess";

import ClientManagement from "./pages/superAdmin/Client-Management/Client-Management";
//import Addtask from "./pages/superAdmin/Client-Management/Add-task";
// import Addnote from "./pages/superAdmin/Client-Management/Add-note";

import Timesheet from "./pages/superAdmin/Timesheet/Timesheet";
import Timesheetshow from "./Dashboard/Appointmentschedule/Timesheetshow";
import AddExpenses from "./pages/superAdmin/Timesheet/AddExpenses";

import Appointment from "./Dashboard/Appointmentschedule/Appt";
import Appointmentdetails from "./Dashboard/Appointmentschedule/After-Appointment";
import AppointExpenses from "./Dashboard/Appointmentschedule/Appoint-Add-Expenses";
import Agreement from "./Dashboard/Appointmentschedule/Agreement";
import ScheduleList from "./Dashboard/Appointmentschedule/List-View";
import AddAppointment from "./Dashboard/Appointmentschedule/Add-Appointment";
import AppointmentDashboard from "./Dashboard/Appointmentschedule/Appointment-Dashboard";
import AppointmentTaskEdit from "./Dashboard/Appointmentschedule/Appointment-Task-Edit";
import TaskAdd from "./Dashboard/Appointmentschedule/Add-Task";
import MenuAdd from "./Dashboard/Appointmentschedule/Menu";
import ClientProfile from "./pages/Stakeholder/clientprofile/profileclient.js";

import GroupList from "./Dashboard/Appointmentschedule/Group-List.js";
import AddlistGroup from "./Dashboard/Appointmentschedule/Add-Group.js";
import EditTimesheet from "./Dashboard/Appointmentschedule/EditTimesheet";

import SlidebarMenu from "./Dashboard/SidebarMenu/Sidebarmenu";

// HealtCarer Page
// import Healthavalability from "./Dashboard/HealthCarer/Health-Carer-Availability";
// import Healthcarerweek from "./Dashboard/HealthCarer/Health-Carer-Week";

// Induction routing

// import Induction from "./pages/superAdmin/Induction/Induction";

import Appointmentview from "./Dashboard/Appointmentschedule/Appointment-view";

import Addgoals from "./pages/healthCarer/Clients/Addgoals";
//import Goals from "./pages/healthCarer/Clients/Goals";
import AppointmenrViewPage from "./pages/components/appointmentView/Appointment-view";
import AddGroup from "./pages/superAdmin/Group/Edit";
import CheckSession from "../src/auth/auth";
import Box from "@mui/material/Box";
import Appbar from "./Dashboard/Layout/Appbar";
import Sidebar from "./Dashboard/Layout/Sidebar";
//import TimesheetAdmin from "./Dashboard/Timesheet/TimesheetAdmin";
import CheckRouteAccess from "./helpers/CheckRouteAccess";

import MedicalHistory from "./pages/superAdmin/Medication-management/Medical-History";
import AddMedicalHistory from "./pages/superAdmin/Medication-management/Add-Medical-History";
import AddMedication from "./pages/superAdmin/Medication-management/Add-Medication";
import EditMedication from "./pages/superAdmin/Medication-management/Edit-Medication";
import MedicationChart from "./pages/superAdmin/Medication-management/Medication-chart";
import PrnChart from "./pages/superAdmin/Medication-management/PrnChart";
import ScrollToTop from "./ScrollTop";

import SetupClientAccount from "../src/components/Explore-Market/Client-Form/Account-Setup";
import ExistingOrNewUser from "../src/components/Explore-Market/Client-Form/ExistingOrNewUser";
import HealthCarerLogin from "../src/components/Explore-Market/Client-Form/HealthCarerLogin";

import Features from "./Website/Features/SILManagement";
// import Blogs from "./Website/Blogs/Blogs";
import RosterManagement from "./Website/Features/RosterManagement";
//production build need to change this page

import GetSupport from "./Website/Get-Support";
import PickupShifts from "./Website/Pickup-Shifts";

// my account pages import

import MyAccount from "./Dashboard/myAccount_tabs/My-Account";
import TimeDemo from "./Dashboard/TimeDemo.js";
//import FundManagement from "./Dashboard/FundManagement/Fund-Management";
import ViewDetail from "./components/Explore-Market/View-Details/View-Details";
import PickupShiftsViewDetail from "./components/Explore-Market/View-Details/PickupShifts-View-Details";
import PickupShiftsViewDetailSp from "./components/Explore-Market/View-Details/PickupShifts-View-Details-SP";

import HelpCenter from "./Website/Help/Help-Center";

import Blogs from "../src/components/Website/Blogs/Blogs";
import BlogDetails from "../src/components/Website/Blogs/Blogs-Details";
import DeleteAccount from "../src/pages/superAdmin/DeleteAccount/Deleteaccountmobile";
import BlogDetailsOld from "./Website/Blogs/Blogs-Details";
import Guides from "../src/components/Website/Guides/Guides";
import GuidesDetails from "../src/components/Website/Guides/Guides-Details";
import BookDemo from "../src/components/Website/BookDemo/demoRequest";
import WebContact from "../src/components/Website/Contact";
import About from "./Website/About";
import HelpCenterDetails from "./Website/Help/Help-Center-Details";
import Pricing from "./Website/Pricing/Pricing";
import LandingPage from "../src/components/Website/LandingPage/Landing-Page";
// import Home from "./Website/Home";
// import Home from "./Website/CommingSoon";

import HomeProduction from "./Website/CommingSoon";
import HomeLocal from "./Website/Home";
import MailChimpPage from "./Website/MailchimpPage.js";

import WebPolicy from "../src/components/Website/Policy/Policy.js";
import MobilePolicy from "../src/components/Website/Policy/MobilePolicy.js";
import WebTermsConditions from "../src/components/Website/TermsConditions/TermsConditions.js";
import MobileTermsConditions from "../src/components/Website/TermsConditions/MobileTermsConditions.js";
import DocumentPassport from "./Website/Features/DocumentPassport";
import StaffInduction from "./Website/Features/StaffInduction";
import SILManagement from "./Website/Features/SILManagement";
import HealthDashboard from "./Website/Features/HealthDashboard";
import RoasterDashboard from "./pages/superAdmin/Dashboard/RoasterDashboard.js";

function App() {
  const isProduction = process.env.REACT_APP_SERVER_TYPE === "PRODUCTION";
  // commenting to go live on 12 June 2024
  // const Home = isProduction ? HomeProduction : HomeLocal;

  //commenting this because neeraj want to see contact page at home 17 june 2024
  //const Home = HomeLocal;

  const Home = isProduction ? MailChimpPage : HomeLocal;

  // async function requestPermission() {
  //   const permission = await Notification.requestPermission();
  //   if (permission === "granted") {
  //     // generate token
  //     const token = await getToken(messaging, {
  //       vapidKey:
  //         "BA4FTe2hwE7IgPNZmequMKyTocWKgYe1ftmrJFdPYilNXXzd4PxakhODuGnHbjx6rQT8DQ7hn22YK5zZEkspcBQ",
  //     });
  //     console.log("token--", token);
  //   } else if (permission == "denied") {
  //     alert("You denied for the notifications");
  //   }
  // }

  useEffect(() => {
    // requestPermission();
    // req user for notifications permission
  }, []);

  // useEffect(() => {
  //   CheckSession();
  // }, []);

  useEffect(() => {
    // Call CheckSession on every click
    const handleClick = () => CheckSession();
    document.addEventListener("click", handleClick);
    // Cleanup function
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const { PUBLIC_URL } = process.env;
  const user = localstorageData();

  //realod the page when user click on the back button clicked
  useEffect(() => {
    window.addEventListener("popstate", () => {
      window.location.reload();
    });
  }, []);

  const healthCarerNavigation = "/health-carer/onboard";
  const supportCoordinatorNavigation = "/support-coordinator/onboard";
  const clientNavigation = "/client/onboard";
  const serviceProviderNavigation = "/service-provider/onboard";
  const superAdminNavigation = "/super-admin/home";
  const stakeholderNavigation = "/stakeholder/home";
  const itManagerNavigation = "/it-manager/home";
  const planManagerNavigation = "/plan-manager/home";
  const accountantNavigation = "/accountant/home";
  const supervisorNavigation = "/supervisor/home";
  const familyMemberNavigation = "/family-member/home";
  const generalManagerNavigation = "/general-manager/home";
  const hrNavigation = "/hr/home";

  let MemoizedAppbar, MemoizedSidebar;

  MemoizedAppbar = useMemo(() => <Appbar />, []);
  MemoizedSidebar = useMemo(() => <Sidebar />, []);

  const SetupAccountHealthCarer = lazy(() =>
    import("./pages/healthCarer/Account-Setup")
  );
  const SetupAccountClient = lazy(() => import("./pages/client/Account-Setup"));

  const SetupAccountServiceProvider = lazy(() =>
    import("./pages/serviceProvider/Account-Setup")
  );

  const SetupAccountSupportCoordinator = lazy(() =>
    import("./pages/supportCoordinator/Account-Setup")
  );

  const roleDataLowerCase = user?.role?.toLowerCase();

  //setup home page routing varun
  const currUrlpathname = window.location.pathname;
  //

  const PendingApproval = lazy(() =>
    import("../src/pages/superAdmin/pending-approval")
  );

  return (
    <>
      <BrowserRouter>
        <Routes>

        {/* <Route path="/test" element={<RoasterDashboard />} /> */}

          <Route path="/slidebarmenu" element={<SlidebarMenu />} />
          <Route path="/medical-history" element={<MedicalHistory />} />
          <Route path="/add-medical-history" element={<AddMedicalHistory />} />
          <Route path="/add-medication" element={<AddMedication />} />
          <Route path="/edit-medication" element={<EditMedication />} />
          <Route path="/medication-chart" element={<MedicationChart />} />
          <Route path="/prn-chart" element={<PrnChart />} />
          <Route path="/edittimesheet" element={<EditTimesheet />} />
          <Route path="/addlistgroup" element={<AddlistGroup />} />
          <Route path="/grouplist" element={<GroupList />} />
          <Route path="/accountsetup" element={<AccountSetup />} />
          <Route path="/availibility" element={<Availibility />} />
          <Route path="/client-management" element={<ClientManagement />} />
          <Route path="/timesheet" element={<Timesheet />} />
          <Route path="/timesheet-show" element={<Timesheetshow />} />
          <Route path="/add-expenses" element={<AddExpenses />} />
          <Route path="/appointment" element={<Appointment />} />
          <Route path="/my-account" element={<MyAccount />} />
          <Route path="/add-appointment" element={<AddAppointment />} />
          <Route
            path="/appointment-dashboard"
            element={<AppointmentDashboard />}
          />
          <Route
            path="/appointment-taskedit"
            element={<AppointmentTaskEdit />}
          />
          <Route path="/appointment-details" element={<Appointmentdetails />} />
          <Route path="/appoint-expenses" element={<AppointExpenses />} />
          <Route path="/agreement" element={<Agreement />} />
          <Route path="/addtask" element={<TaskAdd />} />
          <Route path="/menu-add" element={<MenuAdd />} />
          <Route path="/schedule-list" element={<ScheduleList />} />
          <Route path="/appointmentview" element={<Appointmentview />} />
          <Route path="/addgoals" element={<Addgoals />} />
          <Route path="/timesheet" element={<Timesheet />} />
          <Route path="/add-expenses" element={<AddExpenses />} />
          <Route path="/client-management" element={<ClientManagement />} />
          <Route path="/client-profile" element={<ClientProfile />} />
          <Route path="/appointmentdetails" element={<AppointmenrViewPage />} />
          <Route path="/addgroup" element={<AddGroup />} />
        </Routes>
      </BrowserRouter>

      <Router basename={PUBLIC_URL} forceRefresh={true}>
        <ScrollToTop>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <CheckRouteAccess />

          <Routes>
            <Route path="/slidebarmenu" element={<SlidebarMenu />} />

            <Route path="/timedemo" element={<TimeDemo />} />
            <Route path="/medical-history" element={<MedicalHistory />} />
            <Route
              path="/add-medical-history"
              element={<AddMedicalHistory />}
            />
            <Route path="/add-medication" element={<AddMedication />} />
            <Route path="/edit-medication" element={<EditMedication />} />
            <Route path="/medication-chart" element={<MedicationChart />} />
            <Route path="/prn-chart" element={<PrnChart />} />
            <Route path="/edittimesheet" element={<EditTimesheet />} />
            <Route path="/addlistgroup" element={<AddlistGroup />} />
            <Route path="/grouplist" element={<GroupList />} />
            <Route path="/accountsetup" element={<AccountSetup />} />
            <Route path="/availibility" element={<Availibility />} />
            <Route path="/client-management" element={<ClientManagement />} />
            <Route path="/timesheet" element={<Timesheet />} />
            <Route path="/timesheet-show" element={<Timesheetshow />} />
            <Route path="/add-expenses" element={<AddExpenses />} />
            <Route path="/appointment" element={<Appointment />} />
            <Route path="/my-account" element={<MyAccount />} />
            <Route path="/add-appointment" element={<AddAppointment />} />
            <Route
              path="/appointment-dashboard"
              element={<AppointmentDashboard />}
            />
            <Route
              path="/appointment-taskedit"
              element={<AppointmentTaskEdit />}
            />
            <Route
              path="/appointment-details"
              element={<Appointmentdetails />}
            />
            <Route path="/appoint-expenses" element={<AppointExpenses />} />
            <Route path="/agreement" element={<Agreement />} />
            <Route path="/addtask" element={<TaskAdd />} />
            <Route path="/menu-add" element={<MenuAdd />} />
            <Route path="/schedule-list" element={<ScheduleList />} />
            <Route path="/appointmentview" element={<Appointmentview />} />
            <Route path="/addgoals" element={<Addgoals />} />
            <Route path="/timesheet" element={<Timesheet />} />
            <Route path="/add-expenses" element={<AddExpenses />} />
            <Route path="/client-management" element={<ClientManagement />} />
            <Route path="/client-profile" element={<ClientProfile />} />
            <Route
              path="/appointmentdetails"
              element={<AppointmenrViewPage />}
            />
            <Route path="/addgroup" element={<AddGroup />} />
          </Routes>

          {user.role ? (
            user.role === "healthCarer" ? (
              <>
                <Routes>
                  <Route index element={<Home />} />
                  <Route path="/" element={<Home />} />

                  <Route
                    path="/sign-in"
                    element={<Navigate to={healthCarerNavigation} />}
                  />
                  <Route
                    path="/sign-up"
                    element={<Navigate to={healthCarerNavigation} />}
                  />
                  <Route
                    path="/healthcarer-sign-up"
                    element={<Navigate to={healthCarerNavigation} />}
                  />
                  <Route
                    path="/verify-otp"
                    element={<Navigate to={healthCarerNavigation} />}
                  />
                  <Route
                    path="/forgot-password"
                    element={<Navigate to={healthCarerNavigation} />}
                  />
                  <Route
                    path="/reset-password/:id"
                    element={<Navigate to={healthCarerNavigation} />}
                  />
                  <Route
                    path="/book-demo"
                    element={<Navigate to={healthCarerNavigation} />}
                  />
                  <Route path="/get-support" element={<GetSupport />} />

                  <Route
                    path="/health-carer/onboard"
                    element={
                      <Suspense>
                        <SetupAccountHealthCarer />
                      </Suspense>
                    }
                  />

                  <Route
                    path="/health-carer/pending-approval"
                    element={
                      <Suspense>
                        <PendingApproval />
                      </Suspense>
                    }
                  />

                  <Route path="/pickup-shifts" element={<PickupShifts />} />

                  {user?.isSoleTrader === true ? (
                    <>
                      <Route
                        path="/pickup-shifts/view-details"
                        element={<PickupShiftsViewDetail />}
                      />
                      <Route
                        path="/pickup-shifts/view-details-sp"
                        element={<PickupShiftsViewDetailSp />}
                      />
                    </>
                  ) : null}
                  <Route path="/privacy-policy" element={<WebPolicy />} />

                  {/* ------------------ */}

                  <Route path="/about" element={<About />} />
                  <Route path="/help-center" element={<HelpCenter />} />
                  <Route path="/pricing" element={<Pricing />} />
                  <Route path="/sil-management" element={<SILManagement />} />
                  <Route path="/staff-induction" element={<StaffInduction />} />
                  <Route
                    path="/health-dashboard"
                    element={<HealthDashboard />}
                  />
                  <Route
                    path="/roster-management"
                    element={<RosterManagement />}
                  />
                  <Route
                    path="/document-passport"
                    element={<DocumentPassport />}
                  />
                  <Route path="/blogs" element={<Blogs />} />
                  <Route path="/blog-details" element={<BlogDetails />} />
                  <Route path="/guides" element={<Guides />} />
                  <Route path="/guide-details" element={<GuidesDetails />} />
                  <Route path="/terms" element={<WebTermsConditions />} />

                  {/* ------------------ */}

                  <Route
                    path="/help-center-details"
                    element={<HelpCenterDetails />}
                  />
                  <Route path="/contact" element={<WebContact />} />
                  <Route path="/*" element={<HealthCarer />} />
                </Routes>
              </>
            ) : user.role === "superAdmin" ? (
              <>
                {currUrlpathname === "/privacy-policy" ? (
                  <Routes>
                    <Route path="/privacy-policy" element={<WebPolicy />} />
                  </Routes>
                ) : currUrlpathname === "/help-center-details/" ? (
                  <Routes>
                    <Route
                      path="/help-center-details"
                      element={<HelpCenterDetails />}
                    />
                  </Routes>
                ) : currUrlpathname === "/contact" ? (
                  <Routes>
                    <Route path="/contact" element={<WebContact />} />
                  </Routes>
                ) : currUrlpathname === "/about" ? (
                  <Routes>
                    <Route path="/about" element={<About />} />
                  </Routes>
                ) : currUrlpathname === "/help-center" ? (
                  <Routes>
                    <Route path="/help-center" element={<HelpCenter />} />
                  </Routes>
                ) : currUrlpathname === "/pricing" ? (
                  <Routes>
                    <Route path="/pricing" element={<Pricing />} />
                  </Routes>
                ) : currUrlpathname === "/sil-management" ? (
                  <Routes>
                    <Route path="/sil-management" element={<SILManagement />} />
                  </Routes>
                ) : currUrlpathname === "/staff-induction" ? (
                  <Routes>
                    <Route
                      path="/staff-induction"
                      element={<StaffInduction />}
                    />
                  </Routes>
                ) : currUrlpathname === "/health-dashboard" ? (
                  <Routes>
                    <Route
                      path="/health-dashboard"
                      element={<HealthDashboard />}
                    />
                  </Routes>
                ) : currUrlpathname === "/roster-management" ? (
                  <Routes>
                    <Route
                      path="/roster-management"
                      element={<RosterManagement />}
                    />
                  </Routes>
                ) : currUrlpathname === "/document-passport" ? (
                  <Routes>
                    <Route
                      path="/document-passport"
                      element={<DocumentPassport />}
                    />
                  </Routes>
                ) : currUrlpathname === "/blogs" ? (
                  <Routes>
                    <Route path="/blogs" element={<Blogs />} />
                  </Routes>
                ) : currUrlpathname === "/blog-details" ? (
                  <Routes>
                    <Route path="/blog-details" element={<BlogDetails />} />
                  </Routes>
                ) : currUrlpathname === "/guides" ? (
                  <Routes>
                    <Route path="/guides" element={<Guides />} />
                  </Routes>
                ) : currUrlpathname === "/guide-details" ? (
                  <Routes>
                    <Route path="/guide-details" element={<GuidesDetails />} />
                  </Routes>
                ) : currUrlpathname === "/terms" ? (
                  <Routes>
                    <Route path="/terms" element={<WebTermsConditions />} />
                  </Routes>
                ) : currUrlpathname === "/" ? (
                  <Routes>
                    <Route index element={<Home />} />
                    <Route path="/" element={<Home />} />
                  </Routes>
                ) : (
                  <>
                    <Box className="dashboard-form" id="agency">
                      <Box sx={{ width: "100%" }} className="appbar-border">
                        <Box className="appbar-main">{MemoizedAppbar}</Box>
                      </Box>
                      <Box className="d-flex dashboard-main-box">
                        {MemoizedSidebar}
                        <Routes>
                          <Route
                            path="/sign-in"
                            element={<Navigate to={superAdminNavigation} />}
                          />
                          <Route
                            path="/sign-up"
                            element={<Navigate to={superAdminNavigation} />}
                          />
                          <Route
                            path="/healthcarer-sign-up"
                            element={<Navigate to={superAdminNavigation} />}
                          />
                          <Route
                            path="/verify-otp"
                            element={<Navigate to={superAdminNavigation} />}
                          />
                          <Route
                            path="/forgot-password"
                            element={<Navigate to={superAdminNavigation} />}
                          />
                          <Route
                            path="/reset-password/:id"
                            element={<Navigate to={superAdminNavigation} />}
                          />
                          <Route
                            path="/book-demo"
                            element={<Navigate to={superAdminNavigation} />}
                          />
                          <Route path="/*" element={<SuperAdmin />} />
                        </Routes>
                      </Box>
                    </Box>
                  </>
                )}
              </>
            ) : user.role === "supportCoordinator" ? (
              <>
                <Routes>
                  <Route index element={<Home />} />
                  <Route path="/" element={<Home />} />
                  <Route
                    path="/sign-in"
                    element={<Navigate to={supportCoordinatorNavigation} />}
                  />

                  <Route
                    path="/sign-up"
                    element={<Navigate to={supportCoordinatorNavigation} />}
                  />
                  <Route
                    path="/healthcarer-sign-up"
                    element={<Navigate to={supportCoordinatorNavigation} />}
                  />
                  <Route
                    path="/verify-otp"
                    element={<Navigate to={supportCoordinatorNavigation} />}
                  />
                  <Route
                    path="/verify-email"
                    element={<Navigate to={supportCoordinatorNavigation} />}
                  />
                  <Route
                    path="/forgot-password"
                    element={<Navigate to={supportCoordinatorNavigation} />}
                  />
                  <Route
                    path="/reset-password/:id"
                    element={<Navigate to={supportCoordinatorNavigation} />}
                  />
                  <Route
                    path="/book-demo"
                    element={<Navigate to={supportCoordinatorNavigation} />}
                  />
                  <Route path="/get-support" element={<GetSupport />} />

                  <Route
                    path="/support-coordinator/onboard"
                    element={
                      <Suspense>
                        <SetupAccountSupportCoordinator />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/support-coordinator/pending-approval"
                    element={
                      <Suspense>
                        <PendingApproval />
                      </Suspense>
                    }
                  />
                  <Route path="/privacy-policy" element={<WebPolicy />} />

                  {/* ------------------ */}

                  <Route path="/about" element={<About />} />
                  <Route path="/help-center" element={<HelpCenter />} />
                  <Route path="/pricing" element={<Pricing />} />
                  <Route path="/sil-management" element={<SILManagement />} />
                  <Route path="/staff-induction" element={<StaffInduction />} />
                  <Route
                    path="/health-dashboard"
                    element={<HealthDashboard />}
                  />
                  <Route
                    path="/roster-management"
                    element={<RosterManagement />}
                  />
                  <Route
                    path="/document-passport"
                    element={<DocumentPassport />}
                  />
                  <Route path="/blogs" element={<Blogs />} />
                  <Route path="/blog-details" element={<BlogDetails />} />
                  <Route path="/guides" element={<Guides />} />
                  <Route path="/guide-details" element={<GuidesDetails />} />
                  <Route path="/terms" element={<WebTermsConditions />} />

                  {/* ------------------ */}

                  <Route
                    path="/help-center-details"
                    element={<HelpCenterDetails />}
                  />
                  <Route path="/contact" element={<WebContact />} />
                  <Route path="/*" element={<SupportCoordinator />} />
                </Routes>
              </>
            ) : user.role === "Stakeholder" ? (
              <>
                {currUrlpathname === "/privacy-policy" ? (
                  <Routes>
                    <Route path="/privacy-policy" element={<WebPolicy />} />
                  </Routes>
                ) : currUrlpathname === "/help-center-details/" ? (
                  <Routes>
                    <Route
                      path="/help-center-details"
                      element={<HelpCenterDetails />}
                    />
                  </Routes>
                ) : currUrlpathname === "/contact" ? (
                  <Routes>
                    <Route path="/contact" element={<WebContact />} />
                  </Routes>
                ) : currUrlpathname === "/about" ? (
                  <Routes>
                    <Route path="/about" element={<About />} />
                  </Routes>
                ) : currUrlpathname === "/help-center" ? (
                  <Routes>
                    <Route path="/help-center" element={<HelpCenter />} />
                  </Routes>
                ) : currUrlpathname === "/pricing" ? (
                  <Routes>
                    <Route path="/pricing" element={<Pricing />} />
                  </Routes>
                ) : currUrlpathname === "/sil-management" ? (
                  <Routes>
                    <Route path="/sil-management" element={<SILManagement />} />
                  </Routes>
                ) : currUrlpathname === "/staff-induction" ? (
                  <Routes>
                    <Route
                      path="/staff-induction"
                      element={<StaffInduction />}
                    />
                  </Routes>
                ) : currUrlpathname === "/health-dashboard" ? (
                  <Routes>
                    <Route
                      path="/health-dashboard"
                      element={<HealthDashboard />}
                    />
                  </Routes>
                ) : currUrlpathname === "/roster-management" ? (
                  <Routes>
                    <Route
                      path="/roster-management"
                      element={<RosterManagement />}
                    />
                  </Routes>
                ) : currUrlpathname === "/document-passport" ? (
                  <Routes>
                    <Route
                      path="/document-passport"
                      element={<DocumentPassport />}
                    />
                  </Routes>
                ) : currUrlpathname === "/blogs" ? (
                  <Routes>
                    <Route path="/blogs" element={<Blogs />} />
                  </Routes>
                ) : currUrlpathname === "/blog-details" ? (
                  <Routes>
                    <Route path="/blog-details" element={<BlogDetails />} />
                  </Routes>
                ) : currUrlpathname === "/guides" ? (
                  <Routes>
                    <Route path="/guides" element={<Guides />} />
                  </Routes>
                ) : currUrlpathname === "/guide-details" ? (
                  <Routes>
                    <Route path="/guide-details" element={<GuidesDetails />} />
                  </Routes>
                ) : currUrlpathname === "/terms" ? (
                  <Routes>
                    <Route path="/terms" element={<WebTermsConditions />} />
                  </Routes>
                ) : currUrlpathname === "/" ? (
                  <Routes>
                    <Route index element={<Home />} />
                    <Route path="/" element={<Home />} />
                  </Routes>
                ) : (
                  <Box className="dashboard-form" id="agency">
                    <Box sx={{ width: "100%" }} className="appbar-border">
                      <Box className="appbar-main">{MemoizedAppbar}</Box>
                    </Box>
                    <Box className="d-flex dashboard-main-box">
                      {MemoizedSidebar}
                      <Routes>
                        <Route
                          path="/sign-in"
                          element={<Navigate to={stakeholderNavigation} />}
                        />
                        <Route
                          path="/sign-up"
                          element={<Navigate to={stakeholderNavigation} />}
                        />
                        <Route
                          path="/healthcarer-sign-up"
                          element={<Navigate to={stakeholderNavigation} />}
                        />
                        <Route
                          path="/verify-otp"
                          element={<Navigate to={stakeholderNavigation} />}
                        />
                        <Route
                          path="/forgot-password"
                          element={<Navigate to={stakeholderNavigation} />}
                        />
                        <Route
                          path="/reset-password/:id"
                          element={<Navigate to={stakeholderNavigation} />}
                        />
                        <Route
                          path="/book-demo"
                          element={<Navigate to={stakeholderNavigation} />}
                        />
                        <Route path="/privacy-policy" element={<WebPolicy />} />

                        <Route
                          path="/help-center-details"
                          element={<HelpCenterDetails />}
                        />
                        <Route path="/*" element={<Stakeholder />} />
                      </Routes>
                    </Box>
                  </Box>
                )}
              </>
            ) : user.role === "client" ? (
              <>
                <Routes>
                  <Route index element={<Home />} />
                  <Route path="/" element={<Home />} />
                  <Route
                    path="/sign-in"
                    element={<Navigate to={clientNavigation} />}
                  />
                  <Route
                    path="/sign-up"
                    element={<Navigate to={clientNavigation} />}
                  />
                  <Route
                    path="/healthcarer-sign-up"
                    element={<Navigate to={clientNavigation} />}
                  />
                  <Route path="/get-support" element={<GetSupport />} />

                  <Route
                    path="/verify-otp"
                    element={<Navigate to={clientNavigation} />}
                  />
                  <Route
                    path="/forgot-password"
                    element={<Navigate to={clientNavigation} />}
                  />
                  <Route
                    path="/reset-password/:id"
                    element={<Navigate to={clientNavigation} />}
                  />
                  <Route
                    path="/book-demo"
                    element={<Navigate to={clientNavigation} />}
                  />
                  <Route
                    path="/client/onboard"
                    element={
                      <Suspense>
                        <SetupAccountClient />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/client/pending-approval"
                    element={
                      <Suspense>
                        <PendingApproval />
                      </Suspense>
                    }
                  />
                  <Route path="/view-details" element={<ViewDetail />} />
                  <Route path="/privacy-policy" element={<WebPolicy />} />

                  {/* ------------------ */}

                  <Route path="/about" element={<About />} />
                  <Route path="/help-center" element={<HelpCenter />} />
                  <Route path="/pricing" element={<Pricing />} />
                  <Route path="/sil-management" element={<SILManagement />} />
                  <Route path="/staff-induction" element={<StaffInduction />} />
                  <Route
                    path="/health-dashboard"
                    element={<HealthDashboard />}
                  />
                  <Route
                    path="/roster-management"
                    element={<RosterManagement />}
                  />
                  <Route
                    path="/document-passport"
                    element={<DocumentPassport />}
                  />
                  <Route path="/blogs" element={<Blogs />} />
                  <Route path="/blog-details" element={<BlogDetails />} />
                  <Route path="/guides" element={<Guides />} />
                  <Route path="/guide-details" element={<GuidesDetails />} />
                  <Route path="/terms" element={<WebTermsConditions />} />

                  {/* ------------------ */}

                  <Route
                    path="/help-center-details"
                    element={<HelpCenterDetails />}
                  />
                  <Route path="/contact" element={<WebContact />} />
                  <Route path="/*" element={<Client />} />
                </Routes>
              </>
            ) : user.role === "serviceProvider" ? (
              <>
                <Routes>
                  <Route index element={<Home />} />
                  <Route path="/" element={<Home />} />
                  <Route
                    path="/sign-in"
                    element={<Navigate to={serviceProviderNavigation} />}
                  />
                  <Route
                    path="/sign-up"
                    element={<Navigate to={serviceProviderNavigation} />}
                  />
                  <Route
                    path="/healthcarer-sign-up"
                    element={<Navigate to={serviceProviderNavigation} />}
                  />
                  <Route
                    path="/verify-otp"
                    element={<Navigate to={serviceProviderNavigation} />}
                  />
                  <Route
                    path="/forgot-password"
                    element={<Navigate to={serviceProviderNavigation} />}
                  />
                  <Route
                    path="/reset-password/:id"
                    element={<Navigate to={serviceProviderNavigation} />}
                  />
                  <Route
                    path="/book-demo"
                    element={<Navigate to={serviceProviderNavigation} />}
                  />
                  <Route path="/get-support" element={<GetSupport />} />

                  <Route
                    path="/service-provider/pending-approval"
                    element={
                      <Suspense>
                        <PendingApproval />
                      </Suspense>
                    }
                  />

                  <Route
                    path="/service-provider/onboard"
                    element={
                      <Suspense>
                        <SetupAccountServiceProvider />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/service-provider/recieved-request-detail"
                    element={<ViewDetail />}
                  />
                  <Route path="/privacy-policy" element={<WebPolicy />} />

                  {/* ------------------ */}

                  <Route path="/about" element={<About />} />
                  <Route path="/help-center" element={<HelpCenter />} />
                  <Route path="/pricing" element={<Pricing />} />
                  <Route path="/sil-management" element={<SILManagement />} />
                  <Route path="/staff-induction" element={<StaffInduction />} />
                  <Route
                    path="/health-dashboard"
                    element={<HealthDashboard />}
                  />
                  <Route
                    path="/roster-management"
                    element={<RosterManagement />}
                  />
                  <Route
                    path="/document-passport"
                    element={<DocumentPassport />}
                  />
                  <Route path="/blogs" element={<Blogs />} />
                  <Route path="/blog-details" element={<BlogDetails />} />
                  <Route path="/guides" element={<Guides />} />
                  <Route path="/guide-details" element={<GuidesDetails />} />
                  <Route path="/terms" element={<WebTermsConditions />} />

                  {/* ------------------ */}

                  <Route
                    path="/help-center-details"
                    element={<HelpCenterDetails />}
                  />

                  <Route path="/contact" element={<WebContact />} />

                  <Route path="/*" element={<ServiceProvider />} />
                </Routes>
              </>
            ) : user.role === "itManager" ? (
              <>
                {currUrlpathname === "/privacy-policy" ? (
                  <Routes>
                    <Route path="/privacy-policy" element={<WebPolicy />} />
                  </Routes>
                ) : currUrlpathname === "/help-center-details/" ? (
                  <Routes>
                    <Route
                      path="/help-center-details"
                      element={<HelpCenterDetails />}
                    />
                  </Routes>
                ) : currUrlpathname === "/contact" ? (
                  <Routes>
                    <Route path="/contact" element={<WebContact />} />
                  </Routes>
                ) : currUrlpathname === "/about" ? (
                  <Routes>
                    <Route path="/about" element={<About />} />
                  </Routes>
                ) : currUrlpathname === "/help-center" ? (
                  <Routes>
                    <Route path="/help-center" element={<HelpCenter />} />
                  </Routes>
                ) : currUrlpathname === "/pricing" ? (
                  <Routes>
                    <Route path="/pricing" element={<Pricing />} />
                  </Routes>
                ) : currUrlpathname === "/sil-management" ? (
                  <Routes>
                    <Route path="/sil-management" element={<SILManagement />} />
                  </Routes>
                ) : currUrlpathname === "/staff-induction" ? (
                  <Routes>
                    <Route
                      path="/staff-induction"
                      element={<StaffInduction />}
                    />
                  </Routes>
                ) : currUrlpathname === "/health-dashboard" ? (
                  <Routes>
                    <Route
                      path="/health-dashboard"
                      element={<HealthDashboard />}
                    />
                  </Routes>
                ) : currUrlpathname === "/roster-management" ? (
                  <Routes>
                    <Route
                      path="/roster-management"
                      element={<RosterManagement />}
                    />
                  </Routes>
                ) : currUrlpathname === "/document-passport" ? (
                  <Routes>
                    <Route
                      path="/document-passport"
                      element={<DocumentPassport />}
                    />
                  </Routes>
                ) : currUrlpathname === "/blogs" ? (
                  <Routes>
                    <Route path="/blogs" element={<Blogs />} />
                  </Routes>
                ) : currUrlpathname === "/blog-details" ? (
                  <Routes>
                    <Route path="/blog-details" element={<BlogDetails />} />
                  </Routes>
                ) : currUrlpathname === "/guides" ? (
                  <Routes>
                    <Route path="/guides" element={<Guides />} />
                  </Routes>
                ) : currUrlpathname === "/guide-details" ? (
                  <Routes>
                    <Route path="/guide-details" element={<GuidesDetails />} />
                  </Routes>
                ) : currUrlpathname === "/terms" ? (
                  <Routes>
                    <Route path="/terms" element={<WebTermsConditions />} />
                  </Routes>
                ) : currUrlpathname === "/" ? (
                  <Routes>
                    <Route index element={<Home />} />
                    <Route path="/" element={<Home />} />
                  </Routes>
                ) : (
                  <Box className="dashboard-form" id="agency">
                    <Box sx={{ width: "100%" }} className="appbar-border">
                      <Box className="appbar-main">{MemoizedAppbar}</Box>
                    </Box>
                    <Box className="d-flex dashboard-main-box">
                      {MemoizedSidebar}
                      <Routes>
                        <Route
                          path="/sign-in"
                          element={<Navigate to={itManagerNavigation} />}
                        />

                        <Route
                          path="/sign-up"
                          element={<Navigate to={itManagerNavigation} />}
                        />
                        <Route
                          path="/healthcarer-sign-up"
                          element={<Navigate to={itManagerNavigation} />}
                        />
                        <Route
                          path="/verify-otp"
                          element={<Navigate to={itManagerNavigation} />}
                        />
                        <Route
                          path="/forgot-password"
                          element={<Navigate to={itManagerNavigation} />}
                        />
                        <Route
                          path="/reset-password/:id"
                          element={<Navigate to={itManagerNavigation} />}
                        />
                        <Route
                          path="/book-demo"
                          element={<Navigate to={itManagerNavigation} />}
                        />
                        <Route path="/privacy-policy" element={<WebPolicy />} />
                        <Route
                          path="/help-center-details"
                          element={<HelpCenterDetails />}
                        />
                        <Route path="/*" element={<ITManager />} />
                      </Routes>
                    </Box>
                  </Box>
                )}
              </>
            ) : user.role === "planManager" ? (
              <>
                {currUrlpathname === "/privacy-policy" ? (
                  <Routes>
                    <Route path="/privacy-policy" element={<WebPolicy />} />
                  </Routes>
                ) : currUrlpathname === "/help-center-details/" ? (
                  <Routes>
                    <Route
                      path="/help-center-details"
                      element={<HelpCenterDetails />}
                    />
                  </Routes>
                ) : currUrlpathname === "/contact" ? (
                  <Routes>
                    <Route path="/contact" element={<WebContact />} />
                  </Routes>
                ) : currUrlpathname === "/about" ? (
                  <Routes>
                    <Route path="/about" element={<About />} />
                  </Routes>
                ) : currUrlpathname === "/help-center" ? (
                  <Routes>
                    <Route path="/help-center" element={<HelpCenter />} />
                  </Routes>
                ) : currUrlpathname === "/pricing" ? (
                  <Routes>
                    <Route path="/pricing" element={<Pricing />} />
                  </Routes>
                ) : currUrlpathname === "/sil-management" ? (
                  <Routes>
                    <Route path="/sil-management" element={<SILManagement />} />
                  </Routes>
                ) : currUrlpathname === "/staff-induction" ? (
                  <Routes>
                    <Route
                      path="/staff-induction"
                      element={<StaffInduction />}
                    />
                  </Routes>
                ) : currUrlpathname === "/health-dashboard" ? (
                  <Routes>
                    <Route
                      path="/health-dashboard"
                      element={<HealthDashboard />}
                    />
                  </Routes>
                ) : currUrlpathname === "/roster-management" ? (
                  <Routes>
                    <Route
                      path="/roster-management"
                      element={<RosterManagement />}
                    />
                  </Routes>
                ) : currUrlpathname === "/document-passport" ? (
                  <Routes>
                    <Route
                      path="/document-passport"
                      element={<DocumentPassport />}
                    />
                  </Routes>
                ) : currUrlpathname === "/blogs" ? (
                  <Routes>
                    <Route path="/blogs" element={<Blogs />} />
                  </Routes>
                ) : currUrlpathname === "/blog-details" ? (
                  <Routes>
                    <Route path="/blog-details" element={<BlogDetails />} />
                  </Routes>
                ) : currUrlpathname === "/guides" ? (
                  <Routes>
                    <Route path="/guides" element={<Guides />} />
                  </Routes>
                ) : currUrlpathname === "/guide-details" ? (
                  <Routes>
                    <Route path="/guide-details" element={<GuidesDetails />} />
                  </Routes>
                ) : currUrlpathname === "/terms" ? (
                  <Routes>
                    <Route path="/terms" element={<WebTermsConditions />} />
                  </Routes>
                ) : currUrlpathname === "/" ? (
                  <Routes>
                    <Route index element={<Home />} />
                    <Route path="/" element={<Home />} />
                  </Routes>
                ) : (
                  <Box className="dashboard-form" id="agency">
                    <Box sx={{ width: "100%" }} className="appbar-border">
                      <Box className="appbar-main">{MemoizedAppbar}</Box>
                    </Box>
                    <Box className="d-flex dashboard-main-box">
                      {MemoizedSidebar}
                      <Routes>
                        <Route
                          path="/sign-in"
                          element={<Navigate to={planManagerNavigation} />}
                        />
                        <Route
                          path="/sign-up"
                          element={<Navigate to={planManagerNavigation} />}
                        />
                        <Route
                          path="/healthcarer-sign-up"
                          element={<Navigate to={planManagerNavigation} />}
                        />
                        <Route
                          path="/verify-otp"
                          element={<Navigate to={planManagerNavigation} />}
                        />
                        <Route
                          path="/forgot-password"
                          element={<Navigate to={planManagerNavigation} />}
                        />
                        <Route
                          path="/reset-password/:id"
                          element={<Navigate to={planManagerNavigation} />}
                        />
                        <Route
                          path="/book-demo"
                          element={<Navigate to={planManagerNavigation} />}
                        />
                        <Route path="/privacy-policy" element={<WebPolicy />} />
                        <Route
                          path="/help-center-details"
                          element={<HelpCenterDetails />}
                        />
                        <Route path="/*" element={<PlanManager />} />
                      </Routes>
                    </Box>
                  </Box>
                )}
              </>
            ) : user.role === "supervisor" ? (
              <>
                {currUrlpathname === "/privacy-policy" ? (
                  <Routes>
                    <Route path="/privacy-policy" element={<WebPolicy />} />
                  </Routes>
                ) : currUrlpathname === "/help-center-details/" ? (
                  <Routes>
                    <Route
                      path="/help-center-details"
                      element={<HelpCenterDetails />}
                    />
                  </Routes>
                ) : currUrlpathname === "/contact" ? (
                  <Routes>
                    <Route path="/contact" element={<WebContact />} />
                  </Routes>
                ) : currUrlpathname === "/about" ? (
                  <Routes>
                    <Route path="/about" element={<About />} />
                  </Routes>
                ) : currUrlpathname === "/help-center" ? (
                  <Routes>
                    <Route path="/help-center" element={<HelpCenter />} />
                  </Routes>
                ) : currUrlpathname === "/pricing" ? (
                  <Routes>
                    <Route path="/pricing" element={<Pricing />} />
                  </Routes>
                ) : currUrlpathname === "/sil-management" ? (
                  <Routes>
                    <Route path="/sil-management" element={<SILManagement />} />
                  </Routes>
                ) : currUrlpathname === "/staff-induction" ? (
                  <Routes>
                    <Route
                      path="/staff-induction"
                      element={<StaffInduction />}
                    />
                  </Routes>
                ) : currUrlpathname === "/health-dashboard" ? (
                  <Routes>
                    <Route
                      path="/health-dashboard"
                      element={<HealthDashboard />}
                    />
                  </Routes>
                ) : currUrlpathname === "/roster-management" ? (
                  <Routes>
                    <Route
                      path="/roster-management"
                      element={<RosterManagement />}
                    />
                  </Routes>
                ) : currUrlpathname === "/document-passport" ? (
                  <Routes>
                    <Route
                      path="/document-passport"
                      element={<DocumentPassport />}
                    />
                  </Routes>
                ) : currUrlpathname === "/blogs" ? (
                  <Routes>
                    <Route path="/blogs" element={<Blogs />} />
                  </Routes>
                ) : currUrlpathname === "/blog-details" ? (
                  <Routes>
                    <Route path="/blog-details" element={<BlogDetails />} />
                  </Routes>
                ) : currUrlpathname === "/guides" ? (
                  <Routes>
                    <Route path="/guides" element={<Guides />} />
                  </Routes>
                ) : currUrlpathname === "/guide-details" ? (
                  <Routes>
                    <Route path="/guide-details" element={<GuidesDetails />} />
                  </Routes>
                ) : currUrlpathname === "/terms" ? (
                  <Routes>
                    <Route path="/terms" element={<WebTermsConditions />} />
                  </Routes>
                ) : currUrlpathname === "/" ? (
                  <Routes>
                    <Route index element={<Home />} />
                    <Route path="/" element={<Home />} />
                  </Routes>
                ) : (
                  <Box className="dashboard-form" id="agency">
                    <Box sx={{ width: "100%" }} className="appbar-border">
                      <Box className="appbar-main">{MemoizedAppbar}</Box>
                    </Box>
                    <Box className="d-flex dashboard-main-box">
                      {MemoizedSidebar}
                      <Routes>
                        <Route
                          path="/sign-in"
                          element={<Navigate to={supervisorNavigation} />}
                        />
                        <Route
                          path="/sign-up"
                          element={<Navigate to={supervisorNavigation} />}
                        />
                        <Route
                          path="/healthcarer-sign-up"
                          element={<Navigate to={supervisorNavigation} />}
                        />
                        <Route
                          path="/verify-otp"
                          element={<Navigate to={supervisorNavigation} />}
                        />
                        <Route
                          path="/forgot-password"
                          element={<Navigate to={supervisorNavigation} />}
                        />
                        <Route
                          path="/reset-password/:id"
                          element={<Navigate to={supervisorNavigation} />}
                        />
                        <Route
                          path="/book-demo"
                          element={<Navigate to={supervisorNavigation} />}
                        />
                        <Route path="/privacy-policy" element={<WebPolicy />} />
                        <Route
                          path="/help-center-details"
                          element={<HelpCenterDetails />}
                        />
                        <Route path="/*" element={<Supervisor />} />
                      </Routes>
                    </Box>
                  </Box>
                )}
              </>
            ) : user.role === "accountant" ? (
              <>
                {currUrlpathname === "/privacy-policy" ? (
                  <Routes>
                    <Route path="/privacy-policy" element={<WebPolicy />} />
                  </Routes>
                ) : currUrlpathname === "/help-center-details/" ? (
                  <Routes>
                    <Route
                      path="/help-center-details"
                      element={<HelpCenterDetails />}
                    />
                  </Routes>
                ) : currUrlpathname === "/contact" ? (
                  <Routes>
                    <Route path="/contact" element={<WebContact />} />
                  </Routes>
                ) : currUrlpathname === "/about" ? (
                  <Routes>
                    <Route path="/about" element={<About />} />
                  </Routes>
                ) : currUrlpathname === "/help-center" ? (
                  <Routes>
                    <Route path="/help-center" element={<HelpCenter />} />
                  </Routes>
                ) : currUrlpathname === "/pricing" ? (
                  <Routes>
                    <Route path="/pricing" element={<Pricing />} />
                  </Routes>
                ) : currUrlpathname === "/sil-management" ? (
                  <Routes>
                    <Route path="/sil-management" element={<SILManagement />} />
                  </Routes>
                ) : currUrlpathname === "/staff-induction" ? (
                  <Routes>
                    <Route
                      path="/staff-induction"
                      element={<StaffInduction />}
                    />
                  </Routes>
                ) : currUrlpathname === "/health-dashboard" ? (
                  <Routes>
                    <Route
                      path="/health-dashboard"
                      element={<HealthDashboard />}
                    />
                  </Routes>
                ) : currUrlpathname === "/roster-management" ? (
                  <Routes>
                    <Route
                      path="/roster-management"
                      element={<RosterManagement />}
                    />
                  </Routes>
                ) : currUrlpathname === "/document-passport" ? (
                  <Routes>
                    <Route
                      path="/document-passport"
                      element={<DocumentPassport />}
                    />
                  </Routes>
                ) : currUrlpathname === "/blogs" ? (
                  <Routes>
                    <Route path="/blogs" element={<Blogs />} />
                  </Routes>
                ) : currUrlpathname === "/blog-details" ? (
                  <Routes>
                    <Route path="/blog-details" element={<BlogDetails />} />
                  </Routes>
                ) : currUrlpathname === "/guides" ? (
                  <Routes>
                    <Route path="/guides" element={<Guides />} />
                  </Routes>
                ) : currUrlpathname === "/guide-details" ? (
                  <Routes>
                    <Route path="/guide-details" element={<GuidesDetails />} />
                  </Routes>
                ) : currUrlpathname === "/terms" ? (
                  <Routes>
                    <Route path="/terms" element={<WebTermsConditions />} />
                  </Routes>
                ) : currUrlpathname === "/" ? (
                  <Routes>
                    <Route index element={<Home />} />
                    <Route path="/" element={<Home />} />
                  </Routes>
                ) : (
                  <Box className="dashboard-form" id="agency">
                    <Box sx={{ width: "100%" }} className="appbar-border">
                      <Box className="appbar-main">{MemoizedAppbar}</Box>
                    </Box>
                    <Box className="d-flex dashboard-main-box">
                      {MemoizedSidebar}
                      <Routes>
                        <Route
                          path="/sign-in"
                          element={<Navigate to={accountantNavigation} />}
                        />
                        <Route
                          path="/sign-up"
                          element={<Navigate to={accountantNavigation} />}
                        />
                        <Route
                          path="/healthcarer-sign-up"
                          element={<Navigate to={accountantNavigation} />}
                        />
                        <Route
                          path="/verify-otp"
                          element={<Navigate to={accountantNavigation} />}
                        />
                        <Route
                          path="/forgot-password"
                          element={<Navigate to={accountantNavigation} />}
                        />
                        <Route
                          path="/reset-password/:id"
                          element={<Navigate to={accountantNavigation} />}
                        />
                        <Route
                          path="/book-demo"
                          element={<Navigate to={accountantNavigation} />}
                        />
                        <Route path="/privacy-policy" element={<WebPolicy />} />
                        <Route
                          path="/help-center-details"
                          element={<HelpCenterDetails />}
                        />
                        <Route path="/*" element={<Accountant />} />
                      </Routes>
                    </Box>
                  </Box>
                )}
              </>
            ) : user.role === "seniorAccountant" ? (
              <>
                {currUrlpathname === "/" ? (
                  <Routes>
                    <Route index element={<Home />} />
                    <Route path="/" element={<Home />} />
                  </Routes>
                ) : (
                  <Box className="dashboard-form" id="agency">
                    <Box sx={{ width: "100%" }} className="appbar-border">
                      <Box className="appbar-main">{MemoizedAppbar}</Box>
                    </Box>
                    <Box className="d-flex dashboard-main-box">
                      {MemoizedSidebar}
                      <Routes>
                        <Route
                          path="/sign-in"
                          element={<Navigate to={accountantNavigation} />}
                        />
                        <Route
                          path="/sign-up"
                          element={<Navigate to={accountantNavigation} />}
                        />
                        <Route
                          path="/healthcarer-sign-up"
                          element={<Navigate to={accountantNavigation} />}
                        />
                        <Route
                          path="/verify-otp"
                          element={<Navigate to={accountantNavigation} />}
                        />
                        <Route
                          path="/forgot-password"
                          element={<Navigate to={accountantNavigation} />}
                        />
                        <Route
                          path="/reset-password/:id"
                          element={<Navigate to={accountantNavigation} />}
                        />
                        <Route
                          path="/book-demo"
                          element={<Navigate to={accountantNavigation} />}
                        />
                        <Route path="/privacy-policy" element={<WebPolicy />} />
                        <Route
                          path="/help-center-details"
                          element={<HelpCenterDetails />}
                        />
                        <Route path="/*" element={<SeniorAccountant />} />
                      </Routes>
                    </Box>
                  </Box>
                )}
              </>
            ) : user.role === "familyMember" ? (
              <>
                {currUrlpathname === "/privacy-policy" ? (
                  <Routes>
                    <Route path="/privacy-policy" element={<WebPolicy />} />
                  </Routes>
                ) : currUrlpathname === "/help-center-details/" ? (
                  <Routes>
                    <Route
                      path="/help-center-details"
                      element={<HelpCenterDetails />}
                    />
                  </Routes>
                ) : currUrlpathname === "/contact" ? (
                  <Routes>
                    <Route path="/contact" element={<WebContact />} />
                  </Routes>
                ) : currUrlpathname === "/about" ? (
                  <Routes>
                    <Route path="/about" element={<About />} />
                  </Routes>
                ) : currUrlpathname === "/help-center" ? (
                  <Routes>
                    <Route path="/help-center" element={<HelpCenter />} />
                  </Routes>
                ) : currUrlpathname === "/pricing" ? (
                  <Routes>
                    <Route path="/pricing" element={<Pricing />} />
                  </Routes>
                ) : currUrlpathname === "/sil-management" ? (
                  <Routes>
                    <Route path="/sil-management" element={<SILManagement />} />
                  </Routes>
                ) : currUrlpathname === "/staff-induction" ? (
                  <Routes>
                    <Route
                      path="/staff-induction"
                      element={<StaffInduction />}
                    />
                  </Routes>
                ) : currUrlpathname === "/health-dashboard" ? (
                  <Routes>
                    <Route
                      path="/health-dashboard"
                      element={<HealthDashboard />}
                    />
                  </Routes>
                ) : currUrlpathname === "/roster-management" ? (
                  <Routes>
                    <Route
                      path="/roster-management"
                      element={<RosterManagement />}
                    />
                  </Routes>
                ) : currUrlpathname === "/document-passport" ? (
                  <Routes>
                    <Route
                      path="/document-passport"
                      element={<DocumentPassport />}
                    />
                  </Routes>
                ) : currUrlpathname === "/blogs" ? (
                  <Routes>
                    <Route path="/blogs" element={<Blogs />} />
                  </Routes>
                ) : currUrlpathname === "/blog-details" ? (
                  <Routes>
                    <Route path="/blog-details" element={<BlogDetails />} />
                  </Routes>
                ) : currUrlpathname === "/guides" ? (
                  <Routes>
                    <Route path="/guides" element={<Guides />} />
                  </Routes>
                ) : currUrlpathname === "/guide-details" ? (
                  <Routes>
                    <Route path="/guide-details" element={<GuidesDetails />} />
                  </Routes>
                ) : currUrlpathname === "/terms" ? (
                  <Routes>
                    <Route path="/terms" element={<WebTermsConditions />} />
                  </Routes>
                ) : currUrlpathname === "/" ? (
                  <Routes>
                    <Route index element={<Home />} />
                    <Route path="/" element={<Home />} />
                  </Routes>
                ) : (
                  <Box className="dashboard-form" id="agency">
                    <Box sx={{ width: "100%" }} className="appbar-border">
                      <Box className="appbar-main">{MemoizedAppbar}</Box>
                    </Box>
                    <Box className="d-flex dashboard-main-box">
                      {MemoizedSidebar}
                      <Routes>
                        <Route
                          path="/sign-in"
                          element={<Navigate to={familyMemberNavigation} />}
                        />
                        <Route
                          path="/sign-up"
                          element={<Navigate to={familyMemberNavigation} />}
                        />
                        <Route
                          path="/healthcarer-sign-up"
                          element={<Navigate to={familyMemberNavigation} />}
                        />
                        <Route
                          path="/verify-otp"
                          element={<Navigate to={familyMemberNavigation} />}
                        />
                        <Route
                          path="/forgot-password"
                          element={<Navigate to={familyMemberNavigation} />}
                        />
                        <Route
                          path="/reset-password/:id"
                          element={<Navigate to={familyMemberNavigation} />}
                        />
                        <Route
                          path="/book-demo"
                          element={<Navigate to={familyMemberNavigation} />}
                        />
                        <Route path="/privacy-policy" element={<WebPolicy />} />
                        <Route
                          path="/help-center-details"
                          element={<HelpCenterDetails />}
                        />
                        <Route path="/*" element={<FamilyMember />} />
                      </Routes>
                    </Box>
                  </Box>
                )}
              </>
            ) : user.role === "generalManager" ? (
              <>
                {currUrlpathname === "/privacy-policy" ? (
                  <Routes>
                    <Route path="/privacy-policy" element={<WebPolicy />} />
                  </Routes>
                ) : currUrlpathname === "/help-center-details/" ? (
                  <Routes>
                    <Route
                      path="/help-center-details"
                      element={<HelpCenterDetails />}
                    />
                  </Routes>
                ) : currUrlpathname === "/contact" ? (
                  <Routes>
                    <Route path="/contact" element={<WebContact />} />
                  </Routes>
                ) : currUrlpathname === "/about" ? (
                  <Routes>
                    <Route path="/about" element={<About />} />
                  </Routes>
                ) : currUrlpathname === "/help-center" ? (
                  <Routes>
                    <Route path="/help-center" element={<HelpCenter />} />
                  </Routes>
                ) : currUrlpathname === "/pricing" ? (
                  <Routes>
                    <Route path="/pricing" element={<Pricing />} />
                  </Routes>
                ) : currUrlpathname === "/sil-management" ? (
                  <Routes>
                    <Route path="/sil-management" element={<SILManagement />} />
                  </Routes>
                ) : currUrlpathname === "/staff-induction" ? (
                  <Routes>
                    <Route
                      path="/staff-induction"
                      element={<StaffInduction />}
                    />
                  </Routes>
                ) : currUrlpathname === "/health-dashboard" ? (
                  <Routes>
                    <Route
                      path="/health-dashboard"
                      element={<HealthDashboard />}
                    />
                  </Routes>
                ) : currUrlpathname === "/roster-management" ? (
                  <Routes>
                    <Route
                      path="/roster-management"
                      element={<RosterManagement />}
                    />
                  </Routes>
                ) : currUrlpathname === "/document-passport" ? (
                  <Routes>
                    <Route
                      path="/document-passport"
                      element={<DocumentPassport />}
                    />
                  </Routes>
                ) : currUrlpathname === "/blogs" ? (
                  <Routes>
                    <Route path="/blogs" element={<Blogs />} />
                  </Routes>
                ) : currUrlpathname === "/blog-details" ? (
                  <Routes>
                    <Route path="/blog-details" element={<BlogDetails />} />
                  </Routes>
                ) : currUrlpathname === "/guides" ? (
                  <Routes>
                    <Route path="/guides" element={<Guides />} />
                  </Routes>
                ) : currUrlpathname === "/guide-details" ? (
                  <Routes>
                    <Route path="/guide-details" element={<GuidesDetails />} />
                  </Routes>
                ) : currUrlpathname === "/terms" ? (
                  <Routes>
                    <Route path="/terms" element={<WebTermsConditions />} />
                  </Routes>
                ) : currUrlpathname === "/" ? (
                  <Routes>
                    <Route index element={<Home />} />
                    <Route path="/" element={<Home />} />
                  </Routes>
                ) : (
                  <Box className="dashboard-form" id="agency">
                    <Box sx={{ width: "100%" }} className="appbar-border">
                      <Box className="appbar-main">{MemoizedAppbar}</Box>
                    </Box>
                    <Box className="d-flex dashboard-main-box">
                      {MemoizedSidebar}
                      <Routes>
                        <Route
                          path="/sign-in"
                          element={<Navigate to={generalManagerNavigation} />}
                        />
                        <Route
                          path="/sign-up"
                          element={<Navigate to={generalManagerNavigation} />}
                        />
                        <Route
                          path="/healthcarer-sign-up"
                          element={<Navigate to={generalManagerNavigation} />}
                        />
                        <Route
                          path="/verify-otp"
                          element={<Navigate to={generalManagerNavigation} />}
                        />
                        <Route
                          path="/forgot-password"
                          element={<Navigate to={generalManagerNavigation} />}
                        />
                        <Route
                          path="/reset-password/:id"
                          element={<Navigate to={generalManagerNavigation} />}
                        />
                        <Route
                          path="/book-demo"
                          element={<Navigate to={generalManagerNavigation} />}
                        />
                        <Route path="/privacy-policy" element={<WebPolicy />} />
                        <Route
                          path="/help-center-details"
                          element={<HelpCenterDetails />}
                        />
                        <Route path="/*" element={<GeneralManager />} />
                      </Routes>
                    </Box>
                  </Box>
                )}
              </>
            ) : user.role === "hr" ? (
              <>
                {currUrlpathname === "/privacy-policy" ? (
                  <Routes>
                    <Route path="/privacy-policy" element={<WebPolicy />} />
                  </Routes>
                ) : currUrlpathname === "/help-center-details/" ? (
                  <Routes>
                    <Route
                      path="/help-center-details"
                      element={<HelpCenterDetails />}
                    />
                  </Routes>
                ) : currUrlpathname === "/contact" ? (
                  <Routes>
                    <Route path="/contact" element={<WebContact />} />
                  </Routes>
                ) : currUrlpathname === "/about" ? (
                  <Routes>
                    <Route path="/about" element={<About />} />
                  </Routes>
                ) : currUrlpathname === "/help-center" ? (
                  <Routes>
                    <Route path="/help-center" element={<HelpCenter />} />
                  </Routes>
                ) : currUrlpathname === "/pricing" ? (
                  <Routes>
                    <Route path="/pricing" element={<Pricing />} />
                  </Routes>
                ) : currUrlpathname === "/sil-management" ? (
                  <Routes>
                    <Route path="/sil-management" element={<SILManagement />} />
                  </Routes>
                ) : currUrlpathname === "/staff-induction" ? (
                  <Routes>
                    <Route
                      path="/staff-induction"
                      element={<StaffInduction />}
                    />
                  </Routes>
                ) : currUrlpathname === "/health-dashboard" ? (
                  <Routes>
                    <Route
                      path="/health-dashboard"
                      element={<HealthDashboard />}
                    />
                  </Routes>
                ) : currUrlpathname === "/roster-management" ? (
                  <Routes>
                    <Route
                      path="/roster-management"
                      element={<RosterManagement />}
                    />
                  </Routes>
                ) : currUrlpathname === "/document-passport" ? (
                  <Routes>
                    <Route
                      path="/document-passport"
                      element={<DocumentPassport />}
                    />
                  </Routes>
                ) : currUrlpathname === "/blogs" ? (
                  <Routes>
                    <Route path="/blogs" element={<Blogs />} />
                  </Routes>
                ) : currUrlpathname === "/blog-details" ? (
                  <Routes>
                    <Route path="/blog-details" element={<BlogDetails />} />
                  </Routes>
                ) : currUrlpathname === "/guides" ? (
                  <Routes>
                    <Route path="/guides" element={<Guides />} />
                  </Routes>
                ) : currUrlpathname === "/guide-details" ? (
                  <Routes>
                    <Route path="/guide-details" element={<GuidesDetails />} />
                  </Routes>
                ) : currUrlpathname === "/terms" ? (
                  <Routes>
                    <Route path="/terms" element={<WebTermsConditions />} />
                  </Routes>
                ) : currUrlpathname === "/" ? (
                  <Routes>
                    <Route index element={<Home />} />
                    <Route path="/" element={<Home />} />
                  </Routes>
                ) : currUrlpathname == "/hr/pending-approval" &&
                  user["userDataV.userStatus"] == "Pending" ? (
                  <Routes>
                    <Route index element={<Home />} />
                    <Route path="/" element={<Home />} />
                    <Route
                      path="/hr/pending-approval"
                      element={
                        <Suspense>
                          <PendingApproval />
                        </Suspense>
                      }
                    />
                  </Routes>
                ) : (
                  <Box className="dashboard-form" id="agency">
                    <Box sx={{ width: "100%" }} className="appbar-border">
                      <Box className="appbar-main">{MemoizedAppbar}</Box>
                    </Box>
                    <Box className="d-flex dashboard-main-box">
                      {MemoizedSidebar}
                      <Routes>
                        <Route
                          path="/sign-in"
                          element={<Navigate to={hrNavigation} />}
                        />
                        <Route
                          path="/sign-up"
                          element={<Navigate to={hrNavigation} />}
                        />
                        <Route
                          path="/healthcarer-sign-up"
                          element={<Navigate to={hrNavigation} />}
                        />
                        <Route
                          path="/verify-otp"
                          element={<Navigate to={hrNavigation} />}
                        />
                        <Route
                          path="/forgot-password"
                          element={<Navigate to={hrNavigation} />}
                        />
                        <Route
                          path="/reset-password/:id"
                          element={<Navigate to={hrNavigation} />}
                        />
                        <Route
                          path="/book-demo"
                          element={<Navigate to={hrNavigation} />}
                        />
                        <Route path="/privacy-policy" element={<WebPolicy />} />
                        <Route
                          path="/help-center-details"
                          element={<HelpCenterDetails />}
                        />
                        <Route path="/*" element={<Hr />} />
                      </Routes>
                    </Box>
                  </Box>
                )}
              </>
            ) : (
              <>
                <Box className="dashboard-form" id="agency">
                  <Box sx={{ width: "100%" }} className="appbar-border">
                    <Box className="appbar-main">{MemoizedAppbar}</Box>
                  </Box>
                  <Box className="d-flex dashboard-main-box">
                    {MemoizedSidebar}
                    <Routes>
                      <Route
                        index
                        element={<Navigate to={`${user.role}/home`} />}
                      />
                      <Route
                        path="/sign-in"
                        element={<Navigate to={hrNavigation} />}
                      />
                      <Route
                        path="/sign-up"
                        element={<Navigate to={hrNavigation} />}
                      />
                      <Route
                        path="/healthcarer-sign-up"
                        element={<Navigate to={hrNavigation} />}
                      />
                      <Route
                        path="/verify-otp"
                        element={<Navigate to={hrNavigation} />}
                      />
                      <Route
                        path="/forgot-password"
                        element={<Navigate to={hrNavigation} />}
                      />
                      <Route
                        path="/reset-password/:id"
                        element={<Navigate to={hrNavigation} />}
                      />
                      <Route
                        path="/book-demo"
                        element={<Navigate to={hrNavigation} />}
                      />
                      <Route
                        path={roleDataLowerCase}
                        element={<superAdminNavigation />}
                      />
                      <Route path="/privacy-policy" element={<WebPolicy />} />
                      <Route
                        path="/help-center-details"
                        element={<HelpCenterDetails />}
                      />
                      <Route path="/*" element={<CommanRouting />} />
                    </Routes>
                  </Box>
                </Box>
              </>
            )
          ) : (
            <>
              <Routes>
                <Route index element={<Home />} />
                <Route path="/" element={<Home />} />
                <Route path="/sil-management" element={<SILManagement />} />
                <Route
                  path="/roster-management"
                  element={<RosterManagement />}
                />
                <Route path="/staff-induction" element={<StaffInduction />} />
                <Route
                  path="/document-passport"
                  element={<DocumentPassport />}
                />
                <Route path="/health-dashboard" element={<HealthDashboard />} />

                <Route path="/blogs" element={<Blogs />} />
                <Route path="/blog-details" element={<BlogDetails />} />
                <Route path="/delete-account" element={<DeleteAccount />} />
                <Route path="/blog-details-old" element={<BlogDetailsOld />} />
                <Route path="/guides" element={<Guides />} />
                <Route path="/guide-details" element={<GuidesDetails />} />
                <Route path="/book-demo" element={<BookDemo />} />
                <Route path="/contact" element={<WebContact />} />
                <Route path="/privacy-policy" element={<WebPolicy />} />
                <Route path="/privacy-policy-page" element={<MobilePolicy />} />
                <Route path="/terms" element={<WebTermsConditions />} />
                <Route
                  path="/terms-condition"
                  element={<MobileTermsConditions />}
                />
                <Route path="/about" element={<About />} />
                <Route path="/help-center" element={<HelpCenter />} />
                <Route
                  path="/help-center-details"
                  element={<HelpCenterDetails />}
                />

                <Route path="/pricing" element={<Pricing />} />

                <Route path="/landing-page" element={<LandingPage />} />

                <Route path="*" element={<Signin />} />

                <Route path="/sign-in" element={<Signin />} />
                <Route path="/sign-up" element={<Signup />} />
                <Route path="/healthcarer-sign-up" element={<HcSignup />} />
                <Route path="/verify-otp" element={<VerifyOtp />} />
                <Route path="/forgot-password" element={<Forgotpassword />} />
                <Route path="/reset-password/:id" element={<ResetPassword />} />
                <Route
                  path="/email-verify-success"
                  element={<VerifyEmailSuccess />}
                />
                <Route
                  path="/client/explore-onboard"
                  element={<SetupClientAccount />}
                />
                <Route path="/existing-user" element={<ExistingOrNewUser />} />
                <Route
                  path="/health-carer-login"
                  element={<HealthCarerLogin />}
                />

                <Route path="/get-support" element={<GetSupport />} />
                <Route path="/pickup-shifts" element={<PickupShifts />} />

                <Route path="/view-details" element={<ViewDetail />} />
                <Route
                  path="/pickup-shifts/view-details"
                  element={<PickupShiftsViewDetail />}
                />
                <Route
                  path="/pickup-shifts/view-details-sp"
                  element={<PickupShiftsViewDetailSp />}
                />

                <Route
                  path={roleDataLowerCase}
                  element={<superAdminNavigation />}
                />
              </Routes>
            </>
          )}
        </ScrollToTop>
      </Router>
    </>
  );
}

export default App;
