import React, { useState } from 'react';

function TimeInput() {
  const [timeValue, setTimeValue] = useState('');

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setTimeValue(newValue);
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Enter time (24-hour format)"
        value={timeValue}
        onChange={handleInputChange}
      />
    </div>
  );
}

export default TimeInput;
