import React, { useEffect, useState } from "react";
import Error from "./../components/Error";
import Loader from "../pages/loader/Loader";

const DelayedError = () => {
  const [shouldRenderError, setShouldRenderError] = useState(false);

  useEffect(() => {
    // Delay for a few seconds before rendering the Error component
    const delayTimeout = setTimeout(() => {
      setShouldRenderError(true);
    }, 6000); // Adjust the delay time as needed

    // Clear the timeout on unmount to prevent memory leaks
    return () => clearTimeout(delayTimeout);
  }, []);

  return shouldRenderError ? <Error /> : <Loader />;
};

export { DelayedError };
