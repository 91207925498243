import React from "react";
import "./Roastercard.scss";
import image from "../../assets/images/roasterDashboardImages/Group 3 (3).png"
import { Link } from "react-router-dom";

const RoasterCard = ({image, total, shift, link, state}) => {
  return (
    <Link to={link} state={state}>
    <div className="roaster-card">
      <div className="card-image"><img src={image} /></div>
      <div>
        <h3 className="card-total">{total}</h3>
        <p className="card-shift">{shift}</p>
      </div>
    </div>
    </Link>
  );
};

export default RoasterCard;
