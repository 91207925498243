import * as React from 'react';
import Box from '@mui/material/Box';

import '../../App.css'
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

    

export default function BasicTextFields() {

    const [value, setValue] = React.useState();

    const handleChange = (newValue) => {
      setValue(newValue);
    }; 


    return (
        <Box id='personal-info'>
            <Box className='d-flex align-items-center justify-content-between'>
                <Typography className='step-count'>Step 4/5</Typography>
                <Typography className='employee-id'>Employee ID: <span>12345</span> </Typography>
            </Box>
            <Box className='personal-info-main'>
                <Box className='form-heading-section'>
                    <Typography className='form-heading'>Professional Documents</Typography>
                </Box>

                <Box className='personal-info'>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} sm={6} size="small">
                            <Typography sx={{ mb: 1 }}>Manual Handling Certificate</Typography>
                            <Box className='input-box'>

                                <label for="browse" className='file-label'> <span>Browse</span> </label>
                                <TextField
                                    sx={{ mt: 2, mb: 2 }}
                                    id='browse'
                                    className='form-input d-none'
                                    type="File"
                                    size="small"

                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} >
                            <Typography sx={{ mb: 1 }}>Medication Training Certificate</Typography>
                            <Box className='input-box'>

                                <label for="browse" className='file-label'> <span>Browse</span> </label>
                                <TextField
                                    sx={{ mt: 2, mb: 2 }}
                                    id='browse'
                                    className='form-input d-none'
                                    type="File"
                                    size="small"

                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} size="small" className='input'>
                            <Typography sx={{ mb: 1 }}>NDIS Induction Control</Typography>
                            <Box className='input-box'>

                                <label for="browse" className='file-label'> <span>Browse</span> </label>
                                <TextField
                                    sx={{ mt: 2, mb: 2 }}
                                    id='browse'
                                    className='form-input d-none'
                                    type="File"
                                    size="small"

                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} className='input'>
                            <Typography sx={{ mb: 1 }}>NDIS Infection Control</Typography>
                            <Box className='input-box'>

                                <label for="browse" className='file-label'> <span>Browse</span> </label>
                                <TextField
                                    sx={{ mt: 2, mb: 2 }}
                                    id='browse'
                                    className='form-input d-none'
                                    type="File"
                                    size="small"

                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} size="small" className='input'>
                            <Typography sx={{ mb: 1 }}>NDISWC Worker Screening Number</Typography>
                            <Box className='input-box'>

                                <label for="browse" className='file-label'> <span>Browse</span> </label>
                                <TextField
                                    sx={{ mt: 2, mb: 2 }}
                                    id='browse'
                                    className='form-input d-none'
                                    type="File"
                                    size="small"

                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} className='input'>
                            <Typography sx={{ mb: 1 }}>Covid-19 Training Completion Certificate</Typography>
                            <Box className='input-box'>

                                <label for="browse" className='file-label'> <span>Browse</span> </label>
                                <TextField
                                    sx={{ mt: 2, mb: 2 }}
                                    id='browse'
                                    className='form-input d-none'
                                    type="File"
                                    size="small"

                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={6} sm={6} className='input'>
                            <Typography sx={{ mb: 1 }}>APHRA Registration Certificate (Only For Nurse)</Typography>
                            <Box className='input-box'>

                                <label for="browse" className='file-label'> <span>Browse</span> </label>
                                <TextField
                                    sx={{ mt: 2, mb: 2 }}
                                    id='browse'
                                    className='form-input d-none'
                                    type="File"
                                    size="small"

                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} className='input'>
                            <Typography sx={{ mb: 1 }}>CPR</Typography>
                            <Box className='input-box'>

                                <label for="browse" className='file-label'> <span>Browse</span> </label>
                                <TextField
                                    sx={{ mt: 2, mb: 2 }}
                                    id='browse'
                                    className='form-input d-none'
                                    type="File"
                                    size="small"

                                />
                            </Box>
                        </Grid>

                        <Grid item xs={4} sm={6} className='xs-none'></Grid>

                        <Grid item xs={12} sm={5} md={3}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={3}>
                                    <DesktopDatePicker
                                        className='form-input date-input-filed'
                                        format="DD-MM-YYYY"
                                        value={value}
                                        onChange={handleChange}
                                        renderInput={(params) => <TextField size="small" sx={{ mb: 1 }}  {...params} inputProps={{
                                            ...params.inputProps,
                                            placeholder: "DD-MM-YY"
                                        }} />}
                                    />

                                </Stack>
                            </LocalizationProvider>
                            <label className='date-lable'>Enter Expiry Dose</label>
                        </Grid>

                        <Grid item xs={12} md={6} sm={6} className='input'>
                            <Typography sx={{ mb: 1 }}>First Aid Certificate</Typography>
                            <Box className='input-box'>

                                <label for="browse" className='file-label'> <span>Browse</span> </label>
                                <TextField
                                    sx={{ mt: 2, mb: 2 }}
                                    id='browse'
                                    className='form-input d-none'
                                    type="File"
                                    size="small"

                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={6} sm={6} className='input'>
                            <Typography sx={{ mb: 1 }}>WWCC Number</Typography>
                            <Box className='input-box'>

                                <label for="browse" className='file-label'> <span>Browse</span> </label>
                                <TextField
                                    sx={{ mt: 2, mb: 2 }}
                                    id='browse'
                                    className='form-input d-none'
                                    type="File"
                                    size="small"

                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={3}>
                                    <DesktopDatePicker
                                        className='form-input date-input-filed'
                                        format="DD-MM-YYYY"
                                        value={value}
                                        onChange={handleChange}
                                        renderInput={(params) => <TextField size="small" sx={{ mb: 1 }}  {...params} inputProps={{
                                            ...params.inputProps,
                                            placeholder: "DD-MM-YY"
                                        }} />}
                                    />

                                </Stack>
                            </LocalizationProvider>
                            <label className='date-lable'>Enter Expiry Dose</label>
                        </Grid>

                        <Grid item xs={2} sm={2} md={3} className='sm-none'>

                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={3}>
                                    <DesktopDatePicker
                                        className='form-input date-input-filed'
                                        format="DD-MM-YYYY"
                                        value={value}
                                        onChange={handleChange}
                                        renderInput={(params) => <TextField size="small" sx={{ mb: 1 }}  {...params} inputProps={{
                                            ...params.inputProps,
                                            placeholder: "DD-MM-YY"
                                        }} />}
                                    />

                                </Stack>
                            </LocalizationProvider>
                            <label className='date-lable'>Enter Expiry Dose</label>
                        </Grid>

                    </Grid>



                </Box>

            </Box>

        </Box>

    );
} 