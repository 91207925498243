const FieldsListLoading = () => {
  const styles = {
    borderRadius: "0.475rem",
    boxShadow: "0 0 50px 0 rgb(82 63 105 / 15%)",
    backgroundColor: "#fff",
    color: "#7e8299",
    fontWeight: "500",
    margin: "0",
    width: "auto",
    padding: "1rem 2rem",
    top: "10rem", // Set top to 0 for top of the page
    left: "50%", // Set left to 50% for center alignment
    transform: "translateX(-50%)", // Adjust for center alignment
    position: "fixed", // Change position to fixed
    // top: "calc(50% - 2rem)",
    // left: "calc(50% - 4rem)",
    // position: "absolute",
    zIndex: 999,
  };

  return <div style={{ ...styles, textAlign: "center" }}>Processing...</div>;
};

export { FieldsListLoading };
