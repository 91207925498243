import React from 'react';
import { Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";


const BookDemo = () => {
  return (
    <Box>
      <Box className="bookDemo-main space-80">
        <Box className="text-center bookDemo-wrapper">
          <Typography className="text-semi">
            Experience the Future of Disability Care Management
          </Typography>
          <h1 className="space-pb-40">
            Book a Demo Today and See Hubshift in Action
          </h1>

          <Typography className="space-pb-40 text">
            Whether you're a healthcare professional, support coordinator,
            or NDIS participant, we have a solution tailored for you.
            Schedule a personalized demo now to explore the Hubshift
            advantage.
          </Typography>

          <Link to="/book-demo" className=" home-primary-button bookDemo-btn ">
            <Box className="d-flex align-items-center">
              Book A Demo <ArrowForwardIcon />
            </Box>
          </Link>

          <Box>
            <ul className="d-flex align-items-center justify-content-center">
              <li>Quick Onboarding</li>
              <li>Flexibility at Your Fingertips</li>
              <li>No-Obligation Demo</li>
            </ul>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BookDemo;