import { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { listGuide, deleteGuide, } from "../../../api";
import axios from "axios";
import localstorageData from "../../../helpers/localstorageData";
import zustandStore from "../../../helpers/zustandStore";
import * as React from "react";
import { Link } from "react-router-dom";
import Stack from '@mui/material/Stack';
import "../../../Website/HomeUI/home-ui.css"
import ReactPaginate from "react-paginate";

export default function Guide() {
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [department, setDepartment] = useState();
  const [experience, setExperience] = useState();
  const [listData, setListData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [pageCount, setpageCount] = useState(0);
  const [currentPageVal, setCurrentPageVal] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const { menuList } = zustandStore();
  const [count, setCount] = useState("");
  const [isActive, setActive] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [list, setList] = useState([]);
  const [activeDepartment, setActiveDepartment] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const limit = "12"


  const toggleClass = () => {
    setActive(!isActive);
  };

  const searchItems = (searchValue) => {
    setSearchTerm(searchValue);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let userData = localstorageData();
  let sessionId = userData._id;

  const departments = Array.from(
    new Set(listData.map((blog) => blog.category))
  );

  useEffect(() => {
    setFilteredEmployees(
      listData.filter((blog) => {
        return (
          (!department || department === blog.category) &&
          (!experience ||
            (experience === "LESS_THAN_10"
              ? blog.experience < 10
              : blog.experience >= 10))
        );
      })
    );
  }, [department, experience, listData]);


  const clearFilters = () => {
    setDepartment();
    setExperience();
  };

  // const handleDepartmentButtonClick = (selectedDepartment) => {
  //   setDepartment(selectedDepartment);
  // };

  const handleDepartmentButtonClick = (selectedDepartment) => {
    setDepartment(selectedDepartment);
    setActiveDepartment(selectedDepartment);
  };





  useEffect(() => {
    getaList();
  }, [limit, searchTerm]);

  const getaList = async () => {
    axios
      .get(
        `${listGuide}?searchTerm=${searchTerm}&createdBy=${sessionId}&page=1&items_per_page=${limit}`
      )
      .then((res) => {
        // console.log("resdata", res?.data?.data?.docs);
        setListData(res?.data?.data?.docs);
        let total = res.data.data.totalDocs;
        setpageCount(Math.ceil(total / limit));
        setCount(total);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const fetchPaginationList = async (currentPage) => {
    setLoading(true);
    const res = await axios.get(
      `${listGuide}?searchTerm=${searchTerm}&createdBy=${sessionId}&page=${currentPage}&items_per_page=${limit}`
    );
    const op = res?.data?.data?.docs;
    console.log(op);
    return op;
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    const commentsFormServer = await fetchPaginationList(currentPage);
    setListData(commentsFormServer);
    setCurrentPageVal(currentPage);
    setLoading(false);
  };

  return (
    <Box id="blogFilter">
      <Box className="d-flex align-items-center justify-content-center blog-filter-button">
        <Button
          // className={`blog-filter-activeBtn ${!department && "active"}`}
          className={!activeDepartment ? "blog-filter-activeBtn active" : ""}
          onClick={() => handleDepartmentButtonClick(null)}

        >
          All
        </Button>
        {departments.map((dept) => (
          <Button
            key={dept}
            onClick={() => handleDepartmentButtonClick(dept)}
            // className={department === dept ? "active" : ""}
            className={activeDepartment === dept ? "blog-filter-activeBtn active" : ""}

          >
            {dept}
          </Button>
        ))}
      </Box>

      <Grid container spacing={2} sx={{ mt: 4 }}>
        {filteredEmployees.map((blog) => {
          const {
            _id,
            image,
            category,
            title,
            description,
          } = blog;
          return (
            <Grid item xs={12} sm={6} md={4} key={_id} className="space-pb-80">
              <Box>
                <Box className="blog-cardImg">
                  <img src={image?.location} alt={title} />
                </Box>

                <Box className="blog-card-smTitle">
                  <Box className="w-100 h-100 d-flex align-items-center justify-content-center">
                    <span>{category}</span>
                  </Box>
                </Box>
                <Box className="blog-card-title">
                  <Link
                    to={"/guide-details"}
                    state={{ blogId: _id, pageType: "blogFilter" }}>
                    <h3>
                      {title} </h3>
                  </Link>
                </Box>
                <Box className="blogCard-text">
                  <Typography>{description}</Typography>
                </Box>
              </Box>
            </Grid>
          );
        })}
        {filteredEmployees.length === 0 && (
          <Box>No Guide matching the filter</Box>
        )}
      </Grid>

      <Box className="d-flex align-items-center justify-content-center">
        <Box className="web-pagination position-relative">
          <Stack spacing={2}>

            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-right"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item previous-page-link"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />

          </Stack>
        </Box>
      </Box>
    </Box>
  );
}


