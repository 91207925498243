import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import WebHeader from "../../../Website/Web-Header";
import WebFooter from "../../../Website/Web-footer";
import { Link } from "@mui/material";
import BookDemo from "../../../Website/HomeUI/BookDemo";
import BlogFilter from "./BlogFilter";

const Blogs = () => {
  return (
    <Box id="WebBlogs">
      <Box className="web-topBanner-bg  blog-main-banner">
        <WebHeader />

        <Box className="webContainer">
          <Box className="W-100 ">
            <Box className="home-content-main space-lg">
              <Box className="text-center">
                <h1>Blogs</h1>
                <Typography>
                  Stay up-to-date with the latest support industry trends and
                  explore the <br /> newest features and advancements within the
                  Hubshift platform.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="blurBg blurRed"> </Box>
        {/* <Box className="blurBg blurPurple"> </Box> */}
        <Box className="blurBg blurYellow"></Box>
      </Box>

      <Box className="space-pt-80">
        <Box className="webContainer">
          <BlogFilter />
        </Box>
      </Box>

      {/* book demo section  */}

      <Box className="space-m-80">
        <Box className="webContainer">
          <BookDemo />
        </Box>
      </Box>

      <WebFooter />
    </Box>
  );
};

export default Blogs;
