import { create } from "zustand";

const date = new Date();
// const today = new Date(
//   date.getFullYear(),
//   date.getMonth(),
//   date.getDate()
// );
// let start = today;
// let end = today;
let start = new Date(date.getFullYear(), date.getMonth(), 1);
let end = new Date(date.getFullYear(), date.getMonth() + 1, 0);

const FilterStore = create((set) => ({
  selectedCategoryId: "",
  ApType: "",
  ApType2: [],
  showMenuItem: {},
  status: "",
  slotType: "",
  month: "",
  startDate1: start ? start : null,
  endDate1: end ? end : null,
  searchTerm: "",
  tabIndex: 0,
  appointmentList: [],
  list: [],
  selectedStake: [],
  serviceId: undefined,
  itemId: undefined,
  clientId: undefined,
  healthCarerId: undefined,
  teamDataId: undefined,
  statusDataId: undefined,
  usertype: undefined,
  location: undefined,
  showhide: "",
  value: 0,
  isLiveRoster: false,
  isStarted: false,
  isSilSda: false,
  isNotAttended: false,
  isDelayed: false,
  isReset: false,
  setField: (fieldName, fieldValue) =>
    set((state) => ({ ...state, [fieldName]: fieldValue })),

  resetFields: () =>
    set(() => ({
      selectedCategoryId: "",
      tabIndex: 0,
      ApType: "",
      ApType2: [],
      showMenuItem: {},
      status: "",
      slotType: "",
      month: "",
      startDate1: start ? start : null,
      endDate1: end ? end : null,
      searchTerm: "",
      showhide: "",
      appointmentList: [],
      list: [],
      selectedStake: [],
      serviceId: undefined,
      itemId: undefined,
      clientId: undefined,
      healthCarerId: undefined,
      teamDataId: undefined,
      statusDataId: undefined,
      usertype: undefined,
      location: undefined,
      value: 0,
      isLiveRoster: false,
      isStarted: false,
      isSilSda: false,
      isNotAttended: false,
      isDelayed: false,
      isReset: false,
    })),
}));

export default FilterStore;
