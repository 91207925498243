import React from "react";
import ReactApexChart from "react-apexcharts";

const DonutChart = ({ series, option }) => {
  // Define chart options with a safe formatter for labels and dataLabels
  const options = {
    chart: {
      width: 380,
      type: "donut",
    },
    labels: option || [], // These will be used as the labels for the chart
    dataLabels: {
      enabled: true,
      formatter: (val, opts) => {
        // Ensure series value exists before attempting to convert to string
        const seriesValue = opts?.w?.config?.series?.[opts?.seriesIndex] ?? 0;
        return seriesValue !== undefined ? seriesValue.toString() : "0";  // Convert safely to string
      },
    },
    stroke: {
      show: false, // Removes border strokes on the slices
    },
    responsive: [
      {
        breakpoint: 250,
        options: {
          chart: {
            width: 100,
          },
          legend: {
            show: true,
          },
        },
      },
    ],
    legend: {
      position: 'right', // Position the legend to the right
      offsetY: 0,
      height: 230,
      floating: false,
    },
    tooltip: {
      enabled: true, // Enable tooltips for additional information on hover
      shared: true,
      intersect: false,
    },
  };

  return (
    <div className="mt-4">
      <div className="chart-wrap">
        <div id="chart">
          <ReactApexChart
            options={options}
            series={series} // Pass the series prop which contains the data points
            type="donut"
            width={380} // Set chart width to 380px
          />
        </div>
      </div>
    </div>
  );
};

export default DonutChart;
