import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { loginUser } from "../../api";
import "../../assets/css/Sa-style.css";
import { toast } from "react-toastify";
import WebHeader from "../../Website/Web-Header";
import BannerSlider from "../../components/ui/BannerSlider";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function SignInSide() {
  const [disableButton, setDisableButton] = useState(false);
  const navigate = useNavigate();
  const initialValues = {
    email: "",
    password: "",
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const [passwordTypeNew, setPasswordTypeNew] = useState("password");
  const togglePasswordNew = () => {
    if (passwordTypeNew === "password") {
      setPasswordTypeNew("text");
      return;
    }
    setPasswordTypeNew("password");
  };

  return (
    <Box id="personal-info" width="100%" height="100%">
      <Box>
        <WebHeader />
      </Box>
      <Grid container>
        <Grid item xs={12} sm={12} md={7}>
          <Box>
            <BannerSlider />
          </Box>
        </Grid>

        <Grid 
        item xs={12} sm={12} md={5}
        sx={{ mt: { xs: 8, sm: 2 }, mb: { xs: 3, sm: 3 } }}
        >
          <Box className="w-100 h-100 custom-sign-field d-flex align-items-center justify-content-center">
            <Box 
            
            // className="form-main-box"
            
            >
              <Box>
                <h2 mb={3} className="sign-title">
                  Sign in
                  <p className="text-mute">
                    Welcome back! Please enter your details.
                  </p>
                </h2>
              </Box>
              <Box>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values, formikHelpers) => {
                    setDisableButton(true);
                    axios
                      .post(loginUser, values)
                      .then((res) => {
                        if (res.data.success === false) {
                          toast.error(res.data.message);
                          setDisableButton(false);
                        }
                        if (res.data.success === true) {
                          toast.success(res.data.message);
                          setDisableButton(false);
                          setTimeout(() => {
                            navigate(`/verify-otp`, {
                              state: {
                                emailID: values.email,
                                pageName: "login",
                                selectedUserId: "",
                                apptId: "",
                              },
                            });
                          });
                        }
                      })
                      .catch((error) => {
                        toast.error("Something went wrong");
                      });
                  }}
                >
                  {({ errors, isValid, touched, values, dirty }) => (
                    <Form>
                      <div className="form-field">
                        <label>Email </label>

                        <Field
                          size="small"
                          name="email"
                          type="email"
                          as={TextField}
                          className="form-input"
                          margin="normal"
                          fullWidth
                          id="outlined-required"
                          placeholder="Email"
                          value={
                            values?.email.toLocaleLowerCase()
                          }
                          error={
                            Boolean(errors.email) && Boolean(touched.email)
                          }
                          helperText={Boolean(touched.email) && errors.email}
                        />
                      </div>
                      <div className="form-field">
                        <label> Password </label>
                        <Field
                          size="small"
                          name="password"
                          type={passwordTypeNew}
                          as={TextField}
                          className="form-input"
                          margin="normal"
                          fullWidth
                          id="outlined-required"
                          InputProps={{
                            endAdornment: (
                              <endAdornment
                                className="input-icon"
                                position="end"
                                onClick={togglePasswordNew}
                                style={{ cursor: "pointer" }}
                              >
                                {passwordTypeNew === "password" ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </endAdornment>
                            ),
                          }}
                          placeholder="Password"
                          error={
                            Boolean(errors.password) &&
                            Boolean(touched.password)
                          }
                          helperText={
                            Boolean(touched.password) && errors.password
                          }
                        />
                      </div>
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="end"
                      >
                        <Link
                          href="/forgot-password"
                          variant="body2"
                          className="forgot Signin-demo-link"
                          
                        >
                          Forgot password?
                        </Link>
                      </Box>

                      <Button
                        className="signupbutton"
                        type="submit"
                        fullWidth
                        sx={{ mt: 3, mb: 2 }}
                        disabled={disableButton}
                      >
                        {disableButton === true ? "Processing..." : "Sign In"}
                      </Button>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        {
                          <>
                            <Typography className="Signin-demo-link">
                              <Link className="Signin-demo-link" href="/book-demo" variant="body2">
                                Book a Demo
                              </Link>
                            </Typography>
                            &nbsp; &nbsp; &nbsp;
                            <Typography className='Signin-link'>
                              <Link className='Signin-link' href="/healthcarer-sign-up" variant="body2">
                                Sign Up as Health Carer
                              </Link>
                            </Typography>
                          </>
                        }
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Box>
        </Grid>

      </Grid>
    </Box>
  );
}
