import React, { useRef } from "react";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../../assets/css/dashboard.css";
import { Grid } from "@mui/material";
import { useState, useEffect } from "react";

import "./RoasterDashboard.scss";
import RoasterCard from "../../../components/RoasterDashboardcomp/RoasterCard";
import image1 from "../../../assets/images/roasterDashboardImages/Group 3 (3).png";
import image6 from "../../../assets/images/roasterDashboardImages/Group 3 (2).png";
import image7 from "../../../assets/images/roasterDashboardImages/Group 3 (3) copy.png";
import image8 from "../../../assets/images/roasterDashboardImages/Group 3 (8).png";

import image2 from "../../../assets/images/roasterDashboardImages/Group 3 (4).png";
import image3 from "../../../assets/images/roasterDashboardImages/Group 3 (5).png";
import image4 from "../../../assets/images/roasterDashboardImages/Group 3 (6).png";
import image5 from "../../../assets/images/roasterDashboardImages/Group 3 (7).png"; 
import image9 from "../../../assets/images/roasterDashboardImages/Group 56 copy.png"; 

// Hubshift-admin/frontend/src/assets/images/roasterDashboardImages/Group 56 copy.png
import DonutChart from "../../../components/RoasterDashboardcomp/DonutChart";
import RoasterTable from "../../../components/RoasterDashboardcomp/RoasterTable";

import icon1 from "../../../assets/images/roasterDashboardImages/Group 56.png"; 
import icon2 from "../../../assets/images/roasterDashboardImages/Group 56 (1).png";
import icon3 from "../../../assets/images/roasterDashboardImages/Group 56 (2).png";
import icon4 from "../../../assets/images/roasterDashboardImages/Group 56 (3).png";
import icon5 from "../../../assets/images/roasterDashboardImages/Group 56 (4).png";
import icon6 from "../../../assets/images/roasterDashboardImages/Group 56 (5).png";
import icon7 from "../../../assets/images/roasterDashboardImages/Group 56 (6).png";

import { BarChart } from "@mui/x-charts/BarChart";
import MultiSelectDropdown from "../../../components/RoasterDashboardcomp/MultiSelectDropdown";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import generateUrl from "../../../helpers/urlHelper";
import CalendarExample from "../../../components/RoasterDashboardcomp/CalendarExample";
import axios from "axios";
import LiveClock from "../../../components/RoasterDashboardcomp/LiveClock";
import SwapTable from "../../../components/RoasterDashboardcomp/SwapTable";
import { apiUrl } from "../../../api";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { toast } from "react-toastify";
import NoDataFound from "../../../components/RoasterDashboardcomp/NoDataFound";
import UserActionTable from "../../../components/RoasterDashboardcomp/UserActionTable";

import FilterListIcon from '@mui/icons-material/FilterList';
import HtmlToPdf from "./HtmlToPdf";
import html2pdf from "html2pdf.js";
import DownloadIcon from '@mui/icons-material/Download';
import { useSelector, useDispatch } from "react-redux";
import { pendingStatusFun, startedStatusFun } from "../../../redux/reducer/StatusChangeSlice";
import { changePendingFun, GetInspectorsDetails } from "../../../redux/action/statusAction";
import MultiSelectwithSearch from "../../../components/RoasterDashboardcomp/MultiSelectwithSearch";
import MultiDropDown from "../../../components/RoasterDashboardcomp/MultiDropDown";
import moment from "moment-timezone";


function mergeById(data, key) {
  return data.reduce((acc, curr) => {
      const existing = acc.find(item => item._id === curr._id);
      
      if (existing) {
         existing[key] += curr[key];
      } else {
          acc.push({ _id: curr._id, [key]: curr[key] });
      }
      
      return acc;
  }, []);
}

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "2-digit",
  });
};

const formatDateTwo = (dateString) => {
  const date = new Date(dateString);
  
  const day = String(date.getDate()).padStart(2, '0'); // Day with leading zero
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Month (0-indexed, so +1)
  const year = date.getFullYear(); // Full year
  
  return `${day}/${month}/${year}`;
};


function filterWithMoment(data, startDate, endDate) {
  const start = moment(startDate);
  const end = moment(endDate);

  return data?.filter(item => {
    const itemDate = moment(item.date);
    return itemDate.isBetween(start, end, null, '[]'); // Inclusive of start and end
  });
}

const zerodata = (data) =>{
  return data ? data : "0"
}


const overTimesUser =  [
  {
      totalWorkingTimeHours: 13.63,
      isOverTime: true,
      userId: "668bf741bbe2b21f2467ea27",
      firstName: "Chambu",
      dates: "2024-07-08T19:08:09.956Z",
      overTime: 5.630000000000001
  },
  {
      totalWorkingTimeHours: 12.25,
      isOverTime: true,
      firstName: "Chambu",
      userId: "668bf712bbe2b21f2467e3fb",
      dates: "2024-07-08T19:06:43.344Z",
      overTime: 4.25
  },
  {
      totalWorkingTimeHours: 10,
      isOverTime: true,
      firstName: "Chambu",
      userId: "66702f31fd5c9b2c65a0447e",
      dates: "2024-06-25T10:52:32.595Z",
      overTime: 2
  }
  
]



const RoasterDashboard = () => {
  
  let userData = JSON.parse(localStorage.getItem("userData"));

  const dispatch = useDispatch()
  console.log(userData, "userdgvdh")
  const token = userData?.token
  const role = userData?.role

  console.log(role, "roledata")

  let backurl = "";

  useEffect(()=>{
    if(role==="superAdmin"){
      backurl = "super-admin"
    }
  },[])


  console.log(userData, "dhhfjf")

  const [selectedItemsWorker, setSelectedItemsWorker] = useState([]);
  const [selectedItemsManager, setSelectedItemsManager] = useState([]);
  const [selectedItemsClient, setSelectedItemsClient] = useState([]);
  const [selectedSil, setSelectedSil] = useState([])
  const [filterToggleBtn, setFilterToggleBtn] = useState(false)
 
  const [client, setClient] = useState([])
  const [manager, setManager] = useState([])
  const [worker, setWorker] = useState([])
  const [sildata, setSildata] = useState([])

  const [dateIcon, setDateIcon] = useState(true)

  console.log(sildata, selectedSil,  "sildata")
  

  

  const [roasterData, setRoasterData] = useState([])
  const [timesheetSatatus, setTimesheetStatus] = useState([]);
  const [shiftStatus, setShiftStatus] = useState([]);
  const [topOverUtilize, setTopOverUtilize] = useState([])

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [overTimeUsers, setOverTimeUsers] = useState([])


  const cleardateFilter = () =>{
    setStartDate(null)
    setEndDate(null)
    localStorage.removeItem('startDate');
    localStorage.removeItem('endDate');
    window.location.reload();
  }

  // useEffect(()=>{
  //   if(window.location.reload){
  //   setStartDate(null)
  //   setEndDate(null)
  //   localStorage.removeItem('startDate');
  //   localStorage.removeItem('endDate');
  //   }
  // },[])

  



  useEffect(() => {
    if (startDate) {
      localStorage.setItem('startDate', startDate.toISOString());
    }
    if (endDate) {
      localStorage.setItem('endDate', endDate.toISOString());
    }
  }, [startDate, endDate]);

  

 
  
  const [avalHoursData, setAvalHoursData] = useState([])
  const [plannedHoursData, setPlannedHoursData] = useState([])

  const filterDate = filterWithMoment(avalHoursData, startDate, endDate)
  console.log(filterDate, "djkdjkfj")

  // console.log(avalHoursData.filter((data) => data.date ))

  const [fliterBtn, setFliterBtn] = useState(false)



  console.log(roasterData, "Dates")
  const currentDate = moment(); 
const todaydate = currentDate.format("dddd, MMMM D");
 

const contentRef = useRef();

const element = contentRef.current;
const elementWidth = element?.offsetWidth;
const elementHeight = element?.scrollHeight + 700; // Full height of the content

const handleDownloadPDF = () => {
  const element = contentRef.current;
  const opt = {
    margin: [0, 0, 0, 0], // Set margin to zero for a full-page capture
    // filename: "myDocument.pdf",
      filename: `Roster Dashboard - (${formatDateTwo(startDate)} to ${formatDateTwo(endDate)})`,
  
    image: { type: "jpeg", quality: 1 },
    html2canvas: {
      scale: 2, // Higher scale for better quality
      width: elementWidth, // Set the custom width for the capture
      height: elementHeight, // Set the custom height for the capture
    },
    jsPDF: {
      unit: "px", // Use pixels as the unit
      format: [elementWidth, elementHeight], // Set the page format to match the screen size
      orientation: "portrait", // Or "landscape" depending on your need
    },
  };

  html2pdf().from(element).set(opt).save();
};


const resetFilter = () =>{
  setSelectedItemsWorker([])
  setSelectedItemsManager([])
  setSelectedItemsClient([])
  setSelectedSil([]);
  setFliterBtn(prev => !prev)
}

const [localDate, setLocalDate] = useState(null)
const [localEndDate, setLocalEndDate] = useState(null)

const [toggleApi, setToggleApi] = useState(true)



useEffect(()=>{

  const storedStartDate = localStorage.getItem('startDate');
  const storedEndDate = localStorage.getItem('endDate');


  if (storedStartDate) {
    setStartDate(moment(storedStartDate));
    setLocalDate(storedStartDate);
  }
  if (storedEndDate) {
    setEndDate(moment(storedEndDate));
    setLocalEndDate(storedStartDate);
  }
  
  // setStartDate(moment(storedStartDate).format("DD/MM/YYYY"))
  // setLocalDate(storedStartDate)
  // setLocalEndDate(storedEndDate)
  setToggleApi(prev => !prev)


},[])



  
  useEffect(() => {
    RoasterDashboardData();
  }, [endDate, fliterBtn, toggleApi]);
  const RoasterDashboardData = async () => {

    const currentDate = moment();
    const defaultStartDate = currentDate.clone().startOf('day');;
    const finalStartDate = startDate ? startDate : defaultStartDate;
    const finalEndDate = endDate ? endDate : currentDate;

    console.log("datsfsfd", defaultStartDate, currentDate)

    console.log("superidd", userData?._id)
    
    try{
    const data = await axios
      .get(`${apiUrl}dashboard/shiftDashboard?service_provider_id=${userData?._id}&page_size=10&page_no=1&worker_ids=${selectedItemsWorker}&employee_ids=${selectedItemsManager}&client_ids=${selectedItemsClient}&sil_sda_ids=${selectedSil}&from_date=${finalStartDate || localDate}&to_date=${finalEndDate || localEndDate}`, {
        headers: {
          "Authorization": token,
        }})
      const shiftDonutData = mergeById(data?.data?.data[0]?.appointmentStatusCounts, "totalAppointments")
      const TimesheetData = mergeById(data?.data?.data[0]?.timeSheetStatus, "count")
      setTimesheetStatus(TimesheetData)
      setOverTimeUsers(data?.data?.data[0]?.overTimesUser)
      setAvalHoursData(data?.data?.data[0]?.workforcePlannig[0]?.avalHours.slice(0,3))
      setPlannedHoursData(data?.data?.data[0]?.workforcePlannig[0]?.plannedHours.slice(0,3))
      setShiftStatus(shiftDonutData)
      setRoasterData(data?.data?.data[0])
      setTopOverUtilize(data?.data?.data[0]?.topOverUtilized.slice(0,5))
    }catch(err){
      console.log(err)
    }
  };

  console.log(selectedSil, "sildataids")

  
  

  useEffect(() => {
    clientData('client', setClient);  
  }, []);

  useEffect(() => {
    clientData('manager', setManager);
  }, []);

  useEffect(() => {
    clientData('workers', setWorker);
  }, []);

  // http://localhost:5000/api/dashboard/silSdaList?createdBy=641062840423e7c1cfec8117

  useEffect(() => {
    silSdaFun();
  }, []);

  const silSdaFun = async () => { 
    try{
    const data = await axios
      .get(`${apiUrl}dashboard/silSdaList?createdBy=${userData?._id}`,{
        headers: {
          "Authorization": token,
        
        }})
        console.log(data, "dhjjhdf")
        setSildata(data?.data?.data)
    }catch(err){
      console.log(err)
    } 
  };


  const clientData = async (name, setData) => { 
    try{
    const data = await axios
      .get(`${apiUrl}dashboard/shiftUserList?user_type=${name}`,{
        headers: {
          "Authorization": token,
        
        }})
      setData(data?.data?.data)
    }catch(err){
      console.log(err)
    } 
  };

  console.log("roster",roasterData, timesheetSatatus)

  const leaveRequestCol = ["Employee", "Date", "Status (Slicer)"] 

  const seriesTwo = timesheetSatatus?.map(item => item?.count);
  const labelsTwo = timesheetSatatus?.map(item => `${item?._id} ( ${item?.count} )`);
  const seriesOne = shiftStatus?.map(item => item?.totalAppointments);
  const labelsOne = shiftStatus?.map(item => `${item?._id} ( ${item?.totalAppointments} )`);

  const urlLink = generateUrl();

  const workerOption = [
    { value: "Worker1", label: "Worker 1" },
    { value: "Worker2", label: "Worker 2" },
    { value: "Worker3", label: "Worker 3" },
    { value: "Worker4", label: "Worker 4" },
  ];

  const optionsTwo = [
    { value: "optionTwo1", label: "OptionTwo 1" },
    { value: "optionTwo2", label: "Option Two2" },
    { value: "optionTwo3", label: "OptionOTwo3" },
    { value: "optionTwo4", label: "Option Two4" },
  ];

  // const seriesData = [
  //   {
  //     name: "Bar 1",
  //     label: "Bar 1",
  //     valueFormatter,
  //     data: [4, 3, 5], // Ensure this is defined and not empty
  //   },
  //   {
  //     name: "Bar 2",
  //     label: "Bar 2",
  //     valueFormatter,
  //     data: [1, 6, 3], // Ensure this is defined and not empty
  //   },
  // ];

  const seriesData = [
    {
      name: "Available Hours",
      data: avalHoursData?.map((entry) => entry?.avlHours) || [],
      label: "Available Hours",
      date: avalHoursData?.map((entry) => formatDate(entry.date)) || [],
    },
    {
      name: "Planned Hours",
      data: plannedHoursData?.map((entry) => entry?.plannedHours) || [],
      label: "Planned Hours",
      date :  plannedHoursData?.map((entry) => formatDate(entry.date)) || [],
    },
  ];

  console.log("dates", seriesData)

  const employees = [
    {
      employeeName: "Alice Johnson",
      status: "Approved",
      assigneeName: "Bob Smith",
    },
    {
      employeeName: "Michael Brown",
      status: "Pending",
      assigneeName: "Sarah Connor",
    },
    {
      employeeName: "Jessica Taylor",
      status: "Rejected",
      assigneeName: "Tom Hardy",
    },
    {
      employeeName: "Alice Johnson",
      status: "Approved",
      assigneeName: "Bob Smith",
    },
    {
      employeeName: "Michael Brown",
      status: "Pending",
      assigneeName: "Sarah Connor",
    },
    {
      employeeName: "Jessica Taylor",
      status: "Rejected",
      assigneeName: "Tom Hardy",
    },
    {
      employeeName: "Alice Johnson",
      status: "Approved",
      assigneeName: "Bob Smith",
    },
    { 
      employeeName: "Michael Brown",
      status: "Pending",
      assigneeName: "Sarah Connor",
    },
    {
      employeeName: "Jessica Taylor",
      status: "Rejected",
      assigneeName: "Tom Hardy",
    },
    {
      employeeName: "Alice Johnson",
      status: "Approved",
      assigneeName: "Bob Smith",
    },
    {
      employeeName: "Michael Brown",
      status: "Pending",
      assigneeName: "Sarah Connor",
    },
    {
      employeeName: "Jessica Taylor",
      status: "Rejected",
      assigneeName: "Tom Hardy",
    },
  ];

  function valueFormatter(value) {
    return `${value}mm`;
  }

  console.log("userdaeetaa", userData)

  const topFiveOverUtilize = ["john", "amar","bhawani", "rahul", "abhishek"]

  const data = useSelector((data) => data.status) 
 
  console.log(data, "dkdsjdjf")

  // const startStateFun = () =>{
  //   localStorage.setItem('status', 'started');
  //   localStorage.setItem('started', JSON.stringify(true));
  // }

  return (
    
    <Box ref={contentRef} id="health-carer-dashboard" className="main">
      <Box className="dashboard-form-typo">
        {/* Title/ Breadcrumb & Button section */}
        <Grid
          container
          className="d-flex justi-center padding-left-20  padding-right-20  header-topsection"
        >
          <Grid item xs={6} sm={6} md={10}>
            <Box id="breadcrumb" className="ps-0">
              <Link to={"/" + urlLink + "/home"}>
                {" "}
                <ArrowBackIosIcon />{" "}
              </Link>{" "}
              <Box className="breadcrumb-inr">
                <h4 className="d-flex align-items-center ">Dashboard</h4>
                <Box className="breadcrumbs">
                  <span className="breadcrumbs-link"> Dashboard </span>{" "}
                  <span className="breadcrumbs-icon">{">"}</span>
                  <Link className="breadcrumbs-link breadcrumbs-link-active">
                    Roster management
                  </Link>
                </Box>
              </Box>
            </Box>
          </Grid>
            <Grid xs={2} md={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button className="agency-add filter-btn" onClick={handleDownloadPDF}>
                PDF<DownloadIcon sx={{ marginLeft: '10px' }} />
            </button>
          </Grid>
        </Grid>
        {/* Title/ Breadcrumb & Button section */}
      </Box>

     
      <div id="roasterDashboardBox" className="roaster">
        <div className={`date-filter-section`}>
          <div className="center-flex">
            <h3 className="display-date">{todaydate}</h3>
            
            <div className="date-arr-icon" onClick={()=> setDateIcon((prev)=> !prev)}>
              <KeyboardArrowDownIcon />
            </div>
            { dateIcon && 
            <CalendarExample startPlace ={localDate} endPlace={localEndDate} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
           
            }
            {/* <DatePickerComponent /> */}
            <button onClick={() => setFilterToggleBtn(prev => !prev)} className="agency-add  filter-btn fliter-toggle"><FilterListIcon /></button>
            { endDate && 
              <button className="agency-add reset-btn filter-btn" onClick={cleardateFilter}>Reset Filter</button>
            }
          </div>
          <div className={`filter-drop  ${filterToggleBtn ?  "toggle-state" : ""}`}>
            {/* <MultiSelectDropdown
              id="1"
              label="All Worker"
              options={worker}
              selectedItems={selectedItemsWorker}
              setSelectedItems={setSelectedItemsWorker}
            /> */}

            <MultiDropDown 
              dropdownArray={worker}
              setSelectedItemsData={setSelectedItemsWorker}
              selectedItemsData={selectedItemsWorker}
              placeholder={'All Worker'}
              keyField={'firstName'}
              // disabled={true}
            />


            {/* <MultiSelectDropdown
              id="2"            
              label="All Manager"
              options={manager}
              selectedItems={selectedItemsManager}
              setSelectedItems={setSelectedItemsManager}
            /> */}

            <MultiDropDown 
              dropdownArray={manager}
              setSelectedItemsData={setSelectedItemsManager}
              selectedItemsData={selectedItemsManager}
              placeholder={'All Manager'}
              keyField={'firstName'}
              // disabled={true}
            />
            {/* <MultiSelectDropdown
              id="3"
              label="All Clients"
              options={client}
              selectedItems={selectedItemsClient}
              setSelectedItems={setSelectedItemsClient}
            />   */}

            <MultiDropDown dropdownArray={client}
              setSelectedItemsData={setSelectedItemsClient}
              selectedItemsData={selectedItemsClient}
              placeholder={'All Clients'}
              keyField={'firstName'}
              // disabled={true}
            />

            {/* <MultiSelectwithSearch /> */}

             {/* <MultiSelectwithSearch
              id="4"
              label="SIL/SDA"
              options={sildata}
              selectedItems={selectedSil}
              setSelectedItems={setSelectedSil}
            /> */}


            <MultiDropDown 
              dropdownArray={sildata}
              setSelectedItemsData={setSelectedSil}
              selectedItemsData={selectedSil}
              placeholder={'SIL/SDA'}
              keyField={'locationName'}
              // disabled={true}
            />

        {/* <MultiSelectwithSearch
              items={client}
              // getOptionDisabled={getOptionDisabled}
              label="Select complex values"
              placeholder="Placeholder for textbox"
              selectAllLabel="Select all"
              onChange={setValue}
            /> */}

           
            <button onClick={()=> setFliterBtn(prev => !prev)} className="agency-add filter-btn">Filter data</button>
            <button onClick={resetFilter} className="agency-add reset-btn filter-btn">Reset Filter</button>
          
     {/* <button onClick={cleardateFilter}>
      clear date filter
     </button> */}

          </div>
        </div>
{/*           
          <button  onClick={startStateFun}>
            click me
          </button>

          <button  onClick={() => dispatch(pendingStatusFun(true))}>
            click me 2
          </button>

          <h1>{data.pendingAuto ? "pending": "No"}{data.startedAuto ? "Started": "No"}</h1> */}

        {/*++++++++++++++ card ++++++++++++++ */}
        <div className="roasted-card-box">
          <RoasterCard  link={`/super-admin/list-appointment`}  state={{  localdate:"yes" }} shift="Total Shift" image={image1} total={zerodata(roasterData?.totalShiftCounts)} />
          <RoasterCard 
            // onClick={() => dispatch(startedStatusFun(true))}
            // onClick={startStateFun}
            link={'/super-admin/list-appointment'}
            state={{ status: "Pending", localdate:"yes" }}
          shift="Swap Request" image={image6} total={roasterData?.swapShiftCounts ? roasterData?.swapShiftCounts : "0"} />
          <RoasterCard
            // onClick={() => dispatch(pendingStatusFun(true))}
            shift="Shifts in Progress"
            state={{ status: "Started", localdate:"yes" }}
            link={'/super-admin/list-appointment'}
            image={image7}
            total={roasterData?.inProgressShiftCounts ? roasterData?.inProgressShiftCounts: "0"}
          />
          <RoasterCard link={`/super-admin/list-leave-management`}  state={{  localdate:"yes" }}  shift="Leave Request" image={image8} total={roasterData?.leaveRequestCount ? roasterData?.leaveRequestCount : "0"} />
          <RoasterCard link={`/super-admin/time-sheet`} state={{  localdate:"yes" }} shift="No Breaks" image={image2} total={roasterData?.breaksCount?.noBreaks ? roasterData?.breaksCount?.noBreaks : "0"} />
          <RoasterCard link={`/super-admin/list-attendance`} state={{  localdate:"yes" }} shift="Excessive breaks" image={image3} total={roasterData?.breaksCount?.excessivebreaks ? roasterData?.breaksCount?.excessivebreaks : "0"} />
          <RoasterCard link={`/super-admin/list-progressnote`} state={{  localdate:"yes" }} shift="Progress Notes" image={image4} total={roasterData?.noCaseNotes ? roasterData?.noCaseNotes : "0"} />
          <RoasterCard link={`/super-admin/list-incident`} state={{  localdate:"yes" }} shift="Incidents reported" image={image5} total={roasterData?.incidentReports ? roasterData?.incidentReports : "0  "} />
        </div>

        
        {/*++++++++++++++ chart ++++++++++++++ */}
        <div className="roaster-chart-container">
          <div className="roaster-chart-box m-0 pay-run-input">
            <h3 className="roaster-dash-heading">Shift Status</h3>
            <div className="chart-box">
              {seriesOne.length === 0 ? <NoDataFound /> : <DonutChart series={seriesOne} option={labelsOne} />}
              
            </div>
          </div>
          <div className="roaster-chart-box m-0 pay-run-input">
            <h3 className="roaster-dash-heading">Timesheet Status</h3>
            <div className="chart-box">
            {seriesTwo.length === 0 ? <NoDataFound /> : <DonutChart series={seriesTwo} option={labelsTwo} />}
            </div>
          </div>
        </div>
        {/*++++++++++++++ Table ++++++++++++++ */}
        <div className="w-full p-fixed roaster-chart-box d-flex flex-wrap justify-between">
          <div className="pay-run-input flex-1">
            <h3 className="roaster-dash-heading">Swap Register</h3>
            <div className="table-scroll">
              <SwapTable rows={roasterData?.swapedData} />
            </div>
          </div>
          <div className="pay-run-input flex-1">
            <h3 className="roaster-dash-heading">Live Clock</h3>
            <div className="table-scroll">
              <LiveClock rows={roasterData?.liveClock} />
            </div>
          </div>
          <div className="pay-run-input flex-1">
            <h3 className="roaster-dash-heading">Leave Request</h3>
            <div className="table-scroll">
              <RoasterTable rows={roasterData?.leaveRequestData} coloums={leaveRequestCol} />
            </div>
          </div>
        </div>
        {/*++++++++++++++ working plans ++++++++++++++ */}
        <div className="grid-two mt-3">
          <div className="roaster-chart-box staff-grid">
            <div className="staff-box staff-one">
              <div>
              <h4 className="staff-head">Total Staff</h4>
              <p className="staf-count">{zerodata(roasterData?.totalStaff)}</p>
              </div>
              <div className="align-item-end">
                <div className="staff-icon">
                  <img src={icon1} alt="icon" />
                </div>
              </div>
            </div>

            <div className="staff-box staff-two">
              <div>
              <h4 className="staff-head">Planned hrs</h4>
              <p className="staf-count">{zerodata(roasterData?.plannedHours?.toFixed(2))}K</p>
              </div>
              <div className="align-item-end">
                <div className="staff-icon">
                  <img src={icon2} alt="icon" />
                </div>
              </div>
            </div>    

            <div className="staff-box staff-three">
              <div>
              <h4 className="staff-head">Worked hrs</h4>
              <p className="staf-count">{zerodata(roasterData?.totalWorkedHours?.toFixed(2))}k</p>
              </div>
              <div className="align-item-end">
                <div className="staff-icon">
                  <img src={icon3} alt="icon" />
                </div>
              </div>
            </div>

            <div className="staff-box staff-four">
              <div>
              <h4 className="staff-head">Trainning Expired</h4>
              <p className="staf-count">{zerodata(roasterData?.expiredDataCount)}</p>
              </div>
              <div className="align-item-end">
                <div className="staff-icon">
                  <img src={icon4} alt="icon" />
                </div>
              </div>
            </div>

            <div className="staff-box staff-five">
              <div>
              <h4 className="staff-head">OverBooked</h4>
              <p className="staf-count">{(roasterData?.plannedHours - roasterData?.availableHours).toFixed(2) > 0 ? (roasterData?.plannedHours - roasterData?.availableHours).toFixed(2) : "0"}</p>
              </div>
              <div className="align-item-end">
                <div className="staff-icon">
                  <img src={icon5} alt="icon" />
                </div>
              </div>
            </div>

            <div className="staff-box staff-six">
              <div>
              <h4 className="staff-head">Overtime hrs</h4>
              <p className="staf-count">{zerodata(roasterData?.overTime)}</p>
              </div>
              <div className="align-item-end">
                <div className="staff-icon">
                  <img src={icon6} alt="icon" />
                </div>
              </div>
            </div>

            <div className="staff-box staff-seven">
              <div>
              <h4 className="staff-head">Utilization Rate</h4>
              <p className="staf-count">{isNaN(roasterData?.utilisationRate) ?  "0" :Math.round(roasterData?.utilisationRate/100) }%</p>
              </div>
              <div className="align-item-end">
                <div className="staff-icon">
                  <img src={icon7} alt="icon" />
                </div>
              </div>
            </div>

            <div className="staff-box staff-cta">
              <div>
              <h4 className="staff-head">OnCall</h4>
              <p className="staf-count">{zerodata(roasterData?.CTA)}</p>
              </div>
              <div className="align-item-end">
                <div className="staff-icon">
                  <img src={image9} alt="icon" />  
                </div>
              </div>
            </div>
          </div>
          <div className="roaster-chart-box bar-chart-box">
            <div className="aval-main-box">
            <h3 className="roaster-dash-heading">Workforce Planning</h3>
            <div className="aval-icon-box">
              <div><span className="aval-icon"></span>Avaliable</div>
              <div><span className="aval-icon icons"></span>Planned</div>
            </div>
            </div>
            {avalHoursData?.length === 0 ? <NoDataFound /> : 
            <div>
              <BarChart
                xAxis={[
                  {
                    scaleType: "band",
                    data: seriesData[0]?.date || [],
                    // ["14/08/24", "15/08/24", "16/08/24", "17/08/24", "18/08/24"],
                    axisLabel: {
                      style: {
                        fontSize: 20, // Set custom font size for x-axis labels
                      },
                    },
                  },
                ]}
                yAxis={[
                  {
                    axisLabel: {
                      style: {
                        fontSize: 20, // Set custom font size for y-axis labels
                      },
                    },
                  },
                ]}
                series={seriesData?.map((s, index) => ({
                  name: s.name || "Unnamed",
                  data: s.data.length > 0 ? s.data : [0],
                  label: s.label,
                  color: index % 2 === 0 ? "#886ADD" : "#7AB865", 
                  formatter: (params) => valueFormatter(params?.value),
                }))}
                legend={{
                  show: true, // Display legend
                  position: "top", // Legend position (top, bottom, left, right)
                  itemStyle: {
                    fontSize: "20px", // Customize font size in the legend
                  },
                }}
                width={500}
                height={250}
              />
              {/* <BarChart
        dataset={dataset}
        xAxis={[
          { scaleType: 'band', dataKey: 'month' },
        ]}
        {...chartSetting}
      /> */}


            </div>
           }
          </div>
        </div>

        <div className="roaster-last-section mt-3">
          <div className="roaster-chart-box bar-chart-box">
            <div className="table-scroll">
              <UserActionTable rows={overTimeUsers} action={true} />
            </div>
          </div>
          <div className="roaster-chart-box roaster-utilize">
            <h3 className="utilize-heading">Top 5 Overutilized</h3>
            {topOverUtilize?.map((name, index)=>(
              <div className="utilize-name-box" key={index}>
              <div className="utilize">
                <p className="ut-name">{name?.firstName}</p>
              </div>
            </div>
            ))}
          </div>
        </div>
      </div>
    </Box>
  );
};

export default RoasterDashboard;

