import * as React from "react";
import dayjs from "dayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import FilterStore from "../store/FilterStore";
import TextField from "@mui/material/TextField";

export default function DatePickerValue(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeDate = (event, newValueDate) => {
    setValue(newValueDate);
  };

  const { resetFields, setField, startDate1, endDate1 } = FilterStore();

  const [open, setOpen] = React.useState(false);
  const [openDate, setOpenDate] = React.useState(false);

  const [day, setAge] = React.useState("");
  console.log("value---", startDate1, endDate1);
  const handleSelect = (event) => {
    setAge(event.target.value);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack
        spacing={3}
        className="d-flex align-items flex-column-none justify-content-between"
      >
        <DesktopDatePicker
          className="form-input date-input-filed appt-date"
          inputFormat="DD-MM-YYYY"
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => {
            setOpen(false);
          }}
          value={startDate1}
          disableOpenPicker
          onChange={(newValue) => {
            props && props?.setFields && props?.setFields("startDate1", newValue)
            setField("startDate1", newValue);
          }}

          minDate={null} // Set your desired minimum date for the start date
          maxDate={endDate1}
          PopperProps={{
            placement: "bottom-end",
          }}
          renderInput={(params) => (
            <TextField
              size="small"
              onClick={() => setOpen(true)}
              sx={{ mt: 2, mb: 2 }}
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: "Start Date",
              }}
            />
          )}
        />
        <DesktopDatePicker
          className="form-input date-input-filed"
          inputFormat="DD-MM-YYYY"
          open={openDate}
          onOpen={() => setOpenDate(true)}
          onClose={() => setOpenDate(false)}
          value={endDate1}
          onChange={(newValueDate) => {
            props && props?.setFields && props?.setFields("endDate1", newValueDate)
            setField("endDate1", newValueDate);
          }}
          minDate={startDate1} // Set the current start date as the minimum date for the end date
          maxDate={null}
          PopperProps={{
            placement: "bottom",
          }}
          disableOpenPicker
          renderInput={(params) => (
            <TextField
              size="small"
              onClick={() => setOpenDate(true)}
              sx={{ mt: 2, mb: 2 }}
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: "End Date",
              }}
            />
          )}
        />
      </Stack>
    </LocalizationProvider>
  );
}
