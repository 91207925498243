import * as React from "react";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import {
  getUserTypeList,
  userSignup,
  singleUpload,
  createdBy,
} from "../../api";
import { toast } from "react-toastify";
import { Autocomplete, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import BannerSlider from "../../components/ui/BannerSlider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Container from "@mui/material/Container";
import { IconButton } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import WebHeader from "../../Website/Web-Header";

import Link from "@mui/material/Link";

export default function SignInSide(props) {
  const [disableButton, setDisableButton] = useState(false);
  const navigate = useNavigate();
  const [hidden, setHidden] = useState(false);
  const [userTypeVal, setUserTypeVal] = useState([]);
  const [clientSchema, setClientSchema] = useState(false);

  useEffect(() => {
    axios
      .get(getUserTypeList)
      .then((res) => {
        let userTypeData = res?.data?.data;

        let data = [];
        userTypeData &&
          userTypeData.map((d) => {
            data.push({
              label: `${d.userTypeName}`,
              format: d.userTypeFormat,
            });
          });
        setUserTypeVal(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleUserTypeChange = (event, newValue, setFieldValue) => {
    setFieldValue("userType", newValue.format);
  };
  let validationSchema;

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    userType: "",
    supportcoordinatororganisationname: "",
    healthcarercheckbox: false,
    supportcoordinatorcheckbox: false,
    healthcarerabnnumber: "",
    supportcoordinatorabnnumber: "",
    supportcoordinatorregion: "",
    supportcoordinatorlogo: "",
    serviceprovidercompanyname: "",
    serviceprovidercompanyndisregistraionnumber: "",
    serviceproviderprovidercode: "",
    serviceprovideralternatecontact: "",
    supportcoordinatorregistrationnumber: "",
    isimage: false,
    serviceprovidercompanymobilenumber: "",
    serviceprovidercompanyemailid: "",
    passportNumber: "",
    drivingLicence: "",
  };

  const [sw, setsw] = useState(false);
  const [sc, setsc] = useState(false);
  const [sp, setsp] = useState(false);
  /**Health Carer functionality code starts */
  const [healthCarerVisible, sethealthCarerVisible] = useState(false);
  const healthCarerFunction = (e, setFieldValue) => {
    setFieldValue("healthcarercheckbox", e.target.checked);
    sethealthCarerVisible(e.target.checked);
  };
  /**Health Carer functionality code ends */

  /**support coordinator functionality code starts */
  const [supportCoordinatorVisible, setsupportCoordinatorVisible] =
    useState(false);
  const supportCoordinatorFunction = (e, setFieldValue) => {
    setFieldValue("supportcoordinatorcheckbox", e.target.checked);
    setsupportCoordinatorVisible(e.target.checked);
  };

  /**support coordinator functionality code ends */

  /**support coordinator functionality code starts */
  const [serviceProviderVisible, setserviceProviderVisible] = useState(false);
  const Region = [
    { label: "North", id: "N" },
    { label: "South", id: "S" },
    { label: "East", id: "E" },
    { label: "West", id: "W" },
  ];

  /**support coordinator functionality code ends */

  const [user, setUser] = useState([]);
  const getUserTypeFunction = async (e, value, setFieldValue) => {
    setserviceProviderVisible(false);
    setsupportCoordinatorVisible(false);
    sethealthCarerVisible(false);
    setClientSchema(false);
    if (value != null) {
      setFieldValue("userType", value.format);
      if (value.format == "healthCarer") {
        setUser(value.format);
        setsw(true);
        setsc(false);
        setsp(false);
        // sethealthCarerVisible(true);
        setserviceProviderVisible(false);
        setsupportCoordinatorVisible(false);
        setClientSchema(false);
      } else if (value.format == "supportCoordinator") {
        setUser(value.format);
        setsc(true);
        setsw(false);
        setsp(false);
        setClientSchema(false);
        setserviceProviderVisible(false);
        sethealthCarerVisible(false);
        //  setsupportCoordinatorVisible(true);
      } else if (value.format == "serviceProvider") {
        setUser(value.format);
        setsp(true);
        setsw(false);
        setsc(false);
        setClientSchema(false);
        setserviceProviderVisible(true);
        sethealthCarerVisible(false);
        setsupportCoordinatorVisible(false);
      } else if (value.format == "client") {
        setClientSchema(true);
        setUser(value.format);
        setsp(false);
        setsw(false);
        setsc(false);
        setClientSchema(true);
        setserviceProviderVisible(false);
        sethealthCarerVisible(false);
        setsupportCoordinatorVisible(false);
      }
    }
  };
  const [region, setregion] = useState(null);
  const getRegion = async (e, value, setFieldValue) => {
    if (value) {
      setFieldValue("supportcoordinatorregion", value.label);
      setregion(value.label);
    }
  };
  const [image, setImage] = useState();
  const [logo, setlogo] = useState();
  const [scLogo, setSCLogo] = useState();

  let handlePersonalDoc = (e, setFieldValue) => {
    const file = e.target.files;
    const fileKey = file[0].name;

    let vals = new FormData();
    vals.append("image", file[0]);

    axios
      .post(singleUpload, vals, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        let fileData = res?.data?.data;
        let data = [];

        data.push({
          location: fileData,
          key: fileKey,
        });
        setFieldValue("logo", data[0]);
        setFieldValue("isimage", true);
        setlogo(fileData);
        setSCLogo(fileData);
        toast.success("Logo  uploaded");
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };
  // console.log("scLogo", scLogo);

  let handleAdhaarImage = (e, setFieldValue) => {
    setFieldValue("isimage", true);
    const file = e.target.files;
    // formik.setFieldValue("aadharCard", file[0]);
    setlogo(file[0].name);
    let vals = new FormData();
    vals.append("image", file[0]);
    axios
      .post(singleUpload, vals, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setImage(res.data.data);
        // formik.setFieldValue("aadharCard", res.data.data);
        toast.success("File uploaded");
      })
      .catch((error) => {
        // toast.error("Something went wrong");
      });
  };
  /**for Health Carer starts */

  const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

  if (healthCarerVisible === true) {
    validationSchema = Yup.object().shape({
      firstName: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(60, "Maximum 60 symbols")
        .required("First Name is required"),
      lastName: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(60, "Maximum 60 symbols")
        .required("Last Name is required"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      mobileNumber: Yup.string()
        .matches(phoneRegExp, "Mobile number is not valid")
        .required("Mobile number is required")
        .min(10, "Mobile Number should not be less than 10 digit")
        .max(13, "Mobile Number should not be greater than 13 digit"),
      // passportNumber: Yup.string()
      //   .required("Passport Number is required")
      //   .matches(
      //     /^[0-9A-Z]+$/,
      //     "Invalid Passport Number. Only numbers and capital letters (A-Z) are allowed."
      //   )
      //   .test(
      //     "no-invalid-characters",
      //     "Invalid characters: S, O, Q, and I are not allowed.",
      //     (value) => !/[SOQI]/.test(value)
      //   )
      //   .min(9, "Passport Number should be at least 9 characters")
      //   .max(9, "Passport Number should be at most 9 characters"),
      // drivingLicence: Yup.string()
      //   .required("Driving Licence Number is required")
      //   .matches(
      //     /^[A-Z]{2}\d{6}[A-Z]$/,
      //     "Invalid Driving Licence Number. It should start with two capital letters, followed by six digits, and end with one capital letter."
      //   ),
      userType: Yup.string().required("User Type is required"),
      healthcarercheckbox: Yup.boolean(),

      healthcarerabnnumber: Yup.number().required("ABN Number is required"),
    });
  } else if (supportCoordinatorVisible === true) {
    validationSchema = Yup.object().shape({
      firstName: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(60, "Maximum 60 symbols")
        .required("First Name is required"),
      lastName: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(60, "Maximum 60 symbols")
        .required("Last Name is required"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      mobileNumber: Yup.string()
        .matches(phoneRegExp, "Mobile number is not valid")
        .required("Mobile number is required")
        .min(10, "Mobile Number should not be less than 10 digit")
        .max(13, "Mobile Number should not be greater than 13 digit"),
      // passportNumber: Yup.string()
      //   .required("Passport Number is required")
      //   .matches(
      //     /^[0-9A-Z]+$/,
      //     "Invalid Passport Number. Only numbers and capital letters (A-Z) are allowed."
      //   )
      //   .test(
      //     "no-invalid-characters",
      //     "Invalid characters: S, O, Q, and I are not allowed.",
      //     (value) => !/[SOQI]/.test(value)
      //   )
      //   .min(9, "Passport Number should be at least 9 characters")
      //   .max(9, "Passport Number should be at most 9 characters"),
      // drivingLicence: Yup.string()
      //   .required("Driving Licence Number is required")
      //   .matches(
      //     /^[A-Z]{2}\d{6}[A-Z]$/,
      //     "Invalid Driving Licence Number. It should start with two capital letters, followed by six digits, and end with one capital letter."
      //   ),
      userType: Yup.string().required("User Type is required"),
      supportcoordinatorcheckbox: Yup.boolean(),
      supportcoordinatororganisationname: Yup.string().required(
        "Organisation Name is required"
      ),
      supportcoordinatorabnnumber: Yup.number().required(
        "ABN Number is required"
      ),
      supportcoordinatorregistrationnumber: Yup.string().required(
        "Registration Number is required"
      ),
      supportcoordinatorregion: Yup.string().required("Region is required"),
      isimage: Yup.boolean().oneOf([true], "Logo is required"),
    });
  } else if (serviceProviderVisible === true) {
    validationSchema = Yup.object().shape({
      firstName: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(60, "Maximum 60 symbols")
        .required("First Name is required"),
      lastName: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(60, "Maximum 60 symbols")
        .required("Last Name is required"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      mobileNumber: Yup.string()
        .matches(phoneRegExp, "Mobile Number is not valid")
        .required("Mobile Number is required")
        .min(10, "Mobile Number should not be less than 10 digit")
        .max(13, "Mobile Number should not be greater than 13 digit"),
      // passportNumber: Yup.string()
      //   .required("Passport Number is required")
      //   .matches(
      //     /^[0-9A-Z]+$/,
      //     "Invalid Passport Number. Only numbers and capital letters (A-Z) are allowed."
      //   )
      //   .test(
      //     "no-invalid-characters",
      //     "Invalid characters: S, O, Q, and I are not allowed.",
      //     (value) => !/[SOQI]/.test(value)
      //   )
      //   .min(9, "Passport Number should be at least 9 characters")
      //   .max(9, "Passport Number should be at most 9 characters"),
      // drivingLicence: Yup.string()
      //   .required("Driving Licence Number is required")
      //   .matches(
      //     /^[A-Z]{2}\d{6}[A-Z]$/,
      //     "Invalid Driving Licence Number. It should start with two capital letters, followed by six digits, and end with one capital letter."
      //   ),
      userType: Yup.string().required("User Type is required"),
      serviceprovideralternatecontact: Yup.string()
        .matches(phoneRegExp, "not valid")
        // .required("Alternate Number required")
        .min(10, "Mobile Number should not be less than 10 digit")
        .max(13, "Mobile Number should not be greater than 13 digit"),
      serviceprovidercompanymobilenumber: Yup.string()
        .matches(phoneRegExp, "Comapany Mobile Number is not valid")
        .required("Comapany Mobile Number required")
        .min(10, "Comapany Mobile Number should not be less than 10 digit")
        .max(13, "Comapany Mobile Number should not be greater than 13 digit"),
      serviceproviderprovidercode: Yup.string().required(
        "Provider Code Is required"
      ),
      // serviceprovidercompanyndisregistraionnumber: Yup.string().required(
      //   "Ndis Registration Number is required "
      // ),

      serviceprovidercompanyndisregistraionnumber: Yup.string()
        .required("Ndis Registration Number is required")
        .matches(
          /^(43)\d{7}$/,
          "Ndis Registration Number must start with 43 and have a total of nine digits"
        ),

      serviceprovidercompanyname: Yup.string().required(
        "Company Name required"
      ),
      serviceprovidercompanyemailid: Yup.string()
        .email("Invalid email format")
        .required("Company Email ID required"),
    });
  } else if (clientSchema === true) {
    validationSchema = Yup.object().shape({
      firstName: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(60, "Maximum 60 symbols")
        .required("First Name is required"),
      lastName: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(60, "Maximum 60 symbols")
        .required("Last Name is required"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      mobileNumber: Yup.string()
        .matches(phoneRegExp, "Mobile number is not valid")
        .required("Mobile number is required")
        .min(10, "Mobile Number should not be less than 10 digit")
        .max(13, "Mobile Number should not be greater than 13 digit"),
      userType: Yup.string().required("User Type is required"),
      // passportNumber: Yup.string()
      //   .required("Passport Number is required")
      //   .matches(
      //     /^[0-9A-Z]+$/,
      //     "Invalid Passport Number. Only numbers and capital letters (A-Z) are allowed."
      //   )
      //   .test(
      //     "no-invalid-characters",
      //     "Invalid characters: S, O, Q, and I are not allowed.",
      //     (value) => !/[SOQI]/.test(value)
      //   )
      //   .min(9, "Passport Number should be at least 9 characters")
      //   .max(9, "Passport Number should be at most 9 characters"),
      // drivingLicence: Yup.string().required("Driving Licence Number is required"),
    });
  } else {
    validationSchema = Yup.object().shape({
      firstName: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(60, "Maximum 60 symbols")
        .required("First Name is required"),
      lastName: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(60, "Maximum 60 symbols")
        .required("Last Name is required"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      mobileNumber: Yup.string()
        .matches(phoneRegExp, "Mobile number is not valid")
        .required("Mobile number is required")
        .min(10, "Mobile Number should not be less than 10 digit")
        .max(13, "Mobile Number should not be greater than 13 digit"),
      userType: Yup.string().required("User Type is required"),
      // passportNumber: Yup.string()
      //   .required("Passport Number is required")
      //   .matches(
      //     /^[0-9A-Z]+$/,
      //     "Invalid Passport Number. Only numbers and capital letters (A-Z) are allowed."
      //   )
      //   .test(
      //     "no-invalid-characters",
      //     "Invalid characters: S, O, Q, and I are not allowed.",
      //     (value) => !/[SOQI]/.test(value)
      //   )
      //   .min(9, "Passport Number should be at least 9 characters")
      //   .max(9, "Passport Number should be at most 9 characters"),
      // drivingLicence: Yup.string()
      //   .required("Driving Licence Number is required")
      //   .matches(
      //     /^[A-Z]{2}\d{6}[A-Z]$/,
      //     "Invalid Driving Licence Number. It should start with two capital letters, followed by six digits, and end with one capital letter."
      //   ),
    });
  }

  return (
    <Box id="personal-info" width="100%" height="100%">
      <Box>
        <WebHeader />
      </Box>
      <Grid container>
        <Grid item xs={12} sm={12} md={7}>
          <Box>
            <BannerSlider />
          </Box>
        </Grid>

        <Grid item xs={12} md={5}>
          {/* <Box className='signingrid-box' */}
          <Box className="w-100 h-100 d-flex align-items-center justify-content-center">
            <Box className="form-main-box">
              <Box>
                <h2 mb={3} className="sign-title">
                  Sign up
                  <p className="text-mute"> Please enter your details.</p>
                </h2>
              </Box>

              {/* form start */}
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  if (user === "supportCoordinator") {
                    const postData = {
                      firstName: values.firstName,
                      lastName: values.lastName,
                      createdBy: createdBy,
                      updatedBy: createdBy,
                      email: values.email,
                      mobileNumber: values.mobileNumber,
                      passportNumber: values.passportNumber,
                      drivingLicence: values.drivingLicence,
                      userType: user,
                      isIndividual: values.supportcoordinatorcheckbox,
                      organization: {
                        name: values.supportcoordinatororganisationname
                          ? values.supportcoordinatororganisationname
                          : "",
                        abnNumber: values.supportcoordinatorabnnumber
                          ? values.supportcoordinatorabnnumber
                          : "",
                        registrationNumber:
                          values.supportcoordinatorregistrationnumber
                            ? values.supportcoordinatorregistrationnumber
                            : "",
                        logo: values.logo,
                        region: region,
                      },
                    };
                    setDisableButton(true);
                    axios
                      .post(userSignup, postData)
                      .then((res) => {
                        if (res.data.success === false) {
                          toast.error(res.data.message);
                          setDisableButton(false);
                        }
                        if (res.data.success === true) {
                          setDisableButton(false);
                          toast.success(res.data.message);
                          setHidden(true);
                          setTimeout(() => {
                            navigate("/sign-in");
                          }, 3000);
                        }
                      })
                      .catch((error) => {
                        toast.error("Something went wrong");
                      });
                  } else {
                    const postData = {
                      firstName: values.firstName,
                      lastName: values.lastName,
                      createdBy: createdBy,
                      updatedBy: createdBy,
                      email: values.email,
                      mobileNumber: values.mobileNumber,
                      passportNumber: values.passportNumber,
                      drivingLicence: values.drivingLicence,
                      userType: user,
                      isIndividual: values.healthcarercheckbox,
                      abnNumber: values.healthcarerabnnumber
                        ? values.healthcarerabnnumber
                        : "",
                      companyDetails: {
                        name: values.serviceprovidercompanyname
                          ? values.serviceprovidercompanyname
                          : "",
                        companyEmailId: values.serviceprovidercompanyemailid
                          ? values.serviceprovidercompanyemailid
                          : "",
                        mobileNumber: values.serviceprovidercompanymobilenumber
                          ? values.serviceprovidercompanymobilenumber
                          : "",
                        companyNdisRegnumber:
                          values.serviceprovidercompanyndisregistraionnumber
                            ? values.serviceprovidercompanyndisregistraionnumber
                            : "",
                        companyProviderCode: values.serviceproviderprovidercode
                          ? values.serviceproviderprovidercode
                          : "",
                        alternateNumber: values.serviceprovideralternatecontact
                          ? values.serviceprovideralternatecontact
                          : "",
                      },
                    };
                    setDisableButton(true);
                    axios
                      .post(userSignup, postData)
                      .then((res) => {
                        if (res.data.success === false) {
                          setDisableButton(false);
                          toast.error(res.data.message);
                        }
                        if (res.data.success === true) {
                          setDisableButton(false);
                          toast.success(res.data.message);
                          setHidden(true);
                          setTimeout(() => {
                            navigate("/sign-in");
                          }, 3000);
                        }
                      })
                      .catch((error) => {
                        toast.error("Something went wrong");
                      });
                  }
                }}
              >
                {({
                  setFieldValue,
                  errors,
                  touched,
                  values,
                  handleBlur,
                  handleChange,
                  isSubmitting,
                  isValid,
                  dirty,
                }) => (
                  <>
                    <Form>
                      <div className="form-field">
                        <label>User Type *</label>
                        <Field
                          component={Autocomplete}
                          margin="normal"
                          size="small"
                          disablePortal
                          name="userType"
                          id="combo-box-demo"
                          options={userTypeVal}
                          onChange={(event, newValue) =>
                            getUserTypeFunction(event, newValue, setFieldValue)
                          }
                          renderInput={(params) => (
                            <Field
                              as={TextField}
                              margin="normal"
                              size="small"
                              className="auto-complete-textField"
                              {...params}
                              placeholder="Select User Type"
                              name="userType"
                              value={values?.userType?.format}
                              error={
                                Boolean(errors.userType) &&
                                Boolean(touched.userType)
                              }
                              helperText={
                                Boolean(touched.userType) && errors.userType
                              }
                            />
                          )}
                          className="auto-complete"
                        />
                      </div>
                      <div className="form-field">
                        <label>Full Name *</label>
                        <Stack
                          direction="row"
                          spacing={1}
                          className="form-field"
                        >
                          <div>
                            <Field
                              name="firstName"
                              type="text"
                              as={TextField}
                              margin="normal"
                              size="small"
                              className="form-input"
                              placeholder="First Name"
                              error={
                                Boolean(errors.firstName) &&
                                Boolean(touched.firstName)
                              }
                              helperText={
                                Boolean(touched.firstName) && errors.firstName
                              }
                              maxLength={60}
                              inputProps={{ maxLength: 60 }}
                            />
                          </div>
                          <div>
                            <Field
                              name="lastName"
                              type="text"
                              as={TextField}
                              margin="normal"
                              size="small"
                              className="form-input"
                              placeholder="Last Name"
                              error={
                                Boolean(errors.lastName) &&
                                Boolean(touched.lastName)
                              }
                              helperText={
                                Boolean(touched.lastName) && errors.lastName
                              }
                              maxLength={60}
                              inputProps={{ maxLength: 60 }}
                            />
                          </div>
                        </Stack>
                      </div>
                      <div className="form-field">
                        <label>Email * </label>
                        <Field
                          name="email"
                          type="email"
                          as={TextField}
                          margin="normal"
                          size="small"
                          className="form-input"
                          placeholder="Email"
                          error={
                            Boolean(errors.email) && Boolean(touched.email)
                          }
                          helperText={Boolean(touched.email) && errors.email}
                          maxLength={100}
                          inputProps={{ maxLength: 100 }}
                        />
                      </div>
                      <div className="form-field">
                        <label> Mobile Number * </label>
                        <Field
                          name="mobileNumber"
                          type="text"
                          as={TextField}
                          margin="normal"
                          size="small"
                          className="form-input"
                          placeholder="Mobile Number"
                          error={
                            Boolean(errors.mobileNumber) &&
                            Boolean(touched.mobileNumber)
                          }
                          helperText={
                            Boolean(touched.mobileNumber) && errors.mobileNumber
                          }
                          maxLength={16}
                          inputProps={{ maxLength: 16 }}
                        />
                      </div>

                      {/* <div className="form-field">
                        <label>Passport Number * </label>
                        <Field
                          name="passportNumber"
                          type="text"
                          as={TextField}
                          margin="normal"
                          size="small"
                          className="form-input"
                          placeholder="Passport Number"
                          error={
                            Boolean(errors.passportNumber) &&
                            Boolean(touched.passportNumber)
                          }
                          helperText={
                            Boolean(touched.passportNumber) &&
                            errors.passportNumber
                          }
                          maxLength={20}
                          inputProps={{ maxLength: 20 }}
                        />
                      </div> */}
                      {/* drivingLicence */}
                      {/* <div className="form-field">
                        <label>Driving Licence Number * </label>
                        <Field
                          name="drivingLicence"
                          type="text"
                          as={TextField}
                          margin="normal"
                          size="small"
                          className="form-input"
                          placeholder="Driving Licence Number"
                          error={
                            Boolean(errors.drivingLicence) &&
                            Boolean(touched.drivingLicence)
                          }
                          helperText={
                            Boolean(touched.drivingLicence) &&
                            errors.drivingLicence
                          }
                          maxLength={20}
                          inputProps={{ maxLength: 20 }}
                        />
                      </div> */}
                      <p>&nbsp;</p>
                      {/* code for Health Carer starts */}
                      <Grid container sx={{ display: sw ? "visible" : "none" }}>
                        <Checkbox
                          checked={healthCarerVisible}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                          name="healthcarercheckbox"
                          // className="form-input"
                          aria-label="checkbox-demo"
                          type="checkbox"
                          onChange={(e) =>
                            healthCarerFunction(e, setFieldValue)
                          }
                          value={values.healthcarercheckbox}
                        />
                        <label
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Individual / Sole Trader?
                        </label>
                        {errors.healthcarercheckbox && (
                          <span> {errors.healthcarercheckbox}</span>
                        )}
                        {/* </FormGroup> */}
                      </Grid>
                      <Grid
                        container
                        sx={{
                          display: healthCarerVisible ? "visible" : "none",
                        }}
                      >
                        <label>ABN Number *</label>
                        <Field
                          name="healthcarerabnnumber"
                          type="number"
                          as={TextField}
                          margin="normal"
                          size="small"
                          className="form-input"
                          placeholder="ABN Number"
                          error={
                            Boolean(errors.healthcarerabnnumber) &&
                            Boolean(touched.healthcarerabnnumber)
                          }
                          helperText={
                            Boolean(touched.healthcarerabnnumber) &&
                            errors.healthcarerabnnumber
                          }
                          maxLength={100}
                          inputProps={{ maxLength: 100 }}
                        />
                      </Grid>
                      <Grid container sx={{ display: sc ? "visible" : "none" }}>
                        <FormGroup>
                          <FormControlLabel
                            onClick={(e) => supportCoordinatorFunction(e)}
                            sx={{ mt: 2 }}
                            control={
                              <Checkbox checked={supportCoordinatorVisible} />
                            }
                            label="Business?"
                            name="supportcoordinatorcheckbox"
                            onChange={(e) =>
                              supportCoordinatorFunction(e, setFieldValue)
                            }
                            value={values.supportcoordinatorcheckbox}
                          />
                        </FormGroup>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        className="input pt-0"
                        sx={{
                          display: supportCoordinatorVisible
                            ? "visible"
                            : "none",
                        }}
                      >
                        <label>Organisation Name *</label>
                        <Field
                          name="supportcoordinatororganisationname"
                          type="text"
                          as={TextField}
                          margin="normal"
                          size="small"
                          className="form-input"
                          placeholder="Organisation Name"
                          error={
                            Boolean(
                              errors.supportcoordinatororganisationname
                            ) &&
                            Boolean(touched.supportcoordinatororganisationname)
                          }
                          helperText={
                            Boolean(
                              touched.supportcoordinatororganisationname
                            ) && errors.supportcoordinatororganisationname
                          }
                          maxLength={100}
                          inputProps={{ maxLength: 100 }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        className="input pt-0"
                        sx={{
                          display: supportCoordinatorVisible
                            ? "visible"
                            : "none",
                        }}
                      >
                        <label>ABN Number/ Business Number *</label>
                        <Field
                          name="supportcoordinatorabnnumber"
                          type="number"
                          as={TextField}
                          margin="normal"
                          size="small"
                          className="form-input"
                          placeholder="ABN Number/ Business Number "
                          error={
                            Boolean(errors.supportcoordinatorabnnumber) &&
                            Boolean(touched.supportcoordinatorabnnumber)
                          }
                          helperText={
                            Boolean(touched.supportcoordinatorabnnumber) &&
                            errors.supportcoordinatorabnnumber
                          }
                          maxLength={100}
                          inputProps={{ maxLength: 100 }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        className="input pt-0"
                        sx={{
                          display: supportCoordinatorVisible
                            ? "visible"
                            : "none",
                        }}
                      >
                        <label>Registration Number *</label>
                        <Field
                          name="supportcoordinatorregistrationnumber"
                          type="text"
                          as={TextField}
                          margin="normal"
                          size="small"
                          className="form-input"
                          placeholder="Registration Number"
                          error={
                            Boolean(
                              errors.supportcoordinatorregistrationnumber
                            ) &&
                            Boolean(
                              touched.supportcoordinatorregistrationnumber
                            )
                          }
                          helperText={
                            Boolean(
                              touched.supportcoordinatorregistrationnumber
                            ) && errors.supportcoordinatorregistrationnumber
                          }
                          maxLength={100}
                          inputProps={{ maxLength: 100 }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        className="input pt-0"
                        sx={{
                          display: supportCoordinatorVisible
                            ? "visible"
                            : "none",
                        }}
                      >
                        <label>Select Region *</label>
                        <Field
                          component={Autocomplete}
                          margin="normal"
                          size="small"
                          disablePortal
                          name="supportcoordinatorregion"
                          id="combo-box-demo"
                          options={Region}
                          onChange={(event, newValue) =>
                            getRegion(event, newValue, setFieldValue)
                          }
                          renderInput={(params) => (
                            <Field
                              as={TextField}
                              margin="normal"
                              size="small"
                              className="auto-complete-textField"
                              {...params}
                              placeholder="Select Region"
                              name="supportcoordinatorregion"
                              value={values?.supportcoordinatorregion?.label}
                              error={
                                Boolean(errors.supportcoordinatorregion) &&
                                Boolean(touched.supportcoordinatorregion)
                              }
                              helperText={
                                Boolean(touched.supportcoordinatorregion) &&
                                errors.supportcoordinatorregion
                              }
                            />
                          )}
                          className="auto-complete"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        className="input pt-0"
                        sx={{
                          display: supportCoordinatorVisible
                            ? "visible"
                            : "none",
                        }}
                      >
                        <Typography sx={{ pb: 1 }}>Upload Logo</Typography>
                        <span
                          style={{
                            fontSize: "0.75rem",
                            fontWeight: "400",
                            marginTop: "2px",
                            marginRight: "14px",
                            textAlign: "left",
                            fontFamily:
                              '"Roboto","Helvetica","Arial",sans-serif',
                          }}
                        >
                          [ Upload in .jpg, .jpeg, .png format ]
                        </span>
                        <Container
                          sx={{
                            ml: 0,
                            border: "1px solid #DAE5EB;",
                            borderRadius: "4px",
                          }}
                        >
                          <IconButton
                            color="primary"
                            aria-label="Upload Logo"
                            component="label"
                          >
                            <input
                              hidden
                              accept="image/*"
                              type="file"
                              name="logo"
                              onChange={(e) =>
                                handlePersonalDoc(e, setFieldValue)
                              }
                            />
                            <FileUploadOutlinedIcon size="small" />
                            <Typography varient="h4">Browse Files</Typography>
                          </IconButton>
                          {scLogo !== undefined ? (
                            <a
                              className="mt-6 text-success"
                              href={scLogo}
                              target="_blank"
                              download={true}
                            >
                              &nbsp;&nbsp;( Link )
                            </a>
                          ) : (
                            ""
                          )}
                        </Container>
                        {/* </Box> */}
                        {errors.isimage && (
                          <span
                            style={{
                              color: "#d32f2f",
                              fontSize: "0.75rem",
                              fontWeight: "400",
                              marginTop: "4px",
                              marginRight: "14px",
                              textAlign: "left",
                              fontFamily:
                                '"Roboto","Helvetica","Arial",sans-serif',
                              marginLeft: "14px",
                            }}
                          >
                            {" "}
                            {errors.isimage}
                          </span>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        className="input pt-0"
                        sx={{
                          display: serviceProviderVisible ? "visible" : "none",
                        }}
                      >
                        <label>Company Name *</label>
                        <Field
                          name="serviceprovidercompanyname"
                          type="text"
                          as={TextField}
                          margin="normal"
                          size="small"
                          className="form-input"
                          placeholder="Company Name"
                          error={
                            Boolean(errors.serviceprovidercompanyname) &&
                            Boolean(touched.serviceprovidercompanyname)
                          }
                          helperText={
                            Boolean(touched.serviceprovidercompanyname) &&
                            errors.serviceprovidercompanyname
                          }
                          maxLength={100}
                          inputProps={{ maxLength: 100 }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        className="input pt-0"
                        sx={{
                          display: serviceProviderVisible ? "visible" : "none",
                        }}
                      >
                        <label>Company Email ID *</label>
                        <Field
                          name="serviceprovidercompanyemailid"
                          type="email"
                          as={TextField}
                          margin="normal"
                          size="small"
                          className="form-input"
                          placeholder="Company Email ID"
                          error={
                            Boolean(errors.serviceprovidercompanyemailid) &&
                            Boolean(touched.serviceprovidercompanyemailid)
                          }
                          helperText={
                            Boolean(touched.serviceprovidercompanyemailid) &&
                            errors.serviceprovidercompanyemailid
                          }
                          maxLength={100}
                          inputProps={{ maxLength: 100 }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        className="input pt-0"
                        sx={{
                          display: serviceProviderVisible ? "visible" : "none",
                        }}
                      >
                        <label>Company Mobile Number *</label>
                        <Field
                          name="serviceprovidercompanymobilenumber"
                          type="text"
                          as={TextField}
                          margin="normal"
                          size="small"
                          className="form-input"
                          placeholder="Company Mobile Number"
                          error={
                            Boolean(
                              errors.serviceprovidercompanymobilenumber
                            ) &&
                            Boolean(touched.serviceprovidercompanymobilenumber)
                          }
                          helperText={
                            Boolean(
                              touched.serviceprovidercompanymobilenumber
                            ) && errors.serviceprovidercompanymobilenumber
                          }
                          maxLength={16}
                          inputProps={{ maxLength: 16 }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        className="input pt-0"
                        sx={{
                          display: serviceProviderVisible ? "visible" : "none",
                        }}
                      >
                        <label>Company Ndis Registration Number*</label>
                        <Field
                          name="serviceprovidercompanyndisregistraionnumber"
                          type="text"
                          as={TextField}
                          margin="normal"
                          size="small"
                          className="form-input"
                          placeholder="Company NDIS Registration Number "
                          error={
                            Boolean(
                              errors.serviceprovidercompanyndisregistraionnumber
                            ) &&
                            Boolean(
                              touched.serviceprovidercompanyndisregistraionnumber
                            )
                          }
                          helperText={
                            Boolean(
                              touched.serviceprovidercompanyndisregistraionnumber
                            ) &&
                            errors.serviceprovidercompanyndisregistraionnumber
                          }
                          maxLength={100}
                          inputProps={{ maxLength: 100 }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        className="input pt-0"
                        sx={{
                          display: serviceProviderVisible ? "visible" : "none",
                        }}
                      >
                        <label>Provider Code *</label>
                        <Field
                          name="serviceproviderprovidercode"
                          type="text"
                          as={TextField}
                          margin="normal"
                          size="small"
                          className="form-input"
                          placeholder="Provider Code"
                          error={
                            Boolean(errors.serviceproviderprovidercode) &&
                            Boolean(touched.serviceproviderprovidercode)
                          }
                          helperText={
                            Boolean(touched.serviceproviderprovidercode) &&
                            errors.serviceproviderprovidercode
                          }
                          maxLength={100}
                          inputProps={{ maxLength: 100 }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        className="input pt-0"
                        sx={{
                          display: serviceProviderVisible ? "visible" : "none",
                        }}
                      >
                        <label>Alternate Contact Number</label>
                        <Field
                          name="serviceprovideralternatecontact"
                          type="text"
                          as={TextField}
                          margin="normal"
                          size="small"
                          className="form-input"
                          placeholder="Alternate contact"
                          error={
                            Boolean(errors.serviceprovideralternatecontact) &&
                            Boolean(touched.serviceprovideralternatecontact)
                          }
                          helperText={
                            Boolean(touched.serviceprovideralternatecontact) &&
                            errors.serviceprovideralternatecontact
                          }
                        />
                      </Grid>

                      <Button
                        className="signupbutton"
                        type="submit"
                        fullWidth
                        sx={{ mt: 3, mb: 2 }}
                        disabled={disableButton}
                      >
                        {disableButton === true ? "Processing..." : "Submit"}
                      </Button>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography color="#929495">
                          Already have an account?&nbsp;
                          <Link href="/sign-in" variant="body2">
                            Sign In
                          </Link>
                        </Typography>
                      </Box>
                      {/* </Box> */}
                    </Form>
                  </>
                )}
              </Formik>
              {/* form end */}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
