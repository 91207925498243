// src/components/PrivacyPolicy.js

import React from "react";

import WebHeader from "../../../Website/Web-Header";

import WebFooter from "../../../Website/Web-footer";

import { Box } from "@mui/material";

function PrivacyPolicy() {
  return (
    <Box className="web-topBanner-bg  blog-main-banner">

      <WebHeader />

      <Box className="webContainer">

      <div
        // className="privacy-policy"
        >
          <h1 className="mt-3 mb-3 mb-4">Privacy Policy of Hubshift Pty Ltd</h1>
          <h4>1. Contact Information</h4>
          <p className="mb-3">
            . Company Name: Hubshift Pty Ltd <br />
            . Australian Business Number (ABN): 37656268450 <br />
            . Address: Eastgardens Sydney, NSW 2036 <br />
            . Phone Number 1800 774 954 <br />
            . Email Address: <a href="mailto:Info@Hubshift.au?Subject=Hubshift%20Help%20Support"><b><u>Info@Hubshift.au</u></b></a> <br />
          </p>

          <h4>2. Definitions</h4>
          <p className="mb-3">
            . "We," "Our," or "Us": Refers to Hubshift Pty Ltd. <br />
            . "You": Refers to the users or customers who interact with our services or website. <br />
            . "Services": Encompasses any service provided by Hubshift Pty Ltd to our users or customers. <br />
            . "Website": Refers to Hubshift.au and any other websites operated by Hubshift Pty Ltd. <br />
            . "Personal Information": Any information relating to an identified or identifiable natural person. <br />
          </p>

          <h4>3. Introduction</h4>
          <p className="mb-3">
            Hubshift Pty Ltd is committed to safeguarding your personal information. We adhere to the Australian Privacy Principles as outlined in the Privacy Act 1988. By using our website and/or services, you consent to the processing of your personal information as detailed in this Privacy Policy. If you disagree with any part of this policy, please refrain from using our website and services. 
          </p>
          
          <h4>4. Collection of Personal Information </h4>
          <p className="mb-3">
            We collect personal information directly from you, through your agents, documentation, and third-party sources, as necessary for our business operations. This information may include names, contact details, billing information, usernames, passwords, IP addresses, and any other information relevant to our service provision. Sensitive health or medical information may also be collected under specific circumstances, with your consent or as required by our services.           
          </p>

          <h4>5. Storage and Security of Personal Information  </h4>
          <p className="mb-3">
            Your personal information is stored either in physical or electronic form and is protected against unauthorized access, loss, or misuse. In the event of no longer requiring your personal information, we will securely delete or destroy it in compliance with Australian law and our document retention policy or user can delete his account with this link permanently <a href="https://hubshift.au/delete-account">https://hubshift.au/delete-account</a>            
          </p>

          <h4>6. Use of Digital Technologies </h4>
          <p className="mb-3">
            We employ digital technologies like cookies, web beacons, and server logs for website functionality and service improvement. These technologies may collect non-personal information. Our use of cookies is essential for full website functionality, with options available to manage cookie preferences.             
          </p>

          <h4>7. Purpose and Use of Information </h4>
          <p className="mb-3">
            Your personal information is used for account registration and management, service provision, service customization, improvement, legal compliance, and enforcement of our legal terms. We only collect, use, and disclose your information as necessary for these purposes and in compliance with the law.             
          </p>

          <h4>8. Information Sharing and Third-Party Services </h4>
          <p className="mb-3">
            Your information may be stored and processed by third-party cloud services (e.g., Amazon Web Services) and is subject to their privacy practices. Information may also be shared for corporate transactions, legal obligations, or to prevent harm, with all processing operations conducted within Australia.             
          </p>

          <h4>9. Lawful Basis for Processing </h4>
          <p className="mb-3">
            Our processing of your personal information is based on your consent, contractual obligations, legal requirements, and legitimate interests such as improving our services and protecting rights.              
          </p>

          <h4>10. Communication Preferences and Opt-Out </h4>
          <p className="mb-3">
            You may opt-in to receive communications from us. To unsubscribe, update your preferences through the contact details provided.               
          </p>

          <h4>11. Retention of Personal Information </h4>
          <p className="mb-3">
            We retain your personal information as necessary for the duration of your engagement with our services and in accordance with legal and regulatory requirements.               
          </p>

          <h4>12. Security Measures </h4>
          <p className="mb-3">
            We implement reasonable security measures, including administrative, technical, and physical safeguards, to protect your personal information from unauthorized access and misuse.                
          </p>

          <h4>13. Your Privacy Rights </h4>
          <p className="mb-3">
            You have the right to access and correct your personal information held by us. Requests for access or correction can be made through our designated contact channels.                
          </p>

          <h4>14. Complaints and Dispute Resolution </h4>
          <p className="mb-3">
            If you believe we have breached our privacy obligations, please contact our Privacy Officer. We aim to resolve complaints within 30 days and will provide reasons and expected resolution times if this is not possible.                
          </p>

          <h4>15. Policy Updates </h4>
          <p className="mb-3">
            This Privacy Policy may be updated periodically. We encourage you to review it regularly for any changes. Continued use of our website and services constitutes acceptance of the revised policy.                
          </p>

          <h4>16. Office of the Australian Information Commissioner (OAIC) </h4>
          <p className="mb-3">
            For unresolved privacy concerns, you may contact the OAIC at https://www.oaic.gov.au/.                
          </p>

          <h4>17. Further Information </h4>
          <p className="mb-3">
            For more information or to raise concerns about our privacy practices, please contact our Privacy Officer at Sydney NSW, 2036, or via <a href="info@hubshift.au.">info@hubshift.au.</a>                
          </p>
          <p className="mb-3">
            This Privacy Policy has been updated to align with the latest privacy legislation and best practices, ensuring the protection and respectful handling of your personal information.                
          </p>

        </div>
      </Box>
      <WebFooter />
    </Box>
  );
}

export default PrivacyPolicy;
