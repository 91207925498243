import * as React from "react";
import Box from "@mui/material/Box";
import Appbar from "../../../Dashboard/Layout/Appbar";
import { Typography } from "@mui/material";
import Sidebar from "../../../Dashboard/Layout/Sidebar";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "../../../App.css";
// import "./availibility.css";
import Shift from "../../../components/form/Shifttype/Shift";
import NextWeek from "../../../components/form/Shifttype/NextWeek";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
// modal tools getAllShift
import Loader from "../../loader/Loader";
import { FieldsListLoading } from "../../components/loading/FieldsListLoading";

import {
  getAllShift,
  setAvailability,
  getAvailabilityByUserId,
  AttendanceList,
} from "../../../api";
import { useState, useEffect } from "react";
// import axios from "axios";
import axios from "../../../api/axios";
import { create } from "zustand";
import { toast } from "react-toastify";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import FormGroup from "@mui/material/FormGroup";
import MyStore from "./MyStore";
import localstorageData from "../../../helpers/localstorageData";
import { weekdays } from "../../../helpers/staticDropdwonData";
import { getCreatedBy } from "../../../helpers/commonHelper";
import MonthField from "./MonthField";
import MothlyAvailability from "./MothlyAvailability";
import Calendar from "./Calendar";
import QuarterlyCalendar from "../../../components/set-availability/QuarterlyCalendar";
import generateUrl from "../../../helpers/urlHelper";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

// One time slot every 30 minutes.
const timeSlots = Array.from(new Array(24 * 2)).map(
  (_, index) =>
    `${index < 20 ? "0" : ""}${Math.floor(index / 2)}:${
      index % 2 === 0 ? "00" : "30"
    }`
);

export default function Attendance() {
  const user = localstorageData();
  const createdBy = getCreatedBy();
  const urlLink = generateUrl();
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const [open, setOpen] = React.useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [shiftTypeData, setShiftTypeData] = useState([]);
  const [attendedDates, setattendedDates] = useState([]);

  const [quaterList, setListData] = useState([]);
  // const [selectedQuarter, setSelectedQuarter] = useState();

  useEffect(() => {
    getaList();
  }, []);

  const getaList = async () => {
    axios
      .get(`${AttendanceList}/${user._id}`)
      .then((res) => {
        console.log("res---", res);
        if (res.data.data.docs) setattendedDates(res.data.data.docs[0].dates);

        // setListData(data);
        setisLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setisLoading(false);
      });
  };

  //zustand code
  const {
    resetFields,
    shifttypes,
    Weekly,
    NextWeekly,
    Monthly,
    setField,
    isSubmitting,
    defaultCheck,
    altype,
  } = MyStore();

  // console.log(NextWeekly);
  const setSleepover = (val) => {
    const sleepoverCheckedDays = Weekly[val.id]["sleepoverCheckedDays"];

    //set default values to week
    const object = {
      defaultCheck: false,
      altype: "Weekly",
      copyToDays: [],
      copyFromDay: [],
      sleepoverCheckedDays: sleepoverCheckedDays,
      copyToNextWeeklyDays: [],
      Mon: [
        {
          startTime: sleepoverCheckedDays.includes("Mon") ? val.startTime : "",
          endTime: sleepoverCheckedDays.includes("Mon") ? val.endTime : "",
        },
      ],
      Tue: [
        {
          startTime: sleepoverCheckedDays.includes("Tue") ? val.startTime : "",
          endTime: sleepoverCheckedDays.includes("Tue") ? val.endTime : "",
        },
      ],
      Wed: [
        {
          startTime: sleepoverCheckedDays.includes("Wed") ? val.startTime : "",
          endTime: sleepoverCheckedDays.includes("Wed") ? val.endTime : "",
        },
      ],
      Thu: [
        {
          startTime: sleepoverCheckedDays.includes("Thu") ? val.startTime : "",
          endTime: sleepoverCheckedDays.includes("Thu") ? val.endTime : "",
        },
      ],
      Fri: [
        {
          startTime: sleepoverCheckedDays.includes("Fri") ? val.startTime : "",
          endTime: sleepoverCheckedDays.includes("Fri") ? val.endTime : "",
        },
      ],
      Sat: [
        {
          startTime: sleepoverCheckedDays.includes("Sat") ? val.startTime : "",
          endTime: sleepoverCheckedDays.includes("Sat") ? val.endTime : "",
        },
      ],
      Sun: [
        {
          startTime: sleepoverCheckedDays.includes("Sun") ? val.startTime : "",
          endTime: sleepoverCheckedDays.includes("Sun") ? val.endTime : "",
        },
      ],
    };
    //setField("Weekly", Weekly);

    return object;
  };

  const setWeeklySleepover = (val) => {
    if (
      NextWeekly[val.id] !== undefined &&
      NextWeekly[val.id]["sleepoverCheckedDays"] !== undefined
    ) {
      const sleepoverCheckedDays = NextWeekly[val.id]["sleepoverCheckedDays"];

      //Next Weekly
      const nextObject = {
        defaultCheck: false,
        altype: "",
        copyFromDay: "",
        copyFromType: "",
        copyToWeeklyDays: [],
        copyToNextWeeklyDays: [],
        sleepoverCheckedDays: sleepoverCheckedDays,
        Mon: [
          {
            startTime: sleepoverCheckedDays.includes("Mon")
              ? val.startTime
              : "",
            endTime: sleepoverCheckedDays.includes("Mon") ? val.endTime : "",
          },
        ],
        Tue: [
          {
            startTime: sleepoverCheckedDays.includes("Tue")
              ? val.startTime
              : "",
            endTime: sleepoverCheckedDays.includes("Tue") ? val.endTime : "",
          },
        ],
        Wed: [
          {
            startTime: sleepoverCheckedDays.includes("Wed")
              ? val.startTime
              : "",
            endTime: sleepoverCheckedDays.includes("Wed") ? val.endTime : "",
          },
        ],
        Thu: [
          {
            startTime: sleepoverCheckedDays.includes("Thu")
              ? val.startTime
              : "",
            endTime: sleepoverCheckedDays.includes("Thu") ? val.endTime : "",
          },
        ],
        Fri: [
          {
            startTime: sleepoverCheckedDays.includes("Fri")
              ? val.startTime
              : "",
            endTime: sleepoverCheckedDays.includes("Fri") ? val.endTime : "",
          },
        ],
        Sat: [
          {
            startTime: sleepoverCheckedDays.includes("Sat")
              ? val.startTime
              : "",
            endTime: sleepoverCheckedDays.includes("Sat") ? val.endTime : "",
          },
        ],
        Sun: [
          {
            startTime: sleepoverCheckedDays.includes("Sun")
              ? val.startTime
              : "",
            endTime: sleepoverCheckedDays.includes("Sun") ? val.endTime : "",
          },
        ],
      };

      return nextObject;
    } else {
      return {};
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [check, setCheck] = React.useState(true);

  useEffect(() => {
    getAllShiftTypes();
  }, []);

  const getAllShiftTypes = () => {
    axios
      .get(`${getAllShift}?createdBy=${createdBy}`)
      .then((res) => {
        let data = [];
        res.data.data &&
          res.data.data.map((d, index) => {
            // if (index === 0) {
            pushInitialWeeklyShift(d._id);
            pushInitialNextWeeklyShift(d._id);
            pushInitialMonthlyShift(d._id);
            // }

            if (d.name === "Sleepover") {
              sleepover({
                name: `${d.name}`,
                id: d._id,
                startTime: d.startTime,
                endTime: d.endTime,
                isChecked: index === 0 ? true : false,
                isShow: false,
                altype: "Weekly",
                sleepoverCheckedDaysWeekly: [],
                sleepoverCheckedDaysNextWeekly: [],
              });
            }
            data.push({
              name: `${d.name}`,
              id: d._id,
              startTime: d.startTime,
              endTime: d.endTime,
              // isChecked: index === 0 ? true : false,
              isChecked: false,
              isShow: false,
            });
          });
        setField("shifttypes", data);
        setShiftTypeData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (shiftTypeData.length > 0) {
      getAllUserAvailability(shiftTypeData);
    }
  }, [shiftTypeData]);

  const getAllUserAvailability = (data) => {
    setisLoading(true);
    axios
      .get(getAvailabilityByUserId + "/" + user._id)
      .then((res) => {
        res.data.data.map((value) => {
          value.Fortnightly.map((val) => {
            if (
              val.shiftTypeId !== undefined &&
              val.shiftTypeId !== "" &&
              val.shiftTypeId !== null
            ) {
              setInitialAvalability(val, data);
            }
          });
          value.Weekly.map((val) => {
            if (
              val.shiftTypeId !== undefined &&
              val.shiftTypeId !== "" &&
              val.shiftTypeId !== null
            ) {
              setInitialWeeklyAvalability(val, data);
            }
          });
        });
        setisLoading(false);
      })
      .catch((error) => {
        setisLoading(false);
        console.log(error);
      });
  };

  //set initial DB values to avalability
  const setInitialAvalability = (val, data) => {
    if (data.length > 0) {
      const newShiftType = data.map((vals, index) => {
        if (vals.id === val.shiftTypeId) {
          vals.isChecked = true;
          vals.isShow = true;

          if (val.CurrentWeek !== undefined && val.shiftTypeId !== undefined) {
            if (vals.name === "Sleepover") {
              sleepover({
                name: `${vals.name}`,
                id: vals.id,
                startTime: vals.startTime,
                endTime: vals.endTime,
                isChecked: index === 0 ? true : false,
                isShow: false,
                altype: "Fortnightly",
                sleepoverCheckedDaysWeekly:
                  val?.CurrentWeek?.sleepoverCheckedDays,
                sleepoverCheckedDaysNextWeekly:
                  val?.NextWeek?.sleepoverCheckedDays,
              });
            } else {
              const shiftTypeIds = {
                defaultCheck: val.CurrentWeek.defaultCheck,
                copyFromDay: "",
                copyFromType: "",
                copyToDays: [],
                copyFromDay: [],
                copyToWeeklyDays: [],
                copyToNextWeeklyDays: [],
                altype: "",
                sleepoverCheckedDays: val.CurrentWeek.sleepoverCheckedDays,
                Mon: val.CurrentWeek.Mon,
                Tue: val.CurrentWeek.Tue,
                Wed: val.CurrentWeek.Wed,
                Thu: val.CurrentWeek.Thu,
                Fri: val.CurrentWeek.Fri,
                Sat: val.CurrentWeek.Sat,
                Sun: val.CurrentWeek.Sun,
              };

              if (Weekly[val.shiftTypeId] === undefined) {
                Weekly[val.shiftTypeId] = shiftTypeIds;
                setField("Weekly", Weekly);
              }
            }
          }

          if (val.NextWeek !== undefined && val.shiftTypeId !== undefined) {
            if (vals.name === "Sleepover") {
              sleepover({
                name: `${vals.name}`,
                id: vals.id,
                startTime: vals.startTime,
                endTime: vals.endTime,
                altype: "Fortnightly",
                isChecked: index === 0 ? true : false,
                isShow: false,
                sleepoverCheckedDaysWeekly:
                  val?.CurrentWeek?.sleepoverCheckedDays,
                sleepoverCheckedDaysNextWeekly:
                  val.NextWeek.sleepoverCheckedDays,
              });
            } else {
              const shiftTypeIds = {
                defaultCheck: val.NextWeek.defaultCheck,
                sleepoverCheckedDays: val.NextWeek.sleepoverCheckedDays,
                copyFromDay: "",
                copyFromType: "",
                copyToWeeklyDays: [],
                copyToNextWeeklyDays: [],
                altype: "Fortnightly",
                Mon: val.NextWeek.Mon,
                Tue: val.NextWeek.Tue,
                Wed: val.NextWeek.Wed,
                Thu: val.NextWeek.Thu,
                Fri: val.NextWeek.Fri,
                Sat: val.NextWeek.Sat,
                Sun: val.NextWeek.Sun,
              };

              if (NextWeekly[val.shiftTypeId] === undefined) {
                NextWeekly[val.shiftTypeId] = shiftTypeIds;
                setField("NextWeekly", NextWeekly);
              }
            }
          }
        }
        return vals;
      });

      if (newShiftType !== undefined && newShiftType.length > 0) {
        setField("shifttypes", newShiftType);
      }
    }
  };

  const setInitialWeeklyAvalability = (val, data) => {
    if (data.length > 0) {
      const newShiftType = data.map((vals, index) => {
        if (vals.id === val.shiftTypeId) {
          vals.isChecked = true;
          vals.isShow = true;
          if (vals.name === "Sleepover") {
            sleepover({
              name: `${vals.name}`,
              id: vals.id,
              startTime: vals.startTime,
              endTime: vals.endTime,
              altype: "Weekly",
              isChecked: index === 0 ? true : false,
              isShow: false,
              sleepoverCheckedDaysWeekly: val.week.sleepoverCheckedDays,
              sleepoverCheckedDaysNextWeekly: [],
            });
          } else if (val !== undefined && val.shiftTypeId !== undefined) {
            const shiftTypeIds = {
              defaultCheck: val.week.defaultCheck,
              sleepoverCheckedDays: val.week.sleepoverCheckedDays,
              copyFromDay: "",
              copyFromType: "",
              copyToDays: [],
              copyFromDay: [],
              copyToWeeklyDays: [],
              copyToNextWeeklyDays: [],
              altype: "Weekly",
              Mon: val.week.Mon,
              Tue: val.week.Tue,
              Wed: val.week.Wed,
              Thu: val.week.Thu,
              Fri: val.week.Fri,
              Sat: val.week.Sat,
              Sun: val.week.Sun,
            };

            Weekly[val.shiftTypeId] = shiftTypeIds;
            setField("Weekly", Weekly);

            pushInitialNextWeeklyShift(val.shiftTypeId);
          }
        }
        return vals;
      });

      if (newShiftType !== undefined && newShiftType.length > 0) {
        setField("shifttypes", newShiftType);
      }
    }
  };

  //select and unselect all shift types
  const selectALL = (e, key) => {
    if (e.target.checked) {
      shifttypes.map((val) => {
        val.isChecked = e.target.checked;
        pushInitialWeeklyShift(val.id);
        pushInitialNextWeeklyShift(val.id);
        if (val.name === "Sleepover") {
          sleepover({
            name: `${val.name}`,
            id: val.id,
            startTime: val.startTime,
            endTime: val.endTime,
            altype: "Weekly",
            isChecked: true,
            isShow: false,
            sleepoverCheckedDaysWeekly: [],
            sleepoverCheckedDaysNextWeekly: [],
          });
        }
      });
      setField("shifttypes", shifttypes);
    } else {
      shifttypes.map((val) => {
        val.isChecked = e.target.checked;
        val.isShow = false;
        popWeeklyShift(val.id);
        popNextWeeklyShift(val.id);
      });
      setField("shifttypes", shifttypes);
    }
  };

  const selectOne = (e, key) => {
    // console.log(shifttypes[key].id);
    if (e.target.checked) {
      shifttypes[key].isChecked = e.target.checked;
      setField("shifttypes", shifttypes);
      pushInitialWeeklyShift(shifttypes[key].id);
      pushInitialNextWeeklyShift(shifttypes[key].id);

      if (shifttypes[key].name === "Sleepover") {
        sleepover({
          name: `${shifttypes[key].name}`,
          id: shifttypes[key].id,
          startTime: shifttypes[key].startTime,
          endTime: shifttypes[key].endTime,
          altype: "Weekly",
          isChecked: true,
          isShow: false,
          sleepoverCheckedDaysWeekly: [],
          sleepoverCheckedDaysNextWeekly: [],
        });
      }
    } else {
      shifttypes[key].isChecked = e.target.checked;
      shifttypes[key].isShow = false;
      setField("shifttypes", shifttypes);
      popWeeklyShift(shifttypes[key].id);
      popNextWeeklyShift(shifttypes[key].id);
    }
  };

  const pushInitialMonthlyShift = (shiftTypeId) => {
    const shiftTypeIds = {
      defaultCheck: false,
      altype: "",
      QlSelectedDays: {},
    };
    if (Monthly[shiftTypeId] === undefined) {
      Monthly[shiftTypeId] = shiftTypeIds;
      setField("Monthly", Monthly);
    }
  };

  const pushInitialWeeklyShift = (shiftTypeId) => {
    const shiftTypeIds = {
      defaultCheck: false,
      copyToDays: [],
      copyFromDay: [],
      altype: "Weekly",
      Mon: [
        {
          startTime: "",
          endTime: "",
        },
      ],
      Tue: [
        {
          startTime: "",
          endTime: "",
        },
      ],
      Wed: [
        {
          startTime: "",
          endTime: "",
        },
      ],
      Thu: [
        {
          startTime: "",
          endTime: "",
        },
      ],
      Fri: [
        {
          startTime: "",
          endTime: "",
        },
      ],
      Sat: [
        {
          startTime: "",
          endTime: "",
        },
      ],
      Sun: [
        {
          startTime: "",
          endTime: "",
        },
      ],
    };
    if (Weekly[shiftTypeId] === undefined) {
      Weekly[shiftTypeId] = shiftTypeIds;
      setField("Weekly", Weekly);
    }
  };

  const popWeeklyShift = (shiftTypeId) => {
    delete Weekly[shiftTypeId];
    setField("Weekly", Weekly);
  };

  //Next Weekly Array
  const pushInitialNextWeeklyShift = (shiftTypeId) => {
    const shiftTypeIds = {
      defaultCheck: false,
      copyFromDay: "",
      copyFromType: "",
      copyToWeeklyDays: [],
      copyToNextWeeklyDays: [],
      altype: "",
      Mon: [
        {
          startTime: "",
          endTime: "",
        },
      ],
      Tue: [
        {
          startTime: "",
          endTime: "",
        },
      ],
      Wed: [
        {
          startTime: "",
          endTime: "",
        },
      ],
      Thu: [
        {
          startTime: "",
          endTime: "",
        },
      ],
      Fri: [
        {
          startTime: "",
          endTime: "",
        },
      ],
      Sat: [
        {
          startTime: "",
          endTime: "",
        },
      ],
      Sun: [
        {
          startTime: "",
          endTime: "",
        },
      ],
    };

    if (NextWeekly[shiftTypeId] === undefined) {
      NextWeekly[shiftTypeId] = shiftTypeIds;
      setField("NextWeekly", NextWeekly);
    }
  };

  const popNextWeeklyShift = (shiftTypeId) => {
    delete NextWeekly[shiftTypeId];
    setField("NextWeekly", NextWeekly);
  };

  const showField = (e, key, val) => {
    const newArray = shifttypes.map((obj) => {
      if (obj.id === val.id) {
        return { ...obj, isShow: !obj.isShow };
      }
      return obj;
    });
    setField("shifttypes", newArray);
  };

  const sleepover = (val) => {
    //logic for check active/inactive day in the week.
    const currentDate = new Date();
    const currentDay = currentDate.getDay(); // Returns the current day as a number (0-6)

    const activeDay = [];
    const inactiveday = [];

    weekdays.map((weekday, index) => {
      if (index >= currentDay - 1) {
        activeDay.push(weekday);
      } else {
        inactiveday.push(weekday);
      }
    });

    //set default values to week
    if (Weekly[val.id] !== undefined) {
      Weekly[val.id]["defaultCheck"] = false;
      Weekly[val.id] = {
        defaultCheck: false,
        altype: "Weekly",
        copyToDays: [],
        copyFromDay: [],
        sleepoverCheckedDays: val.sleepoverCheckedDaysWeekly,
        copyToNextWeeklyDays: [],
        Mon: [
          {
            startTime: inactiveday.includes("Mon") ? "" : val.startTime,
            endTime: inactiveday.includes("Mon") ? "" : val.endTime,
          },
        ],
        Tue: [
          {
            startTime: inactiveday.includes("Tue") ? "" : val.startTime,
            endTime: inactiveday.includes("Tue") ? "" : val.endTime,
          },
        ],
        Wed: [
          {
            startTime: inactiveday.includes("Wed") ? "" : val.startTime,
            endTime: inactiveday.includes("Wed") ? "" : val.endTime,
          },
        ],
        Thu: [
          {
            startTime: inactiveday.includes("Thu") ? "" : val.startTime,
            endTime: inactiveday.includes("Thu") ? "" : val.endTime,
          },
        ],
        Fri: [
          {
            startTime: inactiveday.includes("Fri") ? "" : val.startTime,
            endTime: inactiveday.includes("Fri") ? "" : val.endTime,
          },
        ],
        Sat: [
          {
            startTime: inactiveday.includes("Sat") ? "" : val.startTime,
            endTime: inactiveday.includes("Sat") ? "" : val.endTime,
          },
        ],
        Sun: [
          {
            startTime: inactiveday.includes("Sun") ? "" : val.startTime,
            endTime: inactiveday.includes("Sun") ? "" : val.endTime,
          },
        ],
      };
      setField("Weekly", Weekly);
    }

    //Next Weekly
    if (NextWeekly[val.id] !== undefined) {
      NextWeekly[val.id] = {
        defaultCheck: false,
        altype: val?.altype !== "Weekly" ? val?.altype : "",
        copyFromDay: "",
        copyFromType: "",
        copyToWeeklyDays: [],
        copyToNextWeeklyDays: [],
        sleepoverCheckedDays: val.sleepoverCheckedDaysNextWeekly,
        Mon: [
          {
            startTime: val.startTime,
            endTime: val.endTime,
          },
        ],
        Tue: [
          {
            startTime: val.startTime,
            endTime: val.endTime,
          },
        ],
        Wed: [
          {
            startTime: val.startTime,
            endTime: val.endTime,
          },
        ],
        Thu: [
          {
            startTime: val.startTime,
            endTime: val.endTime,
          },
        ],
        Fri: [
          {
            startTime: val.startTime,
            endTime: val.endTime,
          },
        ],
        Sat: [
          {
            startTime: val.startTime,
            endTime: val.endTime,
          },
        ],
        Sun: [
          {
            startTime: val.startTime,
            endTime: val.endTime,
          },
        ],
      };
      setField("NextWeekly", NextWeekly);
    }
  };

  const defaultChangeCalendar = (e, val) => {
    const res = shifttypes.find((vala) => vala.id === val.id);

    if (res.name === "Sleepover") {
      toast.error("Default values cannot be set for the Sleepover shift.");
      return;
    }

    if (e.target.checked) {
      if (Monthly[val.id] !== undefined) {
        //set default values to week
        Monthly[val.id]["defaultCheck"] = e.target.checked;
        setField("Monthly", Monthly);
      }
    } else {
      if (Monthly[val.id] !== undefined) {
        Monthly[val.id]["defaultCheck"] = e.target.checked;
        setField("Monthly", Monthly);
      }
    }
  };

  const defaultChange = (e, val) => {
    const res = shifttypes.find((vala) => vala.id === val.id);

    if (res.name === "Sleepover") {
      toast.error("Default values cannot be set for the Sleepover shift.");
      return;
    }

    if (e.target.checked) {
      if (Weekly[val.id] !== undefined) {
        //logic for check active/inactive day in the week.
        const currentDate = new Date();
        const currentDay = currentDate.getDay(); // Returns the current day as a number (0-6)

        const activeDay = [];
        const inactiveday = [];

        weekdays.map((weekday, index) => {
          if (index >= currentDay - 1) {
            activeDay.push(weekday);
          } else {
            inactiveday.push(weekday);
          }
        });

        //set default values to week
        Weekly[val.id]["defaultCheck"] = e.target.checked;
        Weekly[val.id] = {
          defaultCheck: e.target.checked,
          altype: "Weekly",
          copyToDays: [],
          copyFromDay: [],
          Mon: [
            {
              startTime: inactiveday.includes("Mon") ? "" : val.startTime,
              endTime: inactiveday.includes("Mon") ? "" : val.endTime,
            },
          ],
          Tue: [
            {
              startTime: inactiveday.includes("Tue") ? "" : val.startTime,
              endTime: inactiveday.includes("Tue") ? "" : val.endTime,
            },
          ],
          Wed: [
            {
              startTime: inactiveday.includes("Wed") ? "" : val.startTime,
              endTime: inactiveday.includes("Wed") ? "" : val.endTime,
            },
          ],
          Thu: [
            {
              startTime: inactiveday.includes("Thu") ? "" : val.startTime,
              endTime: inactiveday.includes("Thu") ? "" : val.endTime,
            },
          ],
          Fri: [
            {
              startTime: inactiveday.includes("Fri") ? "" : val.startTime,
              endTime: inactiveday.includes("Fri") ? "" : val.endTime,
            },
          ],
          Sat: [
            {
              startTime: inactiveday.includes("Sat") ? "" : val.startTime,
              endTime: inactiveday.includes("Sat") ? "" : val.endTime,
            },
          ],
          Sun: [
            {
              startTime: inactiveday.includes("Sun") ? "" : val.startTime,
              endTime: inactiveday.includes("Sun") ? "" : val.endTime,
            },
          ],
        };
        setField("Weekly", Weekly);
      }
    } else {
      if (Weekly[val.id] !== undefined) {
        Weekly[val.id]["Mon"][0] = {
          startTime: "",
          endTime: "",
        };
        Weekly[val.id]["Tue"][0] = {
          startTime: "",
          endTime: "",
        };
        Weekly[val.id]["Wed"][0] = {
          startTime: "",
          endTime: "",
        };
        Weekly[val.id]["Thu"][0] = {
          startTime: "",
          endTime: "",
        };
        Weekly[val.id]["Fri"][0] = {
          startTime: "",
          endTime: "",
        };
        Weekly[val.id]["Sat"][0] = {
          startTime: "",
          endTime: "",
        };
        Weekly[val.id]["Sun"][0] = {
          startTime: "",
          endTime: "",
        };
        Weekly[val.id]["copyToDays"] = [];
        Weekly[val.id]["copyFromDay"] = [];
        Weekly[val.id]["defaultCheck"] = e.target.checked;
        Weekly[val.id]["altype"] = "Weekly";
        setField("Weekly", Weekly);
      }
    }
  };

  const defaultChangeNextWeekly = (e, val) => {
    const res = shifttypes.find((vala) => vala.id === val.id);

    if (res.name !== undefined && res.name === "Sleepover") {
      toast.error("Default values cannot be set for the Sleepover shift.");
      return;
    }
    if (e.target.checked) {
      if (NextWeekly[val.id]["altype"] === "Fortnightly") {
        NextWeekly[val.id] = {
          defaultCheck: e.target.checked,
          altype: "Fortnightly",
          copyFromDay: "",
          copyFromType: "",
          copyToWeeklyDays: [],
          copyToNextWeeklyDays: [],
          Mon: [
            {
              startTime: val.startTime,
              endTime: val.endTime,
            },
          ],
          Tue: [
            {
              startTime: val.startTime,
              endTime: val.endTime,
            },
          ],
          Wed: [
            {
              startTime: val.startTime,
              endTime: val.endTime,
            },
          ],
          Thu: [
            {
              startTime: val.startTime,
              endTime: val.endTime,
            },
          ],
          Fri: [
            {
              startTime: val.startTime,
              endTime: val.endTime,
            },
          ],
          Sat: [
            {
              startTime: val.startTime,
              endTime: val.endTime,
            },
          ],
          Sun: [
            {
              startTime: val.startTime,
              endTime: val.endTime,
            },
          ],
        };
        setField("NextWeekly", NextWeekly);
      }
    } else {
      if (NextWeekly[val.id]["altype"] === "Fortnightly") {
        NextWeekly[val.id]["Mon"][0] = {
          startTime: "",
          endTime: "",
        };
        NextWeekly[val.id]["Tue"][0] = {
          startTime: "",
          endTime: "",
        };
        NextWeekly[val.id]["Wed"][0] = {
          startTime: "",
          endTime: "",
        };
        NextWeekly[val.id]["Thu"][0] = {
          startTime: "",
          endTime: "",
        };
        NextWeekly[val.id]["Fri"][0] = {
          startTime: "",
          endTime: "",
        };
        NextWeekly[val.id]["Sat"][0] = {
          startTime: "",
          endTime: "",
        };
        NextWeekly[val.id]["Sun"][0] = {
          startTime: "",
          endTime: "",
        };
        NextWeekly[val.id]["defaultCheck"] = e.target.checked;
        NextWeekly[val.id]["altype"] = "Fortnightly";
        NextWeekly[val.id]["copyFromDay"] = "";
        NextWeekly[val.id]["copyFromType"] = "";
        NextWeekly[val.id]["copyToWeeklyDays"] = [];
        NextWeekly[val.id]["copyToNextWeeklyDays"] = [];
        setField("NextWeekly", NextWeekly);
      }
    }
  };

  const AlTypeChanged = (e, val) => {
    if (e.target.value === "Weekly") {
      Weekly[val.id]["altype"] = "Weekly";
      NextWeekly[val.id]["altype"] = "";
      Monthly[val.id]["altype"] = "";
      setField("Monthly", Monthly);
      setField("Weekly", Weekly);
      setField("NextWeekly", NextWeekly);
    } else if (e.target.value === "Fortnightly") {
      NextWeekly[val.id]["altype"] = "Fortnightly";
      Monthly[val.id]["altype"] = "";
      setField("Monthly", Monthly);

      setField("NextWeekly", NextWeekly);
    } else if (e.target.value === "Monthly") {
      Weekly[val.id]["altype"] = "";
      NextWeekly[val.id]["altype"] = "";
      setField("Weekly", Weekly);
      setField("NextWeekly", NextWeekly);

      Monthly[val.id]["altype"] = "Monthly";
      setField("Monthly", Monthly);
    } else if (e.target.value === "Quarterly") {
      Weekly[val.id]["altype"] = "";
      NextWeekly[val.id]["altype"] = "";
      setField("Weekly", Weekly);
      setField("NextWeekly", NextWeekly);

      Monthly[val.id]["altype"] = "Quarterly";
      setField("Monthly", Monthly);
    }
  };

  const cancelledAvalability = (e) => {
    e.preventDefault();
    getAllShiftTypes();
    if (shiftTypeData.length > 0) {
      getAllUserAvailability(shiftTypeData);
    }
  };

  const [status, setStatus] = React.useState(0); // 0: no show, 1: show yes, 2: show no.

  const radioHandler = (status) => {
    setStatus(status);
  };

  const radioHandlerClose = (status) => {
    setStatus(status);
  };
  

  return (
    <>
      <Box id="agency" className="main Hc-Availibility-main">
        <Box>
          <Grid container className="d-flex header-topsection">
            <Grid item xs={8} sm={8} md={8}>
              <Box id="breadcrumb">
              <Link to={"/" + urlLink + "/home"}>                <ArrowBackIosIcon />
                </Link>
                <Box className="breadcrumb-inr">
                  <h4 className="d-flex align-items-center "> Time Card</h4>
                  <Box className="breadcrumbs">
                    <Link className="breadcrumbs-link breadcrumbs-link-active">
                      Time Card
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box className="divider"> </Box>
        </Box>
        <Calendar attendedDates={attendedDates} getaList={getaList}/>
      </Box>

      {/* Modal start   */}
    </>
  );
}
