import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import WebHeader from "./Web-Header";
import { Link } from "react-router-dom";
import bannerImage from "../assets/images/home-banner.png";
import WebFooter from "./Web-footer";
import LogoSlider from "./HomeUI/Home-logo-slider";
import careManage1 from "../assets/images/care-manage1.jpg";
import careManage2 from "../assets/images/care-manage2.jpg";
import careManage3 from "../assets/images/care-manage3.jpg";
import careManage4 from "../assets/images/care-manage4.jpg";
import webfeature5 from "../assets/images/webfeature5.png";
import featureTick from "../assets/images/feature-tick.png";
import orangeIcon from "../assets/images/orange-star.png";
import yellowIcon from "../assets/images/yellow-star.png";
import Frame from "../assets/images/Frame.png";
import Group from "../assets/images/Group.png";

import transformingImg from "../assets/images/transforming-image.jpg";
import HomeTab from "./HomeUI/Home-tab";
import Homeclientslider from "./HomeUI/Home-client-slider";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DiscoverSlider from "./HomeUI/Discover-slider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import Accordion from "@mui/material/Accordion";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import AccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import BookDemo from "./HomeUI/BookDemo";

const options = {
  items: 1,
  loop: true,
  margin: 10,
  autoplay: false,
  nav: true,
  //   dots: true,

  responsive: {
    0: {
      items: 1,
      nav: false,
    },
    600: {
      items: 1,
      nav: true,
    },
    1000: {
      items: 1,
      nav: true,
    },
  },
};

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export default function Home() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // const [expanded, setExpanded] = React.useState(false);

  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };

  const [isActive, setActive] = React.useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };

  const [isActive2, setActive2] = React.useState(false);

  const toggleClass2 = () => {
    setActive2(!isActive2);
  };

  const [isActive3, setActive3] = React.useState(false);

  const toggleClass3 = () => {
    setActive3(!isActive3);
  };

  return (
    <>
      <Box id="homeMain">
        <Box className="mainHome web-topBanner-bg">
          <WebHeader />
          <Box className=" main-banner">
            <Box>
              <OwlCarousel className="owl-theme" {...options}>
                <Box className="item">
                  <Box className="main-home-section">
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        className="home-content-main homeBanner-main-1 homeBanner-main-container"
                      >
                        <Box className="webContainer">
                          <h1>
                            Personalized Care <br /> Coordination
                          </h1>
                          <Typography>
                            Experience personalized care coordination and
                            <br />
                            support with Hubshift, fostering independence and
                            <br />
                            enhancing your quality of life.
                          </Typography>

                          <Box className="d-flex align-items-center">
                            <Link
                              to="/book-demo"
                              className="home-section-button web-primary-btnLarge cta1"
                            >
                              Book A Demo
                            </Link>

                            {/* <Link className="home-section-button cta2">CTA 2</Link> */}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>

                <Box className="item">
                  <Box className="main-home-section">
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        className="home-content-main homeBanner-main-2 homeBanner-main-container"
                      >
                        <Box className="webContainer">
                          <h1>
                            Streamlined Care
                            <br /> Management
                          </h1>
                          <Typography>
                            Streamline your care responsibilities and improve
                            <br />
                            client outcomes with Hubshift's intuitive tools and
                            <br />
                            resources for efficient care management.
                          </Typography>

                          <Box className="d-flex align-items-center">
                            <Link
                              to="/book-demo"
                              className="home-section-button web-primary-btnLarge cta1"
                            >
                              Book A Demo
                            </Link>
                            {/* <Link className="home-section-button cta2">CTA 2</Link> */}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>

                <Box className="item">
                  <Box className="main-home-section">
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        className="home-content-main homeBanner-main-3 homeBanner-main-container"
                      >
                        <Box className="webContainer">
                          <h1>
                            Connecting Care <br /> for the Community
                          </h1>
                          <Typography>
                            Empower your NDIS Care Management team with
                            <br /> Hubshift and see the difference it can make
                            in <br />
                            connecting care for the community. {" "}
                          </Typography>

                          <Box className="d-flex align-items-center">
                            <Link
                              to="/book-demo"
                              className="home-section-button web-primary-btnLarge cta1"
                            >
                              Book A Demo
                            </Link>
                            {/* <Link className="home-section-button cta2">CTA 2</Link> */}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>

                <Box className="item">
                  <Box className="main-home-section">
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        className="home-content-main homeBanner-main-4 homeBanner-main-container"
                      >
                        <Box className="webContainer">
                          <h1>
                           Choice,Control,
                            <br /> Connectivity{" "}
                          </h1>
                          <Typography>
                            Hubshift keeps families connected and informed
                            <br />
                            about their loved one's care journey,ensuring peace
                            <br />
                            of mind and support.
                          </Typography>

                          <Box className="d-flex align-items-center">
                            <Link
                              to="/book-demo"
                              className="home-section-button web-primary-btnLarge cta1"
                            >
                              Book A Demo
                            </Link>
                            {/* <Link className="home-section-button cta2">CTA 2</Link> */}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </OwlCarousel>
            </Box>
          </Box>
        </Box>
        {/* home banner end here  */}

        {/* <Box className="home-logo-slider">
          <LogoSlider />
        </Box> */}

        {/* home management section  */}

        <Box sx={{ paddingTop: 20 }} className="home-manage-section space-80">
          <Box sx={{ mb: 5 }}>
            <h1 className="text-center w-100">
              Discover Hubshift's Cutting-Edge Features <br /> Revolutionizing
              Care Management
            </h1>
          </Box>

          <Box className="manageCards-main manageCards-main-bg">
            <Box className="webContainer">
              <Grid container spacing={2} className="manageCarer-cardBox">
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={5}
                  className="d-flex align-items-center"
                >
                  <Box className="manageCarer-cardImg">
                    <img src={careManage1} />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={7}
                  md={7}
                  className="d-flex justify-content-end"
                >
                  <Box className="manageCarer-cardContent pLeft-80">
                    <h6>SIL MANAGEMENT</h6>
                    <h3>Manage with Ease </h3>

                    <Box className="manageCarer-cardText">
                      <Typography>
                        Efficiently manage your SIL (Supported Independent
                        Living) homes. From rosters to community access and
                        day-to-day expenses, HUBSHIFT provides a comprehensive
                        financial dashboard to keep everything in check. 
                      </Typography>

                      <Grid
                        container
                        spacing={2}
                        sx={{ mt: 2 }}
                        className="feature-icons-box"
                      >
                        <Grid item xs={6} sm={6} md={6}>
                          <Box className="d-flex align-items-center">
                            <img
                              className="feature-tickIcon"
                              src={featureTick}
                            />
                            <span>Streamlined Roster Management</span>
                          </Box>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <Box className="d-flex align-items-center">
                            <img
                              className="feature-tickIcon"
                              src={featureTick}
                            />
                            <span>Seamless Community Access</span>
                          </Box>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <Box className="d-flex align-items-center">
                            <img
                              className="feature-tickIcon"
                              src={featureTick}
                            />
                            <span>Holistic Financial Dashboard</span>
                          </Box>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <Box className="d-flex align-items-center">
                            <img
                              className="feature-tickIcon"
                              src={featureTick}
                            />
                            <span>Automated Expense Tracking</span>
                          </Box>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <Box className="d-flex align-items-center">
                            <img
                              className="feature-tickIcon"
                              src={featureTick}
                            />
                            <span>Customized Reporting</span>
                          </Box>
                        </Grid>
                        {/* <Grid item xs={6} sm={6} md={6}>
                          <Box className="d-flex align-items-center">
                            <img
                              className="feature-tickIcon"
                              src={featureTick}
                            />
                            <span>Lorem Ipsum</span>
                          </Box>
                        </Grid> */}
                      </Grid>
                    </Box>
                    <Link to="/sil-management" className="home-primary-button">
                      Learn More
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box className="manageCards-main ">
            <Box className="webContainer">
              <Grid container spacing={2} className="manageCarer-cardBox">
                <Grid
                  item
                  xs={12}
                  sm={7}
                  md={7}
                  className="d-flex justify-content-end"
                >
                  <Box className="manageCarer-cardContent pRight-80">
                    <h6>ROSTER MANAGEMENT</h6>
                    <h3>Optimize Your Planning </h3>

                    <Box className="manageCarer-cardText">
                      <Typography className="space-pb-24">
                        Draft and publish rosters with ease. Our Roster
                        Management tool allows you to create draft rosters based
                        on previous weeks and publish them according to your
                        requirements, ensuring optimal staffing at all times. 
                      </Typography>

                      <Grid container className="feature-icons-box">
                        {/* <Grid item xs={12} sm={12} md={12}>
                          <Box className="d-flex space-mb-24 align-items-start">
                            <Box className="manageCard-star-icon">
                              <img src={orangeIcon} />
                            </Box>
                            <Box>
                              <Typography>
                                Effortless Roster Creation
                              </Typography>
                              <span>
                                Draft rosters from past weeks with our intuitive
                                tool, ensuring smooth scheduling and optimal
                                staffing.
                              </span>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Box className="d-flex space-mb-24 align-items-start">
                            <Box className="manageCard-star-icon">
                              <img src={yellowIcon} />
                            </Box>
                            <Box>
                              <Typography>
                                Flexible Staffing Solutions
                              </Typography>
                              <span>
                                Customize rosters for efficient resource
                                allocation and seamless operations, tailored to
                                your specific needs.
                              </span>
                            </Box>
                          </Box>
                        </Grid> */}

                        <Grid item xs={12} sm={12} md={12}>
                          <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                            <Box className="feature-star-icon">
                              <img src={Frame} />
                            </Box>
                            <Box>
                              <Typography>
                                {" "}
                                Effortless Roster Creation
                              </Typography>
                              <span className="space-content">
                                Draft rosters from past weeks with our intuitive
                                tool, ensuring smooth scheduling and optimal
                                staffing.
                              </span>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                            <Box className="feature-star-icon">
                              <img src={Group} />
                            </Box>
                            <Box>
                              <Typography>
                                {" "}
                                Flexible Staffing Solutions
                              </Typography>
                              <span className="space-content">
                                Customize rosters for efficient resource
                                allocation and seamless operations, tailored to
                                your specific needs.
                              </span>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <Link to="/roster-management" className="home-primary-button">
                      Learn More
                    </Link>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={5}
                  className="d-flex align-items-center"
                >
                  <Box className="manageCarer-cardImg">
                    <img src={careManage2} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box className="manageCards-main manageCards-main-bg">
            <Box className="webContainer">
              <Grid container spacing={2} className="manageCarer-cardBox">
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={5}
                  className="d-flex align-items-center"
                >
                  <Box className="manageCarer-cardImg">
                    <img src={careManage3} />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={7}
                  md={7}
                  className="d-flex justify-content-end"
                >
                  <Box className="manageCarer-cardContent pLeft-80">
                    <h6>STAFF INDUCTION</h6>
                    <h3>Enhance Your Training </h3>

                    <Box className="manageCarer-cardText">
                      <Typography>
                        Never has staff induction been so straightforward. Our
                        integrated onboarding and LMS (Learning Management
                        System) induction means staff cannot commence shifts
                        until their induction is complete. This ensures that all
                        team members are fully prepared and compliant with NDIS
                        standards. 
                      </Typography>

                      <Grid
                        container
                        spacing={2}
                        sx={{ mt: 2 }}
                        className="feature-icons-box"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <Box className="d-flex align-items-center">
                            <h3>25%</h3>
                            <span>Increase in workflow efficiency</span>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Box className="d-flex align-items-center">
                            <h3>2X</h3>
                            <span>Growth in business</span>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <Link to="/staff-induction" className="home-primary-button">
                      Learn More
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box className="manageCards-main ">
            <Box className="webContainer">
              <Grid container spacing={2} className="manageCarer-cardBox">
                <Grid
                  item
                  xs={12}
                  sm={7}
                  md={7}
                  className="d-flex justify-content-end"
                >
                  <Box className="manageCarer-cardContent pRight-80">
                    <h6>DOCUMENT PASSPORT</h6>
                    <h3>Secure Your Documents </h3>

                    <Box className="manageCarer-cardText">
                      <Typography>
                        With Hubshift's Document Passport, take control of your
                        identification effortlessly. Store certificates and
                        vital identity documents securely, and receive timely
                        notifications for upcoming expirations.
                      </Typography>

                      <Typography sx={{ mt: 2 }}>
                        Seamlessly manage your profile, even when transitioning
                        jobs, by easily sharing it within the Hubshift platform
                        across Australia. No more email stress - just
                        streamlined document sharing.{" "}
                      </Typography>
                    </Box>
                    <Link to="/document-passport" className="home-primary-button">
                      Learn More
                    </Link>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={5}
                  className="d-flex align-items-center"
                  order={{ xs: 1, sm: 2 }}
                >
                  <Box className="manageCarer-cardImg">
                    <img src={careManage4} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box className="manageCards-main manageCards-main-bg">
            <Box className="webContainer">
              <Grid container spacing={2} className="manageCarer-cardBox">
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={5}
                  className="d-flex align-items-center"
                  // order={{ xs: 1, sm: 2 }}
                >
                  <Box className="manageCarer-cardImg">
                    <img src={webfeature5} />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  className="d-flex justify-content-end"
                >
                  <Box className="manageCarer-cardContent pLeft-80">
                    <h6>HEALTH DASHBOARD</h6>
                    <h3>Manage Health Easily </h3>

                    <Box className="manageCarer-cardText">
                      <Typography>
                        Take control of your health journey with Hubshift Health
                        Dashboard! Keep all your vital information in one secure
                        platform, making it simple to stay organized and
                        proactive about your well-being. Discover a new way to
                        manage your health today.
                      </Typography>

                      {/* <Grid
                        container
                        spacing={2}
                        sx={{ mt: 2 }}
                        className="feature-icons-box"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <Box className="d-flex align-items-center">
                            <h3>25%</h3>
                            <span>Increase in workflow efficiency</span>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Box className="d-flex align-items-center">
                            <h3>2X</h3>
                            <span>Growth in business</span>
                          </Box>
                        </Grid>
                      </Grid> */}
                    </Box>
                    <Link to="/health-dashboard" className="home-primary-button">
                      Learn More
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>

        {/* <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>Collapsible Group Item #1</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography>Collapsible Group Item #2</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography>Collapsible Group Item #3</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </Typography>
          </AccordionDetails>
        </Accordion> */}

        <Box className="webContainer">
          <Box className="home-transforming-section space-80">
            <Box className="text-center space-pb-60">
              <h1>Transforming Care:</h1>
              <h1> Where Efficiency Meets Savings</h1>
            </Box>
            <Grid container>
              <Grid item xs={12} sm={5} md={5}>
                <Box className="pRight-80">
                  <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                    className="transform-accordion "
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      onClick={toggleClass}
                    >
                      <Box
                        sx={{ pl: 1, pe: 2 }}
                        className="d-flex transforming-accordion align-items-center "
                      >
                        <img className="feature-tickIcon" src={featureTick} />
                        <h3>Streamline Efficiency</h3>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails className="position-relative">
                      {/* <Box className="accordion-img accordion-img1">
                        <Box className="transform-img-wrapper">
                          <Box className="transforming-img">
                            <img src={transformingImg} />
                          </Box>
                        </Box>
                      </Box> */}
                      <Typography className="transforming-text transform-box-text">
                        Empower your team with streamlined processes, reducing
                        administrative overhead and optimizing resource
                        allocation. Hubshift's intuitive platform enhances
                        workflow efficiency, ensuring seamless coordination and
                        maximizing productivity, ultimately leading to cost
                        savings for your organization.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                    className="transform-accordion"
                  >
                    <AccordionSummary
                      aria-controls="panel2d-content"
                      id="panel2d-header"
                      onClick={toggleClass2}
                    >
                      <Box
                        sx={{ pl: 1, pe: 2 }}
                        className="d-flex transforming-accordion align-items-center "
                      >
                        <img className="feature-tickIcon" src={featureTick} />
                        <h3>Optimize Resources and Cut Costs</h3>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails className="position-relative">
                      {/* <Box className="accordion-img accordion-img2 ">
                        <Box className="transform-img-wrapper">
                          <Box className="transforming-img">
                            <img src={transformingImg} />
                          </Box>
                        </Box>
                      </Box> */}
                      <Typography className="transforming-text transform-box-text">
                        Maximize resource utilization and trim expenses with
                        Hubshift. Our platform optimizes staffing, streamlines
                        operations, and automates processes, ensuring efficient
                        care delivery while reducing overall costs, enabling
                        organizations to achieve significant savings without
                        compromising quality.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    expanded={expanded === "panel3"}
                    onChange={handleChange("panel3")}
                    className="transform-accordion"
                  >
                    <AccordionSummary
                      aria-controls="panel3d-content"
                      id="panel3d-header"
                      onClick={toggleClass3}
                    >
                      <Box
                        sx={{ pl: 1, pe: 2 }}
                        className="d-flex transforming-accordion align-items-center "
                      >
                        <img className="feature-tickIcon" src={featureTick} />
                        <h3>Simplify Tasks and Reduce Effort</h3>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails className="position-relative">
                      {/* <Box className="accordion-img accordion-img3">
                        <Box className="transform-img-wrapper">
                          <Box className="transforming-img">
                            <img src={transformingImg} />
                          </Box>
                        </Box>
                      </Box> */}
                      <Typography className="transforming-text transform-box-text">
                        Simplify your workload with Hubshift's intuitive tools,
                        reducing the effort required for administrative tasks.
                        Streamline scheduling, documentation, and billing
                        processes to optimize efficiency and focus on delivering
                        exceptional care.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  {/* <Box sx={{ ml: 2 }} className="d-flex align-items-center ">
                    <img className="feature-tickIcon" src={featureTick} />
                    <h3>Streamline Efficiency</h3>
                  </Box> */}
                </Box>
              </Grid>
              <Grid item xs={12} sm={7} md={7}>
                <Box className="transform-img-wrapper position-relative">
                  <Box className="transforming-img">
                    <img src={transformingImg} />
                  </Box>

                  {/* transforming image 1  */}

                  <Box
                    className={
                      isActive
                        ? "transforming-none transformingImage1"
                        : "image-none"
                    }
                  >
                    <Box className="transforming-img">
                      <img src={transformingImg} />
                    </Box>
                  </Box>

                  {/* transforming image 2  */}

                  <Box
                    className={
                      isActive2
                        ? "transforming-none transformingImage2"
                        : "image-none"
                    }
                  >
                    <Box className="transforming-img">
                      <img src={transformingImg} />
                    </Box>
                  </Box>

                  {/* transforming image 3  */}

                  <Box
                    className={
                      isActive3
                        ? "transforming-none transformingImage3"
                        : "image-none"
                    }
                  >
                    <Box className="transforming-img">
                      <img src={transformingImg} />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>

        {/* Home Tabs section  */}

        <Box className="home-module-tabMain success-bg space-pt-80 ">
          <Box className="webContainer">
            <Box className="w-100">
              <HomeTab />
            </Box>
          </Box>
        </Box>

        <Box className="blank-box"></Box>
        {/* Home testimonial  */}

        <Box className="home-client-slider space-m-xl ">
          <Homeclientslider />
        </Box>

        {/* Discover, Learn & Resolve with Resources  */}

        <Box className="discover-slider-section space-pb-xl">
          <Box className="webContainer">
            <Box className="text-center space-pb-60">
              <h1>Discover, Learn & Resolve with Resources</h1>
            </Box>

            <DiscoverSlider />
          </Box>
        </Box>

        {/* book a demo section here  */}
        <Box className="webContainer">
          <BookDemo />
        </Box>
        <Box className="space-mt-80">
          <WebFooter />
        </Box>
      </Box>
    </>
  );
}
