import { create } from "zustand";

const FilterStore = create((set) => ({
  selectedCity: "",
  ApType: "",
  selectedShift: [],
  requestStatus: [],
  showMenuItem: {},

  status: "",
  slotType: "",
  month: "",
  startDate1: null,
  endDate1: null,
  searchTerm: "",
  tabIndex: 0,
  appointmentList: [],
  selectedStake: [],
  serviceId: undefined,
  itemId: undefined,
  clientId: undefined,
  teamDataId: undefined,
  statusDataId: undefined,
  usertype: undefined,
  location: undefined,
  showhide: "",
  cityList: [],
  isShowAllHc: false,

  setField: (fieldName, fieldValue) =>
    set((state) => ({ ...state, [fieldName]: fieldValue })),

  resetFields: () =>
    set(() => ({
      isShowAllHc: false,
      selectedCity: "",
      cityList: [],
      tabIndex: 0,
      ApType: "",
      selectedShift: [],
      requestStatus: [],
      showMenuItem: {},

      status: "",
      slotType: "",
      month: "",
      startDate1: null,
      endDate1: null,
      searchTerm: "",
      showhide: "",
      appointmentList: [],
      selectedStake: [],
      serviceId: undefined,
      itemId: undefined,
      clientId: undefined,
      teamDataId: undefined,
      statusDataId: undefined,
      usertype: undefined,
      location: undefined,
    })),
}));

export default FilterStore;
