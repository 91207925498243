import * as React from "react";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import ExpImage from "../../../assets/images/bag-icon.png";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";
import MapImage from "../../../assets/images/google-maps-svg.png";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import GradeIcon from "@mui/icons-material/Grade";
import axios from "axios";
import Avatar from "@mui/material/Avatar";
import noImage from "../../../assets/images/noImage.png";
import { toast } from "react-toastify";
import BannerSlider from "../../ui/BannerSlider";
import Link from "@mui/material/Link";
import { detailsHcExploreMarket, loginClientAndSP, loginIndividualHC } from "../../../api";
import sleepOver from "../../../assets/images/sleep-icon.png";
import Rating from "@mui/material/Rating";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import WebHeader from "../../../Website/Web-Header";
import WebFooter from "../../../Website/Web-footer";
import Divider from "@mui/material/Divider";

export default function SignInSide() {
  const [value, setValue] = React.useState(5);
  const [disableButton, setDisableButton] = useState(false);
  const [disableButtonResend, setDisableButtonResend] = useState(false);
  const navigate = useNavigate();

  const [userDetails, setUserDetails] = useState([]);
  const exploreMarketData = JSON.parse(localStorage.getItem("exploreMarket"));
  const availabilityData = exploreMarketData?.availability;
  const location = useLocation();
  let hcId;

  console.log("exploreMarketDataexploreMarketDataexploreMarketData", exploreMarketData, location.state)

  if (exploreMarketData !== undefined && exploreMarketData !== null) {
    hcId = exploreMarketData.hcId;
  } else {
    hcId = location.state;
  }

  if (location?.state?.type) {
    hcId = location.state.clientId;
  }


  console.log("hcIdhcIdhcId", hcId)
  const [otp, setOtp] = useState("");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    getaList();
  }, [hcId]);

  const getaList = async () => {
    try {
      const res = await axios.get(`${detailsHcExploreMarket}/${hcId}?type=${location?.state?.types}`);
      let dataval = res?.data?.data;
      console.log("userDetails", dataval);
      setUserDetails(dataval);
    } catch (error) {
      console.log(error);
    }
  };
  function getCurrentDayAndTime() {
    const currentDate = new Date();
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const currentDay = daysOfWeek[currentDate.getDay()]; // Get the current day as a string
    // const currentTime = currentDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // Get the current time in HH:MM format
    return `${currentDay}`;
  }

  function formatDate(dateString) {
    const options = {
      day: "numeric",
      month: "short", // Use 'short' for abbreviated month name
      year: "numeric",
    };
    const dateObj = new Date(dateString);
    return dateObj.toLocaleDateString("en-GB", options);
  }

  const initialValues = {
    email: "",
    password: "",
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const [passwordTypeNew, setPasswordTypeNew] = useState("password");
  const togglePasswordNew = () => {
    if (passwordTypeNew === "password") {
      setPasswordTypeNew("text");
      return;
    }
    setPasswordTypeNew("password");
  };

  const clearStorage = () => {
    window.sessionStorage.clear();
    window.localStorage.clear();
    navigate("/client/explore-onboard");
  };

  const calculateAverageRating = (ratings) => {
    console.log(ratings, "check 452");
    if (!ratings || ratings.length === 0) return 0;

    const sum = ratings.reduce((acc, rating) => acc + rating.ratingPoint, 0);
    console.log(sum, "check 456");
    return sum / ratings.length;
  };

  return (
    <>
      <WebHeader />
      <Box id="personal-info" width="100%" height="100%">
        <Grid container>
          {hcId !== undefined && hcId !== null ? (
            userDetails?.map((item, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} sm={12} md={12}>
                  {/* You can replace the following placeholders with your actual JSX */}

                  <Grid container className="existing-user-main">
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sm={12}
                      className="existing-box-client d-flex align-items-center justify-content-center"
                    >
                      <Box>
                        <p className="existing-looking"> Interested in: </p>
                        <Avatar aria-label="recipe" className="explore-icon">
                          <img
                            src={
                              item?.image?.location
                                ? item?.image?.location
                                : noImage
                            }
                            alt="explore.png"
                            style={{ width: "110px", height: "110px" }}
                          />
                        </Avatar>
                        <Box className="d-flex align-items-center ">
                          <h4 className="existing-user-name">
                            {item?.firstName !== undefined &&
                            item?.lastName !== undefined
                              ? `${item?.firstName} ${item?.lastName}`
                              : "NA"}
                          </h4>
                          <Box className="view-detail-rating-box" display="flex">
                            {location?.state?.types !== "findSupport" ? (
                              <>
                                <Rating
                                  value={calculateAverageRating(item?.hcRating)}
                                  precision={0.5}
                                  readOnly
                                  sx={{ fontSize: "1rem" }} // Adjust the font size of the stars
                                />
                                <Typography
                                  component="div"
                                  className="rating-text wp-no"
                                  sx={{ marginLeft: "0.5rem" }}
                                >
                                  {`Rated By ${item?.hcRating.length} Users`}
                                </Typography>
                              </>
                            ) : (
                              <>
                                <Rating
                                  value={calculateAverageRating(item?.hcRating)}
                                  precision={0.5}
                                  readOnly
                                  sx={{ fontSize: "1rem" }} // Adjust the font size of the stars
                                />
                                <Typography
                                  component="div"
                                  className="rating-text wp-no"
                                  sx={{ marginLeft: "0.5rem" }}
                                >
                                  {`Rated By ${item?.hcRating.length} Users`}
                                </Typography>
                              </>
                            )}
                          </Box>
                        </Box>
                        <Box className="d-flex align-items-center ">
                          <span className="view-porfile-category">
                            {item?.jobTitle !== undefined &&
                            item?.jobTitle !== null
                              ? `${item?.jobTitle}`
                              : "NA"}
                          </span>
                        </Box>
                        <Box className="d-flex align-items-center existing-user-details ">
                          <Box className="d-flex align-items-center">
                            <img src={MapImage} />
                            <Typography variant="body2" component="div">
                              {item?.city || item?.state
                                ? `${item?.city}, ${item?.state}`
                                : "NA"}
                            </Typography>
                          </Box>
                          <Box
                            className="d-flex align-items-center "
                            sx={{ ml: 3 }}
                          >
                            <img src={ExpImage} className="explore-img" />
                            <Typography className="exp-txt">
                              Exp:{" "}
                              <span>
                                {item?.experience
                                  ? `${item?.experience}`
                                  : "NA"}
                              </span>
                            </Typography>
                          </Box>
                        </Box>
                        {availabilityData !== undefined &&
                        availabilityData !== null ? (
                          <>
                            <div className="exp-txt d-flex align-items-center existing-user-details-shift">
                              <img
                                src={sleepOver}
                                alt="sleepover.png"
                                className="exlpore-sleepover-img"
                              />
                              {availabilityData ? (
                                <p>
                                  {availabilityData?.shiftType}{" "}
                                  {formatDate(availabilityData?.date)}{" "}
                                  {availabilityData?.startTime}-
                                  {availabilityData?.endTime}
                                </p>
                              ) : (
                                <p>NA</p>
                              )}
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} className="grid-divider">
                      <Box className="w-100 existing-box-client existing-box-sign-in h-100 d-flex align-items-center justify-content-center">
                        <Box className="form-main-box">
                          <Box>
                            <h2 mb={3} className="sign-title">
                              Sign in
                              <p className="text-mute">
                                Welcome back! Please enter your details.
                              </p>
                            </h2>
                          </Box>
                          <Box>
                            <Formik
                              initialValues={initialValues}
                              validationSchema={validationSchema}
                              onSubmit={(values, formikHelpers) => {
                                setDisableButton(true);

                                axios
                                  .post(
                                    location?.state?.types == "findSupport"
                                      ? loginIndividualHC
                                      : loginClientAndSP,
                                    values
                                  )
                                  .then((res) => {
                                    if (res.data.success === false) {
                                      toast.error(res.data.message);
                                      setDisableButton(false);
                                    }
                                    if (res.data.success === true) {
                                      toast.success(res.data.message);
                                      setDisableButton(false);
                                      setTimeout(() => {
                                        navigate(`/verify-otp`, {
                                          state: {
                                            emailID: values.email,
                                            pageName: "existingUser",
                                            selectedUserId: hcId,
                                            apptId: "",
                                            request: location?.state,
                                          },
                                        });
                                      });
                                    }
                                  })
                                  .catch((error) => {
                                    toast.error("Something went wrong");
                                  });
                              }}
                            >
                              {({ errors, isValid, touched, dirty }) => (
                                <Form>
                                  <div className="form-field">
                                    <label>Email </label>

                                    <Field
                                      size="small"
                                      name="email"
                                      type="email"
                                      as={TextField}
                                      className="form-input"
                                      margin="normal"
                                      fullWidth
                                      id="outlined-required"
                                      placeholder="Email"
                                      error={
                                        Boolean(errors.email) &&
                                        Boolean(touched.email)
                                      }
                                      helperText={
                                        Boolean(touched.email) && errors.email
                                      }
                                    />
                                  </div>
                                  <div className="form-field">
                                    <label> Password </label>
                                    <Field
                                      size="small"
                                      name="password"
                                      type={passwordTypeNew}
                                      as={TextField}
                                      className="form-input"
                                      margin="normal"
                                      fullWidth
                                      id="outlined-required"
                                      InputProps={{
                                        endAdornment: (
                                          <endAdornment
                                            className="input-icon"
                                            position="end"
                                            onClick={togglePasswordNew}
                                            style={{ cursor: "pointer" }}
                                          >
                                            {passwordTypeNew === "password" ? (
                                              <Visibility />
                                            ) : (
                                              <VisibilityOff />
                                            )}
                                          </endAdornment>
                                        ),
                                      }}
                                      placeholder="Password"
                                      error={
                                        Boolean(errors.password) &&
                                        Boolean(touched.password)
                                      }
                                      helperText={
                                        Boolean(touched.password) &&
                                        errors.password
                                      }
                                    />
                                  </div>
                                  <Box
                                    display="flex"
                                    justifyContent="flex-end"
                                    alignItems="end"
                                  >
                                    <Link
                                      href="/forgot-password"
                                      variant="body2"
                                      className="forgot"
                                    >
                                      Forgot password?
                                    </Link>
                                  </Box>

                                  <Button
                                    className="signupbutton"
                                    type="submit"
                                    fullWidth
                                    sx={{ mt: 3, mb: 2 }}
                                    disabled={disableButton}
                                  >
                                    {disableButton === true
                                      ? "Processing..."
                                      : "Sign In"}
                                  </Button>
                                  <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                  >
                                    {
                                      <>
                                        <Typography color="#929495">
                                          New User?&nbsp;
                                          <Link
                                            //href="/client/explore-onboard"
                                            variant="body2"
                                            style={{ cursor: "pointer" }}
                                            onClick={clearStorage}
                                          >
                                            Sign up as guest
                                          </Link>
                                        </Typography>
                                      </>
                                    }
                                  </Box>
                                </Form>
                              )}
                            </Formik>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>

                  {/* Add more JSX elements as needed */}
                </Grid>
              </React.Fragment>
            ))
          ) : (
            <Grid item xs={12} sm={12} md={7}>
              <Box>
                <BannerSlider />
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
      <WebFooter />
    </>
  );
}
