import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Loader from "../../../frontend/src/pages/loader/Loader";
//404 page
import Error from "../components/Error";

export default function Hr() {
  // const Home = lazy(() => import("../pages/Hr/home"));
  const Home = lazy(() => import("../pages/Hr/Hr-dashboard.js"));
  const PendingApproval = lazy(() =>
    import("../pages/superAdmin/pending-approval")
  );

  const MyAccounts = lazy(() => import("../pages/Hr/form/MyAccount"));

  const SignedDocument = lazy(() =>
    import("../components/SignedDocument/ListSignedDocument")
  );

  const ViewSignedDocument = lazy(() =>
    import("../components/SignedDocument/ViewSignedDocument")
  );
  const ListHealthcarer = lazy(() =>
    import("../pages/Supervisor/users/healthCarer")
  );
  const ListSupportcoordinator = lazy(() =>
    import("../pages/Supervisor/users/supportCoordinator")
  );
  const ListItmanager = lazy(() => import("../pages/Hr/users/itManager"));
  const ListGeneralmanager = lazy(() =>
    import("../pages/Hr/users/generalManager")
  );
  const ListAccountant = lazy(() => import("../pages/Hr/users/accountant"));
  const ListSupervisor = lazy(() => import("../pages/Hr/users/supervisor"));

  //leave management new changes

  const ListLeaveCategory = lazy(() =>
    import("../pages/superAdmin/leave-category/list")
  );

  const ListLeaveManagementOld = lazy(() =>
    import("../pages/superAdmin/leaveManagement/list")
  );

  const ListLeaveManagement = lazy(() =>
    import("../pages/superAdmin/leaveManagement/list-tab")
  );

  const EditLeaveManagement = lazy(() =>
    import("../pages/superAdmin/leaveManagement/edit")
  );

  const ViewLeaveManagement = lazy(() =>
    import("../pages/superAdmin/leaveManagement/view")
  );

  const Editbank = lazy(() => import("../pages/Hr/form/Myaccounttab/Editbank"));
  const Addbank = lazy(() => import("../pages/Hr/form/Myaccounttab/Addbank"));

  const ListSetLeaves = lazy(() => import("../pages/superAdmin/setLeave/list"));

  const ListEmployees = lazy(() => import("../components/Employees/list"));

  const ListTemplateMaster = lazy(() =>
    import("../pages/superAdmin/template-master/list")
  );

  const EditTemplateMaster = lazy(() =>
    import("../pages/superAdmin/template-master/edit")
  );

  const Useletter = lazy(() =>
    import("../pages/superAdmin/contentManagement/offer-letter/edit")
  );
  const ListHealthCarerOffBoarding = lazy(() =>
    import("../pages/superAdmin/off-boarding/health-carer/list")
  );
  const ListSupportCoordinatorOffBoarding = lazy(() =>
    import("../pages/superAdmin/off-boarding/support-coordinator/list")
  );

  const OffBoarding = lazy(() =>
    import("../pages/superAdmin/off-boarding/MyAccount")
  );

  const AddSecondaryUser = lazy(() =>
    import("../pages/superAdmin/users/edit")
  );


  const Setting = lazy(() =>
    import("../pages/serviceProvider/Settings/setting.js")
  );

  const SettingSuperannuationList = lazy(() =>
    import("../pages/serviceProvider/Settings/superAnnuation/list")
  );

  const ListSecondaryUsers = lazy(() =>
    import("../pages/superAdmin/users/list")
  );

  const TemplateVariableMaster = lazy(() =>
    import("../pages/superAdmin/template-master/variable/list")
  );
  const Attendance = lazy(() =>
    import("../pages/CommanComponant/Attendance/Attendance")
  );
  return (
    <Routes>
      <Route>
        <Route
          path="/hr/home"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path="/hr/pending-approval"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <PendingApproval />
            </Suspense>
          }
        />

        <Route
          path="/hr/myaccount"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <MyAccounts />
            </Suspense>
          }
        />
        <Route
          path="/hr/list-signed-document"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <SignedDocument />
            </Suspense>
          }
        />
        <Route
          path="/hr/list-secondary-users"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <ListSecondaryUsers />
            </Suspense>
          }
        />

        <Route
          path="/hr/list-template-variable-master"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <TemplateVariableMaster />
            </Suspense>
          }
        />

        <Route
          path="/hr/view-signed-document"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <ViewSignedDocument />
            </Suspense>
          }
        />
        <Route
          path="/hr/list-health-carers"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <ListHealthcarer />
            </Suspense>
          }
        />
        <Route
          path="/hr/list-support-coordinators"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <ListSupportcoordinator />
            </Suspense>
          }
        />
        <Route
          path="/hr/list-it-managers"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <ListItmanager />
            </Suspense>
          }
        />
        <Route
          path="/hr/list-general-managers"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <ListGeneralmanager />
            </Suspense>
          }
        />
        <Route
          path="/hr/list-accountants"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <ListAccountant />
            </Suspense>
          }
        />
        <Route
          path="/hr/attendance"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <Attendance />
            </Suspense>
          }
        />
        <Route
          path="/hr/list-supervisors"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <ListSupervisor />
            </Suspense>
          }
        />

        {/* leave management new changes */}
        <Route
          path="/hr/list-leave-categories"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <ListLeaveCategory />
            </Suspense>
          }
        />

        <Route
          path="/hr/list-leave-management-old"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <ListLeaveManagementOld />
            </Suspense>
          }
        />

        <Route
          path="/hr/list-leave-management"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <ListLeaveManagement />
            </Suspense>
          }
        />

        <Route
          path="/hr/add-leave"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <EditLeaveManagement />
            </Suspense>
          }
        />

        <Route
          path="/hr/edit-leave"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <EditLeaveManagement />
            </Suspense>
          }
        />

        <Route
          path="/hr/view-leave"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <ViewLeaveManagement />
            </Suspense>
          }
        />

        <Route
          path="/hr/list-set-leave"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <ListSetLeaves />
            </Suspense>
          }
        />
        <Route
          path="/hr/edit-bank-details"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <Editbank />
            </Suspense>
          }
        />
        <Route
          path="/hr/add-bank-details"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <Addbank />
            </Suspense>
          }
        />
        <Route
          path="/hr/list-employees"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <ListEmployees />
            </Suspense>
          }
        />

        <Route
          path="/hr/list-template-master"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <ListTemplateMaster />
            </Suspense>
          }
        />

        {/* <Route
          path="/hr/edit-template-master"
          element={
           <Suspense fallback={<div><Loader /></div>}>
              <EditTemplateMaster />
            </Suspense>
          }
        />
        <Route
          path="/hr/add-template-master"
          element={
           <Suspense fallback={<div><Loader /></div>}>
              <EditTemplateMaster />
            </Suspense>
          }
        /> */}
        <Route
          path="/hr/offer-letter"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <Useletter />
            </Suspense>
          }
        />
        <Route
          path="/hr/list-health-carer-offboarding"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <ListHealthCarerOffBoarding />
            </Suspense>
          }
        />
        <Route
          path="/hr/list-support-coordinator-offboarding"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <ListSupportCoordinatorOffBoarding />
            </Suspense>
          }
        />
        <Route
          path="/hr/off-boarding"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <OffBoarding />
            </Suspense>
          }
        />
        <Route
          path="/hr/settings"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <Setting />
            </Suspense>
          }
        />

        <Route
          path="/hr/add-secondary-users"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <AddSecondaryUser />
            </Suspense>
          }
        />

        <Route
          path="/hr/list-superannuation-setting"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <SettingSuperannuationList />
            </Suspense>
          }
        />

        <Route path="*" element={<Error />} />
      </Route>
    </Routes>
  );
}
