import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import "../../App.css";
import "../../assets/css/dashboard.css";
import "../../assets/css/tax.css";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import avtar from "../../assets/images/avtar.jpg";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getAllUnreadNotifications,
  updateNotification,
  clearNotification,
  getuserDetail
} from "../../api";
import { withRouter } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import "../../assets/css/Sa-style.css";

const Notification = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let userId = userData?._id;
  let role = userData?.role;
  const [ws, setWs] = useState(null);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [connected, setConnected] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const server = process.env.REACT_APP_WEB_SOCKET_URL
  // useEffect(() => {
  //   const socketUrl = `ws://${server}:8080`;
  //   console.log("Attempting to connect to WebSocket at:", socketUrl);

  //   const socket = new WebSocket(socketUrl);
  //   socket.onopen = () => {
  //     console.log('WebSocket connected');
  //     // Register user on connection
  //     socket.send(JSON.stringify({ type: 'register', userId }));
  //   };

  //   socket.onmessage = (event) => {

  //     console.log("eventeventevent", event)
  //     const data = JSON.parse(event.data);
  //     if (data.type === 'notificationUpdate') {
  //       // Fetch updated notifications on notification update
  //       fetchNotifications();
  //     }
  //   };

  //   return () => {
  //     socket.close();
  //   };
  // }, []);
  useEffect(() => {
    // Initialize WebSocket connection
    const socket = new WebSocket("ws://localhost:8080"); // Replace with your WebSocket server URL
    setWs(socket);

    socket.onopen = () => {
      console.log("WebSocket connection established");
      setConnected(true);
      const message = JSON.stringify({ type: "register", userId });
      socket.send(message);
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === "notificationUpdate") {
        fetchNotifications();
      }
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed");
      setConnected(false);
    };

    // Cleanup on component unmount
    return () => {
      socket.close();
    };
  }, []);

  // const registerUser = () => {
  //   if (ws && connected) {
  //     ws.send(JSON.stringify({ type: "register", userId }));
  //   } else {
  //     console.error("WebSocket is not connected");
  //   }
  // };

  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  // const [notificationsType, setNotificationsType] = useState('');

  useEffect(() => {
    fetchNotifications();
    // registerUser();
  }, []);
  const fetchNotifications = async () => {
    try {
      const res = await axios.get(`${getAllUnreadNotifications}/${userId}`);
      setNotifications(res?.data?.data);
      // setNotificationsType()
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   const fetchInterval = setInterval(() => {
  //     fetchNotifications();
  //   }, 1 * 60 * 1000); // Fetch notifications every 2 minutes

  //   // Fetch notifications immediately when component mounts
  //   fetchNotifications();

  //   // Clean up interval on unmount
  //   return () => clearInterval(fetchInterval);
  // }, []);

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));
  // const navigateAgreement = (url, state) => {
  //   navigate(url, {
  //     state: state,
  //   });
  // };
  const navigateAgreement = (url, editId, isDelayed, state) => {
    navigate(url, {
      state: {
        editId: editId,
        isDelayed: isDelayed,
        state: state,
        // You can add more properties to the state if needed
      },
    });
    console.log(url, editId, isDelayed, state, "check 109");
  };
  const handleNotificationClick = async (notification) => {
    let url;
    let data;
    if (notification?.typeId) {
      try {
        const res = await axios.get(`${getuserDetail}/${notification?.typeId}`);
        if (res?.data?.success === true) {
          data = res?.data?.data?.userType;
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (notification.type === "leave") {
      if (role === "superAdmin") {
        url = "/super-admin/edit-leave";
      } else if (role === "serviceProvider") {
        url = "/service-provider/edit-leave";
      } else if (role === "healthCarer") {
        url = "/health-carer/view-leave";
      } else if (role === "supportCoordinator") {
        url = "/support-coordinator/view-leave";
      } else if (role === "generalManager") {
        url = "/general-manager/view-leave";
      } else if (role === "hr") {
        url = "/hr/view-leave";
      } else if (role === "accountant") {
        url = "/accountant/view-leave";
      } else if (role === "itManager") {
        url = "/it-manager/view-leave";
      } else if (role === "planManager") {
        url = "/plan-manager/view-leave";
      } else if (role === "supervisor") {
        url = "/supervisor/view-leave";
      } else if (role === "admin") {
        url = "/admin/edit-leave";
      }
    } else if (notification.type === "meeting") {
      if (role === "Stakeholder") {
        url = "/stakeholder/meeting";
      } else if (role === "client") {
        url = "/client/view-meeting-details";
      } else if (role === "superAdmin") {
        url = "/super-admin/list-meeting-client";
      }
    } else if (notification.type === "incident") {
      if (role === "superAdmin") {
        url = "/super-admin/list-incident";
      } else if (role === "supervisor") {
        url = "/supervisor/list-incident";
      } else if (role === "generalManager") {
        url = "/general-manager/list-incident";
      }
    } else if (notification.type === "interview") {
      if (role === "healthCarer") {
        url = "/health-carer/interview-list";
      } else if (role === "superAdmin") {
        url = "/super-admin/list-interview-health-carer";
      }
    } else if (notification.type === "appointment") {
      if (role === "client") {
        url = "/client/appointment-list";
      } else if (role === "superAdmin") {
        url = "/super-admin/edit-appointment";
      } else if (role === "healthCarer") {
        url = "/health-carer/appointment-list";
      } else if (role === "supportCoordinator") {
        url = "/support-coordinator/list-appointments";
      } else if (role === "supervisor") {
        url = "/supervisor/list-appointments";
      } else if (role === "generalManager") {
        url = "/general-manager/list-appointments";
      } else if (role === "serviceProvider") {
        url = "/service-provider/list-appointment";
      } else if (role === "admin") {
        url = "/admin/list-appointment";
      }
    } else if (notification.type === "delay appointment") {
      if (role === "client") {
        url = "/client/appointment-list";
      } else if (role === "superAdmin") {
        url = "/super-admin/edit-appointment";
      } else if (role === "healthCarer") {
        url = "/health-carer/appointments-view";
      }
    } else if (notification.type === "onboard") {
      if (role === "client") {
        url = "/client/home";
      } else if (role === "superAdmin") {
        url = "/super-admin/client-list";
      } else if (role === "admin") {
        url = "/admin/client-list";
      } else if (role === "healthCarer") {
        url = "/health-carer/client-list";
      } else if (role === "supportCoordinator") {
        url = "/support-coordinator/client-list";
      } else if (role === "serviceProvider") {
        url = "/service-provider/client-list";
      }
    } else if (notification.type === "onboardHC") {
      if (role === "superAdmin") {
        url = "/super-admin/list-health-carer";
      } else if (role === "admin") {
        url = "/admin/list-health-carer";
      } else if (role === "supportCoordinator") {
        url = "/support-coordinator/list-health-carer";
      } else if (role === "serviceProvider") {
        url = "/service-provider/list-health-carer";
      } else if (role === "healthCarer") {
        url = "/health-carer/list-health-carer";
      }
    } else if (notification.type === "onboardSC") {
      if (role === "superAdmin") {
        url = "/super-admin/list-support-coordinator";
      } else if (role === "admin") {
        url = "/admin/list-support-coordinator";
      } else if (role === "supportCoordinator") {
        url = "/support-coordinator/list-support-coordinator";
      } else if (role === "serviceProvider") {
        url = "/service-provider/list-support-coordinator";
      } else if (role === "healthCarer") {
        url = "/health-carer/list-support-coordinator";
      }
    } else if (notification.type === "onboardSP") {
      if (role === "superAdmin") {
        url = "/super-admin/list-service-provider";
      } else if (role === "admin") {
        url = "/admin/list-service-provider";
      } else if (role === "supportCoordinator") {
        url = "/support-coordinator/list-service-provider";
      } else if (role === "serviceProvider") {
        url = "/service-provider/list-service-provider";
      } else if (role === "healthCarer") {
        url = "/health-carer/list-service-provider";
      }
    } else if (notification.type === "goal") {
      if (role === "client") {
        url = "/client/view-task";
      } else if (role === "superAdmin") {
        url = "/super-admin/view-task";
      } else if (role === "admin") {
        url = "/admin/view-task";
      } else if (role === "supportCoordinator") {
        url = "/support-coordinator/view-task";
      } else if (role === "serviceProvider") {
        url = "/service-provider/view-task";
      } else if (role === "healthCarer") {
        url = "/health-carer/view-task";
      }
    } else if (notification.type === "demo") {
      if (role === "superAdmin") {
        url = "/super-admin/list-leads";
      }
    } else if (notification.type === "contactForm") {
      if (role === "superAdmin") {
        url = "/super-admin/list-leads";
      }
    } else if (notification.type === "conflict") {
      if (role === "superAdmin") {
        url = "/super-admin/list-conflict";
      } else if (role === "admin") {
        url = "/admin/list-conflict";
      } else if (role === "client") {
        url = "/client/list-conflict";
      } else if (role === "serviceProvider") {
        url = "/service-provider/list-conflict";
      } else if (role === "healthCarer") {
        url = "/health-carer/list-conflict";
      } else if (role === "supportCoordinator") {
        url = "/support-coordinator/list-conflict";
      }
    } else if (notification.type === "timesheet") {
      if (role === "superAdmin") {
        url = "/super-admin/time-sheet";
      } else if (role === "admin") {
        url = "/admin/time-sheet";
      } else if (role === "serviceProvider") {
        url = "/service-provider/time-sheet";
      } else if (role === "healthCarer") {
        url = "/health-carer/time-sheet";
      } else if (role === "supportCoordinator") {
        url = "/support-coordinator/time-sheet-service-task";
      } else if (role === "generalManager") {
        url = "/general-manager/list-appointments";
      } else if (role === "supervisor") {
        url = "/supervisor/list-appointments";
      } else if (role === "client") {
        url = "/client/appointment-list";
      }
    } else if (notification.type === "signedDocument") {
      if (role === "superAdmin") {
        url = "/super-admin/list-signed-document";
      } else if (role === "admin") {
        url = "/admin/list-signed-document";
      } else if (role === "client") {
        url = "/client/home";
      } else if (role === "healthCarer") {
        url = "/health-carer/list-signed-documents";
      } else if (role === "supportCoordinator") {
        url = "/support-coordinator/list-signed-document";
      } else if (role === "generalManager") {
        url = "/general-manager/list-signed-document";
      } else if (role === "supervisor") {
        url = "/supervisor/list-signed-document";
      } else if (role === "hr") {
        url = "/hr/list-signed-document";
      } else if (role === "accountant") {
        url = "/accountant/home";
      } else if (role === "itManager") {
        url = "/it-manager/home";
      } else if (role === "planManager") {
        url = "/plan-manager/home";
      } else if (role === "serviceProvider") {
        url = "/service-provider/list-signed-document";
      }
    } else if (notification.type === "complaint") {
      if (role === "superAdmin") {
        url = "/super-admin/list-complaint";
      } else if (role === "admin") {
        url = "/admin/list-complaint";
      } else if (role === "client") {
        url = "/client/list-complaint";
      } else if (role === "healthCarer") {
        url = "/health-carer/list-complaint";
      } else if (role === "supportCoordinator") {
        url = "/support-coordinator/list-complaint";
      } else if (role === "serviceProvider") {
        url = "/service-provider/list-complaint";
      }
    } else if (notification.type === "sendRequest") {
      if (role === "healthCarer") {
        url = "/health-carer/request-connection-list";
      } else if (role === "client") {
        url = "/client/request-connection-list";
      }
    } else if (notification.type === "docRequest") {
      if (role === "healthCarer") {
        url = "/health-carer/document-connection-list";
      } else if (role === "client") {
        url = "/client/request-connection-list";
      }
    } else if (notification.type === "serviceTask") {
      if (role === "supportCoordinator") {
        url = "/support-coordinator/list-service-task";
      } else if (role === "client") {
        url = "/client/list-service-task";
      } else if (role === "superAdmin") {
        url = "/super-admin/list-service-task";
      } else if (role === "admin") {
        url = "/admin/list-service-task";
      }
    } else if (notification.type === "invoices") {
      if (role === "supportCoordinator") {
        url = "/support-coordinator/invoice";
      } else if (role === "client") {
        url = "/client/invoice";
      } else if (role === "superAdmin") {
        url = "/super-admin/invoice";
      } else if (role === "serviceProvider") {
        url = "/service-provider/invoice";
      } else if (role === "admin") {
        url = "/admin/invoice";
      }
    } else if (notification.type === "expire") {
      if (role === "superAdmin") {
        if(data === "healthCarer"){
          url = "/super-admin/list-health-carer";
        } else if(data === "supportCoordinator"){
          url = "/super-admin/list-support-coordinator";
        } else if(data === "client"){
          url = "/super-admin/client-list";
        } else if(data === "serviceProvider"){
          url = "/super-admin/list-service-provider";
        }
      } else if (role === "serviceProvider") {
        if(data === "healthCarer"){
          url = "/service-provider/list-health-carer";
        } else if(data === "supportCoordinator"){
          url = "/service-provider/list-support-coordinator";
        } else if(data === "client"){
          url = "/service-provider/client-list";
        } else if(data === "serviceProvider"){
          url = "/service-provider/home";
        }
      } else if (role === "healthCarer") {
        if(data === "healthCarer"){
          url = "/health-carer/myaccount";
        } else if(data === "supportCoordinator"){
          url = "/health-carer/list-support-coordinator";
        } else if(data === "client"){
          url = "/health-carer/client-list";
        } else if(data === "serviceProvider"){
          url = "/health-carer/list-service-provider";
        }
      } else if (role === "supportCoordinator") {
        if(data === "healthCarer"){
          url = "/support-coordinator/list-health-carer";
        } else if(data === "supportCoordinator"){
          url = "/support-coordinator/myaccount";
        } else if(data === "client"){
          url = "/support-coordinator/client-list";
        } else if(data === "serviceProvider"){
          url = "/support-coordinator/list-service-provider";
        }
      } else if (role === "generalManager") {
        url = "/general-manager/myaccount";
      } else if (role === "hr") {
        url = "/hr/myaccount";
      } else if (role === "accountant") {
        url = "/accountant/myaccount";
      } else if (role === "itManager") {
        url = "/it-manager/myaccount";
      } else if (role === "planManager") {
        url = "/plan-manager/myaccount";
      } else if (role === "supervisor") {
        url = "/supervisor/myaccount";
      } else if (role === "admin") {
        if(data === "healthCarer"){
          url = "/admin/list-health-carer";
        } else if(data === "supportCoordinator"){
          url = "/admin/list-support-coordinator";
        } else if(data === "client"){
          url = "/admin/client-list";
        } else if(data === "serviceProvider"){
          url = "/admin/list-service-provider";
        }
      }
    } else if (notification.type === "updateUser") {
      if (role === "superAdmin") {
        if(data === "healthCarer"){
          url = "/super-admin/list-health-carer";
        } else if(data === "supportCoordinator"){
          url = "/super-admin/list-support-coordinator";
        } else if(data === "client"){
          url = "/super-admin/client-list";
        } else if(data === "serviceProvider"){
          url = "/super-admin/list-service-provider";
        }
      } else if (role === "serviceProvider") {
        if(data === "healthCarer"){
          url = "/service-provider/list-health-carer";
        } else if(data === "supportCoordinator"){
          url = "/service-provider/list-support-coordinator";
        } else if(data === "client"){
          url = "/service-provider/client-list";
        } else if(data === "serviceProvider"){
          url = "/service-provider/home";
        }
      } else if (role === "healthCarer") {
        if(data === "healthCarer"){
          url = "/health-carer/home";
        } else if(data === "supportCoordinator"){
          url = "/health-carer/list-support-coordinator";
        } else if(data === "client"){
          url = "/health-carer/client-list";
        } else if(data === "serviceProvider"){
          url = "/health-carer/list-service-provider";
        }
      } else if (role === "supportCoordinator") {
        if(data === "healthCarer"){
          url = "/support-coordinator/list-health-carer";
        } else if(data === "supportCoordinator"){
          url = "/support-coordinator/list-support-coordinator";
        } else if(data === "client"){
          url = "/support-coordinator/client-list";
        } else if(data === "serviceProvider"){
          url = "/support-coordinator/list-service-provider";
        }
      } else if (role === "admin") {
        if(data === "healthCarer"){
          url = "/admin/list-health-carer";
        } else if(data === "supportCoordinator"){
          url = "/admin/list-support-coordinator";
        } else if(data === "client"){
          url = "/admin/client-list";
        } else if(data === "serviceProvider"){
          url = "/admin/list-service-provider";
        }
      }
    } else if (
      notification.type === "general" ||
      notification.type === "signup"
    ) {
      if (role === "client") {
        url = "/client/home";
      } else if (role === "superAdmin") {
        url = "/super-admin/home";
      } else if (role === "admin") {
        url = "/admin/home";
      } else if (role === "healthCarer") {
        url = "/health-carer/home";
      } else if (role === "supportCoordinator") {
        url = "/support-coordinator/home";
      } else if (role === "serviceProvider") {
        url = "/service-provider/home";
      } else if (role === "generalManager") {
        url = "/general-manager/home";
      } else if (role === "hr") {
        url = "/hr/home";
      } else if (role === "accountant") {
        url = "/accountant/home";
      } else if (role === "itManager") {
        url = "/it-manager/home";
      } else if (role === "planManager") {
        url = "/plan-manager/home";
      } else if (role === "supervisor") {
        url = "/supervisor/home";
      }
    }
    let editId = notification.typeId;
    console.log(editId, "Notification Edit ID");
    let isDelayed = notification.type === "delay appointment" ? true : false;
    let state = 0;
    if (url) {
      let postData = {
        read: true,
      };
      axios
        .put(`${updateNotification}/${notification._id}`, postData)
        .then((res) => {
          editId = res?.data?.data?.typeId;
          if (res.data.success === true) {
            // navigateAgreement(
            //   url,
            //   role === "superAdmin" ? { editId: editId, isDelayed } : editId
            // );
            if (res.data.data.type === "contactForm") {
              state = 1;
              navigateAgreement(url, editId, isDelayed, state);
            } else {
              state = 0;
              navigateAgreement(url, editId, isDelayed, state);
            }
            fetchNotifications();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // Sample function for clearing notifications
  const handleClearNotifications = () => {
    if (userId) {
      axios
        .put(`${clearNotification}/${userId}`)
        .then((res) => {
          if (res.data.success === true) {
            fetchNotifications();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Box id="Notification">
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip
        // title="Notification"
        >
          <IconButton
            className="header-notification-icon"
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            {/* <Typography className="text">Notification</Typography> */}
            <Badge
              badgeContent={notifications?.length}
              color={
                notifications.some(
                  (notification) => notification.type === "expire"
                )
                  ? "error"
                  : "primary"
              }
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        className="notification-menu"
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box className="notification-main">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              fontWeight="bold"
              variant="h6"
              sx={{ mb: 2, color: "#000" }}
            >
              Notifications
            </Typography>

            {role !== "superAdmin" && (
              <IconButton
                className="custom-clear-icon"
                onClick={handleClearNotifications}
              >
                <ClearIcon /> <p>Clear All</p>
              </IconButton>
            )}
          </Box>

          <Box
            sx={{ width: "100%", height: "100%" }}
            className="notification-content"
          >
            {notifications.length === 0 ? (
              <p>No notifications available</p>
            ) : (
              notifications?.map((notification) => (
                <MenuItem onClick={handleClose}>
                  <Box
                    className="notification-box"
                    key={notification._id}
                    onClick={() => handleNotificationClick(notification)}
                  >
                    <StyledBadge
                      overlap="circular"
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      variant="dot"
                      className="notification-dot"
                    >
                      {/* <Avatar alt="Remy Sharp" src={avtar} /> */}
                      <Avatar src={notification?.userImage?.location} />
                    </StyledBadge>
                    <p
                      fontWeight="400"
                      className="notification-text notification-wrap"
                    >
                      {" "}
                      {notification?.message}
                      <Box className="notification-time">
                        <span className="notification-text">
                          {notification?.createdAtFormatted}
                        </span>
                        <span className="notification-text">
                          {notification?.timeDifference}
                        </span>
                        {/* <span className='notification-text'>Jan 24,2023</span> */}
                      </Box>
                    </p>
                  </Box>
                </MenuItem>
              ))
            )}
          </Box>
        </Box>
      </Menu>
    </Box>
  );
};

export default Notification;
