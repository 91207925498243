import React, { useState } from "react";
import Box from "@mui/material/Box";
import "../../../pages/superAdmin/Timesheet/timesheet.css";
import Appbar from "../../../Dashboard/Layout/Appbar";
import Sidebar from "../../../Dashboard/Layout/Sidebar";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { Button, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import "../../../assets/css/dashboard.css";
import "../../../assets/css/Appointment.css";
import Team_a from "../../../assets/images/team-a.png";
import Team_b from "../../../assets/images/team-b.png";
import Team_c from "../../../assets/images/team-c.png";
import Autocomplete from "@mui/material/Autocomplete";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import call from "../../../assets/images/call.svg";
import clientProfile from "../../../assets/images/client-profile.jpg";
import location from "../../../assets/images/location.svg";
import mail from "../../../assets/images/mail.svg";
import mapIcon from "../../../assets/images/mapIcon.svg";
import Progress from "../../../components/ui/Progress";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import mapLocation from "../../../assets/images/map-location.svg";
import addLocation from "../../../assets/images/add-location.svg";
import healthCarer from "../../../assets/images/health-carer.svg";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import coordinator from "../../../assets/images/coordinator.svg";
import supervisor from "../../../assets/images/supervisor.svg";
import cancel from "../../../assets/images/cancel.svg";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";

import verified from "../../../assets/images/verified.svg";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useEffect } from "react";
// import axios from "axios";
import axios from "../../../api/axios";
import { useFormik } from "formik";
import {
  addSeverity,
  editSeverity,
  AddAppointment,
  getClientlist,
  ListRegion,
  ListSupporttype,
  ListSupportgroup,
  getClientDetailsByUserId,
  Appointmenttype,
  getClientdetails,
  getAppointmentDetailsList,
} from "../../../api";
const label = { inputProps: { "aria-label": "Checkbox demo" } };
function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

const top100Films = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
  { label: "12 Angry Men", year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: "Pulp Fiction", year: 1994 },
];

export default function AddExpenses() {
  const [client, setClient] = useState([]);
  const [region, setRegion] = useState([]);
  const [stype, setStype] = useState([]);
  const [appinttype, setAppinttype] = useState([]);
  const [sgroup, setSgroup] = useState([]);
  const [clientDetail, setClientDetail] = useState([]);
  const location = useLocation();
  const editId = location.state;

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/"
      onClick={handleClick}
    >
      <Typography>Appointments List</Typography>
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="/material-ui/getting-started/installation/"
      onClick={handleClick}
    >
      <Typography>Appointment Details</Typography>
    </Link>,
  ];

  const [value, setValue] = React.useState();

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  const [listData, setListData] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [showResults, setShowResults] = React.useState(false);
  const [isActive, setIsActive] = useState(false);

  function toogleShow() {
    setShowResults(!showResults);
    setIsActive((current) => !current);
  }
  const initialValues = {
    // title: data !== undefined ? data.title : "",
    // assignedToId:data !== undefined ? data.assignedToId : "",
    // //  StackHolderName:data !== undefined ? data.StackHolderName : "",
    //  document:data !== undefined ? data.document : "",
    //  dueDate:data !== undefined ? data.dueDate : "",
    // status:data !== undefined ? data.status : "",
    //  clientId:data !== undefined ? data.clientId : "",
    //   // ClientName:data !== undefined ? data.ClientName : "",
    // note:data !== undefined ? data.note : "",
    //    isPrivate: data !== undefined ? data.isPrivate : "false",
  };

  const validationSchema = Yup.object().shape({
    //  title: Yup.string().required("Title is Required"),
    //   assignedToId: Yup.string().required("Steackholder is Required"),
    //     note: Yup.string().required("Description is Required"),
    //     dueDate: Yup.string().required("Date is Required"),
    //      clientId: Yup.string().required("Client is Required"),
    //      status: Yup.string().required("Status is Required"),
  });

  const calculate_age = (dob1) => {
    var today = new Date();

    var birthDate = new Date(dob1);

    var age_now = today.getFullYear() - birthDate.getFullYear();

    var m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    } // console.log(dob1, "dob1");

    if (dob1 !== undefined) {
      return age_now + " Yr";
    } else {
      return "";
    }
  };

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    getAppointmentDetails(user._id);
  }, []);

  const getAppointmentDetails = () => {
    axios
      .get(`${getAppointmentDetailsList}/${editId}`)
      // .get(getAppointmentDetailsList + "/" + editId)
      // .get(`${getAppointmentDetailsList}/646dc49d7301449ec9038976`)
      .then((res) => {
        const data = res.data.data;
        console.log(data);
        setisLoading(false);
        setListData(res?.data?.data);
      })
      .catch((error) => {
        setisLoading(false);
        console.log(error);
      });
  };

  return (
    <Box className="dashboard-form" id="add-appointment">
      <Box sx={{ width: "100%" }} className="appbar-border">
        <Box className="appbar-main">
          <Appbar />
        </Box>
      </Box>
      <Box className=" d-flex dashboard-main-box">
        <Sidebar />
        <Box className="main">
          <Grid container className="d-flex header-topsection">
            <Grid item xs={8} md={8} sm={8}>
              <Box id="breadcrumb">
                <ArrowBackIosIcon />
                <Box className="breadcrumb-inr">
                  <h4 className="d-flex align-items-center ">
                    Appointment Details
                  </h4>
                  <Box className="breadcrumbs">
                    <Link className="breadcrumbs-link"> Appointment List</Link>
                    <span className="breadcrumbs-icon">{">"}</span>

                    <Link className="breadcrumbs-link breadcrumbs-link-active">
                      Appointment Details
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid xs={4} md={4} sm={4}>
              <Box className="d-flex align-items-center justify-content-end padding-right-20">
                <Button
                  variant="outlined"
                  size="large"
                  style={{ marginRight: "10px" }}
                >
                  Start
                </Button>

                <Button
                  variant="outlined"
                  size="large"
                  style={{ marginLeft: "10px" }}
                >
                  Swap
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box className="divider"></Box>
        

          <Box>
            <Box className="bg-strip">
              <Typography>Client Details</Typography>
            </Box>
          </Box>

          {/* hide and show client details section  */}

          <Box className="hide-client-details main-padding">
            <Box>
              <Typography
                onClick={toogleShow}
                className="d-flex hide-client-drop align-items-center"
              >
                {showResults ? "Hide Client Details" : "Show Client Details"}{" "}
                <span className="hide-client-arrow">
                  <KeyboardArrowUpIcon
                    className={isActive ? "rotate-arrow" : ""}
                  />
                </span>{" "}
              </Typography>
            </Box>

            {showResults && (
              <Box className="hide-box">
                <Grid
                  container
                  spacing={4}
                  className="align-items-start add-appointment-input-box"
                >
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={3}
                    className="input flex-column hide-client-img-main d-flex pt-0"
                  >
                    <Box className="hide-client-img-box">
                      <Box className="hide-client-img">
                        <img src={listData[0]?.clientDetails?.image}></img>
                      </Box>
                      <Typography>
                        {listData[0]?.clientDetails?.name},
                        {calculate_age(listData[0]?.clientDetails?.age)} (
                        {listData[0]?.clientDetails?.gender})
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    md={8}
                    className="input flex-column d-flex pt-0"
                  >
                    <Grid container spacing={4} className="align-items-start">
                      <Grid item xs={12} sm={4} md={4}>
                        <Box>
                          <Box className="hide-content">
                            <Typography>
                              Date of Birth {" "}{" "}
                              <span>{listData[0]?.clientDetails?.dob},</span>
                            </Typography>
                          </Box>
                          <Box className="hide-content">
                            <Typography>
                              {" "}
                              <img src={call}></img>{" "}
                              <a href="#">
                                {listData[0]?.clientDetails?.mobile}
                              </a>
                            </Typography>
                          </Box>
                          <Box className="hide-content">
                            <Typography>
                              Plan Managed{" "}
                              <span>
                                {listData[0]?.clientDetails?.planManaged}
                              </span>
                            </Typography>
                          </Box>
                          <Box className="hide-content">
                            <Typography>
                              Category{" "}
                              <span>
                                {listData[0]?.clientDetails?.category}
                              </span>
                            </Typography>
                          </Box>
                          <Box className="hide-content pb-0">
                            <Typography>Agreement Period</Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Box>
                          <Box className="hide-content">
                            <Typography>
                              <img src={location}></img>{" "}
                              {listData[0]?.clientDetails?.location}
                            </Typography>
                          </Box>
                          <Box className="hide-content">
                            <Typography>
                              {" "}
                              <img src={mail}></img>{" "}
                              <a href="#">
                                {listData[0]?.clientDetails?.email}
                              </a>
                            </Typography>
                          </Box>
                          <Box className="hide-content">
                            <Typography>
                              NDIS No.{" "}
                              <span>
                                {listData[0]?.clientDetails?.ndisNumber}
                              </span>
                            </Typography>
                          </Box>
                          <Box className="hide-content">
                            <Typography>
                              Status{" "}
                              <span>{listData[0]?.clientDetails?.status}</span>
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Box>
                          <Box className="hide-content">
                            <Box className="mapicon">
                              {" "}
                              <img src={mapIcon}></img>{" "}
                            </Box>
                          </Box>
                          <Box className="hide-content">
                            <a href="#">
                              Map <br /> View
                            </a>
                          </Box>

                          <Box className="hide-content">
                            <Typography>
                              NDIS Zone{" "}
                              <span>
                                {listData[0]?.clientDetails?.ndisZone}
                              </span>
                            </Typography>
                          </Box>
                          <Box className="hide-content">
                            <Typography>
                              Funding{" "}
                              <span>{listData[0]?.clientDetails?.funding}</span>
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} className="pt-0">
                        <Box className="hide-client-progress">
                          <Progress />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>

          <Grid
            container
            spacing={4}
            className="align-items-start add-appointment-input-box"
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className="input flex-column d-flex pt-0"
            >
              {/* <label>Funding Source</label> */}
              <Box className="hide-content">
                <Typography>
                  Funding Source{" "}
                  <span>{listData[0]?.clientDetails?.planManaged}</span>
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className="input flex-column d-flex pt-0"
            >
              {/* <label>Region</label> */}
              <Box className="hide-content">
                <Typography>
                  {" "}
                  Region: <span> {listData[0]?.clientDetails?.region}</span>
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box>
            <Box className="bg-strip service-strip">
              <Typography>Service Details</Typography>
            </Box>
          </Box>

          <Grid
            container
            spacing={4}
            className="align-items-start add-appointment-input-box"
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className="input flex-column d-flex pt-0"
            >
              <Box className="hide-content">
                <Typography>
                  {" "}
                  Category: <span> {listData[0]?.clientDetails?.category}</span>
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className="input flex-column d-flex pt-0"
            >
              <Box className="hide-content">
                <Typography>
                  {" "}
                  Support Type:{" "}
                  <span> {listData[0]?.serviceDetails?.supportType}</span>
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              sx={{ mt: 2 }}
              className="input flex-column d-flex pt-0"
            >
              <Box className="hide-content">
                <Typography>
                  {" "}
                  Group Name:{" "}
                  <span> {listData[0]?.serviceDetails?.groupName}</span>
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              sx={{ mt: 2 }}
              className="input flex-column d-flex pt-0"
            >
              <Box className="hide-content">
                <Typography>
                  {" "}
                  Support Category Name:{" "}
                  <span> {listData[0]?.serviceDetails?.supportCategory} </span>
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              sx={{ mt: 2 }}
              className="input flex-column d-flex pt-0"
            >
              <Box className="hide-content">
                <Typography>
                  {" "}
                  Support Item Name :{" "}
                  <span> {listData[0]?.serviceDetails?.supportItem} </span>
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box>
            <Box className="bg-strip service-strip">
              <Typography>Appointment Details</Typography>
            </Box>
          </Box>

          <Grid
            container
            spacing={4}
            className="align-items-start add-appointment-input-box"
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className="input flex-column d-flex pt-0"
            >
              <Box className="hide-content">
                <Typography>
                  {" "}
                  Appointment Type :{" "}
                  <span>
                    {" "}
                    {listData[0]?.appointmentDetails?.appointmentType}{" "}
                  </span>
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className="input flex-column d-flex pt-0"
            >
              <Box className="hide-content">
                <Typography>
                  {" "}
                  Appointment Date & Time :{" "}
                  <span>
                    {" "}
                    {
                      listData[0]?.appointmentDetails?.appointmentDateAndTime
                    }{" "}
                  </span>
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className="input flex-column d-flex pt-0"
            >
              <Box className="hide-content">
                <Typography>
                  {" "}
                  From-To : <span> Sydney - Brisbane - Alabama </span>
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className="input flex-column d-flex pt-0"
            >
              <Box className="hide-content">
                <Typography>
                  {" "}
                  Total Distance: <span> Sydney - Brisbane - Alabama </span>
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={3}
              md={2}
              className="input flex-column d-flex pt-0"
            >
              <Box className="hide-content">
                <Typography>
                  {" "}
                  KM To:{" "}
                  {listData[0]?.appointmentDetails?.kmTo && (
                    <span> {listData[0]?.appointmentDetails?.kmTo} KM </span>
                  )}
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={3}
              md={2}
              className="input flex-column d-flex pt-0"
            >
              <Box className="hide-content">
                <Typography>
                  {" "}
                  KM With:{" "}
                  {listData[0]?.appointmentDetails?.kmWith && (
                    <span> {listData[0]?.appointmentDetails?.kmWith} KM </span>
                  )}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box className="client-map">
            <Box className="map-modal bg-light">
              <div class="mapouter">
                <div class="gmap_canvas">
                  <iframe
                    width="100%"
                    height="323px"
                    className="map-iframe"
                    id="gmap_canvas"
                    src="https://maps.google.com/maps?q=Sydney&t=&z=10&ie=UTF8&iwloc=&output=embed"
                    frameborder="0"
                    scrolling="no"
                    marginheight="0"
                    marginwidth="0"
                  ></iframe>
                </div>
              </div>
            </Box>
          </Box>

          <Box>
            <Box className="bg-strip service-strip">
              <Typography>Team Details</Typography>
            </Box>
          </Box>

          <Grid
            container
            spacing={2}
            className="align-items-start add-appointment-input-box"
          >
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              className="input flex-column d-flex pt-0"
            >
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      alt="Remy Sharp"
                      src={listData[0]?.team?.healthCarerImage}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={listData[0]?.team?.healthCarerName}
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {listData[0]?.team?.healthCarerUserType}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              className="input flex-column d-flex pt-0"
            >
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      alt="Remy Sharp"
                      src={listData[0]?.team?.supportCoordinatorImage}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={listData[0]?.team?.supportCoordinatorName}
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {listData[0]?.team?.supportCoordinatorUserType}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              className="input flex-column d-flex pt-0"
            >
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      alt="Remy Sharp"
                      src={listData[0]?.team?.superviserImage}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={listData[0]?.team?.superviserName}
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {listData[0]?.team?.superviserUserType}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>

          <Box>
            <Box className="bg-strip service-strip">
              <Typography>Routine & Tasks</Typography>
            </Box>
          </Box>

          {/* Routine stepper section start here  */}

          <Box class="routine-stepper-box">
            <Box>
              <span className="routine-chip">Exercise </span>
            </Box>

            <Grid
              container
              sx={{ mt: 2 }}
              spacing={4}
              className="d-flex align-items-center "
            >
              <Grid item xs={12} sm={12} md={12}>
                <Box className="add-task-box d-flex flex-wrap align-items-center">
                  <Box className="add-task-item">
                    <Typography>Task 1</Typography>
                    <span className="addtask done-task">
                      {" "}
                      <span className="task-num">1</span>{" "}
                    </span>
                    <img src={verified} className="verified-none"></img>
                    <VerifiedIcon className="verified-icon" />
                  </Box>
                  <Box className="connector-line"></Box>
                  <Box className="add-task-item">
                    <Typography>Task 2</Typography>
                    <span className="addtask done-task">
                      {" "}
                      <span className="task-num">2</span>{" "}
                    </span>
                    <img src={verified} className="verified-none"></img>
                    <VerifiedIcon className="verified-icon" />
                  </Box>
                  <Box className="connector-line"></Box>
                  <Box className="add-task-item">
                    <Typography>Task 3</Typography>
                    <span className="addtask ">
                      {" "}
                      <span className="task-num">3</span>{" "}
                    </span>
                    <img src={verified}></img>
                    <VerifiedIcon className="verified-icon verified-none" />
                  </Box>
                  <Box className="connector-line"></Box>
                  <Box className="add-task-item">
                    <Typography>Task 4</Typography>
                    <span className="addtask ">
                      {" "}
                      <span className="task-num">4</span>{" "}
                    </span>
                    <img src={verified}></img>
                    <VerifiedIcon className="verified-icon verified-none" />
                  </Box>
                  <Box className="connector-line"></Box>
                  <Box className="add-task-item">
                    <Typography>Task 5</Typography>
                    <span className="addtask ">
                      {" "}
                      <span className="task-num">5</span>{" "}
                    </span>
                    <img src={verified}></img>
                    <VerifiedIcon className="verified-icon verified-none" />
                  </Box>
                  <Box className="connector-line"></Box>
                  <Box className="add-task-item">
                    <Typography>Task 6</Typography>
                    <span className="addtask ">
                      {" "}
                      <span className="task-num">6</span>{" "}
                    </span>
                    <img src={verified}></img>
                    <VerifiedIcon className="verified-icon verified-none" />
                  </Box>
                  <Box className="connector-line"></Box>
                  <Box className="add-task-item">
                    <Typography>Task 7</Typography>
                    <span className="addtask ">
                      {" "}
                      <span className="task-num">7</span>{" "}
                    </span>
                    <img src={verified}></img>
                    <VerifiedIcon className="verified-icon verified-none" />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Box className="bg-strip service-strip">
              <Typography>Other Details</Typography>
            </Box>
          </Box>

          {/* <Box className="other-details"> */}

          <Grid
            container
            spacing={4}
            className="align-items-start add-appointment-input-box"
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className="input flex-column d-flex pt-0"
            >
              <Box className="hide-content">
                <Typography>
                  {" "}
                  Accounts: <span> {listData[0]?.otherDetails?.account} </span>
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className="input flex-column d-flex pt-0"
            >
              <Box className="hide-content">
                <Typography>
                  {" "}
                  Flexible with time?{" "}
                  <span>
                    {" "}
                    {listData[0]?.otherDetails?.felixible ? "Yes" : "No"}
                  </span>
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              sx={{ mt: 2 }}
              className="input flex-column d-flex pt-0"
            >
              <Box className="hide-content">
                <Typography>
                  {" "}
                  Tasks: <span>{listData[0]?.otherDetails?.tasks} </span>
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              sx={{ mt: 2 }}
              className="input flex-column d-flex pt-0"
            >
              <Box className="hide-content">
                <Typography>
                  {" "}
                  Notes: <span> {listData[0]?.otherDetails?.notes} </span>
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box className="agency-btn-section add-expenses-btn">
            {/* <Link href='javascript:;' >Cancel</Link> */}
            <Button variant="outlined" size="large">
              Back
            </Button>
          </Box>
          {/* </Box> */}
        </Box>
      </Box>
    </Box>
  );
}
