// import React, { useEffect, useRef, useState } from "react";
// import { Checkbox, Chip, Grid } from "@mui/material";
// import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
// import SearchCustom from "./SearchCustom";
// import "./multi-select-dropdown.scss";

// const MultiDropDown = ({
//   dropdownArray,
//   setSelectedItemsData,
//   selectedItemsData,
//   placeholder,
//   disabled,
//   emptyDropdownArrayOnChangeOrDelete,
// }) => {
//   const [searchTerm, setSearchTerm] = useState("");
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//   const [openUpward, setOpenUpward] = useState(false); // New state for controlling direction
//   const [selectedItems, setSelectedItems] = useState([]);
//   const dropdownRef = useRef(null);
//   const dropdownContentRef = useRef(null); // New ref for dropdown content

//   useEffect(() => {
//     if (selectedItemsData) {
//       setSelectedItems(selectedItemsData);
//     }
//   }, [selectedItemsData]);

//   console.log(selectedItems, "dnjdf")

//   // Close the dropdown when the user clicks outside of it
//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//         setIsDropdownOpen(false);
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [dropdownRef]);

//   // Calculate space and toggle dropdown direction
//   useEffect(() => {
//     if (isDropdownOpen) {
//       const dropdownRect = dropdownRef.current.getBoundingClientRect();
//       const dropdownHeight = dropdownContentRef.current.offsetHeight;
//       const windowHeight = window.innerHeight;

//       // Check if there's enough space below; if not, open upward
//       if (windowHeight - dropdownRect.bottom < dropdownHeight) {
//         setOpenUpward(true);
//       } else {
//         setOpenUpward(false);
//       }
//     }
//   }, [isDropdownOpen]);

//   // Filter the array based on the search term
//   const filteredOptions = dropdownArray?.filter((item) => {
//     if (!searchTerm) return true;
//     return item?.firstName?.toLowerCase().includes(searchTerm.toLowerCase());
//   });

//   const handleSelectItem = (item) => {
//     const updatedSelectedItems = selectedItems.includes(item._id)
//       ? selectedItems.filter((selectedItem) => selectedItem !== item._id)
//       : [...selectedItems, item._id];

//     setSelectedItems(updatedSelectedItems);
//     setSelectedItemsData(updatedSelectedItems); // Set the selected IDs in the parent component
//     if (emptyDropdownArrayOnChangeOrDelete) {
//       emptyDropdownArrayOnChangeOrDelete();
//     }
//   };

//   const handleDeleteItem = (itemId) => {
//     const updatedSelectedItems = selectedItems.filter(
//       (selectedItem) => selectedItem !== itemId,
//     );
//     setSelectedItems(updatedSelectedItems);
//     setSelectedItemsData(updatedSelectedItems); // Update the parent component
//     if (emptyDropdownArrayOnChangeOrDelete) {
//       emptyDropdownArrayOnChangeOrDelete();
//     }
//   };

//   const handleSelectAll = () => {
//     if (selectedItems.length === filteredOptions.length) {
//       setSelectedItems([]); // Unselect all
//       setSelectedItemsData([]); // Update the parent component
//     } else {
//       const allItemIds = filteredOptions.map((item) => item._id);
//       setSelectedItems(allItemIds); // Select all
//       setSelectedItemsData(allItemIds); // Update the parent component
//     }
//   };

//   return (
//     <div
//       className={`custom-multi-select-dropdown-component ${disabled ? "disabled" : ""}`}
//       ref={dropdownRef}
//     >
//       <div className="dropdown">
//         <Grid
//           onClick={(e) => {
//             e.preventDefault();
//             if (!disabled) {
//               setIsDropdownOpen(!isDropdownOpen);
//             }
//           }}
//           className={`dropbtn ${disabled ? "disabled" : ""}`}
//           display={"flex"}
//           justifyContent={"space-between"}
//           style={{
//             cursor: disabled ? "default" : "pointer",
//             backgroundColor: disabled ? "rgba(239, 239, 239, 0.3)" : "",
//           }}
//         >
//           <div className="custom-multi-select-selected-chip"
//             style={{
//               display: 'flex',
//               flexWrap: 'nowrap', // Prevent wrapping
//               overflowX: 'auto', // Enable horizontal scrolling
//             }}
//           >
//             {selectedItems?.length ? (
//               selectedItems?.map((itemId, index) => {
//                 const item = dropdownArray?.find((data) => data?._id === itemId);
//                 return (
//                   <Chip
//                     key={index}
//                     label={
//                     item?.firstName
//                     }
//                     onDelete={() => handleDeleteItem(itemId)}
//                     style={{ opacity: "1" }}
//                     disabled={disabled}
//                   />
//                 );
//               })
//             ) : (
//               <p>{placeholder}</p>
//             )}
//           </div>

//           {!isDropdownOpen ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
//         </Grid>
//         <div
//           id="myDropdown"
//           className={`dropdown-content ${openUpward ? "open-upward" : ""}`}
//           style={{
//             display: `${isDropdownOpen ? "block" : "none"}`,
//             pointerEvents: disabled ? "none" : "auto",
//           }}
//           ref={dropdownContentRef} // New ref for content
//         >
//           <Grid padding={"0 12px"} mb={"10px"}>
//             <SearchCustom setSearchKey={setSearchTerm} />
//           </Grid>
//           <Grid
//             style={{
//               display: "flex",
//               backgroundColor:
//                 selectedItems.length === dropdownArray?.length
//                   ? "rgba(17, 205, 239, 0.1)"
//                   : "#FFFFFF",
//             }}
//             onClick={handleSelectAll}
//             className="custom-multi-select-menu-item"
//           >
//             <Checkbox
//               checked={selectedItems?.length === dropdownArray?.length}
//               disabled={disabled}
//             />
//             <p style={{ display: "inline" }}>Select All</p>
//           </Grid>
//           {filteredOptions?.length ? (
//             filteredOptions?.map((item, index) => (
//               <Grid
//                 key={index}
//                 style={{
//                   backgroundColor: selectedItems.includes(item?._id)
//                     ? "rgba(17, 205, 239, 0.1)"
//                     : "#FFFFFF",
//                 }}
//                 onClick={() => handleSelectItem(item)}
//                 className="custom-multi-select-menu-item"
//               >
//                   <p>{item?.firstName}</p>
//               </Grid>
//             ))
//           ) : (
//             <Grid className="custom-multi-select-menu-item item-not-found">
//               <p>No results found.</p>
//             </Grid>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default MultiDropDown;


import React, { useEffect, useRef, useState } from "react";
import { Checkbox, Chip, Grid } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import SearchCustom from "./SearchCustom";
import "./multi-select-dropdown.scss";

const MultiDropDown = ({
  dropdownArray,
  setSelectedItemsData,
  selectedItemsData,
  placeholder,
  disabled,
  emptyDropdownArrayOnChangeOrDelete,
  keyField
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [openUpward, setOpenUpward] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const dropdownRef = useRef(null);
  const dropdownContentRef = useRef(null);

  useEffect(() => {
    if (selectedItemsData) {
      setSelectedItems(selectedItemsData);
    }
  }, [selectedItemsData]);

  // Close the dropdown when the user clicks outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  // Calculate space and toggle dropdown direction
  useEffect(() => {
    if (isDropdownOpen) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect();
      const dropdownHeight = dropdownContentRef.current.offsetHeight;
      const windowHeight = window.innerHeight;

      // Check if there's enough space below; if not, open upward
      if (windowHeight - dropdownRect.bottom < dropdownHeight) {
        setOpenUpward(true);
      } else {
        setOpenUpward(false);
      }
    }
  }, [isDropdownOpen]);

  // Filter the array based on the search term
  const filteredOptions = dropdownArray?.filter((item) => {
    if (!searchTerm) return true;
    return item?.[keyField]?.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleSelectItem = (item) => {
    const updatedSelectedItems = selectedItems.includes(item._id)
      ? selectedItems.filter((selectedItem) => selectedItem !== item._id)
      : [...selectedItems, item._id];

    setSelectedItems(updatedSelectedItems);
    setSelectedItemsData(updatedSelectedItems);
    if (emptyDropdownArrayOnChangeOrDelete) {
      emptyDropdownArrayOnChangeOrDelete();
    }
  };

  const handleDeleteItem = (itemId) => {
    const updatedSelectedItems = selectedItems.filter(
      (selectedItem) => selectedItem !== itemId,
    );
    setSelectedItems(updatedSelectedItems);
    setSelectedItemsData(updatedSelectedItems);
    if (emptyDropdownArrayOnChangeOrDelete) {
      emptyDropdownArrayOnChangeOrDelete();
    }
  };

  const handleSelectAll = () => {
    if (selectedItems.length === filteredOptions.length) {
      setSelectedItems([]);
      setSelectedItemsData([]);
    } else {
      const allItemIds = filteredOptions.map((item) => item._id);
      setSelectedItems(allItemIds);
      setSelectedItemsData(allItemIds);
    }
  };

  return (
    <div
      className={`custom-multi-select-dropdown-component ${disabled ? "disabled" : ""}`}
      ref={dropdownRef}
    >
      <div className="dropdown">
        <Grid
          onClick={(e) => {
            e.preventDefault();
            if (!disabled) {
              setIsDropdownOpen(!isDropdownOpen);
            }
          }}
          className={`dropbtn ${disabled ? "disabled" : ""}`}
          display={"flex"}
          justifyContent={"space-between"}
          style={{
            cursor: disabled ? "default" : "pointer",
            backgroundColor: disabled ? "rgba(239, 239, 239, 0.3)" : "",
          }}
        >
          <div
            className="custom-multi-select-selected-chip"
            style={{
              display: 'flex',
              flexWrap: 'nowrap',
              overflowX: 'auto',
            }}
          >
            {selectedItems?.length ? (
              selectedItems?.map((itemId, index) => {
                const item = dropdownArray?.find((data) => data?._id === itemId);
                return (
                  <Chip
                    key={index}
                    label={item?.[keyField]}
                    onDelete={() => handleDeleteItem(itemId)}
                    style={{ opacity: "1", fontSize:"10px", height:"24px", margin:0 }}
                    disabled={disabled}
                  />
                );
              })
            ) : (
              <p>{placeholder}</p>
            )}
          </div>

          {!isDropdownOpen ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        </Grid>
        <div
          id="myDropdown"
          className={`dropdown-content ${openUpward ? "open-upward" : ""}`}
          style={{
            display: `${isDropdownOpen ? "block" : "none"}`,
            pointerEvents: disabled ? "none" : "auto",
          }}
          ref={dropdownContentRef}
        >
          <Grid padding={"0 6px"} mb={"10px"}>
            <SearchCustom setSearchKey={setSearchTerm} />
          </Grid>
          <Grid
            style={{
              display: "flex",
              backgroundColor:
                selectedItems.length === dropdownArray?.length
                  ? "rgba(17, 205, 239, 0.1)"
                  : "#FFFFFF",
            }}
            onClick={handleSelectAll}
            className="custom-multi-select-menu-item"
          >
            <Checkbox
              checked={selectedItems?.length === dropdownArray?.length}
              disabled={disabled}
            />
            <p style={{ display: "inline" }}>Select All</p>
          </Grid>
          {filteredOptions?.length ? (
            filteredOptions?.map((item, index) => (
              <Grid
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: selectedItems.includes(item?._id)
                    ? "rgba(17, 205, 239, 0.1)"
                    : "#FFFFFF",
                }}
                onClick={() => handleSelectItem(item)}
                className="custom-multi-select-menu-item"
              >
                <Checkbox
                  checked={selectedItems.includes(item?._id)}
                  onChange={() => handleSelectItem(item)} // Handle checkbox state
                  disabled={disabled}
                />
                <p>{item?.[keyField]}</p>
              </Grid>
            ))
          ) : (
            <Grid className="custom-multi-select-menu-item item-not-found">
              <p>No results found.</p>
            </Grid>
          )}
        </div>
      </div>
    </div>
  );
};

export default MultiDropDown;

