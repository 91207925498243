import { Search } from "@mui/icons-material";
import { FormControl, Grid, InputAdornment, OutlinedInput } from "@mui/material";
import React, { useState, useEffect } from "react";
// import './search-custom.scss';
import PropTypes from 'prop-types';

const SearchCustom = ({ setSearchKey, debounceDelay = 0 }) => {
  const [searchTerm, setSearchTerm] = useState('');


  // ********************** for debounce search *******************
  useEffect(() => {
    if (searchTerm.length >= 3) {
      // Set a timeout to trigger the debounced search
      // setSearchKey(searchTerm);

      const handler = setTimeout(() => {
        setSearchKey(searchTerm);
      }, debounceDelay);

      // Cleanup the timeout if the component unmounts or searchTerm changes before delay
      return () => {
        clearTimeout(handler);
      };
    } else {
      // Reset the searchKey if searchTerm length is less than 3
      setSearchKey('');
    }
  }, [searchTerm, debounceDelay, setSearchKey]);



  // *********************** handle change for search box ********************
  const handleSearchChange = (e) => {
    let { value } = e.target;
    if (value === '' || value[0] !== ' ') {
      setSearchTerm(e.target.value);
    }
  };

  return (
    <Grid className="search-custom">
      <FormControl
        fullWidth
        sx={{ width: "100%" }}
        size="medium"
        variant="outlined"
      >
        <OutlinedInput
          id="outlined-adornment-weight"
          sx={{height: "40px"}}
          placeholder="Search ....."
          value={searchTerm}
          onChange={handleSearchChange}
          startAdornment={
            <InputAdornment position="start">
              <Search sx={{fontSize: "20px"}} />
            </InputAdornment>
          }
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            "aria-label": "weight",
          }}
        />
      </FormControl>
    </Grid>
  );
}

SearchCustom.propTypes = {
  setSearchKey: PropTypes.func.isRequired,
  debounceDelay: PropTypes.number, // New prop for setting debounce delay
};

export default SearchCustom;
