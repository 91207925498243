import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Box from "@mui/material/Box";

import { FieldsListLoading } from "../pages/components/loading/FieldsListLoading";
import { toast } from "react-toastify";
import "../App.css";
import "../assets/css/dashboard.css";

import GradeIcon from "@mui/icons-material/Grade";

import "../assets/css/web-css.css";

import { getClientRating } from "../api";

export default function RatingClient(props) {
  const clientId = props?.props?.clientId;
  const [rating, setRating] = useState(0);
  useEffect(() => {
    CheckClientRating();
  }, [clientId]);

  const CheckClientRating = async () => {
    try {

      console.log("clientIdclientId", clientId)
      axios
        .get(`${getClientRating}/${clientId}`)
        .then((res) => {
          let listUserTypeList = res?.data?.data;
          setRating(
            listUserTypeList?.roundedAverageRating !== null
              ? listUserTypeList?.roundedAverageRating
              : 0
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const renderRatingIcons = () => {
    const ratingIcons = [];

    for (let i = 0; i < rating; i++) {
      ratingIcons.push(<GradeIcon key={i} className="rating-cion-sty" />);
    }

    return rating > 0 ? ratingIcons : "";
  };

  return (
    <>
      <Box className="worker-card-rating">{renderRatingIcons()}</Box>
    </>
  );
}
