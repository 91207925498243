import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import WebHeader from "../../../Website/Web-Header";
import WebFooter from "../../../Website/Web-footer";
import BookDemo from "../../../Website/HomeUI/BookDemo";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  XIcon,
  TwitterIcon,
} from "react-share";



import "../../../Website/HomeUI/home-ui.css";
import { getBlog, listBlogForDetails } from "../../../api";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "../../../api/axios";
import zustandStore from "../../../helpers/zustandStore";

export default function BlogsDetails() {
  const [data, setData] = useState();
  const [listData, setListData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [pageCount, setpageCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const { menuList } = zustandStore();
  const [count, setCount] = useState("");
  const [isActive, setActive] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [list, setList] = useState([]);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const navigate = useNavigate();
  const location = useLocation();
  const blogId = location?.state?.blogId;
  const pageType = location?.state?.pageType;

  const limit = "3";

  useEffect(() => {
    if (blogId !== null) {
      axios
        .get(`${getBlog}/${blogId}`)
        .then((res) => {
          let dataval = res?.data?.data;
          setData(dataval);
          console.log(dataval, "datavaldataval");  // Console log the blog details here

        })
        .catch((error) => {
          console.log(error);
        });

      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [blogId]);

  useEffect(() => {
    getaList();
  }, [limit, searchTerm, blogId]);

  const getaList = async () => {
    axios
      .get(
        `${listBlogForDetails}?searchTerm=${searchTerm}&page=1&items_per_page=${limit}&currentBlogId=${blogId}`
      )
      .then((res) => {
        setListData(res?.data?.data?.docs);
        let total = res.data.data.totalDocs;
        setpageCount(Math.ceil(total / limit));
        setCount(total);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const shareUrl = window.location.href;
  // const shareUrl = "http://13.237.24.54:3000/blog-details";
  const title = data?.title;



  // const shareUrl = "http://github.com";

  return (
    <Box id="guides-details">
      <Box className="w-100">
        <WebHeader />

        <Box className="webContainer">
          <Box className="W-100 ">
            <Box className="guide-details-Content space-pt-80">
              <Box className="text-center">
                <h1>{data?.title}</h1>
                <Typography className="space-32">
                  {data?.description}
                </Typography>

                <Typography className="text-sm-bold p-0">
                  {data?.author}
                </Typography>
                <span>{new Date(data?.createdAt).toDateString()}</span>
              </Box>
            </Box>
          </Box>

          <Box className="w-100 space-pt-80">
            <Box className="w-100 ">
              <img
                src={data?.image?.location}
                className="w-100 radious-20"
                alt="Guide Image"
              />
            </Box>
          </Box>

          {/* Guides details text  */}
          <Box>
            <Box className="guides-details-text ">
              <Typography className="space-pt-60">
                <div dangerouslySetInnerHTML={{ __html: data?.content }} />
              </Typography>
            </Box>
          </Box>

          <Box className="guide-sm-divider"></Box>

          <Box className="d-flex space-pt-32 align-items-center justify-content-center">

            <div>

              <LinkedinShareButton
                className="share-button-linkedin"
                url={shareUrl}
                title={title}
              >
                <LinkedinIcon size={50} round />
              </LinkedinShareButton>

            </div>

            &nbsp;
            &nbsp;


            <div>

              <FacebookShareButton
                className="share-button-facebook"
                url={shareUrl}
                title={title}
              >
                <FacebookIcon size={50} round />
              </FacebookShareButton>

            </div>

            &nbsp;
            &nbsp;

            <div>


              {/* <TwitterShareButton
                className="share-button-x"
                url={window.location.href}
                title={data?.title}
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton> */}

              <TwitterShareButton
                url={shareUrl}
                title={title}
                className="share-button-x"
              >
                <XIcon size={50} round />
              </TwitterShareButton>

            </div>

            &nbsp;
            &nbsp;


          </Box>
        </Box>
      </Box>

      <Box className="space-lg web-topBanner-bg space-mt-lg">
        <Box className="webContainer">
          <h1 className="space-pb-60"> You may also like these Blogs</h1>
          <Grid container spacing={2}>
            {listData.map((blogItem) => (
              <Grid item xs={12} sm={12} md={4} key={blogItem?._id}>
                <Box>
                  <Box className="blog-cardImg">
                    <img src={blogItem?.image?.location} alt={blogItem.title} />
                  </Box>

                  <Box className="blog-card-smTitle">
                    <Box className="w-100 h-100 d-flex align-items-center justify-content-center">
                      <span>{blogItem?.category}</span>
                    </Box>
                  </Box>
                  <Box className="blog-card-title">
                    <Link
                      to={"/blog-details"}
                      state={{ blogId: blogItem?._id, pageType: "blogDetails" }}
                      onClick={() =>
                        window.scrollTo({ top: 0, behavior: "smooth" })
                      }
                    >
                      <h3>{blogItem?.title}</h3>
                    </Link>
                  </Box>
                  <Box className="blogCard-text">
                    <Typography>{blogItem?.description}</Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      {/* book demo section  */}

      <Box className="space-m-80">
        <Box className="webContainer">
          <BookDemo />
        </Box>
      </Box>

      <WebFooter />
    </Box>
  );
}
