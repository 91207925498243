import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import WebHeader from "./Web-Header";
import WebFooter from "./Web-footer";
import { Link } from "@mui/material";
import BookDemo from "./HomeUI/BookDemo";
import EmailIcon from "../assets/images/email-icon.png";
import MapIcon from "../assets/images/map-icon.png";
import CallIcon from "../assets/images/call-icon.png";
import TextField from "@mui/material/TextField";
import AboutbigImage from "../assets/images/about-big-img.jpg";
import teamImg1 from "../assets/images/team-img1.png";
import teamImg2 from "../assets/images/team-img2.png";
import teamImg3 from "../assets/images/team-img3.png";
import teamImg4 from "../assets/images/team-img4.png";
import teamImg5 from "../assets/images/team-img5.png";
import teamImg6 from "../assets/images/team-img6.png";
import teamImg7 from "../assets/images/team-img7.png";
import teamImg8 from "../assets/images/team-img8.png";
import linkdinIcon from "../assets/images/about-linkdin-icon.png";
import Counter from "./HomeUI/Counter";

const About = () => {
  return (
    <Box id="about">
      <Box className="web-topBanner-bg  blog-main-banner">
        <WebHeader />

        <Box className="webContainer">
          <Box className="W-100 ">
            <Box className="home-content-main space-pb-200 space-lg">
              <Box className="text-center ">
                <h1>
                  Empowering Care, <br /> Transforming Lives
                </h1>
                <Typography>
                  Hubshift is a CRM solution that brings efficiency, simplicity,
                  <br />
                  and connectivity to your disability care services.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="blurBg blurRed"> </Box>
        <Box className="blurBg blurPurple"> </Box>
        <Box className="blurBg blurYellow"></Box>
      </Box>

      <Box className="webContainer about-hubshift-img-wrapper">
        <Box className="w-100 about-hubshift-img">
          <Box className="w-100 ">
            <img src={AboutbigImage} className="w-100 radious-20" />
          </Box>
        </Box>
      </Box>

      {/* about description  */}

      <Box className="webContainer">
        <Grid container className="about-description space-pb-120">
          <Grid item xs={12} sm={5} md={5}>
            <h1>About Hubshift</h1>
          </Grid>

          <Grid item xs={12} sm={7} md={7}>
            <Typography>
              Welcome to Hubshift, a pioneering software company dedicated to
              enhancing the management and delivery of NDIS services across
              Australia. With a deep understanding and personal experience in
              the disability sector, we've developed a CRM platform that is not
              just a tool, but a solution for the complex challenges faced by
              NDIS providers, clients, support coordinators, health carers, and
              their families. 
            </Typography>
            <Typography>
              Hubshift was born out of a desire to make a real difference. Our
              founders, with over four years of personal experience as NDIS
              providers, recognized the inefficiencies and obstacles that
              hindered the delivery of care. Determined to change this, we
              leveraged our firsthand knowledge to create a platform that
              addresses these issues head-on, facilitating smoother operations,
              better care, and stronger connections within the NDIS.
            </Typography>

            <Typography>
              Choosing Hubshift means partnering with a company that understands
              the intricacies of the NDIS space from the inside out. Our
              platform is crafted with the user in mind, ensuring ease of use,
              efficiency, and effectiveness. By simplifying the complexities of
              NDIS management, we empower providers and carers to deliver
              superior care, enhancing the lives of those they serve. 
            </Typography>

            <Typography>
              As a new startup, we're agile, responsive, and committed to
              innovation. With Hubshift, you're not just adopting a CRM
              platform; you're joining a movement to improve the quality and
              accessibility of disability care across Australia's major cities. 
            </Typography>
            <Typography>
              We invite you to join us on this journey, as we work together to
              make a lasting impact in the lives of individuals with
              disabilities and the dedicated professionals who support them. 
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {/* do differently section   */}

      <Box className=" space-lg web-topBanner-bg space-80">
        <Box className="webContainer">
          <h1> We do this differently</h1>

          <Box className="about-full-divider"></Box>

          <Counter />
        </Box>
      </Box>

      {/* mission section  */}

      <Box className="webContainer">
        <Box className="about-missions space-80">
          <h1>
            Our <span>Mission</span>
          </h1>

          <Grid container className="about-missions-content space-pt-40">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="about-missions-content-row"
            >
              <Grid container>
                <Grid item xs={12} sm={4} md={4}>
                  <h3>Empowering NDIS Providers</h3>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography>
                    Our mission is to simplify NDIS management with Hubshift.
                    Our platform streamlines interactions among stakeholders,
                    offering tools like Shift Swap and SIL Management, reducing
                    administrative burdens by over 70%. We empower providers to
                    focus on delivering quality care.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="about-missions-content-row"
            >
              <Grid container>
                <Grid item xs={12} sm={4} md={4}>
                  <h3>
                    Connecting Care for <br />
                    Community
                  </h3>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography>
                    At Hubshift, we aim to connect care for the community. Our
                    user-friendly solutions streamline NDIS care administration,
                    allowing providers and carers to prioritize exceptional
                    support. With innovative tools, we simplify the complexities
                    of care management for a more supportive society.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="about-missions-content-row"
            >
              <Grid container>
                <Grid item xs={12} sm={4} md={4}>
                  <h3>
                    Enhancing Lives Through <br />
                    Innovation
                  </h3>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography>
                    Hubshift is dedicated to enhancing the lives of people with
                    disabilities. Our mission is to provide accessible and
                    intuitive software solutions for NDIS care management. By
                    simplifying care planning and coordination, we enable
                    personalized, high-quality care delivery and foster
                    inclusivity.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="about-missions-content-row"
            >
              <Grid container>
                <Grid item xs={12} sm={4} md={4}>
                  <h3>
                    Continuous Innovation for <br />
                    Inclusive Care
                  </h3>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography>
                    At Hubshift, we're committed to continuous innovation for
                    inclusive care. Driven by real-world needs, our platform
                    evolves to simplify care planning, coordination, and
                    delivery. By empowering users and fostering support, we
                    strive to create a more inclusive society.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box className="about-missions space-pb-80">
          <Box className="">
            <h1>
              Our <span>Vision</span>
            </h1>
          </Box>

          <Grid container className="about-missions-content space-pt-40">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="about-missions-content-row"
            >
              <Grid container>
                <Grid item xs={12} sm={4} md={4}>
                  <h3>Seamless NDIS Connectivity</h3>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography>
                    Our vision at Hubshift is a future where every NDIS
                    community member is effortlessly connected. Through our
                    platform, we bridge gaps between providers, clients, and
                    support personnel, fostering a cohesive and supportive
                    environment for all.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="about-missions-content-row"
            >
              <Grid container>
                <Grid item xs={12} sm={4} md={4}>
                  <h3>
                    Empowering Disability
                    <br /> Independence
                  </h3>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography>
                    We envision empowering every individual with disabilities to
                    lead lives of choice, independence, and opportunity.
                    Hubshift aims to revolutionize NDIS care management with
                    innovative software solutions, ensuring seamless support
                    services for care recipients, providers, and the broader
                    community.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="about-missions-content-row"
            >
              <Grid container>
                <Grid item xs={12} sm={4} md={4}>
                  <h3>
                    Innovative Solutions for <br />
                    Enhanced Care
                  </h3>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography>
                    Hubshift's vision is to enhance the quality of care through
                    innovative technology. We strive to streamline NDIS
                    management processes, making the experience more accessible
                    and efficient for everyone involved. Our goal is to
                    revolutionize NDIS care management for impactful support
                    services.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="about-missions-content-row"
            >
              <Grid container>
                <Grid item xs={12} sm={4} md={4}>
                  <h3>
                    Bridging Gaps for Inclusive <br />
                    Support
                  </h3>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography>
                    At Hubshift, we're dedicated to bridging gaps in NDIS
                    support services. Our vision is to revolutionize care
                    management, ensuring seamless connectivity between care
                    recipients, providers, and the community. Through innovative
                    solutions, we aim to create an inclusive and supportive
                    environment for all.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* about team section  */}

      <Box className="about-team-section space-lg">
        <Box className="webContainer">
          <Box className="text-center">
            <h1>Meet Our Amazing Team</h1>
            <Typography className="space-40">
              Get to know the faces behind Hubshift's success – a dedicated team
              committed to revolutionizing <br /> NDIS care management with
              innovation, empathy, and expertise.
            </Typography>
          </Box>

          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={3} md={3} className="about-team-card">
              <Box className="w-100 position-relative">
                <img src={teamImg1} className="w-100" />
                <img src={linkdinIcon} className="team-linkdin-icon" />
              </Box>
              <Box className="text-center about-team-content">
                <h3>Neeraj Khurana</h3>
                <Typography>CEO & Founder</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} md={3} className="about-team-card">
              <Box className="w-100 position-relative">
                <img src={teamImg2} className="w-100" />
                <img src={linkdinIcon} className="team-linkdin-icon" />
              </Box>
              <Box className="text-center about-team-content">
                <h3>Peter Anderson</h3>
                <Typography>Role</Typography>
              </Box>
            </Grid>
            {/*
            <Grid item xs={12} sm={3} md={3} className="about-team-card">
              <Box className="w-100 position-relative">
                <img src={teamImg3} className="w-100" />
                <img src={linkdinIcon} className="team-linkdin-icon" />
              </Box>
              <Box className="text-center about-team-content">
                <h3>Ana Lee</h3>
                <Typography>Role</Typography>
              </Box>
            </Grid>
            
            <Grid item xs={12} sm={3} md={3} className="about-team-card">
              <Box className="w-100 position-relative">
                <img src={teamImg4} className="w-100" />
                <img src={linkdinIcon} className="team-linkdin-icon" />
              </Box>
              <Box className="text-center about-team-content">
                <h3>Robbin Caraway</h3>
                <Typography>Role</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} md={3} className="about-team-card">
              <Box className="w-100 position-relative">
                <img src={teamImg5} className="w-100" />
                <img src={linkdinIcon} className="team-linkdin-icon" />
              </Box>
              <Box className="text-center about-team-content">
                <h3>Hannah Roger</h3>
                <Typography>Role</Typography>
              </Box>
            </Grid>
           
            <Grid item xs={12} sm={3} md={3} className="about-team-card">
              <Box className="w-100 position-relative">
                <img src={teamImg6} className="w-100" />
                <img src={linkdinIcon} className="team-linkdin-icon" />
              </Box>
              <Box className="text-center about-team-content">
                <h3>Sam Nova</h3>
                <Typography>Role</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} md={3} className="about-team-card">
              <Box className="w-100 position-relative">
                <img src={teamImg7} className="w-100" />
                <img src={linkdinIcon} className="team-linkdin-icon" />
              </Box>
              <Box className="text-center about-team-content">
                <h3>Megan Caraway</h3>
                <Typography>CEO & Founder</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} md={3} className="about-team-card">
              <Box className="w-100 position-relative">
                <img src={teamImg8} className="w-100" />
                <img src={linkdinIcon} className="team-linkdin-icon" />
              </Box>
              <Box className="text-center about-team-content">
                <h3>Jack Lee</h3>
                <Typography>Role</Typography>
              </Box>
            </Grid> */}
          </Grid>
        </Box>
      </Box>

      {/* book demo section  */}

      <Box className="space-m-80">
        <Box className="webContainer">
          <BookDemo />
        </Box>
      </Box>

      <WebFooter />
    </Box>
  );
};

export default About;
