import React, { useMemo, lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Box from "@mui/material/Box";

import Appbar from "./../Dashboard/Layout/Appbar";
import Sidebar from "./../Dashboard/Layout/Sidebar";
import Error from "./../components/Error";
import localstorageData from "../helpers/localstorageData";
import { useState, useEffect } from "react";
import { getServiceProviderActivePackage, accessRoleList } from "../api";
import axios from "axios";
import { SuperAdminRoutes } from "./AllRoutesArray";
import { DelayedError } from "./DelayedError";
import { getCreatedBy } from "../helpers/commonHelper";
import Loader from "../../../frontend/src/pages/loader/Loader";
const routes = [
  // {
  //   path: "/service-provider/home",
  //   component: lazy(() => import("../pages/serviceProvider/form/home")),
  // },
  {
    path: "/service-provider/request-connection-list",
    component: lazy(() =>
      import("../pages/superAdmin/Request-Document/Connection-request")
    ),
  },

  {
    path: "/service-provider/availability-calendar-view",
    component: lazy(() => import("../components/availability/Calendar")),
  },
  {
    path: "/service-provider/health-carer-on-call",
    component: lazy(() =>
      import("../pages/superAdmin/health-carer/on-call/list")
    ),
  },
  {
    path: "/service-provider/time-sheet",
    // component: lazy(() => import("../components/timesheet/Timesheet")),
    component: lazy(() => import("../components/timesheet/CommonTimeSheet")),
  },
  {
    path: "/service-provider/timesheet-pdf",
    component: lazy(() => import("../components/pay-run/PayRunPdf")),
  },

  {
    path: "/service-provider/list-funds-management",
    component: lazy(() =>
      import("../pages/superAdmin/FundManagement/Fund-Management")
    ),
  },
  {
    path: "/service-provider/view-funds-management",
    component: lazy(() =>
      import("../pages/superAdmin/FundManagement/View-Fund-Management")
    ),
  },
  {
    path: "/service-provider/access-role-permission",
    component: lazy(() =>
      import("../pages/superAdmin/access-role-permission/list")
    ),
  },
  {
    path: "/service-provider/pay-run",
    component: lazy(() => import("../components/pay-run/PayRun")),
  },

  {
    path: "/service-provider/pay-run-contractor",
    component: lazy(() => import("../components/pay-run-contractor/PayRun.js")),
  },

  {
    path: "/service-provider/pay-run-pdf",
    component: lazy(() => import("../components/pay-run/PayRunPdf")),
  },

  {
    path: "/service-provider/pay-run-pdf-contractor",
    component: lazy(() => import("../components/pay-run-contractor/PayRunPdf")),
  },

  {
    path: "/service-provider/list-user-pay-run",
    component: lazy(() =>
      import("../pages/superAdmin/PayRun/list-user-pay-run")
    ),
  },
  {
    path: "/service-provider/list-user-pay-run-contractor",
    component: lazy(() =>
      import("../pages/superAdmin/PayRunContractor/list-user-pay-run.js")
    ),
  },
  {
    path: "/service-provider/pdf-pay-run",
    component: lazy(() => import("../pages/superAdmin/PayRun/Pdf")),
  },
  {
    path: "/service-provider/pdf-pay-run-contractor",
    component: lazy(() => import("../pages/superAdmin/PayRunContractor/Pdf")),
  },

  {
    path: "/service-provider/list-appointment",
    component: lazy(() => import("../pages/serviceProvider/appointment/list")),
  },
  {
    path: "/service-provider/add-appointment",
    component: lazy(() =>
      import("../pages/superAdmin/appointment/add-appointment")
    ),
  },
  {
    path: "/service-provider/edit-appointment",
    component: lazy(() =>
      import("../pages/superAdmin/appointment/add-appointment")
    ),
  },
  {
    path: "/service-provider/timesheet-view",
    component: lazy(() => import("../components/timesheet/TimesheetView")),
  },
  {
    path: "/service-provider/list-support-coordinator-offboarding",
    component: lazy(() =>
      import("../pages/superAdmin/off-boarding/support-coordinator/list")
    ),
  },
  {
    path: "/service-provider/off-boarding",
    component: lazy(() => import("../pages/superAdmin/off-boarding/MyAccount")),
  },
  {
    path: "/service-provider/add-sil-sda-appointment",
    component: lazy(() =>
      import("../pages/superAdmin/sil-sda/silsda-add-appointment.js")
    ),
  },
  {
    path: "/service-provider/new-sil-sda-appointment",
    component: lazy(() =>
      import("../pages/superAdmin/sil-sda/new-sil-sda-appointment")
    ),
  },

  // {
  //   path: "/service-provider/new-sil-sda-appointment-bak",
  //   component: lazy(() =>
  //     import("../pages/superAdmin/sil-sda/new-sil-sda-appointmentbak.js")
  //   ),
  // },

  {
    path: "/service-provider/manage-lms-student",
    component: lazy(() =>
      import("../pages/superAdmin/lms-student/openMoodle.js")
    ),
  },
  {
    path: "/service-provider/list-policy",
    component: lazy(() => import("../pages/superAdmin/policy/list")),
  },
  {
    path: "/service-provider/add-policy",
    component: lazy(() => import("../pages/superAdmin/policy/edit")),
  },
  {
    path: "/service-provider/edit-policy",
    component: lazy(() => import("../pages/superAdmin/policy/edit")),
  },
  // {
  //   path: "/service-provider/add-conflict",
  //   component: lazy(() => import("../pages/superAdmin/conflict/edit")),
  // },

  // {
  //   path: "/service-provider/edit-conflict",
  //   component: lazy(() => import("../pages/superAdmin/conflict/edit")),
  // },

  {
    path: "/service-provider/add-conflict",
    component: lazy(() => import("../pages/superAdmin/conflictClient/edit")),
  },
  {
    path: "/service-provider/edit-conflict",
    component: lazy(() => import("../pages/superAdmin/conflictClient/edit")),
  },

  {
    path: "/service-provider/view-medical-history-dev",
    component: lazy(() =>
      import(
        "../pages/superAdmin/Medication-management-dev/View-Medical-History"
      )
    ),
  },

  {
    path: "/service-provider/edit-medical-history-dev",
    component: lazy(() =>
      import(
        "../pages/superAdmin/Medication-management-dev/Add-Medical-History"
      )
    ),
  },

  {
    path: "/service-provider/medical-history-pdf",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/medicalHistoryPdf")
    ),
  },

  {
    path: "/service-provider/medical-history-print",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/print")
    ),
  },

  {
    path: "/service-provider/add-medical-history-dev",
    component: lazy(() =>
      import(
        "../pages/superAdmin/Medication-management-dev/Add-Medical-History"
      )
    ),
  },
  {
    path: "/service-provider/add-medication-dev",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/Add-Medication")
    ),
  },

  {
    path: "/service-provider/edit-medication-dev",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/Add-Medication")
    ),
  },
  {
    path: "/service-provider/view-medication-dev",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/View-Medication")
    ),
  },
  {
    path: "/service-provider/view-mestruation-observation",
    component: lazy(() =>
      import(
        "../pages/superAdmin/client-list/Viewtab/Observation/Mestruation/view"
      )
    ),
  },

  {
    path: "/service-provider/appointments-view",
    component: lazy(() =>
      import("../components/calendar-view/Appointment-View")
    ),
  },
  {
    path: "/service-provider/list-package-menu",
    component: lazy(() => import("../components/packageMenu/list")),
  },
  {
    path: "/service-provider/settings",
    component: lazy(() => import("../pages/serviceProvider/Settings/setting")),
  },
  {
    path: "/service-provider/manage-master-data",
    component: lazy(() =>
      import("../pages/serviceProvider/Settings/manageMasterData")
    ),
  },
  {
    path: "/service-provider/ato-settings",
    component: lazy(() =>
      import("../pages/serviceProvider/Settings/atoSetting")
    ),
  },
  {
    path: "/service-provider/payments",
    component: lazy(() => import("../pages/serviceProvider/Settings/payment")),
  },
  {
    path: "/service-provider/subscription-plans-list",
    component: lazy(() =>
      import("../pages/serviceProvider/subscription-plan/list")
    ),
  },
  {
    path: "/service-provider/payment-gateway",
    component: lazy(() =>
      import("../pages/serviceProvider/subscription-plan/paymentGateway")
    ),
  },

  {
    path: "/service-provider/myaccount",
    component: lazy(() => import("../pages/serviceProvider/form/MyAccount")),
  },

  {
    path: "/service-provider/client-list",
    component: lazy(() => import("../pages/superAdmin/client-list/list")),
  },
  {
    path: "/service-provider/add-client",
    component: lazy(() => import("../pages/superAdmin/client-list/edit")),
  },
  {
    path: "/service-provider/edit-client",
    component: lazy(() => import("../pages/superAdmin/client-list/edit")),
  },
  {
    path: "/service-provider/client-view",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/client-view")
    ),
  },
  // ... Add other routes for Client section ...

  {
    path: "/service-provider/list-health-carer",
    component: lazy(() => import("../pages/superAdmin/health-carer/list")),
  },
  // {
  //   path: "/service-provider/bulk-upload-health-carer-onboard",
  //   component: lazy(() => import("../components/Bulk-Upload/HealthCarer/Import")),
  // },
  {
    path: "/service-provider/edit-health-carer",
    component: lazy(() => import("../pages/superAdmin/health-carer/edit")),
  },
  {
    path: "/service-provider/add-health-carer",
    component: lazy(() => import("../pages/superAdmin/health-carer/edit")),
  },
  {
    path: "/service-provider/on-boarding",
    component: lazy(() =>
      import("../pages/superAdmin/health-carer/on-boarding")
    ),
  },
  {
    path: "/service-provider/view-support-coordinator-details-old",
    component: lazy(() =>
      import("../pages/superAdmin/support-coordinator/view-details")
    ),
  },
  // ... Add other routes for Health Carer section ...

  {
    path: "/service-provider/health-carer-details",
    component: lazy(() => import("../pages/superAdmin/health-carer/details")),
  },
  {
    path: "/service-provider/view-health-carer-details-old",
    component: lazy(() =>
      import("../pages/superAdmin/health-carer/view-details")
    ),
  },
  // {
  //   path: "/service-provider/view-health-carer-details",
  //   component: lazy(() =>
  //     import("../components/emavailability/ViewDetailHC.js")
  //   ),
  // },
  {
    path: "/service-provider/view-health-carer-details",
    component: lazy(() =>
      import("../pages/superAdmin/health-carer/view-details-hc")
    ),
  },

  {
    path: "/service-provider/list-health-carer/interview-history",
    component: lazy(() =>
      import(
        "../pages/superAdmin/health-carer/scheduleInterviewHistory/History"
      )
    ),
  },
  // ... Add other routes for Health Carer Details and Interview History ...

  {
    path: "/service-provider/list-support-coordinator",
    component: lazy(() =>
      import("../pages/superAdmin/support-coordinator/list")
    ),
  },
  {
    path: "/service-provider/bulk-upload-supportcoordinator-onboard",
    component: lazy(() =>
      import("../components/Bulk-Upload/SupportCoordinator/Import")
    ),
  },
  {
    path: "/service-provider/edit-support-coordinator",
    component: lazy(() =>
      import("../pages/superAdmin/support-coordinator/edit")
    ),
  },
  {
    path: "/service-provider/add-support-coordinator",
    component: lazy(() =>
      import("../pages/superAdmin/support-coordinator/edit")
    ),
  },
  {
    path: "/service-provider/bulk-upload-client-onboard",
    component: lazy(() => import("../components/Bulk-Upload/Client/Import")),
  },
  {
    path: "/service-provider/bulk-upload-health-carer-onboard",
    component: lazy(() =>
      import("../components/Bulk-Upload/HealthCarer/Import")
    ),
  },
  {
    path: "/service-provider/bulk-upload-staff",
    component: lazy(() => import("../components/Bulk-Upload/Staff/Import")),
  },

  {
    path: "/service-provider/bulk-upload-service-provider-onboard",
    component: lazy(() =>
      import("../components/Bulk-Upload/ServiceProvider/Import")
    ),
  },

  {
    path: "/service-provider/list-payrun",
    component: lazy(() =>
      import("../pages/serviceProvider/Settings/payRun/payRun")
    ),
  },
  // ... Add other routes for Support Coordinator section ...

  {
    path: "/service-provider/list-view-schedule-meeting",
    component: lazy(() => import("../components/meeting-view/details")),
  },

  {
    path: "/service-provider/list-meeting-client",
    component: lazy(() =>
      import("../pages/superAdmin/schedule-meeting/client/list-tab")
    ),
  },

  {
    path: "/service-provider/list-calendar",
    component: lazy(() =>
      import("../pages/superAdmin/schedule-common-meeting/client/list-tab")
    ),
  },

  {
    path: "/service-provider/list-view-other-schedule-meeting",
    component: lazy(() => import("../components/meeting-view-other/details")),
  },
  {
    path: "/service-provider/schedule-other-meeting",
    component: lazy(() =>
      import("../pages/superAdmin/schedule-other-meeting/meeting")
    ),
  },
  {
    path: "/service-provider/edit-other-meeting",
    component: lazy(() =>
      import("../pages/superAdmin/schedule-other-meeting/meeting")
    ),
  },

  {
    path: "/service-provider/schedule-meeting",
    component: lazy(() =>
      import("../pages/superAdmin/schedule-meeting/meeting")
    ),
  },

  {
    path: "/service-provider/schedule-interview",
    component: lazy(() =>
      import(
        "../pages/superAdmin/schedule-interview/health-carer/schedule-interview"
      )
    ),
  },

  {
    path: "/service-provider/list-health-carer/interview-history",
    component: lazy(() =>
      import(
        "../pages/superAdmin/health-carer/scheduleInterviewHistory/History"
      )
    ),
  },

  {
    path: "/service-provider/list-interview-health-carer",
    component: lazy(() =>
      import("../pages/superAdmin/schedule-interview/health-carer/list-tab")
    ),
  },

  {
    path: "/service-provider/list-view-schedule-interview",
    component: lazy(() => import("../components/interview-view/details")),
  },

  {
    path: "/service-provider/list-secondary-users",
    component: lazy(() => import("../pages/superAdmin/users/list")),
  },

  {
    path: "/service-provider/edit-secondary-users",
    component: lazy(() => import("../pages/superAdmin/users/edit")),
  },
  {
    path: "/service-provider/add-secondary-users",
    component: lazy(() => import("../pages/superAdmin/users/edit")),
  },

  {
    path: "/service-provider/view-client-details",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/view-details-client")
    ),
  },

  {
    path: "/service-provider/view-support-coordinator-details",
    component: lazy(() =>
      import("../pages/superAdmin/support-coordinator/view-details-sc")
    ),
  },

  {
    path: "/service-provider/list-meeting-type",
    component: lazy(() => import("../pages/superAdmin/meeting-type/list")),
  },

  {
    path: "/service-provider/add-meeting-type",
    component: lazy(() => import("../pages/superAdmin/meeting-type/edit")),
  },

  {
    path: "/service-provider/edit-meeting-type",
    component: lazy(() => import("../pages/superAdmin/meeting-type/edit")),
  },
  {
    path: "/service-provider/list-menu-masters",
    component: lazy(() => import("../pages/superAdmin/menuMaster/list")),
  },

  {
    path: "/service-provider/smtp-setup",
    component: lazy(() =>
      import("../pages/serviceProvider/Settings/smtpSetup.js")
    ),
  },
  {
    path: "/service-provider/list-funding-source",
    component: lazy(() => import("../pages/superAdmin/Funding-Source/list")),
  },

  {
    path: "/service-provider/add-funding-source",
    component: lazy(() => import("../pages/superAdmin/Funding-Source/edit")),
  },

  {
    path: "/service-provider/edit-funding-source",
    component: lazy(() => import("../pages/superAdmin/Funding-Source/edit")),
  },

  {
    path: "/service-provider/list-category",
    component: lazy(() => import("../pages/superAdmin/client-category/list")),
  },
  // commenting it because it is not needed
  // {
  //   path: "/service-provider/add-category",
  //   component: lazy(() => import("../pages/superAdmin/client-category/edit")),
  // },

  {
    path: "/service-provider/edit-category",
    component: lazy(() => import("../pages/superAdmin/client-category/edit")),
  },

  {
    path: "/service-provider/list-appointment-type",
    component: lazy(() => import("../pages/superAdmin/Appointment-type/list")),
  },

  {
    path: "/service-provider/add-appointment-type",
    component: lazy(() => import("../pages/superAdmin/Appointment-type/edit")),
  },

  {
    path: "/service-provider/edit-appointment-type",
    component: lazy(() => import("../pages/superAdmin/Appointment-type/edit")),
  },

  {
    path: "/service-provider/list-groups",
    component: lazy(() => import("../pages/superAdmin/groups/list")),
  },

  {
    path: "/service-provider/add-groups",
    component: lazy(() => import("../pages/superAdmin/groups/edit")),
  },

  {
    path: "/service-provider/edit-groups",
    component: lazy(() => import("../pages/superAdmin/groups/edit")),
  },

  {
    path: "/service-provider/list-hundred-points",
    component: lazy(() => import("../pages/superAdmin/hundred-points/list")),
  },

  {
    path: "/service-provider/edit-hundred-points",
    component: lazy(() => import("../pages/superAdmin/hundred-points/edit")),
  },

  {
    path: "/service-provider/add-hundred-points",
    component: lazy(() => import("../pages/superAdmin/hundred-points/edit")),
  },

  {
    path: "/service-provider/list-support-group",
    component: lazy(() => import("../pages/superAdmin/support-group/list")),
  },
  {
    path: "/service-provider/edit-support-group",
    component: lazy(() => import("../pages/superAdmin/support-group/edit")),
  },
  {
    path: "/service-provider/add-support-group",
    component: lazy(() => import("../pages/superAdmin/support-group/edit")),
  },

  {
    path: "/service-provider/list-packages",
    component: lazy(() => import("../pages/superAdmin/packages/list")),
  },
  {
    path: "/service-provider/edit-packages",
    component: lazy(() => import("../pages/superAdmin/packages/edit")),
  },
  {
    path: "/service-provider/add-packages",
    component: lazy(() => import("../pages/superAdmin/packages/edit")),
  },

  {
    path: "/service-provider/list-menu-masters",
    component: lazy(() => import("../pages/superAdmin/menuMaster/list")),
  },
  {
    path: "/service-provider/smtp-setup",
    component: lazy(() =>
      import("../pages/serviceProvider/Settings/smtpSetup.js")
    ),
  },
  {
    path: "/service-provider/edit-menu-masters",
    component: lazy(() => import("../pages/superAdmin/menuMaster/edit")),
  },
  {
    path: "/service-provider/add-menu-masters",
    component: lazy(() => import("../pages/superAdmin/menuMaster/edit")),
  },

  {
    path: "/service-provider/list-package-menu",
    component: lazy(() => import("../pages/superAdmin/packageMenu/list")),
  },

  {
    path: "/service-provider/add-package-menu",
    component: lazy(() => import("../pages/superAdmin/packageMenu/edit")),
  },
  {
    path: "/service-provider/edit-package-menu",
    component: lazy(() => import("../pages/superAdmin/packageMenu/edit")),
  },

  {
    path: "/service-provider/list-zone",
    component: lazy(() => import("../pages/superAdmin/zone/list")),
  },
  {
    path: "/service-provider/edit-zone",
    component: lazy(() => import("../pages/superAdmin/zone/edit")),
  },
  {
    path: "/service-provider/add-zone",
    component: lazy(() => import("../pages/superAdmin/zone/edit")),
  },

  {
    path: "/service-provider/list-agencies",
    component: lazy(() => import("../pages/superAdmin/agencies/list")),
  },
  {
    path: "/service-provider/edit-agencies",
    component: lazy(() => import("../pages/superAdmin/agencies/edit")),
  },
  {
    path: "/service-provider/add-agencies",
    component: lazy(() => import("../pages/superAdmin/agencies/edit")),
  },

  {
    path: "/service-provider/list-shifttype",
    component: lazy(() => import("../pages/superAdmin/shift-type/list")),
  },
  {
    path: "/service-provider/edit-shifttype",
    component: lazy(() => import("../pages/superAdmin/shift-type/edit")),
  },
  {
    path: "/service-provider/add-shifttype",
    component: lazy(() => import("../pages/superAdmin/shift-type/edit")),
  },

  {
    path: "/service-provider/list-skills",
    component: lazy(() => import("../pages/superAdmin/skill/list")),
  },
  {
    path: "/service-provider/edit-skills",
    component: lazy(() => import("../pages/superAdmin/skill/edit")),
  },
  {
    path: "/service-provider/add-skills",
    component: lazy(() => import("../pages/superAdmin/skill/edit")),
  },

  {
    path: "/service-provider/list-levels",
    component: lazy(() => import("../pages/superAdmin/level/list")),
  },
  {
    path: "/service-provider/edit-levels",
    component: lazy(() => import("../pages/superAdmin/level/edit")),
  },
  {
    path: "/service-provider/add-levels",
    component: lazy(() => import("../pages/superAdmin/level/edit")),
  },

  {
    path: "/service-provider/list-quarters",
    component: lazy(() => import("../pages/superAdmin/quarter/list")),
  },
  {
    path: "/service-provider/edit-quarters",
    component: lazy(() => import("../pages/superAdmin/quarter/edit")),
  },
  {
    path: "/service-provider/add-quarters",
    component: lazy(() => import("../pages/superAdmin/quarter/edit")),
  },

  {
    path: "/service-provider/list-job-titles",
    component: lazy(() => import("../pages/superAdmin/job-title/list")),
  },
  {
    path: "/service-provider/edit-job-titles",
    component: lazy(() => import("../pages/superAdmin/job-title/edit")),
  },
  {
    path: "/service-provider/add-job-titles",
    component: lazy(() => import("../pages/superAdmin/job-title/edit")),
  },

  {
    path: "/service-provider/list-job-types",
    component: lazy(() => import("../pages/superAdmin/job-type/list")),
  },
  {
    path: "/service-provider/edit-job-types",
    component: lazy(() => import("../pages/superAdmin/job-type/edit")),
  },
  {
    path: "/service-provider/add-job-types",
    component: lazy(() => import("../pages/superAdmin/job-type/edit")),
  },

  {
    path: "/service-provider/add-state",
    component: lazy(() => import("../pages/superAdmin/state/edit")),
  },
  {
    path: "/service-provider/edit-state",
    component: lazy(() => import("../pages/superAdmin/state/edit")),
  },
  {
    path: "/service-provider/list-state",
    component: lazy(() => import("../pages/superAdmin/state/list")),
  },

  {
    path: "/service-provider/add-risk-assessment",
    component: lazy(() => import("../pages/superAdmin/risk-assessment/edit")),
  },
  {
    path: "/service-provider/edit-risk-assessment",
    component: lazy(() => import("../pages/superAdmin/risk-assessment/edit")),
  },

  {
    path: "/service-provider/list-risk-assessment",
    component: lazy(() => import("../pages/superAdmin/risk-assessment/list")),
  },
  {
    path: "/service-provider/add-agreement",
    component: lazy(() =>
      import("../pages/superAdmin/appointment/appointment-agreement/edit")
    ),
  },
  {
    path: "/service-provider/edit-agreement",
    component: lazy(() =>
      import("../pages/superAdmin/appointment/appointment-agreement/edit")
    ),
  },
  {
    path: "/service-provider/list-agreement",
    component: lazy(() =>
      import("../pages/superAdmin/appointment/appointment-agreement/list")
    ),
  },
  {
    path: "/service-provider/list-severity",
    component: lazy(() => import("../pages/superAdmin/severity/list")),
  },
  {
    path: "/service-provider/add-severity",
    component: lazy(() => import("../pages/superAdmin/severity/edit")),
  },
  {
    path: "/service-provider/edit-severity",
    component: lazy(() => import("../pages/superAdmin/severity/edit")),
  },

  {
    path: "/service-provider/add-user-role",
    component: lazy(() => import("../pages/superAdmin/user-role/edit")),
  },
  {
    path: "/service-provider/edit-user-role",
    component: lazy(() => import("../pages/superAdmin/user-role/edit")),
  },
  {
    path: "/service-provider/list-user-role",
    component: lazy(() => import("../pages/superAdmin/user-role/list")),
  },

  {
    path: "/service-provider/add-file",
    component: lazy(() => import("../pages/superAdmin/files/edit")),
  },
  {
    path: "/service-provider/edit-file",
    component: lazy(() => import("../pages/superAdmin/files/edit")),
  },
  {
    path: "/service-provider/list-file",
    component: lazy(() => import("../pages/superAdmin/files/list")),
  },

  {
    path: "/service-provider/edit-folder",
    component: lazy(() => import("../pages/superAdmin/Folder/edit")),
  },
  {
    path: "/service-provider/add-folder",
    component: lazy(() => import("../pages/superAdmin/Folder/edit")),
  },

  {
    path: "/service-provider/list-folder",
    component: lazy(() => import("../pages/superAdmin/Folder/list")),
  },

  {
    path: "/service-provider/view-folder",
    component: lazy(() => import("../pages/superAdmin/Folder/view")),
  },

  {
    path: "/service-provider/list-progress-objective-masters",
    component: lazy(() => import("../pages/superAdmin/progressObjective/list")),
  },
  {
    path: "/service-provider/add-progress-objective-masters",
    component: lazy(() => import("../pages/superAdmin/progressObjective/edit")),
  },
  {
    path: "/service-provider/edit-progress-objective-masters",
    component: lazy(() => import("../pages/superAdmin/progressObjective/edit")),
  },

  {
    path: "/service-provider/list-cancellation-reason",
    component: lazy(() =>
      import("../pages/superAdmin/cancellationReason/list")
    ),
  },
  {
    path: "/service-provider/edit-cancellation-reason",
    component: lazy(() =>
      import("../pages/superAdmin/cancellationReason/edit")
    ),
  },
  {
    path: "/service-provider/add-cancellation-reason",
    component: lazy(() =>
      import("../pages/superAdmin/cancellationReason/edit")
    ),
  },

  {
    path: "/service-provider/list-holiday",
    component: lazy(() => import("../pages/superAdmin/public-holiday/list")),
  },

  {
    path: "/service-provider/add-holiday",
    component: lazy(() => import("../pages/superAdmin/public-holiday/edit")),
  },
  {
    path: "/service-provider/edit-holiday",
    component: lazy(() => import("../pages/superAdmin/public-holiday/edit")),
  },

  {
    path: "/service-provider/list-medicine-frequency",
    component: lazy(() => import("../pages/superAdmin/medicineFrequency/list")),
  },
  {
    path: "/service-provider/edit-medicine-frequency",
    component: lazy(() => import("../pages/superAdmin/medicineFrequency/edit")),
  },
  {
    path: "/service-provider/add-medicine-frequency",
    component: lazy(() => import("../pages/superAdmin/medicineFrequency/edit")),
  },

  {
    path: "/service-provider/list-health-issue",
    component: lazy(() => import("../pages/superAdmin/healthIssue/list")),
  },
  {
    path: "/service-provider/edit-health-issue",
    component: lazy(() => import("../pages/superAdmin/healthIssue/edit")),
  },
  {
    path: "/service-provider/add-health-issue",
    component: lazy(() => import("../pages/superAdmin/healthIssue/edit")),
  },

  {
    path: "/service-provider/list-main-type-symptoms",
    component: lazy(() => import("../pages/superAdmin/mainTypeSymptoms/list")),
  },
  {
    path: "/service-provider/add-main-type-symptoms",
    component: lazy(() => import("../pages/superAdmin/mainTypeSymptoms/edit")),
  },
  {
    path: "/service-provider/edit-main-type-symptoms",
    component: lazy(() => import("../pages/superAdmin/mainTypeSymptoms/edit")),
  },

  {
    path: "/service-provider/list-sub-type-symptoms",
    component: lazy(() => import("../pages/superAdmin/subTypeSymptoms/list")),
  },
  {
    path: "/service-provider/add-sub-type-symptoms",
    component: lazy(() => import("../pages/superAdmin/subTypeSymptoms/edit")),
  },
  {
    path: "/service-provider/edit-sub-type-symptoms",
    component: lazy(() => import("../pages/superAdmin/subTypeSymptoms/edit")),
  },

  {
    path: "/service-provider/list-dose-form",
    component: lazy(() => import("../pages/superAdmin/doseForm/list")),
  },
  {
    path: "/service-provider/edit-dose-form",
    component: lazy(() => import("../pages/superAdmin/doseForm/edit")),
  },
  {
    path: "/service-provider/add-dose-form",
    component: lazy(() => import("../pages/superAdmin/doseForm/edit")),
  },

  {
    path: "/service-provider/list-observationName",
    component: lazy(() => import("../pages/superAdmin/observationName/list")),
  },
  {
    path: "/service-provider/edit-observationName",
    component: lazy(() => import("../pages/superAdmin/observationName/edit")),
  },
  {
    path: "/service-provider/add-observationName",
    component: lazy(() => import("../pages/superAdmin/observationName/edit")),
  },

  {
    path: "/service-provider/list-taxations",
    component: lazy(() => import("../pages/superAdmin/taxation/list")),
  },
  {
    path: "/service-provider/edit-taxations",
    component: lazy(() => import("../pages/superAdmin/taxation/edit")),
  },
  {
    path: "/service-provider/add-taxations",
    component: lazy(() => import("../pages/superAdmin/taxation/edit")),
  },

  {
    path: "/service-provider/list-payschedule",
    component: lazy(() => import("../pages/superAdmin/paySchedule/list")),
  },

  {
    path: "/service-provider/add-payschedule",
    component: lazy(() => import("../pages/superAdmin/paySchedule/edit")),
  },

  {
    path: "/service-provider/edit-payschedule",
    component: lazy(() => import("../pages/superAdmin/paySchedule/edit")),
  },

  {
    path: "/service-provider/edit-payrunstatus",
    component: lazy(() => import("../pages/superAdmin/payrunStatus/edit")),
  },
  {
    path: "/service-provider/add-payrunstatus",
    component: lazy(() => import("../pages/superAdmin/payrunStatus/edit")),
  },
  {
    path: "/service-provider/list-payrunstatus",
    component: lazy(() => import("../pages/superAdmin/payrunStatus/list")),
  },

  {
    path: "/service-provider/list-super-annuation",
    component: lazy(() => import("../pages/superAdmin/superFunds/list")),
  },

  {
    path: "/service-provider/add-super-annuation",
    component: lazy(() => import("../pages/superAdmin/superFunds/edit")),
  },

  {
    path: "/service-provider/edit-super-annuation",
    component: lazy(() => import("../pages/superAdmin/superFunds/edit")),
  },

  {
    path: "/service-provider/list-region",
    component: lazy(() => import("../pages/superAdmin/Region/list")),
  },

  {
    path: "/service-provider/list-availability-status",
    component: lazy(() => import("../pages/superAdmin/availabilitySatus/list")),
  },
  {
    path: "/service-provider/edit-availability-status",
    component: lazy(() => import("../pages/superAdmin/availabilitySatus/edit")),
  },
  {
    path: "/service-provider/add-availability-status",
    component: lazy(() => import("../pages/superAdmin/availabilitySatus/edit")),
  },

  {
    path: "/service-provider/support-duration-add",
    component: lazy(() => import("../pages/superAdmin/supportDuration/edit")),
  },
  {
    path: "/service-provider/support-duration-edit",
    component: lazy(() => import("../pages/superAdmin/supportDuration/edit")),
  },
  {
    path: "/service-provider/support-duration-list",
    component: lazy(() => import("../pages/superAdmin/supportDuration/list")),
  },

  {
    path: "/service-provider/support-hours-add",
    component: lazy(() => import("../pages/superAdmin/support-Hours/edit")),
  },
  {
    path: "/service-provider/support-hours-edit",
    component: lazy(() => import("../pages/superAdmin/support-Hours/edit")),
  },
  {
    path: "/service-provider/support-hours-list",
    component: lazy(() => import("../pages/superAdmin/support-Hours/list")),
  },

  {
    path: "/service-provider/add-routine-icons",
    component: lazy(() => import("../pages/superAdmin/routine-icon")),
  },

  {
    path: "/service-provider/set-access-role",
    component: lazy(() => import("../pages/superAdmin/access-role/list")),
  },

  {
    path: "/service-provider/add-access-role",
    component: lazy(() => import("../pages/superAdmin/access-role/add.js")),
  },

  {
    path: "/service-provider/email",
    component: lazy(() => import("../components/email/list")),
  },
  {
    path: "/service-provider/email/chatopenai",
    component: lazy(() => import("../components/email/chatAi")),
  },

  {
    path: "/service-provider/view-email",
    component: lazy(() => import("../components/email/view")),
  },
  {
    path: "/service-provider/edit-email",
    component: lazy(() => import("../components/email/newEmail/edit")),
  },

  {
    path: "/service-provider/list-superannuation-setting",
    component: lazy(() =>
      import("../pages/serviceProvider/Settings/superAnnuation/list")
    ),
  },

  {
    path: "/service-provider/shift-notes-list",
    component: lazy(() =>
      import("../pages/superAdmin/shift-notes/shiftNoteclientlist.js")
    ),
  },
  {
    path: "/service-provider/shift-notes-print",
    component: lazy(() => import("../pages/superAdmin/shiftNotes/print.js")),
  },
  {
    path: "/service-provider/list-task-notes",
    component: lazy(() =>
      import("../pages/healthCarer/TaskNotes/listTasknote")
    ),
  },
  {
    path: "/service-provider/list-delayed-appointment",
    component: lazy(() =>
      import("../components/calendar-view/Table/DelayedAppointment")
    ),
  },
  {
    path: "/service-provider/add-superannuation-setting",
    component: lazy(() =>
      import("../pages/serviceProvider/Settings/superAnnuation/edit")
    ),
  },

  {
    path: "/service-provider/edit-superannuation-setting",
    component: lazy(() =>
      import("../pages/serviceProvider/Settings/superAnnuation/edit")
    ),
  },

  {
    path: "/service-provider/health-carer/onboard",
    component: lazy(() => import("../pages/healthCarer/Account-Setup")),
  },

  {
    path: "/service-provider/support-coordinator/onboard",
    component: lazy(() => import("../pages/supportCoordinator/Account-Setup")),
  },

  {
    path: "/service-provider/service-provider/onboard",
    component: lazy(() => import("../pages/serviceProvider/Account-Setup")),
  },

  {
    path: "/service-provider/client/onboard",
    component: lazy(() => import("../pages/client/Account-Setup")),
  },
  {
    path: "/service-provider/list-report",
    component: lazy(() => import("../pages/superAdmin/Reports/list")),
  },

  {
    path: "/service-provider/add-report",
    component: lazy(() => import("../pages/superAdmin/Reports/edit")),
  },
  {
    path: "/service-provider/edit-report",
    component: lazy(() => import("../pages/superAdmin/Reports/edit")),
  },
  {
    path: "/service-provider/reports",
    component: lazy(() => import("../pages/superAdmin/Reports/report")),
  },

  {
    path: "/service-provider/medical-history-dev",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/Medical-History")
    ),
  },
  {
    path: "/service-provider/medication-chart-dev",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/Medication-chart")
    ),
  },
  {
    path: "/service-provider/observation-chart-dev",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/Observation-chart")
    ),
  },
  {
    path: "/service-provider/quick-task",
    component: lazy(() =>
      import("../pages/healthCarer/appointment/dashboard/QuickTask")
    ),
  },
  {
    path: "/service-provider/prn-chart-dev",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/Medication-chart")
    ),
  },
  {
    path: "/service-provider/view-shift-notes",
    component: lazy(() => import("../pages/superAdmin/shiftNotes/view")),
  },
  {
    path: "/service-provider/pdf-shift-task-notes",
    component: lazy(() => import("../pages/superAdmin/shiftNotes/pdf")),
  },
  {
    path: "/service-provider/list-progressnote",
    component: lazy(() => import("../pages/superAdmin/progressNote/list")),
  },
  {
    path: "/service-provider/view-progressnote",
    component: lazy(() => import("../pages/superAdmin/progressNote/view")),
  },
  {
    path: "/service-provider/edit-service-item",
    component: lazy(() => import("../pages/superAdmin/service-item/edit")),
  },
  {
    path: "/service-provider/appointment-connection-list",
    component: lazy(() =>
      import("../components/Explore-Market/Appointment-request")
    ),
  },
  {
    path: "/service-provider/list-health-carer-offboarding",
    component: lazy(() =>
      import("../pages/superAdmin/off-boarding/health-carer/list")
    ),
  },
  {
    path: "/service-provider/list-employee-offboarding",
    component: lazy(() =>
      import("../pages/superAdmin/off-boarding/employee/list")
    ),
  },
  {
    path: "/service-provider/view-funds-management",
    component: lazy(() =>
      import("../pages/superAdmin/FundManagement/View-Fund-Management")
    ),
  },
  {
    path: "/service-provider/print-funds-management",
    component: lazy(() => import("../pages/superAdmin/FundManagement/print")),
  },
  {
    path: "/service-provider/risk-assesment",
    component: lazy(() =>
      import("../pages/Stakeholder/Riskassesment/Riskassesment")
    ),
  },
  {
    path: "/service-provider/list-conflict",
    component: lazy(() => import("../pages/superAdmin/conflictClient/list")),
  },

  // {
  //   path: "/service-provider/list-emergency-contact",
  //   component: lazy(() =>
  //     import("../pages/healthCarer/Emergency-Contact/list")
  //   ),

  // },
  {
    path: "/service-provider/list-emergency-contact",
    component: lazy(() => import("../pages/superAdmin/emergency-contact/list")),
  },
  //Wrong routes used user and usertype DD was not in the below route
  // {
  //   path: "/service-provider/add-emergency-contact",
  //   component: lazy(() =>
  //     import("../pages/healthCarer/Emergency-Contact/edit")
  //   ),
  // },
  // {
  //   path: "/service-provider/edit-emergency-contact",
  //   component: lazy(() =>
  //     import("../pages/healthCarer/Emergency-Contact/edit")
  //   ),
  // },

  {
    path: "/service-provider/edit-emergency-contact",
    component: lazy(() => import("../pages/superAdmin/emergency-contact/edit")),
  },

  {
    path: "/service-provider/add-emergency-contact",
    component: lazy(() => import("../pages/superAdmin/emergency-contact/edit")),
  },
  {
    path: "/service-provider/view-backup-options",
    component: lazy(() => import("../components/dataBackup/list")),
  },

  {
    path: "/service-provider/list-employee-birthdays",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/employee/employeeBirthday")
    ),
  },

  {
    path: "/service-provider/list-employee-details",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/employee/employeeDetails")
    ),
  },
  {
    path: "/service-provider/list-employee-emergency-contacts",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/employee/emergencyContact")
    ),
  },

  {
    path: "/service-provider/list-unpaid-employee",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/employee/unpaidEmployee")
    ),
  },
  {
    path: "/service-provider/list-jobmaker-employee-notice",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/atoReporting/jobmakerEmployeeNotice")
    ),
  },

  {
    path: "/service-provider/list-jobkeeper-employee-nominations",
    component: lazy(() =>
      import(
        "../pages/superAdmin/Reports/atoReporting/jobkeeperEmployeeNomination"
      )
    ),
  },
  {
    path: "/service-provider/list-tax-file-declaration",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/atoReporting/taxFileDeclaration")
    ),
  },
  {
    path: "/service-provider/list-attendance",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/timeAndAttendance/attendanceReport")
    ),
  },
  {
    path: "/service-provider/list-employee-satisfaction",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/employee/employeeSatisfaction")
    ),
  },
  {
    path: "/service-provider/list-single-touch-payroll",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/atoReporting/singleTouchPayroll")
    ),
  },
  {
    path: "/service-provider/employee-leave-history",
    component: lazy(() => import("../pages/superAdmin/Reports/leave-history")),
  },
  {
    path: "/service-provider/list-employee-details-audit",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/employee/employeeDetailsAudit")
    ),
  },
  {
    path: "/service-provider/list-employee-jokeeper-eligibility",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/employee/jobKeeperEligibility")
    ),
  },
  {
    path: "/service-provider/settings-xero-myob-list",
    component: lazy(() => import("../pages/superAdmin/xero-myob/list")),
  },

  {
    path: "/service-provider/financial-setting-list",
    component: lazy(() =>
      import(
        "../pages/serviceProvider/Settings/financialSettings/financialSettings"
      )
    ),
  },

  // leave management new changes

  {
    path: "/service-provider/list-leave-categories",
    component: lazy(() => import("../pages/superAdmin/leave-category/list")),
  },

  {
    path: "/service-provider/add-leave-categories",
    component: lazy(() => import("../pages/superAdmin/leave-category/edit")),
  },

  {
    path: "/service-provider/edit-leave-categories",
    component: lazy(() => import("../pages/superAdmin/leave-category/edit")),
  },

  {
    path: "/service-provider/list-leave-management-old",
    component: lazy(() => import("../pages/superAdmin/leaveManagement/list")),
  },

  {
    path: "/service-provider/list-leave-management",
    component: lazy(() =>
      import("../pages/superAdmin/leaveManagement/list-tab")
    ),
  },

  {
    path: "/service-provider/add-leave",
    component: lazy(() => import("../pages/superAdmin/leaveManagement/edit")),
  },

  {
    path: "/service-provider/edit-leave",
    component: lazy(() => import("../pages/superAdmin/leaveManagement/edit")),
  },

  {
    path: "/service-provider/view-leave",
    component: lazy(() => import("../pages/superAdmin/leaveManagement/view")),
  },

  {
    path: "/service-provider/list-set-leave",
    component: lazy(() => import("../pages/superAdmin/setLeave/list")),
  },

  {
    path: "/service-provider/add-set-leave",
    component: lazy(() => import("../pages/superAdmin/setLeave/edit")),
  },

  {
    path: "/service-provider/edit-set-leave",
    component: lazy(() => import("../pages/superAdmin/setLeave/edit")),
  },
  {
    path: "/service-provider/edit-incident",
    component: lazy(() => import("../pages/superAdmin/incident/edit")),
  },
  {
    path: "/service-provider/list-incident",
    component: lazy(() => import("../pages/superAdmin/incident/list")),
  },
  {
    path: "/service-provider/view-incident",
    component: lazy(() => import("../pages/superAdmin/incident/view")),
  },
  {
    path: "/service-provider/list-severity",
    component: lazy(() => import("../pages/superAdmin/severity/list")),
  },
  {
    path: "/service-provider/closure-incident",
    component: lazy(() =>
      import("../pages/Supervisor/incident/incidentClosure")
    ),
  },
  {
    path: "/service-provider/add-incident",
    component: lazy(() => import("../pages/superAdmin/incident/edit")),
  },
  {
    path: "/service-provider/pdf-incident",
    component: lazy(() => import("../pages/healthCarer/incident/pdf")),
  },

  {
    path: "/service-provider/edit-task-evaluation",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/add-task/edit")
    ),
  },
  {
    path: "/service-provider/add-task-evaluation",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/add-task/edit")
    ),
  },
  {
    path: "/service-provider/add-note-evaluation",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/add-note/edit")
    ),
  },
  {
    path: "/service-provider/edit-note-evaluation",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/add-note/edit")
    ),
  },
  {
    path: "/service-provider/list-asset",
    component: lazy(() =>
      import("../pages/superAdmin/assetManagement/list.js")
    ),
  },
  {
    path: "/service-provider/add-asset",
    component: lazy(() => import("../pages/superAdmin/assetManagement/edit")),
  },
  {
    path: "/service-provider/edit-asset",
    component: lazy(() => import("../pages/superAdmin/assetManagement/edit")),
  },

  {
    path: "/service-provider/sil-sda-location-add",
    component: lazy(() => import("../pages/superAdmin/sil-sda-location/edit")),
  },
  {
    path: "/service-provider/sil-sda-location-edit",
    component: lazy(() => import("../pages/superAdmin/sil-sda-location/edit")),
  },
  {
    path: "/service-provider/sil-sda-location-list",
    component: lazy(() => import("../pages/superAdmin/sil-sda-location/list")),
  },
  {
    path: "/service-provider/list-homefeature",
    component: lazy(() => import("../pages/superAdmin/HomeFeature/list")),
  },
  {
    path: "/service-provider/add-homefeature",
    component: lazy(() => import("../pages/superAdmin/HomeFeature/edit")),
  },
  {
    path: "/service-provider/edit-homefeature",
    component: lazy(() => import("../pages/superAdmin/HomeFeature/edit")),
  },
  {
    path: "/service-provider/edit-compliance",
    component: lazy(() => import("../pages/superAdmin/compliance/edit")),
  },
  {
    path: "/service-provider/add-compliance",
    component: lazy(() => import("../pages/superAdmin/compliance/edit")),
  },
  {
    path: "/service-provider/list-compliance",
    component: lazy(() => import("../pages/superAdmin/compliance/list")),
  },
  {
    path: "/service-provider/chat-history",
    component: lazy(() => import("../pages/superAdmin/chathistory/list.js")),
  },
  {
    path: "/service-provider/list-appointment-request",
    component: lazy(() =>
      import("../pages/superAdmin/Request-Appointment/Connection-request")
    ),
  },
  {
    path: "/service-provider/invoice-pdf",
    component: lazy(() => import("../components/invoice/InvoicePdf")),
  },
  {
    path: "/service-provider/invoice-pdf-sil",
    component: lazy(() => import("../components/invoice/invoiceSil.js")),
  },
  {
    path: "/service-provider/list-live-roaster-appointment-request",
    component: lazy(() =>
      import("../pages/superAdmin/requestLiveRoasterAppointment/list.js")
    ),
  },
  {
    path: "/service-provider/view-appointment-request",
    component: lazy(() =>
      import("../pages/superAdmin/Request-Appointment/View-request")
    ),
  },
  {
    path: "/service-provider/view-request",
    component: lazy(() =>
      import("../pages/superAdmin/Request-Document/View-request")
    ),
  },
  {
    path: "/service-provider/list-template-variable-master",
    component: lazy(() =>
      import("../pages/superAdmin/template-master/variable/list")
    ),
  },

  {
    path: "/service-provider/edit-template-variable-master",
    component: lazy(() =>
      import("../pages/superAdmin/template-master/variable/edit")
    ),
  },
  {
    path: "/service-provider/add-template-variable-master",
    component: lazy(() =>
      import("../pages/superAdmin/template-master/variable/edit")
    ),
  },
  {
    path: "/service-provider/list-template-master",
    component: lazy(() => import("../pages/superAdmin/template-master/list")),
  },
  {
    path: "/service-provider/list-template",
    component: lazy(() =>
      import("../components/email/templateMaster/list-cards")
    ),
  },
  {
    path: "/service-provider/add-template",
    component: lazy(() => import("../components/email/templateMaster/edit")),
  },
  {
    path: "/service-provider/edit-template",
    component: lazy(() => import("../components/email/templateMaster/edit")),
  },
  {
    path: "/service-provider/edit-template-master",
    component: lazy(() => import("../pages/superAdmin/template-master/edit")),
  },
  {
    path: "/service-provider/add-template-master",
    component: lazy(() => import("../pages/superAdmin/template-master/edit")),
  },
  {
    path: "/service-provider/offer-letter",
    component: lazy(() =>
      import("../pages/superAdmin/contentManagement/offer-letter/edit")
    ),
  },
  {
    path: "/service-provider/list-signed-document",
    component: lazy(() =>
      import("../components/SignedDocument/ListSignedDocument")
    ),
  },
  // {
  //   path: "/service-provider/time-sheet",
  //   component: lazy(() => import("../components/timesheet/Timesheet")),
  // },
  {
    path: "/service-provider/view-signed-document",
    component: lazy(() =>
      import("../components/SignedDocument/ViewSignedDocument")
    ),
  },
  {
    path: "/service-provider/home",
    component: lazy(() =>
      import("../pages/serviceProvider/Dashboard/Sp-Dashboard")
    ),
  },
  {
    path: "/service-provider/list-service-provider",
    component: lazy(() => import("../pages/superAdmin/service-provider/list")),
  },
  {
    path: "/service-provider/service-provider-details",
    component: lazy(() =>
      import("../pages/superAdmin/service-provider/view-details-sp")
    ),
  },
  {
    path: "/service-provider/add-service-provider",
    component: lazy(() => import("../pages/superAdmin/service-provider/edit")),
  },
  {
    path: "/support-coordinator/edit-service-provider",
    component: lazy(() => import("../pages/superAdmin/service-provider/edit")),
  },
  {
    path: "/service-provider/recieved-request-detail",
    component: lazy(() =>
      import("../pages/client/RecievedRequestView/RecievedRequestDetails")
    ),
  },
  {
    path: "/service-provider/invoice",
    //component: lazy(() => import("../components/invoice/Invoice")),
    component: lazy(() => import("../components/invoice/CommonInvoice")),
  },
  {
    path: "/service-provider/payslips",
    //component: lazy(() => import("../components/payslips-appointment/Invoice")),
    component: lazy(() =>
      import("../components/payslips-appointment/CommonPaySlip")
    ),
  },
  {
    path: "/service-provider/payslips-pdf",
    component: lazy(() =>
      import("../components/payslips-appointment/InvoicePdf")
    ),
  },
  {
    path: "/service-provider/select-client",
    component: lazy(() => import("../components/goals/ClientSelect")),
  },
  {
    path: "/service-provider/add-goal",
    component: lazy(() => import("../components/goals/edit")),
  },

  {
    path: "/service-provider/edit-goal",
    component: lazy(() => import("../components/goals/edit")),
  },

  {
    path: "/service-provider/list-goal-as-per-client",
    component: lazy(() => import("../components/goals/list")),
  },
  {
    path: "/service-provider/pdf-service-task-notes",
    component: lazy(() =>
      import("../pages/superAdmin/serviceTaskNotes/serviceTaskpdf.js")
    ),
  },

  {
    path: "/service-provider/add-goal-routine",
    component: lazy(() => import("../components/goals/addRoutineTask/edit")),
  },

  {
    path: "/service-provider/view-task",
    component: lazy(() => import("../components/goals/view")),
  },
  {
    path: "/service-provider/export-pdf",
    component: lazy(() => import("../components/goals/pdf")),
  },
  {
    path: "/service-provider/export-pdf-with-image",
    component: lazy(() => import("../components/goals/pdfWithImage")),
  },

  {
    path: "/service-provider/edit-goal-routine",
    component: lazy(() => import("../components/goals/addRoutineTask/edit")),
  },
  {
    path: "/service-provider/bar-chart-goal",
    component: lazy(() => import("../components/goals/barChart")),
  },
  {
    path: "/service-provider/list-plan-manager",
    component: lazy(() => import("../pages/client/plamManager/list")),
  },
  {
    path: "/service-provider/list-plan-manager",
    component: lazy(() => import("../pages/client/plamManager/list")),
  },
  {
    path: "/service-provider/list-service-task",
    component: lazy(() => import("../pages/superAdmin/service-task/list")),
  },
  {
    path: "/service-provider/add-service-task",
    component: lazy(() => import("../pages/superAdmin/service-task/edit")),
  },
  {
    path: "/service-provider/edit-service-task",
    component: lazy(() => import("../pages/superAdmin/service-task/edit")),
  },
  {
    path: "/service-provider/view-service-task",
    component: lazy(() => import("../pages/superAdmin/service-task/view")),
  },
  {
    path: "/service-provider/view-blood-observation",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/Viewtab/Observation/Blood/view")
    ),
  },
  {
    path: "/service-provider/view-bowel-observation",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/Viewtab/Observation/Bowel/view")
    ),
  },
  {
    path: "/service-provider/view-food-observation",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/Viewtab/Observation/Food/view")
    ),
  },
  {
    path: "/service-provider/view-personalcare-observation",
    component: lazy(() =>
      import(
        "../pages/superAdmin/client-list/Viewtab/Observation/PersonalCare/view"
      )
    ),
  },
  {
    path: "/service-provider/view-seizure-observation",
    component: lazy(() =>
      import(
        "../pages/superAdmin/Medication-management-dev/Observation/Seizure/view"
      )
    ),
  },
  {
    path: "/service-provider/view-weight-observation",
    component: lazy(() =>
      import(
        "../pages/superAdmin/Medication-management-dev/Observation/weight/view"
      )
    ),
  },

  {
    path: "/service-provider/list-service-task-notes",
    component: lazy(() => import("../pages/superAdmin/serviceTaskNotes/list")),
  },
  {
    path: "/service-provider/list-service-item",
    component: lazy(() => import("../pages/superAdmin/service-item/list")),
  },
  {
    path: "/service-provider/add-service-item",
    component: lazy(() => import("../pages/superAdmin/service-item/edit")),
  },
  {
    path: "/service-provider/list-medical-history",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/listMedicalHistory")
    ),
  },
  {
    path: "/service-provider/list-medication-chart",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/listMedicalHistory")
    ),
  },
  {
    path: "/service-provider/list-observation",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/listMedicalHistory")
    ),
  },
  {
    path: "/service-provider/list-prn-chart",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/listMedicalHistory")
    ),
  },
  {
    path: "/service-provider/list-complaint",
    component: lazy(() => import("../pages/superAdmin/complaintClient/list")),
  },
  {
    path: "/service-provider/add-complaint",
    component: lazy(() => import("../pages/superAdmin/complaintClient/edit")),
  },
  {
    path: "/service-provider/edit-complaint",
    component: lazy(() => import("../pages/superAdmin/complaintClient/edit")),
  },
  {
    path: "/service-provider/service-task-payslips",
    component: lazy(() =>
      import("../components/payslips-service-task/Invoice")
    ),
  },
  {
    path: "/service-provider/service-task-payslip-pdf",
    component: lazy(() =>
      import("../components/payslips-service-task/InvoicePdf")
    ),
  },
  {
    path: "/service-provider/add-progressnote",
    component: lazy(() => import("../pages/superAdmin/progressNote/edit")),
  },
  {
    path: "/service-provider/list-progressnote",
    component: lazy(() => import("../pages/superAdmin/progressNote/list")),
  },
  {
    path: "/service-provider/print-progress-notes",
    component: lazy(() => import("../pages/superAdmin/progressNote/print")),
  },
  {
    path: "/service-provider/view-secondary-users",
    component: lazy(() => import("../pages/superAdmin/users/view.js")),
  },
  {
    path: "/service-provider/edit-attendance",
    component: lazy(() =>
      import(
        "../pages/superAdmin/Reports/timeAndAttendance/TimeCardTracking.js"
      )
    ),
  },

  {
    path: "/service-provider/pay-run-attendance",
    component: lazy(() =>
      import(
        "../pages/superAdmin/Reports/timeAndAttendance/payRunAttandance.js"
      )
    ),
  },

  {
    path: "/service-provider/profit-and-loss",
    component: lazy(() => import("../pages/superAdmin/Profit/Profit.js")),
  },

  {
    path: "/service-provider/list-client-rating",
    component: lazy(() => import("../pages/superAdmin/Ratings/client")),
  },
  {
    path: "/service-provider/list-health-carer-rating",
    component: lazy(() => import("../pages/superAdmin/Ratings/healthCarer")),
  },

  {
    path: "/service-provider/add-family-member",
    component: lazy(() =>
      import("../pages/superAdmin/family-member-client/edit")
    ),
  },

  {
    path: "/service-provider/edit-family-member",
    component: lazy(() =>
      import("../pages/superAdmin/family-member-client/edit")
    ),
  },

  {
    path: "/service-provider/list-bsp-chart",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/listMedicalHistory")
    ),
  },

  {
    path: "/service-provider/add-bsp-chart",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/addBsp")
    ),
  },
  {
    path: "/service-provider/report-list/shift-swapping",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/timeAndAttendance/shiftswapping")
    ),
  },
  {
    path: "/service-provider/list-bsp-question",
    component: lazy(() => import("../pages/superAdmin/Bsp/list")),
  },
  {
    path: "/service-provider/list-induction-category",
    component: lazy(() => import("../pages/superAdmin/inductionCategory/list")),
  },
  {
    path: "/service-provider/list-induction-heading-title",
    component: lazy(() => import("../pages/superAdmin/inductionHeading/list")),
  },
  {
    path: "/service-provider/add-induction-heading",
    component: lazy(() => import("../pages/superAdmin/inductionHeading/edit")),
  },
  {
    path: "/service-provider/add-induction-category",
    component: lazy(() => import("../pages/superAdmin/inductionCategory/edit")),
  },
  {
    path: "/service-provider/edit-induction-category",
    component: lazy(() => import("../pages/superAdmin/inductionCategory/edit")),
  },
  {
    path: "/service-provider/add-bsp-question",
    component: lazy(() => import("../pages/superAdmin/Bsp/edit")),
  },
  {
    path: "/service-provider/edit-bsp-question",
    component: lazy(() => import("../pages/superAdmin/Bsp/edit")),
  },

  {
    path: "/service-provider/add-template-type",
    component: lazy(() => import("../pages/superAdmin/templateType/edit")),
  },
  {
    path: "/service-provider/edit-template-type",
    component: lazy(() => import("../pages/superAdmin/templateType/edit")),
  },
  {
    path: "/service-provider/list-template-type",
    component: lazy(() => import("../pages/superAdmin/templateType/list")),
  },
  {
    path: "/service-provider/list-holiday",
    component: lazy(() => import("../pages/superAdmin/public-holiday/list")),
  },
  {
    path: "/service-provider/add-holiday",
    component: lazy(() => import("../pages/superAdmin/public-holiday/edit")),
  },
  {
    path: "/service-provider/edit-holiday",
    component: lazy(() => import("../pages/superAdmin/public-holiday/edit")),
  },
];

export default function ServiceProvider() {
  const MemoizedAppbar = useMemo(() => <Appbar />, []);
  const MemoizedSidebar = useMemo(() => <Sidebar />, []);
  const [menuList, setMenuList] = useState([]);
  const user = localstorageData();

  const createdBy = getCreatedBy();

  const getServiceProviderActivePackageData = () => {
    axios
      .get(
        `${getServiceProviderActivePackage}?id=${user._id}&userTypeId=${user.userTypeId}`
      )
      .then(function (res) {
        if (res) {
          const out = [];

          res.data.data.map((item) => {
            if (item?.isSubMenu === false) {
              out.push(item?.mainMenu?.url);
            } else {
              item?.subMenu?.map((sub) => {
                out.push(sub.url);
              });
            }
          });

          console.log(out);
          setMenuList(out);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getServiceProviderRouteAccess = () => {
    axios
      .get(
        `${accessRoleList}?roleId=${
          user.userTypeId
        }&createdBy=${createdBy}&role=${user.role}&userId=${
          user._id
        }&userType=${user?.createdByUserType}&child=${
          user?.createdByUserType
        }&isABNHolder=${user?.isSoleTrader}&isABNHolderHc=${
          user?.isSoleTrader
        }&bookAdemo=${user?.isBookDemoUser}&isSc=${
          user?.isSoleTrader
        }&evlClient=${
          user?.packageMenuId == false && user?.role == "client"
            ? "true"
            : "false"
        }`
      )
      .then(function (res) {
        console.log("res.data.data.length", res.data.data.length, res.data);
        if (res.data.data.length > 0) {
          setMenuList((prevData) => [
            ...prevData,
            ...res.data.data,
            ...[
              "/service-provider/pending-approval",
              "/service-provider/list-package-menu",
              "/service-provider/subscription-plans-list",
              "/service-provider/settings",
              "/service-provider/myaccount",
              "/service-provider/payments",
              "/service-provider/settings-xero-myob-list",
              "/service-provider/list-payrun",
              "/service-provider/list-severity",
              "/service-provider/list-superannuation-setting",
              "/service-provider/financial-setting-list",
              "/service-provider/list-menu-masters",
              "/service-provider/smtp-setup",
              "/service-provider/quick-task",
            ],
          ]);
        } else {
          setMenuList((prevData) => [
            ...prevData,
            ...[
              "/service-provider/pending-approval",
              "/service-provider/list-package-menu",
              "/service-provider/subscription-plans-list",
              "/service-provider/settings",
              "/service-provider/myaccount",
              "/service-provider/payments",
              "/service-provider/settings-xero-myob-list",
              "/service-provider/list-payrun",
              "/service-provider/list-severity",
              "/service-provider/list-superannuation-setting",
              "/service-provider/financial-setting-list",
              "/service-provider/list-menu-masters",
              "/service-provider/smtp-setup",
              "/service-provider/quick-task",
            ],
          ]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    // getServiceProviderActivePackageData();
    getServiceProviderRouteAccess();
  }, []);

  function isPathInRoutes(path) {
    return menuList.includes(path);
  }
  // const newPath = route.path.replace("/service-provider", "/service-provider");
  return (
    <Box className="dashboard-form" id="agency">
      <Box sx={{ width: "100%" }} className="appbar-border">
        <Box className="appbar-main">{MemoizedAppbar}</Box>
      </Box>
      <Box className="d-flex dashboard-main-box">
        {MemoizedSidebar}
        <Routes>
          <Route>
            {routes.map((route, index) =>
              isPathInRoutes(route.path) ? (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <route.component />
                    </Suspense>
                  }
                />
              ) : null
            )}

            {SuperAdminRoutes?.map(
              (route, index) =>
                isPathInRoutes(route.path) ? (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <route.component />
                      </Suspense>
                    }
                  />
                ) : null // You can also use an empty fragment <> </> instead of null
            )}

            {SuperAdminRoutes?.map(
              (route, index) =>
                isPathInRoutes(route.path) ? (
                  <Route
                    key={index}
                    path={route.path.replace(
                      "/service-provider",
                      "/service-provider"
                    )}
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <route.component />
                      </Suspense>
                    }
                  />
                ) : null // You can also use an empty fragment <> </> instead of null
            )}
          </Route>
          <Route path="*" element={<DelayedError />} />
        </Routes>
      </Box>
    </Box>
  );
}
