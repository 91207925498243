import { create } from "zustand";

const HCStore = create((set) => ({
  buttonClicked: false,
  buttonClickedPinfo: false,
  buttonClickedProdDoc: false,
  supportStatus: "",
  guestUserId: "",
  requiredFor: "",
  ndisNumber: "",

  setField: (fieldName, fieldValue) =>
    set((state) => ({ ...state, [fieldName]: fieldValue })),

  resetFields: () =>
    set(() => ({
      buttonClicked: false,
      buttonClickedPinfo: false,
      buttonClickedProdDoc: false,
      supportStatus: "",
      guestUserId: "",
      requiredFor: "",
      ndisNumber: "",
    })),
}));

export default HCStore;
