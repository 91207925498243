// features/counterSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';


export const statusChangeSlice = createSlice({
  name: 'status',
  initialState: {
    pendingAuto: false,
    startedAuto: false,
  },
  reducers: {
    pendingStatusFun: (state) => {
      state.pendingAuto  = true;
    },
    startedStatusFun: (state) => {
        state.startedAuto  = true;
    },
  },
});

export const { pendingStatusFun, startedStatusFun } = statusChangeSlice.actions;

export default statusChangeSlice.reducer;

// console.log(, "djhsd")

    