import React from 'react';
import { Typography, Box } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const NoDataFound  = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="25vh"
    >
      <ErrorOutlineIcon style={{ fontSize: '100px', color: '#989898' }} />
      <Typography variant="h5" style={{ marginTop: '20px', color: '#989898' }}>
        No Data Found
      </Typography>
    </Box>
  );    
};

export default NoDataFound;
