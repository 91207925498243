import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import sleepOver from "../../../assets/images/sleep-icon.png";
import { useNavigate } from "react-router-dom";

const FortnightlyCell = (props) => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();

  const userTypeName = userData?.userTypeName;

  let userTypeUrl = "super-admin";
  if (userTypeName === "Service Provider") {
    userTypeUrl = "service-provider";
  } else if (userTypeName === "Support Coordinator") {
    userTypeUrl = "support-coordinator";
  } else if (userTypeName === "Admin") {
    userTypeUrl = "admin";
  }

  const [isToggled, setIsToggled] = useState(false);
  const [displayMore, setDisplayViewMore] = useState({});

  const handleToggle = () => {
    setIsToggled((prevState) => !prevState);
  };

  const updateState = () => {
    props.data.map((val, i) => {
      return val?.NextWeek?.[props.day]?.map((weekval, index) => {
        if (index === 1 || i === 1) {
          setDisplayViewMore((prevTaskShow) => ({
            ...prevTaskShow,
            [props._id]: true,
          }));
        }
      });
    });
  };

  useEffect(() => {
    updateState();
  }, []);

  const addAppointment = (e, date, startTime, endTime, healthCarerId) => {
    e.preventDefault();
    const state = {
      healthCarerId: healthCarerId,
      date: date,
      startTime: startTime,
      endTime: endTime,
      back: "Add New",
    };

    navigate(`/${userTypeUrl}/add-appointment`, { state });
  };

  function isDateInPast(date) {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set time to midnight (00:00:00)

    const selectedDate = new Date(date);
    selectedDate.setHours(0, 0, 0, 0); // Set time to midnight (00:00:00)

    return selectedDate < currentDate;
  }

  return (
    <div className="w-100">
      {isToggled && (
        <div>
          {props?.data?.map((val, i) => {
            return val?.NextWeek?.[props?.day]?.map((weekval, index) => {
              return i === 0 ? (
                index === 0 ? null : (
                  <div
                    key={index}
                    className="calender-client-cell employeeTime calender-view-primary"
                  >
                    <div>
                      <Box className="employee-detail">
                        {val?.shiftDetails?.name === "Sleepover" ? (
                          <>
                            <img
                              src={sleepOver}
                              className="health-calender-profile-icon"
                            />
                            <span> Sleepover</span>
                          </>
                        ) : (
                          <span>
                            {weekval?.startTime} - {weekval?.endTime}
                          </span>
                        )}

                        {userData?.role === "superAdmin" &&
                        !isDateInPast(props?.startDate) ? (
                          weekval?.isLeavePresent === false ? (
                            weekval?.isAppoinmentPresent === false ? (
                              <Box
                                className="calender-add-icon position-relative"
                                onClick={(e) =>
                                  addAppointment(
                                    e,
                                    props?.startDate,
                                    weekval?.startTime,
                                    weekval?.endTime,
                                    props?.healthCarerId
                                  )
                                }
                              >
                                <AddCircleIcon className="calender-add-icon-color" />
                                <Box
                                  className="table-icon-popover employee-popover d-flex align-items-center 
                 justify-content-center"
                                >
                                  <AddCircleIcon className="calender-add-icon-color add-svg" />{" "}
                                  <Typography>Add Appointment</Typography>
                                  <ArrowDropDownIcon className="calender-add-popover employee-down-svg calender-add-popover-icon" />
                                </Box>
                              </Box>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </Box>
                    </div>
                  </div>
                )
              ) : (
                <div
                  key={index}
                  className="calender-client-cell employeeTime calender-view-primary"
                >
                  <div>
                    {val?.shiftDetails?.name === "Sleepover" ? (
                      <>
                        <img
                          src={sleepOver}
                          className="health-calender-profile-icon"
                        />
                        <span> Sleepover</span>
                      </>
                    ) : (
                      <span>
                        {weekval?.startTime} - {weekval?.endTime}
                      </span>
                    )}

                    {userData?.role === "superAdmin" &&
                    !isDateInPast(props?.startDate) ? (
                      weekval?.isLeavePresent === false ? (
                        weekval?.isAppoinmentPresent === false ? (
                          <Box
                            className="calender-add-icon position-relative"
                            onClick={(e) =>
                              addAppointment(
                                e,
                                props?.startDate,
                                weekval?.startTime,
                                weekval?.endTime,
                                props?.healthCarerId
                              )
                            }
                          >
                            <AddCircleIcon className="calender-add-icon-color" />
                            <Box
                              className="table-icon-popover employee-popover d-flex align-items-center 
                 justify-content-center"
                            >
                              <AddCircleIcon className="calender-add-icon-color add-svg" />{" "}
                              <Typography>Add Appointment</Typography>
                              <ArrowDropDownIcon className="calender-add-popover employee-down-svg calender-add-popover-icon" />
                            </Box>
                          </Box>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            });
          })}
        </div>
      )}
      {displayMore[props._id] ? (
        <div className="d-flex align-items-center justify-content-between w-100">
          <span className="view-all" onClick={handleToggle}>
            {isToggled ? (
              <span className="less">
                View Less <KeyboardDoubleArrowUpIcon />
              </span>
            ) : (
              <span className="viewall">
                View All <KeyboardDoubleArrowDownIcon />
              </span>
            )}
          </span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default FortnightlyCell;
