  import "react-dates/initialize";
  import { DateRangePicker } from "react-dates";
  import "react-dates/lib/css/_datepicker.css";
  import { useState } from "react";
  // import moment from "moment";
  // import moment from "moment-timezone";
  import "./Roastercard.scss";
  import moment from "moment-timezone";

  const formatDateTwo = (dateString) => {
    const date = new Date(dateString);
    
    const day = String(date.getDate()).padStart(2, '0'); // Day with leading zero
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month (0-indexed, so +1)
    const year = date.getFullYear(); // Full year
    
    return `${day}/${month}/${year}`;
  };

  const MyDateRangePicker = ({startDate, setStartDate, endDate,setEndDate, startPlace, endPlace}) => {
    const [focusedInput, setFocusedInput] = useState(null);
    // const [timeZone]
    // const formatDate = (date) => (date ? moment(date).format("DD/MM/YY") : null);

    const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    console.log(systemTimezone, "timezone")

    // let startDateData = formatDate(startDate)

    return (
      <DateRangePicker
        startDate={startDate} // momentPropTypes.momentObj or null,
        startDateId="your_unique_start_date_id"
        endDate={endDate} // momentPropTypes.momentObj or null,
        endDateId="your_unique_end_date_id"
        onDatesChange={({ startDate, endDate }) => {
          // const timezone = "America/New_York";
          const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          // console.log(startDate, moment(startDate).tz(systemTimezone),  "hdchjhcd")
          
      
          setStartDate(moment(startDate).tz(systemTimezone));
          setEndDate(moment(endDate).tz(systemTimezone));
        }}
        focusedInput={focusedInput}
        displayFormat="DD/MM/YYYY"
        onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
        isOutsideRange={() => false}  
         startDatePlaceholderText={formatDateTwo(startPlace)}
         endDatePlaceholderText={formatDateTwo(endPlace)}
        className="custom-date-range-picker"

      />
    );
  };

  export default MyDateRangePicker;


  // import * as React from 'react';
  // import dayjs from 'dayjs';
  // import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
  // import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
  // import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

  // import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';

  // export default function CalendarExample() {
  //   const [value, setValue] = React.useState([
  //     dayjs('2022-04-17'),
  //     dayjs('2022-04-21'),
  //   ]);

  //   return (
  //     <LocalizationProvider dateAdapter={AdapterDayjs}>
  //       <DemoContainer components={['DateRangePicker', 'DateRangePicker']}>
  //         <DemoItem label="Uncontrolled picker" component="DateRangePicker">
  //           <DateRangePicker
  //             defaultValue={[dayjs('2022-04-17'), dayjs('2022-04-21')]}
  //           />
  //         </DemoItem>
  //         <DemoItem label="Controlled picker" component="DateRangePicker">
  //           <DateRangePicker
  //             value={value}
  //             onChange={(newValue) => setValue(newValue)}
  //           />
  //         </DemoItem>
  //       </DemoContainer>
  //     </LocalizationProvider>
  //   );
  // }



  // import React, { useState } from 'react';
  // import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
  // import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
  // import TextField from '@mui/material/TextField';
  // import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
  // import "./Roastercard.scss"

  // const CalendarExample = () => {
  //   const [selectedDate, setSelectedDate] = useState(null);

  //   return (
  //     <LocalizationProvider dateAdapter={AdapterDateFns}>
  //       <DatePicker
  //         value={selectedDate}
  //         onChange={(newValue) => {
  //           setSelectedDate(newValue);
  //         }}
  //         renderInput={(params) => <TextField {...params} />}
  //       />
  //     </LocalizationProvider>
  //   );
  // };

  // export default CalendarExample;


  // import React, { useState } from 'react';
  // import { LocalizationProvider, DateRangePicker } from '@mui/x-date-pickers';
  // import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
  // import { TextField } from '@mui/material';
  // import dayjs from 'dayjs';

  // const DateRangeSelector = () => {
  //   const [value, setValue] = useState([null, null]); // [startDate, endDate]

  //   return (
  //     <LocalizationProvider dateAdapter={AdapterDayjs}>
  //       <DateRangePicker
  //         startText="Start"
  //         endText="End"
  //         value={value}
  //         onChange={(newValue) => setValue(newValue)}
  //         renderInput={(startProps, endProps) => (
  //           <>
  //             <TextField {...startProps} variant="outlined" />
  //             <TextField {...endProps} variant="outlined" />
  //           </>
  //         )}
  //       />
  //     </LocalizationProvider>
  //   );
  // };

  // export default DateRangeSelector;


