import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./Roastercard.scss";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CheckIcon from "@mui/icons-material/Check";
import NodataTable from "./NodataTable";

export default function LiveClock({ rows, columns, action }) {
  return (
    <TableContainer sx={{ margin: "20px" }} component={Paper}>
      <Table
        sx={{ borderCollapse: "separate", borderSpacing: 0 }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                color: "#14A07D",
                background: "#D0ECE5",
                border: "1px solid #14A07D",
                borderTopLeftRadius: "10px",
              }}
              align="center"
            >
              Emp Name
            </TableCell>
            <TableCell
              style={{
                color: "#14A07D",
                background: "#D0ECE5",
                border: "1px solid #14A07D",
              }}
              align="center"
            >
               Client
            </TableCell>
            <TableCell
              style={{
                color: "#14A07D",
                background: "#D0ECE5",
                border: "1px solid #14A07D",
                borderTopRightRadius: action ? "0px" : "10px",
              }}
              align="center"
            >
               Status 
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.length === 0 ? <NodataTable col={3} /> : rows?.map((row, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                style={{ color: "#181818", border: "1px solid #14A07D", justifyContent:"flex-start" }}
                component="th"
                scope="row"
              >
                {row?.employeeName}
              </TableCell>
              <TableCell
                style={{ color: "#181818", border: "1px solid #14A07D",justifyContent:"flex-start" }}
                align="center"
              >
                 {row?.clientNamez}
              </TableCell>
              
                <TableCell
                  style={{ color: "#181818", border: "1px solid #14A07D" }}
                  align="left"
                >
                  <div className="btn-flex flex-start">
                    {row?.status} minutes
                  </div>
                </TableCell>
              
             
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
