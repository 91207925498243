import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import "../../assets/css/dashboard.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";

function UpdateAvailability(props) {
  const [inputText, setInputText] = useState("");

  const handleEnterPress = (e, type) => {
    if (e.keyCode === 13) {
      props.addNewAvailability(inputText, type);
      setInputText("");
    }
  };

  const initialValues = {
    startTime:
      props?.editstartTimeSelected !== undefined
        ? props?.editstartTimeSelected
        : "",
    endTime:
      props?.editendTimeSelected !== undefined
        ? props?.editendTimeSelected
        : "",
  };

  const validationSchema = Yup.object().shape({
    startTime: Yup.string().required("Start Time is Required"),
    endTime: Yup.string().required("End Time is Required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      props.addNewAvailability(inputText, values.startTime, values.endTime);
      props.handleClose();
      setInputText("");
    },
  });

  useEffect(() => {
    if (props?.shift?.defaultOn) {
      formik.setFieldValue("startTime", props?.shift?.startTime);
      formik.setFieldValue("endTime", props?.shift?.endTime);
    } else {
      formik.setFieldValue(
        "startTime",
        props?.editstartTimeSelected !== undefined
          ? props?.editstartTimeSelected
          : ""
      );
      formik.setFieldValue(
        "endTime",
        props?.editendTimeSelected !== undefined
          ? props?.editendTimeSelected
          : ""
      );
    }
  }, [props?.shift?.defaultOn]);

  return (
    <div className="input-container dashboard-form">
      <Box>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Box className="d-flex flex-column">
            <label>Start at</label>
            <TextField
              id="start"
              defaultValue="10:10"
              variant="filled"
              className="inputlist-field"
              type="time"
              inputProps={{
                step: 300, // set to 5 minutes
                inputMode: "numeric",
                pattern: "[0-9]{2}:[0-9]{2}", // set the format to HH:MM
              }}
              onChange={(e) => {
                setInputText(e.target.value);
                formik.setFieldValue("startTime", e.target.value);
                //formik?.handleChange();
              }}
              name="startTime"
              onBlur={formik?.handleBlur}
              value={formik?.values?.startTime}
              onKeyDown={(e) => handleEnterPress(e, "startTime")}
            />

            {formik.touched.startTime && formik.errors.startTime && (
              <span role="alert" style={{ color: "red", paddingBottom: "2%" }}>
                {formik.errors.startTime}
              </span>
            )}
            <label>End at</label>
            <TextField
              id="End"
              defaultValue="10:18"
              variant="filled"
              className=" inputlist-field"
              type="time"
              // value={inputText.id}
              inputProps={{
                step: 300, // set to 5 minutes
                inputMode: "numeric",
                pattern: "[0-9]{2}:[0-9]{2}", // set the format to HH:MM
              }}
              onChange={(e) => {
                setInputText(e.target.value);
                formik.setFieldValue("endTime", e.target.value);
                //formik?.handleChange();
              }}
              name="endTime"
              onBlur={formik?.handleBlur}
              value={formik?.values?.endTime}
              onKeyDown={(e) => handleEnterPress(e, "endTime")}
            />
            {formik.touched.endTime && formik.errors.endTime && (
              <span role="alert" style={{ color: "red", paddingBottom: "2%" }}>
                {formik.errors.endTime}
              </span>
            )}
          </Box>
          <Box className="agency-btn-section">
            <a
              href="javascript:;"
              onClick={(e) => {
                props.handleClose();
              }}
            >
              Skip
            </a>
            <a
              href="javascript:;"
              autoFocus
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              Update
            </a>
          </Box>
        </form>
      </Box>
    </div>
  );
}

export default UpdateAvailability;
