import axios from "axios";
const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const userData = localStorage.getItem("userData");
    const userObject =
      userData !== undefined && userData !== null ? JSON.parse(userData) : "";

    if (!config.headers["Authorization"]) {
      config.headers["Authorization"] = `Bearer ${userObject.token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
export default axiosInstance;
