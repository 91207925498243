import * as React from "react";
import Typography from "@mui/material/Typography";
import { helpManagementContent } from "../../api";
import { useState, useEffect } from "react";
import axios from "axios";

function HelpManagementContent(props) {
  const helpModelId = props?.props?.helpModelId;
  const title = props?.props?.title;

  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(true); // Track loading state

  useEffect(() => {
    if (helpModelId) {
      listExportAll();
    }
  }, [helpModelId]);

  const listExportAll = async () => {
    setLoading(true); // Set loading to true before fetching
    try {
      const res = await axios.get(
        `${helpManagementContent}?helpModelId=${helpModelId}`
      );
      setContent(res?.data?.data?.content || ""); // Set content or empty string if no content
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  return (
    <>
      {loading ? (
        <Typography className="">Loading {title}...</Typography> // Show loading text
      ) : (
        <Typography className="">
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </Typography>
      )}
    </>
  );
}

export default HelpManagementContent;
