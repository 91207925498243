import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
//404 page
import Error from "../components/Error";
import Loader from "../../../frontend/src/pages/loader/Loader";
export default function Stakeholder() {
  //const Home = lazy(() => import("../pages/Stakeholder/home"));
  const Home = lazy(() => import("../pages/Stakeholder/Stackholder-Dashboard"));
  //const Home = lazy(() => import("../pages/client/Dashboard/Client-Dashboard"));
  const PendingApproval = lazy(() =>
    import("../pages/superAdmin/pending-approval")
  );
  const Meeting = lazy(() => import("../pages/Stakeholder/meetings"));
  const TaskList = lazy(() => import("../pages/Stakeholder/task/list"));
  const NoteList = lazy(() => import("../pages/Stakeholder/note/list"));
  const MyAccount = lazy(() => import("../pages/Stakeholder/form/MyAccount"));
  const ClientProfile = lazy(() =>
    import("../pages/Stakeholder/clientprofile/profileclient")
  );

  /*meeting routes*/

  const ListClientScheduleMeetingNew = lazy(() =>
    import("../pages/superAdmin/schedule-meeting/client/list-tab")
  );

  const ListCommonCalander = lazy(() =>
    import("../pages/superAdmin/schedule-common-meeting/client/list-tab")
  );

  const ClientMeetingDetailsNew = lazy(() =>
    import("../components/meeting-view/details")
  );

  const ClientSelectionPage = lazy(() =>
    import("../components/goals/ClientSelect")
  );

  const ClientGoalList = lazy(() => import("../components/goals/list"));

  //

  const ClientEditGoalRoutine = lazy(() =>
    import("../components/goals/addRoutineTask/edit")
  );
  const ClientExportPdfWithImage = lazy(() =>
    import("../components/goals/pdfWithImage")
  );
  const ClientExportPdf = lazy(() => import("../components/goals/pdf"));
  const ClientViewGoal = lazy(() => import("../components/goals/view"));
  const ClientEditGoal = lazy(() => import("../components/goals/edit"));
  const BarChartGoal = lazy(() => import("../components/goals/barChart"));
  const Riskassesment = lazy(() =>
    import("../pages/Stakeholder/Riskassesment/Riskassesment")
  );

  const ListMedicalHistory = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/listMedicalHistory")
  );

  const MedicalHistoryPdf = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/medicalHistoryPdf")
  );

  const MedicalHistoryPrint = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/print")
  );

  const MedicalHistory = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/Medical-History")
  );

  const EditMedicalHistory = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/Add-Medical-History")
  );

  //ViewMedicalHistory

  const ViewMedicalHistory = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/View-Medical-History")
  );

  const MedicationChart = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/Medication-chart")
  );

  const EditMedicationChart = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/Add-Medication")
  );

  const ScheduleOtherMeeting = lazy(() =>
    import("../pages/superAdmin/schedule-other-meeting/meeting")
  );
  //ViewMedicationChart

  const ViewMedicationChart = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/View-Medication")
  );

  const Setting = lazy(() =>
    import("../pages/serviceProvider/Settings/setting.js")
  );

  const AddList = lazy(() => import("../pages/Stakeholder/task/edit"));
  const EditList = lazy(() => import("../pages/Stakeholder/task/edit"));
  const Viewtask = lazy(() => import("../pages/Stakeholder/task/view"));
  return (
    <Routes>
      <Route>
        <Route
          path="/stakeholder/home"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Home />
            </Suspense>
          }
        />
        <Route
          path="/stakeholder/pending-approval"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <PendingApproval />
            </Suspense>
          }
        />
        <Route
          path="/stakeholder/meeting"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Meeting />
            </Suspense>
          }
        />

        <Route
          path="/stakeholder/list-task"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <TaskList />
            </Suspense>
          }
        />

        <Route
          path="/stakeholder/list-note"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <NoteList />
            </Suspense>
          }
        />

        <Route
          path="/stakeholder/myaccount"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <MyAccount />
            </Suspense>
          }
        />
        <Route
          path="stakeholder/client-profile"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ClientProfile />
            </Suspense>
          }
        />

        <Route
          path="/stakeholder/list-meeting-client"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListClientScheduleMeetingNew />
            </Suspense>
          }
        />

        <Route
          path="/stakeholder/list-calendar"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListCommonCalander />
            </Suspense>
          }
        />

        <Route
          path="/stakeholder/list-view-schedule-meeting"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ClientMeetingDetailsNew />
            </Suspense>
          }
        />

        <Route
          path="/stakeholder/select-client"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ClientSelectionPage />
            </Suspense>
          }
        />

        <Route
          path="/stakeholder/list-goal-as-per-client"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ClientGoalList />
            </Suspense>
          }
        />

        <Route
          path="/stakeholder/schedule-other-meeting"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ScheduleOtherMeeting />
            </Suspense>
          }
        />

        <Route
          path="/stakeholder/add-goal"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ClientEditGoal />
            </Suspense>
          }
        />
        <Route
          path="/stakeholder/edit-goal"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ClientEditGoal />
            </Suspense>
          }
        />

        <Route
          path="/stakeholder/view-task"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ClientViewGoal />
            </Suspense>
          }
        />
        <Route
          path="/stakeholder/export-pdf"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ClientExportPdf />
            </Suspense>
          }
        />
        <Route
          path="/stakeholder/export-pdf-with-image"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ClientExportPdfWithImage />
            </Suspense>
          }
        />
        <Route
          path="/stakeholder/add-goal-routine"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ClientEditGoalRoutine />
            </Suspense>
          }
        />
        <Route
          path="/stakeholder/edit-goal-routine"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ClientEditGoalRoutine />
            </Suspense>
          }
        />

        <Route
          path="/stakeholder/bar-chart-goal"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <BarChartGoal />
            </Suspense>
          }
        />

        <Route
          path="/stakeholder/risk-assesment"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Riskassesment />
            </Suspense>
          }
        />

        <Route
          path="/stakeholder/medical-history-pdf"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <MedicalHistoryPdf />
            </Suspense>
          }
        />

        <Route
          path="/stakeholder/medical-history-print"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <MedicalHistoryPrint />
            </Suspense>
          }
        />

        <Route
          path="/stakeholder/list-medical-history"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListMedicalHistory />
            </Suspense>
          }
        />
        <Route
          path="/stakeholder/list-medication-chart"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListMedicalHistory />
            </Suspense>
          }
        />
        <Route
          path="/stakeholder/list-prn-chart"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListMedicalHistory />
            </Suspense>
          }
        />
        <Route
          path="/stakeholder/medical-history-dev"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <MedicalHistory />
            </Suspense>
          }
        />
        <Route
          path="/stakeholder/add-medical-history-dev"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <EditMedicalHistory />
            </Suspense>
          }
        />
        <Route
          path="/stakeholder/edit-medical-history-dev"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <EditMedicalHistory />
            </Suspense>
          }
        />

        <Route
          path="/stakeholder/view-medical-history-dev"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ViewMedicalHistory />
            </Suspense>
          }
        />
        <Route
          path="/stakeholder/medication-chart-dev"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <MedicationChart />
            </Suspense>
          }
        />
        <Route
          path="/stakeholder/add-medication-dev"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <EditMedicationChart />
            </Suspense>
          }
        />
        <Route
          path="/stakeholder/edit-medication-dev"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <EditMedicationChart />
            </Suspense>
          }
        />
        <Route
          path="/stakeholder/view-medication-dev"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ViewMedicationChart />
            </Suspense>
          }
        />
        <Route
          path="/stakeholder/prn-chart-dev"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <MedicationChart />
            </Suspense>
          }
        />

        <Route
          path="/stakeholder/add-task"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <AddList />
            </Suspense>
          }
        />

        <Route
          path="/stakeholder/edit-task"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <EditList />
            </Suspense>
          }
        />
        <Route
          path="/stakeholder/task-view"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Viewtask />
            </Suspense>
          }
        />
        <Route
          path="/stakeholder/settings"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Setting />
            </Suspense>
          }
        />

        <Route path="*" element={<Error />} />
      </Route>
    </Routes>
  );
}
