import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Loader from "../../../frontend/src/pages/loader/Loader";
//404 page
import Error from "../components/Error";

export default function ITManager() {
  // const Home = lazy(() => import("../pages/ITManager/home"));
  const Home = lazy(() => import("../pages/ITManager/ITManager-dasjboard.js"));
  const PendingApproval = lazy(() =>
    import("../pages/superAdmin/pending-approval")
  );
  const MyAccount = lazy(() => import("../pages/ITManager/form/MyAccount"));

  //leave management new changes

  const ListLeaveCategory = lazy(() =>
    import("../pages/superAdmin/leave-category/list")
  );

  const ListLeaveManagementOld = lazy(() =>
    import("../pages/superAdmin/leaveManagement/list")
  );

  const ListLeaveManagement = lazy(() =>
    import("../pages/superAdmin/leaveManagement/list-tab")
  );

  const EditLeaveManagement = lazy(() =>
    import("../pages/superAdmin/leaveManagement/edit")
  );

  const ViewLeaveManagement = lazy(() =>
    import("../pages/superAdmin/leaveManagement/view")
  );
  const ListSetLeaves = lazy(() => import("../pages/superAdmin/setLeave/list"));
  const EditAsset = lazy(() =>
    import("../pages/superAdmin/assetManagement/edit.js")
  );
  const AddAsset = lazy(() =>
    import("../pages/superAdmin/assetManagement/edit.js")
  );
  const ListAsset = lazy(() =>
    import("../pages/superAdmin/assetManagement/list.js")
  );
  const Attendance = lazy(() =>
    import("../pages/CommanComponant/Attendance/Attendance")
  );

  return (
    <Routes>
      <Route>
        <Route
          path="/it-manager/home"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Home />
            </Suspense>
          }
        />
        <Route
          path="/it-manager/pending-approval"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <PendingApproval />
            </Suspense>
          }
        />
        <Route
          path="/it-manager/myaccount"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <MyAccount />
            </Suspense>
          }
        />

        {/* leave management new changes */}
        <Route
          path="/it-manager/list-leave-categories"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListLeaveCategory />
            </Suspense>
          }
        />

        <Route
          path="/it-manager/list-leave-management-old"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListLeaveManagementOld />
            </Suspense>
          }
        />

        <Route
          path="/it-manager/list-leave-management"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListLeaveManagement />
            </Suspense>
          }
        />

        <Route
          path="/it-manager/add-leave"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <EditLeaveManagement />
            </Suspense>
          }
        />

        <Route
          path="/it-manager/edit-leave"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <EditLeaveManagement />
            </Suspense>
          }
        />

        <Route
          path="/it-manager/view-leave"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ViewLeaveManagement />
            </Suspense>
          }
        />

        <Route
          path="/it-manager/list-set-leave"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListSetLeaves />
            </Suspense>
          }
        />

        <Route
          path="/it-manager/add-asset"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <AddAsset />
            </Suspense>
          }
        />

        <Route
          path="/it-manager/edit-asset"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <EditAsset />
            </Suspense>
          }
        />

        <Route
          path="/it-manager/list-asset"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListAsset />
            </Suspense>
          }
        />

        <Route
          path="/it-manager/attendance"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Attendance />
            </Suspense>
          }
        />

        <Route path="*" element={<Error />} />
      </Route>
    </Routes>
  );
}
