import {
  getJobTitleList,
  getJobTypeList,
  getLevelList,
  getState,
  getCountry,
  getCountryDetails,
  getStateDetails,
  getStateList,
  getLevelDetails,
  getJobTypeDetails,
  getJobTitleDetails,
  AustraliaId,
  addGoogleCount,
  updateGoogleCount,
  getIndividualCountDetails,
  SAcreatedBy,
  listAllSkill,
  getAllSkillList,
} from "../../../../api";
import React, { useEffect, useState } from "react";
import moment from "moment";
// import axios from "axios";
import axios from "../../../../api/axios";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MailIcon from "@mui/icons-material/Mail";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import CallIcon from "@mui/icons-material/Call";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import { toast } from "react-toastify";
import { getAllBeforeOnboarding } from "../../../../helpers/commonHelper";
import HCStore from "../HCStore";
import "../../../../pages/client/form/stakeholder.css";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Radio } from "@mui/material";

const PersonalInfo = (props) => {
  const { formik } = props;

  const [dobError, setDobError] = useState("");
  const { resetFields, setField, buttonClickedPinfo } = HCStore();

  let createdBy = getAllBeforeOnboarding();

  if (createdBy === "superAdmin") {
    createdBy = SAcreatedBy;
  }

  const gender = [
    { label: "Male", id: "Male" },
    { label: "Female", id: "Female" },
    { label: "Other", id: "Other" },
  ];

  const titles = [
    {
      label: "Ms",
      id: "Ms",
    },
    { label: "Mr", id: "Mr" },
    { label: "Mrs", id: "Mrs" },
  ];

  let dateOfBirth = null;

  if (formik.values.dob !== undefined && formik.values.dob !== null) {
    dateOfBirth = moment(formik.values.dob).format("YYYY-MM-DD");
  }

  const HandleDateEvent = (e, docName) => {
    const name = docName;
    if (new Date(e) == "Invalid Date") {
      setDobError("Please select a valid date.");
    } else {
      formik.setFieldValue(name, new Date(e));
      setDobError("");
    }
  };
  const [skillList, setSkillList] = useState();
  const [open, setOpen] = React.useState(false);
  const handleCheckboxToggle = (skillId) => {
    console.log("skillId", skillId);
    const skills = formik?.values?.skillRequired;
    console.log("skills", skills);
    if (skills?.includes(skillId)) {
      formik.setFieldValue(
        "skillRequired",
        skills.filter((id) => id !== skillId)
      );
    } else {
      formik.setFieldValue("skillRequired", [...skills, skillId]);
    }
  };
  useEffect(() => {
    axios
      .get(`${getAllSkillList}?createdBy=${SAcreatedBy}`)
      .then((res) => {
        setSkillList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [selectedValue, setSelectedValue] = useState("");
  const [showPersonalDetails, setShowPersonalDetails] = useState(false);
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    // Show personal details when "Family Member" or "My Client" is selected
    setShowPersonalDetails(
      event.target.value === "Family Member" ||
      event.target.value === "My Client"
    );
  };
  return (
    <Box id="personal-info" className="account-form">
      <Box className="d-flex align-items-center justify-content-between">
        <Typography className="step-count">Step 3/4</Typography>
      </Box>

      <Box className="personal-info-main">
        <Box className="form-heading-section">
          <Typography className="form-heading form-heading-align">
            Looking For Support
          </Typography>
        </Box>
        <Box className="personal-info">
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <label >Support Required</label>

              <RadioGroup row aria-label="support-type" name="supportRequired" sx={{ p: 2, ml: -2 }}>
                <FormControlLabel
                  onClick={() => {
                    formik.setFieldValue("supportRequired", "Disability Care");
                    setField("supportRequired", "Disability Care");
                  }}
                  value="Disability Care"
                  control={<Radio />}
                  label="Disability Care"
                  checked={
                    formik.values.supportRequired === "Disability Care"
                      ? true
                      : ""
                  }
                />
                &nbsp;&nbsp;
                <FormControlLabel
                  onClick={() => {
                    formik.setFieldValue("supportRequired", "Aged Care");
                    setField("supportRequired", "Aged Care");
                  }}
                  value="Aged Care"
                  control={<Radio />}
                  label="Aged Care"
                  checked={
                    formik.values.supportRequired === "Aged Care" ? true : ""
                  }
                />
                &nbsp;&nbsp;
                <FormControlLabel
                  onClick={() => {
                    formik.setFieldValue("supportRequired", "Nursing");
                    setField("supportRequired", "Nursing");
                  }}
                  value="Nursing"
                  control={<Radio />}
                  label="Nursing"
                  checked={
                    formik.values.supportRequired === "Nursing" ? true : ""
                  }
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Box>
        <Box className="personal-info">
          <Grid item xs={12}>
            <Box>
              <label component="div" className="label-color-ac">
                Skills Required <span className="red-color">*</span>
              </label>
              <Grid container spacing={0}>
                {skillList !== undefined &&
                  skillList?.map((row) => (
                    <Grid item xs={2} md={2} key={row?.id}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="label-color-ac"
                            checked={
                              formik?.values?.skillRequired !== undefined &&
                                formik?.values?.skillRequired?.length > 0 &&
                                formik?.values?.skillRequired?.includes(row?.id)
                                ? true
                                : false
                            }
                            onClick={() => {
                              handleCheckboxToggle(row?.id);
                            }}
                          />
                        }
                        label={row?.label}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default PersonalInfo;
