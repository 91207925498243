import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./Roastercard.scss";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CheckIcon from "@mui/icons-material/Check";
import NodataTable from "./NodataTable";

export default function RoasterTable({ rows, columns, action }) {
  return (
    <TableContainer sx={{ margin: "20px" }} component={Paper}>
      <Table
        sx={{ borderCollapse: "separate", borderSpacing: 0 }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                color: "#14A07D",
                background: "#D0ECE5",
                border: "1px solid #14A07D",
                borderTopLeftRadius: "10px",
              }}
              align="center"
            >
              Employee
            </TableCell>
            <TableCell
              style={{
                color: "#14A07D",
                background: "#D0ECE5",
                border: "1px solid #14A07D",
              }}
              align="center"
            >
               Date
            </TableCell>
            <TableCell
              style={{
                color: "#14A07D",
                background: "#D0ECE5",
                border: "1px solid #14A07D",
                borderTopRightRadius: action ? "0px" : "10px",
              }}
              align="center"
            >
               Status 
            </TableCell>
            {action && (
              <TableCell
                style={{
                  color: "#14A07D",
                  background: "#D0ECE5",
                  border: "1px solid #14A07D",
                  borderTopRightRadius: "10px",
                }}
                align="center"
              >
                Action
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.length === 0 ? <NodataTable col={3} /> : rows?.map((row, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                style={{ color: "#181818", border: "1px solid #14A07D" }}
                component="th"
                scope="row"
              >
                {row?.leaveUserDataFirstName}
              </TableCell>
              <TableCell
                style={{ color: "#181818", border: "1px solid #14A07D" }}
                align="center"
              >
               {row?.leaveemployeesFrom ? new Date(row.leaveemployeesFrom).toLocaleDateString('en-US') : null}
              </TableCell>
              {row?.leaveemployeesStatus === "Approved" ? (
                <TableCell
                  style={{ color: "#15A07D", border: "1px solid #14A07D" }}
                  align="left"
                >
                  <div className="btn-flex flex-start">
                    {row?.leaveemployeesStatus}
                    <div className="my-icon">
                      <div className="icons">
                        <CloseIcon sx={{ fontSize: "12px" }} />
                      </div>
                    </div>
                  </div>
                </TableCell>
              ) : row?.leaveemployeesStatus === "Pending" ? (
                <TableCell
                  style={{ color: "#EBAF14", border: "1px solid #14A07D" }}
                  align="left"
                >
                  <div className="btn-flex flex-start">
                    {row?.leaveemployeesStatus}
                    <div className="my-icon check">
                      <div className="icons check">
                        <CheckIcon sx={{ fontSize: "12px" }} />
                      </div>
                    </div>
                    <div className="my-icon">
                      <div className="icons">
                        <CloseIcon sx={{ fontSize: "12px" }} />
                      </div>
                    </div>
                  </div>
                </TableCell>
              ) : (
                <TableCell
                  style={{ color: "#FF4D2B", border: "1px solid #14A07D" }}
                  align="left"
                >
                  <div className="btn-flex flex-start">
                    {row.status}
                    <div className="my-icon check">
                      <div className="icons check">
                        <CheckIcon sx={{ fontSize: "12px" }} />
                      </div>
                    </div>
                  </div>
                </TableCell>
              )}
              {action && (
                <TableCell
                  style={{ border: "1px solid #14A07D" }}
                  align="center"
                >
                  <div className="btn-flex">
                    <div className="my-icon check">
                      <div className="icons check">
                        <CheckIcon sx={{ fontSize: "12px" }} />
                      </div>
                    </div>
                    <div className="my-icon">
                      <div className="icons">
                        <CloseIcon sx={{ fontSize: "12px" }} />
                      </div>
                    </div>
                    <div className="my-icon edit">
                      <div className="icons edit">
                        <BorderColorIcon sx={{ fontSize: "12px" }} />
                      </div>
                    </div>
                  </div>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
