import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import "../../../assets/css/dashboard.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import axios from "axios";
import { exportDemoTime, exportDemoRequest } from "../../../api";
import { format } from "date-fns";
import Grid from "@mui/material/Grid";
import { TextField, Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import "../../../Website/HomeUI/home-ui.css"


function DemoTimeSlot(props) {
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [timeInterval, setTimeInterval] = useState(60);
  const [bookedSlots, setBookedSlots] = useState([]);

  const handleTimeSlotSelect = (selectedTime) => {
    setSelectedTime(selectedTime);


  };

  const handleSubmit = () => {
    if (selectedTime) {
      // Find the index of the selected time in the timeSlots array
      const selectedIndex = timeSlots.findIndex(slot => slot.time === selectedTime);

      // If the selected time is not the last time slot, send the next time slot as a prop
      let nextTimeSlot = null;
      if (selectedIndex < timeSlots.length - 1) {
        nextTimeSlot = timeSlots[selectedIndex + 1].time;
      }

      // Call the onTimeSlotSelect function with selectedTime and nextTimeSlot as props
      props.onTimeSlotSelect(props.selectedDate, selectedTime, nextTimeSlot);

      // Close the window
      window.close();
    } else {
      toast.error("Please select a time slot.");
    }
  };


  const formik = useFormik({});

  useEffect(() => {
    axios.get(exportDemoTime).then((res) => {
      const data = res?.data?.data;

      if (Array.isArray(data) && data.length > 0) {
        const startTime = data[0].startTime;
        const endTime = data[0].endTime;
        const timeInterval = parseInt(data[0].timeInterval);

        setTimeInterval(timeInterval);

        const slots = generateTimeSlots(startTime, endTime, timeInterval, bookedSlots);
        setTimeSlots(slots);
      }
    });
  }, [bookedSlots]);

  useEffect(() => {
    axios.get(exportDemoRequest).then((res) => {
      const data = res?.data?.data;

      if (Array.isArray(data) && data.length > 0) {
        // Filter demoRequests based on availability date
        const selectedDateISO = props.selectedDate.toISOString();

        const demoRequests = data.filter(request => request.availability && request.availability.date === selectedDateISO);

        // Extract availability.time values from all matching requests
        const bookedTimes = demoRequests.map(request => request.availability.time).filter(time => time);
        console.log("bookedTimes", bookedTimes)
        // Set bookedSlots to the array of availability.time values
        setBookedSlots(bookedTimes);
      }
    });
  }, [props.selectedDate]);


  const generateTimeSlots = (startTime, endTime, interval, blockedTimeSlots) => {
    const slots = [];
    let currentTime = startTime;

    while (currentTime <= endTime) {
      const isBlocked = blockedTimeSlots.includes(currentTime);
      slots.push({ time: currentTime, isBlocked });

      const [hours, minutes] = currentTime.split(":");
      const totalMinutes = parseInt(hours) * 60 + parseInt(minutes);
      currentTime = formatTime(totalMinutes + interval);
    }
    console.log('----------------slots', slots)
    return slots;
  };


  const formatTime = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`;
  };


  return (
    <div className="input-container dashboard-form">
      <Box className="demoTimeSlot">
        <form noValidate onSubmit={formik.handleSubmit}>
          <Box>
            <Grid container spacing={2} >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className="pt-0 mb-0 d-flex align-items-center justify-content-between"
              >
                <Typography className="selected-date">
                  {format(props?.selectedDate, "dd-MM-yyyy")}
                </Typography>
                <Typography
                  style={{ textAlign: "center" }}
                  className="duration"
                >
                  Duration: {timeInterval} minutes
                </Typography>
              </Grid>
              <Box className="hc-card-divider"></Box>
            </Grid>
            <Box className="w-100">
              <Box className="d-flex align-items-center modal-slot-main g-4 flex-wrap">
                {timeSlots.slice(0, -1).map((slot, index) => (
                  <Box className="modal-slot-wrapper" key={index}>
                    <FormControlLabel
                      control={<Checkbox sx={{ display: "none" }} />}
                      label={slot.time}
                      className={`modal-slotTime ${slot.isBlocked ? "modal-slotTime-disable" : ""} ${selectedTime === slot.time ? "bookSlot-success-bg" : ""}`}
                      onClick={() => !slot.isBlocked && handleTimeSlotSelect(slot.time)}
                      disabled={slot.isBlocked}
                    />
                  </Box>
                ))}

              </Box>
            </Box>
          </Box>

          <Box className="agency-btn-section" sx={{ mb: 3 }}>
            {/* <a
              href="javascript:;"
              onClick={(e) => {
                props.handleClose();
              }}
            >
              Cancel
            </a> */}
            <Link to="javascript:;" className="w-100" autoFocus onClick={handleSubmit}>
              Confirm
            </Link>
          </Box>
        </form>
      </Box>
    </div>
  );
}

export default DemoTimeSlot;
