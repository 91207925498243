import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button } from "@mui/material";
import "../../../assets/css/dashboard.css";
import "../../../assets/css/Appointment.css";
import DemoTimeSlot from "./DemoTimeSlot";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { exportDemoTime } from "../../../api";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import "../../../Website/HomeUI/home-ui.css";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import "./calenderCustom.css";

const Calendar = ({ onDateTimeSelect }) => {
  // const { Formik } = props;
  // console.log(Formik, "---------------------formik")
  const [events, setEventData] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [availability, setAvailability] = useState({
    startTime: null,
    endTime: null,
  });

  const renderEventContent = (cellInfo) => {
    const eventDate = cellInfo.event.start;

    const dateOnly = new Date(
      eventDate.getFullYear(),
      eventDate.getMonth(),
      eventDate.getDate()
    ); // Extract only date part
    const ISTOffset = 5.5 * 60 * 60 * 1000; // Convert hours to milliseconds
    const ISTDate = new Date(dateOnly.getTime() + ISTOffset); // Adjust date to IST

    return (
      <div>
        <Button
          size="medium"
          disableRipple
          className="calender-add-icon-newcolor2 button-no-hover"
          onClick={(e) => {
            setIsPopupOpen(true);
            setSelectedDate(ISTDate);
          }}
        />
      </div>
    );
  };

  useEffect(() => {
    // Generate events for all future dates
    const today = new Date();
    const futureDatesData = [];
    for (let i = 1; i <= 30; i++) {
      const futureDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + i
      );
      futureDatesData.push({
        title: `Event ${i}`,
        start: futureDate,
      });
    }

    setEventData(futureDatesData);
  }, []);

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  const handleTimeSlotSelect = (selectedDate, selectedTime, nextTimeSlot) => {
    console.log("nextTimeSlot", nextTimeSlot);
    // Call the callback function to update the state in the parent component
    onDateTimeSelect(selectedDate, selectedTime, nextTimeSlot);
    setSelectedTime(selectedTime);

    handleClose();
  };

  useEffect(() => {
    axios.get(exportDemoTime).then((res) => {
      const data = res?.data?.data[0];

      if (data) {
        setAvailability({
          startTime: data.startTime,
          endTime: data.endTime,
        });
      }
    });
  }, []);

  return (
    <div id="calender" className="bookDemo-calender-main">
      <Typography
        variant="subtitle1"
        gutterBottom
        className="d-flex align-items-center "
      >
        <AccessTimeIcon className="watch-icon" /> We are available from{" "}
        {availability.startTime} to {availability.endTime}.
      </Typography>

      <div
        // className="demo-app-main"
        className="my-calendar-container calender-tile calender-button"
      >
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          initialView="dayGridMonth"
          weekends={true}
          initialDate={new Date()}
          events={events}
          dayMaxEvents={1}
          customButtons={{
            today: { text: "today" },
          }}
          eventContent={renderEventContent}
          eventDisplay="list-item"
          eventBorderColor="red"
          eventBackgroundColor="green"
        />
      </div>

      <Dialog
        open={isPopupOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
        className="book-modalMain"
      >
        <DialogContent className="modal-content-wrapper">
          <DialogContentText
            id="alert-dialog-description"
            className="modal-content"
          >
            <Grid item xs={12} md={12} className="input pt-0" sx={{ mb: 2 }}>
              <Box className="form-heading-section  bookDemo-modalTitle">
                <Typography
                  className="form-heading d-flex align-items-center justify-content-between w-100"
                  style={{ color: "black" }}
                >
                  Select Time
                  <CloseIcon
                    onClick={(e) => {
                      handleClose();
                    }}
                  />
                </Typography>
              </Box>
            </Grid>

            <Box className="item">
              <DemoTimeSlot
                selectedDate={selectedDate}
                handleClose={handleClose}
                onTimeSlotSelect={handleTimeSlotSelect}
              />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="modal-button"></DialogActions>
      </Dialog>
    </div>
  );
};

export default Calendar;
