import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import "../../pages/superAdmin/Timesheet/timesheet.css";
import { Popover, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import "../../assets/css/dashboard.css";
import "../../assets/css/Appointment.css";
import noImage from "../../assets/images/noImage.png";
import call from "../../assets/images/call.svg";
import locationIcon from "../../assets/images/location.svg";
import mail from "../../assets/images/mail.svg";
import mapIcon from "../../assets/images/mapIcon.svg";
// import Progress from "./Progress";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import NewProgress from "./NewProgress";
import ClientImage from "../../assets/images/client.jpg";
import axios from "axios";
import { getClientdetails, getStatelist } from "../../api";
import { Link } from "react-router-dom";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import moment from "moment";
export default function ClientDetailsCard(props) {
  const location = useLocation();

  const userPrimaryId = props.userPrimaryId || location.state;

  const [showResults, setShowResults] = React.useState(false);
  const [state, setState] = useState();
  const [stateName, setStateName] = useState();
  const [isActive, setIsActive] = useState(false);
  let userData = JSON.parse(localStorage.getItem("userData"));
  function getProgress(start, end) {
    if (
      start === undefined ||
      end === undefined ||
      start === null ||
      end === null
    ) {
      return 0;
    } else {
      const now = new Date().valueOf();
      start = new Date(start).valueOf();
      end = new Date(end).valueOf();

      let progress = ((now - start) / (end - start)) * 100;
      progress = Math.max(progress, 0); //
      progress = Math.min(progress, 100); // Cap the progress at 100%
      return progress;
    }
  }

  const [isLoading, setisLoading] = useState(true);
  const [clientDetail, setClientDetail] = useState([]);

  let userDataSession = JSON.parse(localStorage.getItem("userData"));
  let userTypeName = userDataSession?.userTypeName;
  let role = userDataSession?.role;
  let isSoleTrader = userDataSession?.isSoleTrader;
  let userTypeUrl = "super-admin";
  if (userTypeName === "Service Provider") {
    userTypeUrl = "service-provider";
  } else if (userTypeName === "Support Coordinator") {
    userTypeUrl = "support-coordinator";
  } else if (userTypeName === "Admin") {
    userTypeUrl = "admin";
  } else if (userTypeName === "Health Carer") {
    userTypeUrl = "health-carer";
  }

  const getLastRenewHistory = (history) => {
    if (Array.isArray(history) && history.length > 0) {
      return history[history.length - 1];
    }
    return null;
  };

  useEffect(() => {
    setClientDetail([]);
    if (Array.isArray(userPrimaryId)) {
      Promise.all(
        userPrimaryId?.map(async (user) => {
          const clientRes = await axios.get(`${getClientdetails}/${user._id}`);
          const clientData = clientRes?.data?.data;
          return {
            clientData,
            userId: user._id,
            userFullName: user.userFullName,
          };
        })
      )
        .then((responses) => {
          const allClientData = responses.reduce((acc, { clientData }) => {
            return acc.concat(clientData || []);
          }, []);
          setClientDetail(allClientData);
          setisLoading(false);
        })
        .catch((error) => {
          setisLoading(false);
          console.log(error);
        });
    } else {
      axios
        .get(`${getClientdetails}/${userPrimaryId}`)
        .then((res) => {
          const clientDetailsData = res?.data?.data[0];
          if (clientDetailsData !== undefined) {
            setClientDetail(clientDetailsData);
            setState(clientDetailsData?.address?.state);
          }
          setisLoading(false);
        })
        .catch((error) => {
          setisLoading(false);
          console.log(error);
        });
    }
  }, [userPrimaryId]);

  useEffect(() => {
    axios
      .get(`${getStatelist}/${state}`)
      .then((res) => {
        setStateName(res?.data?.data[0]?.name);
      })
      .catch((error) => {
        console.log(error);
      });
  });

  function toogleShow() {
    setShowResults(!showResults);
    setIsActive((current) => !current);
  }

  function getAge(dateString) {
    if (dateString === null || dateString === undefined) {
      return null;
    }
    const parts = dateString.split("-");
    if (parts.length !== 3) {
      return null;
    }

    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);

    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      return null;
    }

    const today = new Date();
    const birthDate = new Date(year, month - 1, day); // Month is 0-based

    if (isNaN(birthDate.getTime())) {
      return null;
    }

    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age >= 0 ? age : null;
  }

  const renderMobileNumber = (clientDetail) => {
    if (
      !clientDetail ||
      !clientDetail?.mobileNumber ||
      clientDetail?.mobileNumber === ""
    ) {
      return "NA";
    }

    // Format the mobile number if needed
    const formattedMobileNumber = clientDetail?.mobileNumber; // You can add formatting logic if required

    // Render the clickable mobile number
    return (
      <a href={`tel:${formattedMobileNumber}`} className="mobile-number">
        {formattedMobileNumber}
      </a>
    );
  };

  const progressDataVal = getProgress(
    clientDetail?.planDetails?.planStartDate,
    clientDetail?.planDetails?.planEndDate
  );

  const getProgressMutliClient = (start, end) => {
    if (
      start === undefined ||
      end === undefined ||
      start === null ||
      end === null
    ) {
      return "In Progress";
    } else {
      const now = new Date().valueOf();
      start = new Date(start).valueOf();
      end = new Date(end).valueOf();

      let progress = ((now - start) / (end - start)) * 100;
      progress = Math.max(progress, 0); //
      progress = Math.min(progress, 100); // Cap the progress at 100%
      const result = progressDataVal >= 100 ? "Finished" : "In Progress";
      return result;
    }
  };

  let setSupportTypeMultiple =
    clientDetail?.planDetails?.supportTypeDetailsMultiple;

  console.log("setSupportTypeMultiple", setSupportTypeMultiple);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip
      placement="left"
      arrow
      {...props}
      classes={{ popper: className }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#fff",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 260,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #f9f9f9",
      boxShadow: "0 0 10px #f1f1f1",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
      "&::before": {
        backgroundColor: theme.palette.common.white,
        border: "1px solid #f9f9f9",
        boxShadow: "0 0 10px #f1f1f1",
      },
    },
  }));

  const lastRenewHistory = getLastRenewHistory(
    clientDetail?.agreementRenewHistory
  );

  return (
    <>
      {clientDetail[0] == undefined ? (
        <Box className="hide-box">
          <Grid container spacing={4} className="align-items-start ">
            {/* <> */}

            <Grid
              item
              xs={12}
              sm={3}
              md={3}
              className="input flex-column hide-client-img-main d-flex pt-0"
            >
              <Box className="hide-client-img-box hide-client-img-box-height">
                <Box className="hide-client-img">
                  <img
                    src={
                      clientDetail?.image?.location
                        ? clientDetail?.image?.location
                        : noImage
                    }
                  ></img>
                </Box>
                <Box className="client-profile-detail">
                  <Typography className="">
                    {" "}
                    {clientDetail?.userFullName !== ""
                      ? clientDetail?.userFullName
                      : "NA"}
                    , &nbsp;
                    {clientDetail?.DOB !== undefined &&
                    clientDetail?.DOB !== null &&
                    clientDetail?.DOB !== ""
                      ? getAge(clientDetail?.DOB)
                      : "NA"}{" "}
                    yr, &nbsp;
                    {clientDetail?.gender !== "" ? clientDetail?.gender : "NA"}
                  </Typography>

                  <span>
                    {role === "superAdmin" ||
                    role === "serviceProvider" ||
                    (role === "supportCoordinator" && isSoleTrader) ? (
                      <Link
                        to={`/${userTypeUrl}/view-client-details?id=${userPrimaryId}`}
                      >
                        ID -{" "}
                        {clientDetail?.employeeID !== ""
                          ? clientDetail?.employeeID
                          : "NA"}
                      </Link>
                    ) : (
                      <span>
                        {" "}
                        ID -{" "}
                        {clientDetail?.employeeID !== ""
                          ? clientDetail?.employeeID
                          : "NA"}
                      </span>
                    )}
                  </span>
                </Box>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={8}
              md={8}
              className="input flex-column d-flex pt-0"
            >
              <Grid container spacing={4} className="align-items-start">
                <Grid item xs={12} sm={4} md={4}>
                  <Box>
                    <Box className="hide-content">
                      <Typography>
                        {" "}
                        <img src={call}></img>{" "}
                        {/* <span className="mobile-number">
                      {" "}
                      {clientDetail?.mobileNumber !== ""
                        ? clientDetail?.mobileNumber
                        : "NA"}
                    </span> */}
                        {role !== "healthCarer" ? (
                          <span>{renderMobileNumber(clientDetail)}</span>
                        ) : (
                          "************"
                        )}
                      </Typography>
                    </Box>

                    {role !== "healthCarer" ? (
                      <Box className="hide-content">
                        <Typography>
                          {/* D.O.B{" "} */}
                          Date of Birth{" "}
                          <span>
                            {clientDetail?.DOB ? clientDetail?.DOB : "NA"}
                          </span>
                        </Typography>
                      </Box>
                    ) : (
                      ""
                    )}
                    <Box className="hide-content">
                      <Typography>
                        NDIS Client{" "}
                        {role !== "healthCarer" ? (
                          <span>
                            {clientDetail?.planDetails?.ndisClient === true
                              ? "Yes"
                              : "No"}
                          </span>
                        ) : (
                          "*****"
                        )}
                      </Typography>
                    </Box>
                    <Box className="hide-content">
                      <Typography>
                        Category{" "}
                        <span>
                          {" "}
                          {clientDetail?.category?.map((x) => x).join(", ")
                            ? clientDetail?.category?.map((x) => x).join(", ")
                            : "NA"}
                        </span>
                      </Typography>
                    </Box>

                    {/* setSupportTypeMultiple */}
                    {/* <Box className="hide-content">
                      <Typography>
                        Support Type{" "}
                        <span>
                          {" "}
                          {clientDetail?.planDetails?.supportTypeName}
                        </span>
                      </Typography>
                    </Box> */}

                    <Box className="hide-content">
                      <Typography>
                        Support Type{" "}
                        <span>
                          {" "}
                          {setSupportTypeMultiple
                            ?.map((x) => x?.name)
                            .join(", ")
                            ? setSupportTypeMultiple
                                ?.map((x) => x?.name)
                                .join(", ")
                            : "NA"}
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={4} md={4}>
                  <Box>
                    <Box className="hide-content">
                      <Box className="d-flex align-items-center wp-no ">
                        <Box className="">
                          <img src={mail}></img>
                        </Box>

                        <Typography
                          // className="hide-content-map-text"
                          title={
                            clientDetail?.Email !== ""
                              ? clientDetail?.Email
                              : "NA"
                          }
                        >
                          {role === "superAdmin" ||
                          role === "serviceProvider" ||
                          (role === "supportCoordinator" && isSoleTrader) ? (
                            <Link
                              to={"/" + userTypeUrl + "/email"}
                              state={{ email: clientDetail?.Email }}
                            >
                              &nbsp;{" "}
                              {clientDetail?.Email ? clientDetail?.Email : "NA"}
                            </Link>
                          ) : role === "healthCarer" ? (
                            <span>
                              &nbsp;{" "}
                              {clientDetail?.Email ? "**************" : "NA"}
                            </span>
                          ) : (
                            <span>
                              &nbsp;{" "}
                              {clientDetail?.Email ? clientDetail?.Email : "NA"}
                            </span>
                          )}
                        </Typography>
                      </Box>
                    </Box>

                    <Box className="hide-content">
                      <Typography>
                        Agreement Status{" "}
                        {/* <span>
                      {clientDetail?.status !== "" &&
                      clientDetail?.status !== undefined &&
                      clientDetail?.status !== null
                        ? clientDetail?.status
                        : "NA"}
                    </span> */}
                        <span>
                          {/* {progressDataVal >= 100 ? "Finished" : "In Progress"} */}
                          {lastRenewHistory?.isCanceled === true
                            ? "Cancelled"
                            : lastRenewHistory?.planEndDate &&
                              moment(lastRenewHistory?.planEndDate).isBefore(
                                moment()
                              )
                            ? "Expired"
                            : lastRenewHistory?.isCanceled === false
                            ? "Active"
                            : "NA"}
                        </span>
                      </Typography>
                    </Box>
                    <Box className="hide-content">
                      <Typography>
                        NDIS No &nbsp;
                        {role !== "healthCarer" ? (
                          clientDetail?.planDetails?.ndisClient === true ? (
                            <span>
                              {clientDetail?.planDetails?.ndisNumber !== ""
                                ? clientDetail?.planDetails?.ndisNumber
                                : "NA"}
                            </span>
                          ) : (
                            <span> NA</span>
                          )
                        ) : (
                          "****-****-****"
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                {console.log("clientDetailclientDetail", clientDetail)}
                <Grid item xs={12} sm={4} md={4}>
                  <Box>
                    <Box className="hide-content">
                      <Box className="d-flex align-items-center ">
                        {/* <Box className="mapicon client-address-icon"> */}
                        {/* {" "} */}
                        <Typography
                          className="hide-content-map-text"
                          aria-owns={open ? "mouse-over-popover" : undefined}
                          aria-haspopup="true"
                          onMouseEnter={handlePopoverOpen}
                          onMouseLeave={handlePopoverClose}
                        >
                          <img src={locationIcon}></img> {/* </Box> */}
                          {clientDetail?.address !== undefined
                            ? clientDetail?.address?.addressLine1
                              ? clientDetail?.address?.addressLine1 +
                                ", " +
                                clientDetail?.address?.city +
                                ", " +
                                (stateName !== undefined
                                  ? `${stateName},`
                                  : " ") +
                                clientDetail?.address?.postalCode
                              : "NA"
                            : " NA"}
                        </Typography>
                        <Box className="mapicon" sx={{ ml: 2 }}>
                          {" "}
                          <a
                            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                              clientDetail?.address?.addressLine1
                            )},${encodeURIComponent(
                              clientDetail?.address?.city
                            )},${encodeURIComponent(
                              stateName !== undefined ? stateName : ""
                            )},${encodeURIComponent(
                              clientDetail?.address?.postalCode
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: "none",
                              color: "inherit",
                            }}
                          >
                            <img src={mapIcon} alt="Map icon" />
                          </a>
                          {/* <img src={mapIcon}></img>{" "} */}
                        </Box>

                        {/* Popover */}

                        <Popover
                          id="mouse-over-popover"
                          sx={{
                            pointerEvents: "none",
                          }}
                          open={open}
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          onClose={handlePopoverClose}
                          disableRestoreFocus
                        >
                          <Typography sx={{ p: 1 }}>
                            {clientDetail?.address !== undefined
                              ? clientDetail?.address?.addressLine1
                                ? clientDetail?.address?.addressLine1 +
                                  ", " +
                                  clientDetail?.address?.city +
                                  ", " +
                                  (stateName !== undefined
                                    ? `${stateName}, `
                                    : " ") +
                                  clientDetail?.address?.postalCode
                                : "NA"
                              : " NA"}
                          </Typography>
                        </Popover>

                        {/* Popover */}
                      </Box>
                    </Box>

                    {/* for the time being NDIS zone is static  */}

                    {userData?.role !== undefined &&
                    userData?.role !== null &&
                    (userData?.role === "superAdmin" ||
                      userData?.role === "serviceProvider" ||
                      userData?.role === "supportCoordinator") ? (
                      <Box className="hide-content">
                        <Typography>
                          Funding{" "}
                          <span>
                            <Link
                              to={"/" + userTypeUrl + "/list-funds-management"}
                              state={userPrimaryId}
                            >
                              $
                              {clientDetail?.planDetails
                                ?.currentFundingAvailable !== undefined &&
                              clientDetail?.planDetails
                                ?.currentFundingAvailable !== null &&
                              clientDetail?.planDetails
                                ?.currentFundingAvailable !== ""
                                ? clientDetail?.planDetails
                                    ?.currentFundingAvailable
                                : "NA"}
                            </Link>
                          </span>
                        </Typography>
                      </Box>
                    ) : (
                      ""
                    )}

                    <Box className="hide-content">
                      <Typography>
                        Managed{" "}
                        <span>
                          {clientDetail?.planDetails?.ndiaManaged === true &&
                            "NDIA/NDIS"}
                          {clientDetail?.planDetails?.ndiaManaged === true &&
                            clientDetail?.planDetails?.planManaged === true &&
                            ", "}
                          {clientDetail?.planDetails?.planManaged === true &&
                            "Plan"}
                          {(clientDetail?.planDetails?.ndiaManaged === true ||
                            clientDetail?.planDetails?.planManaged === true) &&
                            clientDetail?.planDetails?.selfManaged === true &&
                            ", "}
                          {clientDetail?.planDetails?.selfManaged === true &&
                            "Self"}
                        </span>
                      </Typography>
                    </Box>

                    {/* <Box className="hide-content">
                  <Typography>
                    NDIS Zone{" "}
                    <span>
                      {clientDetail?.ndisZone ? clientDetail?.ndisZone : " "}
                    </span>
                  </Typography>
                </Box> */}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={4} md={4} className="pt-0">
                  <Box className="hide-content">
                    <Typography>
                      State{" "}
                      <span>
                        {clientDetail?.stateName !== null &&
                        clientDetail?.stateName !== undefined &&
                        clientDetail?.stateName !== ""
                          ? clientDetail?.stateName
                          : "NA"}
                      </span>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={4} className="pt-0">
                  <Box className="hide-content">
                    <Typography>
                      Region{" "}
                      <span>
                        {clientDetail?.regionData !== undefined &&
                        clientDetail?.regionData !== null &&
                        clientDetail?.regionData !== ""
                          ? clientDetail?.regionData
                          : "NA"}
                      </span>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={4} className="pt-0">
                  <Box className="hide-content">
                    <Typography>
                      Zone{" "}
                      <span>
                        {clientDetail?.zoneName !== null &&
                        clientDetail?.zoneName !== undefined &&
                        clientDetail?.zoneName !== ""
                          ? clientDetail?.zoneName
                          : "NA"}
                      </span>
                    </Typography>
                  </Box>
                </Grid>

                {/* this condition added https://codeplateau.atlassian.net/browse/HUB-7994 */}
                {role !== "healthCarer" ? (
                  <>
                    <Grid item xs={12} sm={12} md={12} className="pt-0">
                      {/* hide-content */}
                      <Box className="client-buttons-box pb-0">
                        {/* <Typography>Agreement Period</Typography> */}
                        {(() => {
                          const progress = getProgress(
                            clientDetail?.planDetails?.planStartDate,
                            clientDetail?.planDetails?.planEndDate
                          );

                          // Display the Note based on the progress.completed value
                          // Display the Note based on the progress.completed value
                          if (progress >= 80) {
                            return (
                              <>
                                {progress >= 80 && (
                                  <>
                                    <Stack direction="row" spacing={5}>
                                      <span
                                        style={{
                                          // display: "inline",
                                          color: "#202E2A",
                                        }}
                                      >
                                        Agreement Period
                                      </span>

                                      <div>
                                        <HtmlTooltip
                                          title={
                                            lastRenewHistory?.isCanceled
                                              ? lastRenewHistory.cancellationReason
                                              : ""
                                          }
                                        >
                                          <IconButton
                                            className="header-notification-icon"
                                            size="small"
                                            // sx={{ ml: 2 }}
                                            aria-controls={
                                              open ? "account-menu" : undefined
                                            }
                                            aria-haspopup="true"
                                            aria-expanded={
                                              open ? "true" : undefined
                                            }
                                          >
                                            <Badge
                                              badgeContent={
                                                lastRenewHistory?.isCanceled ===
                                                true
                                                  ? "Cancelled"
                                                  : moment(
                                                      lastRenewHistory?.planEndDate
                                                    ).isBefore(moment())
                                                  ? "Expired"
                                                  : ""
                                              }
                                              color={
                                                lastRenewHistory?.isCanceled ===
                                                true
                                                  ? "warning"
                                                  : moment(
                                                      lastRenewHistory?.planEndDate
                                                    ).isBefore(moment())
                                                  ? "error"
                                                  : "default"
                                              }
                                            />
                                          </IconButton>
                                        </HtmlTooltip>
                                      </div>

                                      <span
                                        style={{
                                          color: "red",
                                          // display: "inline",
                                        }}
                                      >
                                        &nbsp;&nbsp;Please Renew your agreement
                                      </span>
                                    </Stack>
                                  </>
                                )}
                              </>
                            );
                          } else {
                            // If progress.completed is less than 80%, show the Agreement Period label
                            return (
                              <>
                                <Stack direction="row" spacing={6}>
                                  <Typography>Agreement Period</Typography>

                                  <div>
                                    <HtmlTooltip
                                      title={
                                        lastRenewHistory?.isCanceled
                                          ? lastRenewHistory.cancellationReason
                                          : ""
                                      }
                                    >
                                      <IconButton
                                        className="header-notification-icon"
                                        size="small"
                                        sx={{ ml: 2 }}
                                        aria-controls={
                                          open ? "account-menu" : undefined
                                        }
                                        aria-haspopup="true"
                                        aria-expanded={
                                          open ? "true" : undefined
                                        }
                                      >
                                        <Badge
                                          badgeContent={
                                            lastRenewHistory?.isCanceled ===
                                            true
                                              ? "Cancelled"
                                              : moment(
                                                  lastRenewHistory?.planEndDate
                                                ).isBefore(moment())
                                              ? "Expired"
                                              : ""
                                          }
                                          color={
                                            lastRenewHistory?.isCanceled ===
                                            true
                                              ? "warning"
                                              : moment(
                                                  lastRenewHistory?.planEndDate
                                                ).isBefore(moment())
                                              ? "error"
                                              : "default"
                                          }
                                        />
                                      </IconButton>
                                    </HtmlTooltip>
                                  </div>
                                </Stack>
                              </>
                            );
                          }
                        })()}
                      </Box>
                      <Box className="hide-client-progress">
                        <NewProgress
                          progressValue={getProgress(
                            clientDetail?.planDetails?.planStartDate,
                            clientDetail?.planDetails?.planEndDate
                          )}
                          planStartDate={
                            clientDetail?.planDetails?.planStartDate
                          }
                          planEndDate={clientDetail?.planDetails?.planEndDate}
                        />
                      </Box>
                    </Grid>
                  </>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>

            {/* </> */}
          </Grid>
        </Box>
      ) : (
        <>
          {clientDetail?.map((clientDetail) => {
            return (
              <Box className="hide-box" sx={{ marginTop: "10px" }}>
                <Grid container spacing={4} className="align-items-start ">
                  {/* <> */}

                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={3}
                    className="input flex-column hide-client-img-main d-flex pt-0"
                  >
                    <Box className="hide-client-img-box hide-client-img-box-height">
                      <Box className="hide-client-img">
                        <img
                          src={
                            clientDetail?.image?.location
                              ? clientDetail?.image?.location
                              : noImage
                          }
                        ></img>
                      </Box>
                      <Box className="client-profile-detail">
                        <Typography className="">
                          {" "}
                          {clientDetail?.userFullName !== ""
                            ? clientDetail?.userFullName
                            : "NA"}
                          , &nbsp;
                          {clientDetail?.DOB !== undefined &&
                          clientDetail?.DOB !== null &&
                          clientDetail?.DOB !== ""
                            ? getAge(clientDetail?.DOB)
                            : "NA"}{" "}
                          yr, &nbsp;
                          {clientDetail?.gender !== ""
                            ? clientDetail?.gender
                            : "NA"}
                        </Typography>

                        <span>
                          {role === "superAdmin" ||
                          role === "serviceProvider" ||
                          (role === "supportCoordinator" && isSoleTrader) ? (
                            <Link
                              to={`/${userTypeUrl}/view-client-details?id=${userPrimaryId}`}
                            >
                              ID -{" "}
                              {clientDetail?.employeeID !== ""
                                ? clientDetail?.employeeID
                                : "NA"}
                            </Link>
                          ) : (
                            <span>
                              {" "}
                              ID -{" "}
                              {clientDetail?.employeeID !== ""
                                ? clientDetail?.employeeID
                                : "NA"}
                            </span>
                          )}
                        </span>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={8}
                    md={8}
                    className="input flex-column d-flex pt-0"
                  >
                    <Grid container spacing={4} className="align-items-start">
                      <Grid item xs={12} sm={4} md={4}>
                        <Box>
                          <Box className="hide-content">
                            <Typography>
                              {" "}
                              <img src={call}></img>{" "}
                              {/* <span className="mobile-number">
                        {" "}
                        {clientDetail?.mobileNumber !== ""
                          ? clientDetail?.mobileNumber
                          : "NA"}
                      </span> */}
                              {role !== "healthCarer" ? (
                                <span>{renderMobileNumber(clientDetail)}</span>
                              ) : (
                                "************"
                              )}
                            </Typography>
                          </Box>
                          {role !== "healthCarer" ? (
                            <Box className="hide-content">
                              <Typography>
                                {/* D.O.B{" "} */}
                                Date of Birth{" "}
                                <span>
                                  {clientDetail?.DOB ? clientDetail?.DOB : "NA"}
                                </span>
                              </Typography>
                            </Box>
                          ) : (
                            ""
                          )}
                          <Box className="hide-content">
                            <Typography>
                              NDIS Client{" "}
                              {role !== "healthCarer" ? (
                                <span>
                                  {clientDetail?.planDetails?.ndisClient ===
                                  true
                                    ? "Yes"
                                    : "No"}
                                </span>
                              ) : (
                                "*****"
                              )}
                            </Typography>
                          </Box>
                          <Box className="hide-content">
                            <Typography>
                              Category{" "}
                              <span>
                                {" "}
                                {clientDetail?.category
                                  ?.map((x) => x)
                                  .join(", ")
                                  ? clientDetail?.category
                                      ?.map((x) => x)
                                      .join(", ")
                                  : "NA"}
                              </span>
                            </Typography>
                          </Box>

                          <Box className="hide-content">
                            <Typography>
                              Support Type{" "}
                              <span>
                                {" "}
                                {clientDetail?.planDetails?.supportTypeName}
                              </span>
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={4} md={4}>
                        <Box>
                          <Box className="hide-content">
                            <Box className="d-flex align-items-center ">
                              <Box className="">
                                <img src={mail}></img>
                              </Box>

                              <Typography
                                // className="hide-content-map-text"
                                title={
                                  clientDetail?.Email !== ""
                                    ? clientDetail?.Email
                                    : "NA"
                                }
                              >
                                {role === "superAdmin" ||
                                role === "serviceProvider" ||
                                (role === "supportCoordinator" &&
                                  isSoleTrader) ? (
                                  <Link
                                    to={"/" + userTypeUrl + "/email"}
                                    state={{ email: clientDetail?.Email }}
                                  >
                                    &nbsp;{" "}
                                    {clientDetail?.Email
                                      ? clientDetail?.Email
                                      : "NA"}
                                  </Link>
                                ) : role === "healthCarer" ? (
                                  <span>
                                    &nbsp;{" "}
                                    {clientDetail?.Email
                                      ? "**************"
                                      : "NA"}
                                  </span>
                                ) : (
                                  <span>
                                    &nbsp;{" "}
                                    {clientDetail?.Email
                                      ? clientDetail?.Email
                                      : "NA"}
                                  </span>
                                )}
                              </Typography>
                            </Box>
                          </Box>

                          <Box className="hide-content">
                            <Typography>
                              Agreement Status{" "}
                              {/* <span>
                        {clientDetail?.status !== "" &&
                        clientDetail?.status !== undefined &&
                        clientDetail?.status !== null
                          ? clientDetail?.status
                          : "NA"}
                      </span> */}
                              <span>
                                {getProgressMutliClient(
                                  clientDetail?.planDetails?.planStartDate,
                                  clientDetail?.planDetails?.planEndDate
                                )}
                              </span>
                            </Typography>
                          </Box>
                          <Box className="hide-content">
                            <Typography>
                              NDIS No &nbsp;
                              {clientDetail?.planDetails?.ndisClient ===
                              true ? (
                                role !== "healthCarer" ? (
                                  <span>
                                    {clientDetail?.planDetails?.ndisNumber !==
                                    ""
                                      ? clientDetail?.planDetails?.ndisNumber
                                      : "NA"}
                                  </span>
                                ) : (
                                  <span>{"****-****-****"}</span>
                                )
                              ) : (
                                <span>{"****-****-****"}</span>
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Box>
                          <Box className="hide-content">
                            <Box className="d-flex align-items-center ">
                              {/* <Box className="mapicon client-address-icon"> */}
                              {/* {" "} */}
                              <Typography className="hide-content-map-text">
                                <img src={locationIcon}></img> {/* </Box> */}
                                {clientDetail?.address !== undefined &&
                                clientDetail?.address.length > 0
                                  ? clientDetail?.address[0]?.addressLine1 +
                                    ", " +
                                    clientDetail?.address[0]?.city +
                                    ", " +
                                    (stateName !== undefined
                                      ? `${stateName}, `
                                      : " ") +
                                    clientDetail?.address?.postalCode
                                  : " NA"}
                              </Typography>
                              <Box className="mapicon" sx={{ ml: 2 }}>
                                {" "}
                                <a
                                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                                    clientDetail?.address[0]?.addressLine1
                                  )},${encodeURIComponent(
                                    clientDetail?.address[0]?.city
                                  )}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                  }}
                                >
                                  <img src={mapIcon}></img>{" "}
                                </a>
                                {/* <img src={mapIcon}></img>{" "} */}
                              </Box>
                            </Box>
                          </Box>

                          {/* for the time being NDIS zone is static  */}

                          {userData?.role !== undefined &&
                          userData?.role !== null &&
                          (userData?.role === "superAdmin" ||
                            userData?.role === "serviceProvider" ||
                            userData?.role === "supportCoordinator") ? (
                            <Box className="hide-content">
                              <Typography>
                                Funding{" "}
                                <span>
                                  <Link
                                    to={
                                      "/" +
                                      userTypeUrl +
                                      "/list-funds-management"
                                    }
                                    state={userPrimaryId}
                                  >
                                    $
                                    {clientDetail?.planDetails
                                      ?.currentFundingAvailable !== undefined &&
                                    clientDetail?.planDetails
                                      ?.currentFundingAvailable !== null &&
                                    clientDetail?.planDetails
                                      ?.currentFundingAvailable !== ""
                                      ? clientDetail?.planDetails
                                          ?.currentFundingAvailable
                                      : "NA"}
                                  </Link>
                                </span>
                              </Typography>
                            </Box>
                          ) : (
                            ""
                          )}

                          <Box className="hide-content">
                            <Typography>
                              Managed{" "}
                              <span>
                                {clientDetail?.planDetails?.ndiaManaged ===
                                  true && "NDIA/NDIS"}
                                {clientDetail?.planDetails?.ndiaManaged ===
                                  true &&
                                  clientDetail?.planDetails?.planManaged ===
                                    true &&
                                  ", "}
                                {clientDetail?.planDetails?.planManaged ===
                                  true && "Plan"}
                                {(clientDetail?.planDetails?.ndiaManaged ===
                                  true ||
                                  clientDetail?.planDetails?.planManaged ===
                                    true) &&
                                  clientDetail?.planDetails?.selfManaged ===
                                    true &&
                                  ", "}
                                {clientDetail?.planDetails?.selfManaged ===
                                  true && "Self"}
                              </span>
                            </Typography>
                          </Box>

                          {/* <Box className="hide-content">
                    <Typography>
                      NDIS Zone{" "}
                      <span>
                        {clientDetail?.ndisZone ? clientDetail?.ndisZone : " "}
                      </span>
                    </Typography>
                  </Box> */}
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={4} md={4} className="pt-0">
                        <Box className="hide-content">
                          <Typography>
                            State{" "}
                            <span>
                              {clientDetail?.stateName !== null &&
                              clientDetail?.stateName !== undefined &&
                              clientDetail?.stateName !== ""
                                ? clientDetail?.stateName
                                : "NA"}
                            </span>
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} className="pt-0">
                        <Box className="hide-content">
                          <Typography>
                            Region{" "}
                            <span>
                              {clientDetail?.regionData !== undefined &&
                              clientDetail?.regionData !== null &&
                              clientDetail?.regionData !== ""
                                ? clientDetail?.regionData
                                : "NA"}
                            </span>
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} className="pt-0">
                        <Box className="hide-content">
                          <Typography>
                            Zone{" "}
                            <span>
                              {clientDetail?.zoneName !== null &&
                              clientDetail?.zoneName !== undefined &&
                              clientDetail?.zoneName !== ""
                                ? clientDetail?.zoneName
                                : "NA"}
                            </span>
                          </Typography>
                        </Box>
                      </Grid>

                      {/* this condition added https://codeplateau.atlassian.net/browse/HUB-7994 */}
                      {role !== "healthCarer" ? (
                        <>
                          <Grid item xs={12} sm={12} md={12} className="pt-0">
                            <Box className="hide-content pb-0">
                              {/* <Typography>Agreement Period</Typography> */}
                              {(() => {
                                const progress = getProgress(
                                  clientDetail?.planDetails?.planStartDate,
                                  clientDetail?.planDetails?.planEndDate
                                );
                                // Display the Note based on the progress.completed value
                                if (progress >= 80) {
                                  return (
                                    <>
                                      <Stack direction="row" spacing={2}>
                                        <span
                                          style={{
                                            display: "inline",
                                            color: "#202E2A",
                                          }}
                                        >
                                          Agreement Period
                                        </span>

                                        {progress >= 80 && (
                                          <span
                                            style={{
                                              color: "red",
                                              display: "inline",
                                            }}
                                          >
                                            &nbsp;&nbsp;Please Renew your
                                            agreement
                                          </span>
                                        )}
                                      </Stack>
                                    </>
                                  );
                                } else {
                                  // If progress.completed is less than 80%, show the Agreement Period label
                                  return (
                                    <Typography>Agreement Period</Typography>
                                  );
                                }
                              })()}
                            </Box>
                            <Box className="hide-client-progress">
                              <NewProgress
                                progressValue={getProgress(
                                  clientDetail?.planDetails?.planStartDate,
                                  clientDetail?.planDetails?.planEndDate
                                )}
                                planStartDate={
                                  clientDetail?.planDetails?.planStartDate
                                }
                                planEndDate={
                                  clientDetail?.planDetails?.planEndDate
                                }
                              />
                            </Box>
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>

                  {/* </> */}
                </Grid>
              </Box>
            );
          })}
        </>
      )}
    </>
  );
}
