import { Box, Typography, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import AvailabilityCalender from "../../components/emavailability/AvailabilityCalender";

const AvailabilityPopup = ({ open, content, hcId, handleClose }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
      sx={{ "& .MuiDialog-paper": { width: "80%", maxWidth: "none" } }}
    >
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          className="modal-content"
        >
          <Grid item xs={12} md={12} className="input pt-0" sx={{ mb: 2 }}>
            <Box
              className="form-heading-section"
              sx={{
                backgroundColor: "#E2ECF2",
                borderRadius: "2px",
                p: 1,
                height: "50px",
                width: "100%",
              }}
            >
              <Typography className="form-heading" style={{ color: "black" }}>
                <b>Availability</b>
              </Typography>
            </Box>
          </Grid>
          <Box className="item">
            {/* <span>{content}</span> */}
            <AvailabilityCalender props={{ 
              sendRequestId: hcId,
              hcId: hcId 
              }} 
              />
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="modal-button">
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AvailabilityPopup;
