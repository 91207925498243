import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import WebHeader from "../Web-Header";
import WebFooter from "../Web-footer";
import { Link } from "@mui/material";
import BookDemo from "../HomeUI/BookDemo";
import HelpTabs from "./Help-Tabs";

const HelpCenter = () => {
  return (
    <Box id="help-center">
      <Box className="web-topBanner-bg  blog-main-banner">
        <WebHeader />

        <Box className="webContainer">
          <Box className="W-100 ">
            <Box className="home-content-main  space-lg">
              <Box className="text-center ">
                <h1>
                Help Center
                </h1>
                <Typography>
                Quick Answers to Common Queries: Explore our Frequently Asked <br /> Questions section to find concise and helpful solutions to your inquiries.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="blurBg blurRed"> </Box>
        {/* <Box className="blurBg blurPurple"> </Box> */}
        <Box className="blurBg blurYellow"></Box>
      </Box>

   

      {/* account details section  */}

      <Box className="space-pt-80">
      <Box className="webContainer">
            <HelpTabs/>
      </Box>
    
      </Box> 
    

      {/* book demo section  */}

      <Box className="space-m-80">
        <Box className="webContainer">
          <BookDemo />
        </Box>
      </Box>

      <WebFooter />
    </Box>
  );
};

export default HelpCenter;
