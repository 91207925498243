import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import WebHeader from "../../../Website/Web-Header";
// import { Link } from "@mui/material";
import { Button, Avatar } from "@mui/material";
import "../../../assets/css/dashboard.css";
import "../../../assets/css/web-css.css";
import "../../../assets/css/Sa-style.css";
import "../../../assets/css/Appointment.css";
import "../../../App.css";
import axios from "axios";
import noImage from "../../../assets/images/noImage.png";
import { useRef } from "react";
import SendIcon from "@mui/icons-material/Send";
import viewProfile from "../../../assets/images/explore-image.png";
import PropTypes from "prop-types";
import { useState, useEffect, useCallback } from "react";
import Rating from "@mui/material/Rating";
import MapImage from "../../../assets/images/google-maps-svg.png";
import ExpImage from "../../../assets/images/bag-icon.png";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import List from "@mui/material/List";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { toast } from "react-toastify";
import ListItem from "@mui/material/ListItem";
import CheckIcon from "@mui/icons-material/Check";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import googleMap from "../../../assets/images/googlemap.jpg";
import sendIcon from "../../../assets/images/send.png";
import HeartIcon from "../../../assets/images/heart-icon.png";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import doneIcon from "../../../assets/images/done-icon.png";
// import ViewDetailAvailability from "./View-Detail-Availability.js";
import AvailabilityCalender from "../../../components/emavailability/AvailabilityCalender.js";
import { useLocation, useNavigate, Link } from "react-router-dom";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import {
  detailsHcExploreMarket,
  getDetailsBySendTo,
  removeToFavorites,
  addToFavorites,
  getClientDetailsByUserId,
  addSendRequest,
  cancelsendRequest,
  getsendRequestDetails,
  updateRequestDetails,
  getAvailabilityByUserId,
} from "../../../api";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  DirectionsRenderer,
  AutocompleteService,
  InfoWindow,
} from "@react-google-maps/api";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ViewDetail = () => {
  const navigate = useNavigate();
  let userData = JSON.parse(localStorage.getItem("userData"));
  let hcData = JSON.parse(localStorage.getItem("exploreMarket"));
  let userId = userData?._id;

  const [value, setValue] = React.useState(5);
  const [isLoading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState([]);
  const [tabValue, settabValue] = React.useState(0);
  const [expanded, setExpanded] = React.useState("panel1");
  const [markers, setMarkers] = useState([]);
  const [map, setMap] = useState(/** @type google.maps.Map */(null));
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [workLocations, setWorkLocations] = useState([]);
  const [initialMarkerData, setInitialMarkerData] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [hoveredMarkerIndex, setHoveredMarkerIndex] = useState(null);
  const [access, setAccess] = useState(false);
  const mapRef = useRef(null);
  const placesServiceRef = useRef(null);
  const [requestSent, setRequestSent] = useState([]);
  const [favourite, setFavourite] = useState([]);
  const [sentRequestDetails, setSentRequestDetails] = useState();
  const [availability, setAvailability] = useState([]);

  const handleChange = (event, newValue) => {
    settabValue(newValue);
  };

  const location = useLocation();
  // let hcId = location?.state?.hcId;

  console.log(location, "location");
  let hcId;
  let createdBy;
  if (
    userData !== undefined &&
    userData !== null &&
    userData?.role == "client"
  ) {
    hcId = hcData.hcId;
  } else {
    hcId = location?.state?.hcId;
    createdBy = location?.state?.createdBy;

    if (hcId !== undefined && hcId !== null) {
      let exploreMarket =
        JSON.parse(localStorage.getItem("exploreMarket")) || {};
      exploreMarket.hcId = hcId;
      exploreMarket.createdBy = createdBy;
      localStorage.setItem("exploreMarket", JSON.stringify(exploreMarket));
    }
  }

  useEffect(() => {
    getaList();
  }, [hcId]);

  const getaList = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${detailsHcExploreMarket}/${hcId}`);
      let dataval = res?.data?.data;
      console.log("userDetails", dataval);
      setUserDetails(dataval);
      let personalDocuments = dataval[0]?.personalDocuments;
      console.log("dataval", dataval);
      const documentsArray = Object.keys(personalDocuments)
        .filter((documentType) =>
          Array.isArray(personalDocuments[documentType])
        )
        .map((documentType) => {
          return {
            documentType: documentType,
            documents: personalDocuments[documentType],
          };
        });

      setDocuments(documentsArray);
      setInitialMarkerData(dataval[0]?.preferedWorkLocations);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    console.log("userData", userData, "hcId", hcId);
    if (
      userData !== null &&
      userData !== undefined &&
      hcId !== undefined &&
      hcId !== null
    ) {
      getSendRequestDetailsFunction();
    }
  }, []);

  const getSendRequestDetailsFunction = async () => {
    setLoading(true);
    try {
      if (
        userData !== null &&
        userData !== undefined &&
        hcId !== undefined &&
        hcId !== null
      ) {
        console.log("userData._id", userData._id, "hcId", hcId);
        const res = await axios.get(
          `${getDetailsBySendTo}/${userData._id}/${hcId}`
        );
        console.log("res", res);
        let dataval = res?.data?.data;
        console.log("datavalll", dataval);
        setSentRequestDetails(dataval);
        setAccess(dataval?.documentRequest);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userData !== undefined && userData !== null) {
      getClientDetails();
    }
  }, []);

  const getClientDetails = async () => {
    setLoading(true);
    try {
      if (userData !== null && userData !== undefined) {
        const res = await axios.get(
          `${getClientDetailsByUserId}/${userData._id}`
        );
        setFavourite(res?.data?.data[0]?.favoriteHc);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userData !== undefined && userData !== null) {
      getSendRequestDetails();
    }
  }, []);

  const getSendRequestDetails = async () => {
    setLoading(true);
    try {
      if (userData !== null && userData !== undefined) {
        const res = await axios.get(`${getsendRequestDetails}/${userData._id}`);
        const sendToList = res?.data?.data.map((item) => item.sendTo);
        setRequestSent(sendToList);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const [autocompleteService, setAutocompleteService] = useState(null);
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);

  useEffect(() => {
    setAutocompleteService(new window.google.maps.places.AutocompleteService());
  }, []);

  const [predictions, setPredictions] = useState([]);

  useEffect(() => {
    // Load the AutocompleteService when the component mounts
    const autocomplete = new window.google.maps.places.AutocompleteService();
    setAutocompleteService(autocomplete);
  }, []);

  useEffect(() => {
    if (window.google && window.google.maps && window.google.maps.places) {
      placesServiceRef.current = new window.google.maps.places.PlacesService(
        mapRef.current
      );
    }
  }, []);

  const handleMapLoad = (map) => {
    mapRef.current = map;
  };

  const addFavoritesHC = async (hcId) => {
    try {
      let payload = {
        favoriteHc: hcId,
      };

      axios
        .put(`${addToFavorites}/${userId}`, payload)
        .then((res) => {
          toast.success(res?.data?.message);
          getClientDetails();
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const removeFavoritesHC = async (hcId) => {
    try {
      let payload = {
        favoriteHc: hcId,
      };

      axios
        .put(`${removeToFavorites}/${userId}`, payload)
        .then((res) => {
          toast.success(res.data.message);
          getClientDetails();
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const addRequestFunction = async (hcId) => {
    try {
      let payload = {
        sendBy: userData?._id,
        sendTo: hcId,
        createdBy: userData?._id,
        updatedBy: userData?._id,
      };

      axios
        .post(`${addSendRequest}`, payload)
        .then((res) => {
          toast.success(res.data.message);
          getSendRequestDetails();
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const removeRequestFunction = async (hcId) => {
    try {
      axios
        .put(`${cancelsendRequest}/${hcId}`)
        .then((res) => {
          toast.success(res.data.message);
          getSendRequestDetails();
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const RedirectDirectFunction = async (hcId) => {
    try {
      console.log("hcId", hcId);
      setTimeout(() => {
        navigateAgreement("1", hcId);
      }, 1000);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  const navigateAgreement = (event, newValue) => {
    console.log("newValue", newValue);
    navigate("/existing-user", {
      state: newValue,
    });
  };
  const cancelDocumentAccessRequestFunction = async (id) => {
    try {
      let payload = {
        documentRequest: false,
      };
      axios
        .put(`${updateRequestDetails}/${id}`, payload)
        .then((res) => {
          toast.success(res.data.message);
          getSendRequestDetailsFunction();
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const sendDocumentAccessRequestFunction = async (id) => {
    console.log("id", id);
    try {
      let payload = {
        documentRequest: true,
      };
      axios
        .put(`${updateRequestDetails}/${id}`, payload)
        .then((res) => {
          toast.success(res.data.message);
          getSendRequestDetailsFunction();
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  const calculateAverageRating = (ratings) => {
    console.log(ratings, "check 452");
    if (!ratings || ratings.length === 0) return 0;

    const sum = ratings.reduce((acc, rating) => acc + rating.ratingPoint, 0);
    console.log(sum, "check 456");
    return sum / ratings.length;
  };
  const handleDownload = async () => {
    if (documents?.length > 0) {
      const zip = new JSZip();

      const fetchPromises = [];

      documents?.forEach((val) => {
        val?.documents?.forEach((items) => {
          const url = new URL(items?.location);
          const fileName = decodeURIComponent(url.pathname.split("/").pop());

          const fetchPromise = fetch(items?.location)
            .then((response) => response.blob())
            .then((blob) => {
              zip.file(fileName, blob);
            })
            .catch((error) => {
              console.log("Download error:", error);
            });

          fetchPromises.push(fetchPromise);
        });
      });

      Promise.all(fetchPromises)
        .then(() => {
          zip.generateAsync({ type: "blob" }).then((content) => {
            saveAs(content, "documents.zip");
          });
        })
        .catch((error) => {
          console.log("Error fetching documents:", error);
        });
    }
  };

  function formatDocumentType(documentType) {
    // Split the documentType by camelCase
    const words = documentType.split(/(?=[A-Z])/);

    // Capitalize each word and join them with a space
    let formattedDocumentType = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    // Apply custom formatting for specific cases
    if (formattedDocumentType === "Police Clr") {
      formattedDocumentType = "Police Clearance";
    } else if (formattedDocumentType === "Ndis W C Worker Screening Number") {
      formattedDocumentType = "Ndis WC Worker Screening Number";
    } else if (formattedDocumentType === "Covid19 T C C") {
      formattedDocumentType = "Covid19 TCC";
    }

    return formattedDocumentType;
  }

  return (
    <>
      <Box id="view-details" className=" client-module-view-details">
        <Box>
          <WebHeader />

          <Box mb={5}>
            {/* <h3>View Details</h3> */}
          </Box>

          {userDetails?.map((item, index) => (
            <Box className="view-details-main-box webContainer">

              {/* Title/ Breadcrumb & Button section */}

              <Grid mb={3} container className="d-flex padding-left-20 padding-right-20 header-topsection">

                <Grid item xs={12} sm={6} md={6}>
                  <Box id="breadcrumb" className="ps-0">
                    <Link to="/get-support">
                      <ArrowBackIosIcon />
                    </Link>
                    <Box className="breadcrumb-inr">
                      <h4 className="d-flex align-items-center "> View Details </h4>
                      <Box className="breadcrumbs">
                        <Link className="breadcrumbs-link"> View Details </Link>{" "}
                        {/* <span className="breadcrumbs-icon">{">"}</span> */}

                        {/* <Link className="breadcrumbs-link breadcrumbs-link-active">
        pageTitle
        </Link> */}
                      </Box>
                    </Box>
                  </Box>
                </Grid>

              </Grid>

              {/* Title/ Breadcrumb & Button section */}

              <Box className="view-profile-detail">

                <Box className="d-flex align-items-start">
                  <Box className="view-detail-profile-image">
                    <Avatar aria-label="recipe" className="explore-icon">
                      {item?.isAvtarActivate === true ? (
                        <img
                          src={
                            item?.avtarImage !== null &&
                              item?.avtarImage !== undefined
                              ? item?.avtarImage?.location
                              : noImage
                          }
                          alt="explore.png"
                        />
                      ) : (
                        <img
                          src={
                            item?.image !== null &&
                              item?.image !== undefined
                              ? item?.image?.location
                              : noImage
                          }
                          alt="explore.png"
                        />
                      )}
                    </Avatar>
                  </Box>
                  <Box className="view-detail-profile-content">
                    <Box className="d-flex align-items-center ">
                      <Typography className="profile-title mobile-profile-view">
                        {" "}
                        {item?.firstName !== undefined &&
                          item?.lastName !== undefined
                          ? `${item?.firstName} ${item?.lastName}`
                          : "NA"}
                      </Typography>
                      <Box className="view-detail-rating-box">
                        <Rating
                          name="simple-controlled"
                          value={calculateAverageRating(item?.hcRating)}
                          readOnly
                        />
                      </Box>
                    </Box>

                    <span className="view-porfile-category">
                      {item?.jobTitle}
                    </span>

                    <Box className="view-detail-client-address card-details-pickup d-flex align-items-center">
                      <Box className="d-flex align-items-center">
                        <img src={MapImage} />
                        <Typography variant="body2" component="div">
                          {item?.city || item?.state
                            ? `${item?.city}, ${item?.state}`
                            : "NA"}
                        </Typography>
                      </Box>
                      <Box className="d-flex align-items-center card-icon-pickup" sx={{ ml: 3 }}>
                        <img src={ExpImage} />
                        <Typography className="exp-txt">
                          Exp:{" "}
                          <span>
                            {item?.experience ? `${item?.experience}` : "NA"}
                          </span>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box className="view-details-divider">
                <Box className="divider" sx={{ mt: 3 }}></Box>
                {/* view details tabs start  */}
              </Box>
              <Box className="view-tap-box">
                <Tabs
                  centered
                  className="tab-container border-none"
                  value={tabValue}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  {/* {<><p className="tab-text"> List View </p></>} */}
                  <Tab
                    className="view-tab"
                    iconPosition="start"
                    label="About"
                    {...a11yProps(0)}
                  />

                  <Tab
                    mt={2}
                    className="view-tab"
                    iconPosition="start"
                    label="Documents"
                    {...a11yProps(1)}
                  />

                  <Tab
                    mt={2}
                    className="view-tab"
                    iconPosition="start"
                    label="Availability"
                    {...a11yProps(2)}
                  />
                </Tabs>

                <Box className="view-detail-panel">
                  <TabPanel value={tabValue} index={0}>
                    <Grid
                      container
                      spacing={2}
                      className="about-panel-txt"
                      sx={{ mt: 1 }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        className="pt-0 view-detail-panel-grid"
                      >
                        <Box className="d-flex align-items-start">
                          <Typography>Qualification:</Typography>
                          <span>
                            {item?.qualification?.length
                              ? item.qualification.join(", ")
                              : "NA"}
                          </span>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        className="pt-0 view-detail-panel-grid"
                      >
                        <Box className="d-flex align-items-start">
                          <Typography>Badges:</Typography>
                          <span>{item?.badges ? item.badges : "NA"}</span>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        className="pt-0 view-detail-panel-grid"
                      >
                        <Box className="d-flex align-items-center">
                          <Typography>Skills:</Typography>
                          <Box className="view-tab-chip">
                            {item?.skills?.length ? (
                              item?.skills?.map((skill, index) => (
                                <Chip key={index} label={skill} />
                              ))
                            ) : (
                              <Chip label="NA" />
                            )}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        className="pt-0 view-detail-panel-grid"
                      >
                        <Box className="d-flex align-items-start">
                          <Typography>Languages:</Typography>
                          <span>
                            {item?.languages?.length
                              ? item.languages.join(", ")
                              : "NA"}
                          </span>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        className="pt-0 view-detail-panel-grid"
                      >
                        <Box className="d-flex align-items-start">
                          <Typography>Interests & Hobbies:</Typography>
                          <span>
                            {item?.interest?.length
                              ? item.interest.join(", ")
                              : "NA"}
                          </span>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        className="pt-0 view-detail-panel-grid"
                      >
                        <Box className="d-flex align-items-start">
                          <Typography>Immunization:</Typography>
                          <span>
                            {item?.immunization ? item?.immunization : "NA"}
                          </span>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        className="pt-0 view-detail-panel-grid"
                      >
                        <Box className="d-flex align-items-start">
                          <Typography>Religion:</Typography>
                          <span>{item?.religion ? item?.religion : "NA"}</span>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        className="pt-0 view-detail-panel-grid"
                      >
                        <Box className="d-flex align-items-start">
                          <Typography>About:</Typography>
                          <span>{item?.about ? item?.about : "NA"}</span>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        className="pt-0 view-detail-panel-grid"
                      >
                        <Box className="d-flex align-items-start">
                          <Typography>Preferred Work Locations:</Typography>
                          {/* <span>Hi, I am Tom. I’m passionate about helping people. I’m available to do household tasks, personal care and paperwork.</span> */}
                        </Box>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        className="pt-0 view-detail-panel-grid"
                      >
                        <Box className="google-map">
                          <GoogleMap
                            center={center}
                            ref={mapRef}
                            zoom={3}
                            // mapContainerStyle={{
                            //   width: "1040px",
                            //   height: "443px",
                            // }}
                            mapContainerStyle={{ width: "100%", height: "332px" }}
                            options={{
                              zoomControl: false,
                              streetViewControl: false,
                              mapTypeControl: false,
                              fullscreenControl: false,
                            }}
                            onLoad={handleMapLoad}
                          >
                            {center && <Marker position={center} />}
                            {directionsResponse && (
                              <DirectionsRenderer
                                directions={directionsResponse}
                              />
                            )}

                            {initialMarkerData?.map((marker, index) => {
                              return (
                                <Marker
                                  key={index}
                                  position={{
                                    lat: parseFloat(marker?.lat),
                                    lng: parseFloat(marker?.lng),
                                  }}
                                  onMouseOver={() =>
                                    setHoveredMarkerIndex(index)
                                  }
                                >
                                  {hoveredMarkerIndex === index && (
                                    <InfoWindow
                                      position={{
                                        lat: parseFloat(marker?.lat),
                                        lng: parseFloat(marker?.lng),
                                      }}
                                      onMouseOver={() =>
                                        setHoveredMarkerIndex(index)
                                      }
                                      onMouseOut={() =>
                                        setHoveredMarkerIndex(null)
                                      }
                                    >
                                      <div
                                        onMouseOver={() =>
                                          setHoveredMarkerIndex(index)
                                        }
                                        onMouseOut={() =>
                                          setHoveredMarkerIndex(null)
                                        }
                                      >
                                        <h4>Location Information</h4>

                                        <p>Location Name: {marker?.name}</p>
                                      </div>
                                    </InfoWindow>
                                  )}
                                </Marker>
                              );
                            })}
                          </GoogleMap>
                        </Box>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel
                    value={tabValue}
                    index={1}
                    className="view-details-document-panel"
                  >
                    <Box className="text-right">
                      {userData?.role == "client" ? (
                        <>
                          <Button
                            variant="contained"
                            className="res-iconbtn"
                            startIcon={<SendIcon />}
                            onClick={() => {
                              if (
                                access == true &&
                                sentRequestDetails?.isDocumentAccess == true
                              ) {
                                handleDownload();
                              } else if (
                                access &&
                                sentRequestDetails?.isDocumentAccess == false
                              ) {
                                cancelDocumentAccessRequestFunction(
                                  sentRequestDetails?._id
                                );
                              } else {
                                sendDocumentAccessRequestFunction(
                                  sentRequestDetails?._id
                                );
                              }
                            }}
                          >
                            {access
                              ? sentRequestDetails?.isDocumentAccess
                                ? "Download Documents"
                                : "Cancel Document Request"
                              : "Send Document Request"}
                          </Button>
                        </>
                      ) : (
                        <>
                          <Link to="/sign-in" className="explore-view-link">
                            {" "}
                            {/* Wrap the button with Link */}
                            <Button
                              variant="text"
                              // className="res-iconbtn"
                              className="Send-document-btn"
                              startIcon={<SendIcon />}
                              onClick={() => {
                                RedirectDirectFunction(item._id);
                              }}
                            >
                              Request Document
                            </Button>
                          </Link>
                        </>
                      )}
                    </Box>
                    <Box mt={2} className="document-tab-box">
                      {/* <List> */}
                      <Grid container spacing={2}>
                        {Object.keys(item?.personalDocuments || {}).map(
                          (documentType) => {
                            const formattedDocumentType =
                              formatDocumentType(documentType); // Format documentType
                            const documents =
                              item?.personalDocuments[documentType];
                            if (
                              Array.isArray(documents) &&
                              documents.length > 0
                            ) {
                              return documents.map((document, index) => (
                                // <ListItem key={index}>

                                <Grid item md={6} xs={12} key={index}>
                                  <Stack direction="row" spacing={2}>
                                    <CheckIcon className="cross-iconbtn" />
                                    <Stack
                                      direction="column"
                                      className="duc-text"
                                    >
                                      <Typography component="div">
                                        {formattedDocumentType}
                                      </Typography>
                                      {/* <Typography component="div">{document?.location}</Typography> */}
                                    </Stack>
                                    {/* <Stack direction="column" className="duc-text">
              <Typography component="div">
                <RemoveRedEye />
              </Typography>
            </Stack> */}
                                  </Stack>
                                </Grid>

                                // </ListItem>
                              ));
                            }
                            return null;
                          }
                        )}
                        {Object.keys(item?.personalDocuments || {}).every(
                          (documentType) =>
                            !Array.isArray(
                              item?.personalDocuments[documentType]
                            ) ||
                            item?.personalDocuments[documentType].length === 0
                        ) && (
                            <ListItem>
                              <Typography component="div" color='#8c8c8c'>
                                No documents available
                              </Typography>
                            </ListItem>
                          )}
                      </Grid>

                      {/* </List> */}
                    </Box>
                  </TabPanel>
                  <TabPanel value={tabValue} index={2}>
                    <AvailabilityCalender availability={availability} props={{ hcId: item._id }} />
                  </TabPanel>
                </Box>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{ mt: 3, mb: 5 }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <Stack direction="row" spacing={2}>


                    {userData?.role == "client" ? (
                      <>
                        <Button
                          variant="contained"
                          className="res-iconbtn"
                          startIcon={<SendIcon />}
                          onClick={() => {
                            if (requestSent?.includes(item?._id)) {
                              removeRequestFunction(item?._id);
                            } else {
                              addRequestFunction(item?._id);
                            }
                          }}
                        >
                          {requestSent?.includes(item?._id)
                            ? "Cancel Request"
                            : "Request"}
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          className="res-iconbtn"
                          startIcon={<SendIcon />}
                          onClick={() => {
                            RedirectDirectFunction(hcId);
                          }}
                        >
                          Request
                        </Button>
                      </>
                    )}

                    {userData?.role === "client" ? (
                      <Button
                        variant="outlined"
                        className="fav-iconbtn"
                        startIcon={
                          favourite.includes(item?._id) ? (
                            <FavoriteIcon style={{ color: "#15A07D" }} />
                          ) : (
                            <FavoriteBorderOutlinedIcon />
                          )
                        }
                        onClick={() => {
                          if (favourite.includes(item?._id)) {
                            removeFavoritesHC(item?._id);
                          } else {
                            addFavoritesHC(item?._id);
                          }
                        }}
                      >
                        {favourite.includes(item?._id)
                          ? "Remove from Favourites"
                          : "Add To Favourites"}
                      </Button>
                    ) : (
                      <Link to="/sign-in" className="explore-view-link">
                        {" "}
                        {/* Wrap the button with Link */}
                        <Button
                          variant="outlined"
                          className="fav-iconbtn"
                          startIcon={<FavoriteBorderOutlinedIcon />}
                          onClick={() => {
                            RedirectDirectFunction(hcId);
                          }}
                        >
                          Add To Favourites
                        </Button>
                      </Link>
                    )}



                  </Stack>
                </Grid>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default ViewDetail;
