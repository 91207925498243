import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import WebHeader from "../Web-Header";
import WebFooter from "../Web-footer";
import silImage1 from "../../assets/images/silImage1.png";
import silImage2 from "../../assets/images/silImage2.png";
import silImage3 from "../../assets/images/silImage3.png";
import silImage4 from "../../assets/images/silImage4.png";
import silImage5 from "../../assets/images/silImage5.png";

import { Link } from "react-router-dom";
import "../../assets/css/dashboard.css";
import "../../assets/css/web-css.css";
import "../HomeUI/home-ui.css";
import silIcon1 from "../../assets/images/silIcon1.svg";
import silIcon2 from "../../assets/images/silIcon2.svg";
import silIcon3 from "../../assets/images/silIcon3.svg";
import silIcon4 from "../../assets/images/silIcon4.svg";
import silIcon5 from "../../assets/images/silIcon5.svg";
import silIcon6 from "../../assets/images/silIcon6.svg";
import silIcon7 from "../../assets/images/silIcon7.svg";
import silIcon8 from "../../assets/images/silIcon8.svg";
import silIcon9 from "../../assets/images/silIcon9.svg";
import silIcon10 from "../../assets/images/silIcon10.svg";
import silIcon11 from "../../assets/images/silIcon11.svg";
import silIcon12 from "../../assets/images/silIcon12.svg";
import silIcon13 from "../../assets/images/silIcon13.svg";
import silIcon14 from "../../assets/images/silIcon14.svg";

import QuestionAccordion from "../HomeUI/Question-Accordian";
import BookDemo from "../HomeUI/BookDemo";

const SILManagement = () => {
  return (
    <Box id="features">
      <Box className="web-topBanner-bg  feature-main-banner">
        <WebHeader />

        <Box className="feature-titleBox">
          <Box className="webContainer">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} className="home-content-main ">
                <Box>
                  <h1>
                    SIL Management
                    <br />
                    {/* Title */}
                  </h1>
                  <Typography>
                    Our solution is tailored to simplify the operational aspects
                    of managing SIL homes, enabling you to prioritize delivering
                    exceptional care and support.
                  </Typography>

                  <Box className="d-flex align-items-center">
                    <Link
                      to="/book-demo"
                      className="home-section-button web-primary-btnLarge cta1"
                    >
                      Book A Demo
                    </Link>
                    {/* <Link className="home-section-button cta2">CTA 2</Link> */}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box className="manageCarer-cardImg manageCarer-obj">
                  <img src={silImage1} />
                </Box>

                <Box className="blurBg blurRed"> </Box>
                {/* <Box className="blurBg blurPurple"> </Box> */}
                <Box className="blurBg blurYellow"></Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <Box className="webContainer">
        <Grid container spacing={2} className="manageCarer-cardBox space-80">
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex align-items-center"
          >
            <Box className="manageCarer-cardImg manageCarer-obj">
              <img src={silImage2} />
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex justify-content-end"
          >
            <Box className="manageCarer-cardContent pLeft-80">
              <h6>Streamlined Roster Management</h6>
              <h3>Empower Your Team</h3>

              <Box className="manageCarer-cardText">
                <Typography className="space-pb-24">
                  Efficient roster management is crucial for the smooth
                  operation of SIL homes. Our platform offers an intuitive
                  rostering tool that simplifies the scheduling process,
                  ensuring the right staff are in the right place, at the right
                  time.
                </Typography>

                <Grid container className="feature-icons-box">
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={silIcon1} />
                      </Box>
                      <Box>
                        <Typography>
                          Manage staff availability efficiently
                        </Typography>
                        <span>
                          Monitor and update staff availability in real-time.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={silIcon2} />
                      </Box>
                      <Box>
                        <Typography>Optimize workforce allocation</Typography>
                        <span>
                          Allocate workforce resources more effectively.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={silIcon3} />
                      </Box>
                      <Box>
                        <Typography>Simplify leave tracking</Typography>
                        <span>Track and manage leave requests seamlessly.</span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={silIcon4} />
                      </Box>
                      <Box>
                        <Typography>Improve care delivery quality</Typography>
                        <span>
                          Enhance quality of care by optimizing staff
                          deployment.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {/* <Link to="#" className="home-primary-button">
                Learn More
              </Link> */}
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} className="manageCarer-cardBox space-80">
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex justify-content-end"
            order={{ xs: 2, sm: 1 }}
          >
            <Box className="manageCarer-cardContent pRight-80">
              <h6>Seamless Community Access </h6>
              <h3>Connect More, Worry Less</h3>

              <Box className="manageCarer-cardText">
                <Typography className="space-pb-24">
                  HUBSHIFT's SIL Management solution provides seamless community
                  access, enabling residents to safely and easily engage with
                  their communities.
                </Typography>

                <Grid container className="feature-icons-box">
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={silIcon5} />
                      </Box>
                      <Box>
                        <Typography>
                          Facilitate resident activity participation
                        </Typography>
                        <span>
                          Enable easy resident engagement in activities.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={silIcon6} />
                      </Box>
                      <Box>
                        <Typography>Streamlined access to services</Typography>
                        <span>
                          Simplify access to essential services with ease.
                        </span>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={silIcon7} />
                      </Box>
                      <Box>
                        <Typography>Robust security measures</Typography>
                        <span>Strong security protocols for protection.</span>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {/* <Link to="#" className="home-primary-button">
                Learn More
              </Link> */}
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex align-items-center"
            order={{ xs: 1, sm: 2 }}
          >
            <Box className="manageCarer-cardImg">
              <img src={silImage3} />
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} className="manageCarer-cardBox space-80">
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex align-items-center"
          >
            <Box className="manageCarer-cardImg manageCarer-obj">
              <img src={silImage4} />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex justify-content-end"
          >
            <Box className="manageCarer-cardContent pLeft-80">
              <h6>Holistic Financial Dashboard </h6>
              <h3>Financial Clarity at Your Fingertips</h3>

              <Box className="manageCarer-cardText">
                <Typography className="space-pb-24">
                  Gain a complete overview of your financial health with our
                  holistic financial dashboard. Our dashboard provides the
                  insights you need to make informed decisions, ensuring
                  financial sustainability and compliance. 
                </Typography>

                <Grid container sx={{ mt: 2 }} className="feature-icons-box">
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={silIcon8} />
                      </Box>
                      <Box>
                        <Typography>Track your budgets</Typography>
                        <span>
                          Easily oversee and manage budget allocations.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={silIcon9} />
                      </Box>
                      <Box>
                        <Typography>Daily expense monitoring</Typography>
                        <span>
                          Keep a close eye on daily spending activities.
                        </span>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={silIcon10} />
                      </Box>
                      <Box>
                        <Typography>
                          Consolidated financial reporting
                        </Typography>
                        <span>
                          Access comprehensive financial reports effortlessly.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {/* <Link to="#" className="home-primary-button">
                Learn More
              </Link> */}
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} className="manageCarer-cardBox space-80">
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex justify-content-end"
            order={{ xs: 2, sm: 1 }}
          >
            <Box className="manageCarer-cardContent pRight-80">
              <h6>Customized Reporting </h6>
              <h3>Insights Tailored to You</h3>

              <Box className="manageCarer-cardText">
                <Typography className="space-pb-24">
                  Understanding your operation's nuances is key to continual
                  improvement. That's why HUBSHIFT offers customized reporting
                  capabilities, allowing you to generate reports that matter
                  most to your SIL homes.
                </Typography>

                <Grid container className="feature-icons-box">
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={silIcon11} />
                      </Box>
                      <Box>
                        <Typography>
                          Progress tracking towards set goals
                        </Typography>
                        <span>
                          Monitor advancement towards established objectives.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={silIcon12} />
                      </Box>
                      <Box>
                        <Typography>
                          Identification of improvement areas
                        </Typography>
                        <span>
                          Highlight and pinpoint potential improvement zones.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={silIcon13} />
                      </Box>
                      <Box>
                        <Typography>Customizable report generation</Typography>
                        <span>
                          Generate reports tailored to specific needs.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={silIcon14} />
                      </Box>
                      <Box>
                        <Typography>Actionable insights delivery</Typography>
                        <span>
                          Deliver insights that drive actionable decisions.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {/* <Link to="#" className="home-primary-button">
                Learn More
              </Link> */}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex align-items-center"
            order={{ xs: 1, sm: 2 }}
          >
            <Box className="manageCarer-cardImg manageCarer-obj">
              <img src={silImage5} />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="question-accordian web-topBanner-bg space-lg">
        <Box className="webContainer">
          <Box className="text-center">
            <h1>Questions? We've got you covered.</h1>
          </Box>
          <Box className="space-pt-60">
            <QuestionAccordion />
          </Box>
        </Box>
      </Box>

      <Box className="space-80">
        <Box className="webContainer">
          <BookDemo />
        </Box>
      </Box>

      <WebFooter />
    </Box>
  );
};

export default SILManagement;
