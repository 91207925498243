import React from 'react';
import {
  TableRow,
  TableCell,
  Typography,
  Box,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const NodataTable = ({col}) => {
  return (
    <TableRow>
      <TableCell colSpan={col} align="center">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100px"
        >
          <ErrorOutlineIcon style={{ fontSize: '50px', color: '#999' }} />
          <Typography variant="body1" color="textSecondary" style={{ marginTop: '10px' }}>
            No Data Found
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default NodataTable;
