import { Flex } from "@chakra-ui/react";
import { RotatingLines } from "react-loader-spinner";

const Loader = () => {
  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          background: "rgba(255,255,255, 0.95)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <span style={{ paddingRight: "40%", paddingTop: "10%" }}>
            <RotatingLines
              strokeColor="#15a07d"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
              visible={true}
            />
          </span>
        </div>
      </div>
    </>
  );
};
export default Loader;
