import React, { useState } from "react";
import Box from "@mui/material/Box";
import Appbar from "../Layout/Appbar";
import Sidebar from "../Layout/Sidebar";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import "./timesheet.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Stack from "@mui/material/Stack";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";



const top100Films = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
  { label: "12 Angry Men", year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: "Pulp Fiction", year: 1994 },
];



export default function Timesheet() {
  
  return (
    <Box className="dashboard-form" id="timesheet">
      <Box sx={{ width: "100%" }} className="appbar-border">
        <Box className="appbar-main">
          <Appbar />
        </Box>
      </Box>
      <Box className=" d-flex dashboard-main-box">
        <Sidebar />
        <Box className="main">
          <Box
            className="dashboard-form-typo dashboard-form-typo-up"
            sx={{ borderBottom: "none" }}
          >
            <Grid container className="d-flex header-topsection">
              <Grid item xs={6} sm={6} md={6}>
                <Box id="breadcrumb">
                  <ArrowBackIosIcon />
                  <Box className="breadcrumb-inr">
                    <h4 className="d-flex align-items-center "> Timesheets</h4>
                    <Box className="breadcrumbs">
                      {/* <Link className="breadcrumbs-link">Timesheets</Link> */}
                      {/* <span className="breadcrumbs-icon">{">"}</span> */}
                      <Link className="breadcrumbs-link breadcrumbs-link-active">
                        Timesheets
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>

           

          </Box>

          <Box id="personal-info" mt={4}>

          <Box className="personal-info">

          <Grid
                  container
                  spacing={4}
                  className="align-items-end add-appointment-input-box"
                >
          
          <Grid item xs={12} sm={12} md={6} className="input pt-0">
                    <label>User </label>

                    <Autocomplete
                      name="supportCoordinatorId"
                      disablePortal
                      id="combo-box-demo"
                      sx={{ mt: 2, mb: 2 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "Select Support Coordinator",
                          }}
                         
                        />
                      )}
                      size="small"
                      options={top100Films}
                     
                    />
            </Grid>

            <Grid item xs={12} sm={12} md={3} className="pt-0 ">
                    <label>Date Range</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={3}>
                        <DesktopDatePicker
                          className="form-input date-input-filed"
                          inputFormat="DD-MM-YYYY"
                          // maxDate={new Date()}
                          // value={formik?.values?.declarationDate}
                          // onChange={(e) =>
                          //   HandleDateEvent(e, "declarationDate")
                          // }
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              name="declarationDate"
                              sx={{ mb: 2, mt: 2 }}
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "DD-MM-YY",
                              }}
                             
                            />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>

            </Grid>

            </Box>

          </Box>



         

          <Box className="agency-btn-section add-expenses-btn">
            <Link style={{display:'none'}} href="#cancel"></Link>
            <Link href="#save" to="/timesheet">
              Show
            </Link>
          </Box>
        </Box>
      </Box>

     
    </Box>
  );
}
