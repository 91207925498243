import React, { useState } from "react";
import { Box, TextField, Button, Typography, Grid } from "@mui/material";
import "./DeleteAccount.css";
import Logoimg from "../../../assets/images/hubshift-logo1.png";
import axios from "axios";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { resendOtp, verifyOtpUserDelete, MobileuserDelete } from "../../../api";

export default function DeleteAccount() {
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [disableButtonResend, setDisableButtonResend] = useState(false);

  const initialValuesSendOtp = {
    email: "",
  };

  const initialValuesVerifyOtp = {
    emailOTP: "",
  };

  const validationSchemaSendOtp = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
  });

  const validationSchemaVerifyOtp = Yup.object().shape({
    emailOTP: Yup.string().required("OTP is required"),
  });

  const handleSendOtp = async (values, actions) => {
    const postData = {
      email: values.email,
    };
    setDisableButtonResend(true);
    try {
      const res = await axios.post(resendOtp, postData);
      if (res.data.success) {
        toast.success(res.data.message);
        setUserId(res.data.data?._id);
        setEmail(values?.email);
        setOtpSent(true);
        setDisableButtonResend(false);
      } else {
        setDisableButtonResend(false);
        toast.error(res.data.message);
      }
    } catch (error) {
      setDisableButtonResend(false);
      toast.error("Please try again, something went wrong!");
    }
    actions.setSubmitting(false);
  };

  const handleVerifyOtp = async (values, actions) => {
    const postData = {
      email: email,
      emailOTP: values.emailOTP,
    };
    setDisableButton(true);
    try {
      const res = await axios.post(verifyOtpUserDelete, postData);
      if (res.data.success) {
        setDisableButton(false);
        toast.success(res.data.message);
        handleDeleteAccount();
      } else {
        setDisableButton(false);
        toast.error(res.data.message);
      }
    } catch (error) {
      setDisableButton(false);
      toast.error("Please try again, something went wrong!");
    }
    actions.setSubmitting(false);
  };

  const handleDeleteAccount = async () => {
    try {
      const response = await axios.delete(`${MobileuserDelete}/${userId}`);
      if (response.data.success) {
        toast.success("Account deleted successfully");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed to delete account");
    }
  };

  return (
    <Box className="deleteAccountContainer">
      <img src={Logoimg} alt="Logo" className="deleteAccountLogo" />
      <Typography variant="h4" gutterBottom>
        Delete Account
      </Typography>
      <Box className="deleteAccountForm">
        <Formik
          initialValues={initialValuesSendOtp}
          validationSchema={validationSchemaSendOtp}
          onSubmit={handleSendOtp}
        >
          {({ handleSubmit, errors, touched, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    name="email"
                    as={TextField}
                    label="Enter Email Id"
                    variant="outlined"
                    fullWidth
                    className="deleteAccountTextField"
                    error={Boolean(errors.email) && Boolean(touched.email)}
                    helperText={Boolean(touched.email) && errors.email}
                    // helperText={<ErrorMessage name="email" />}
                    // error={!!ErrorMessage}
                  />
                  <Button
                    type="submit"
                    className="deleteAccountButton"
                    disabled={disableButtonResend || isSubmitting}
                  >
                    {disableButtonResend ? "Sending..." : "Send OTP"}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
        {otpSent && (
          <Formik
            initialValues={initialValuesVerifyOtp}
            validationSchema={validationSchemaVerifyOtp}
            onSubmit={handleVerifyOtp}
          >
            {({ handleSubmit, errors, touched, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      name="emailOTP"
                      as={TextField}
                      label="Verify OTP"
                      variant="outlined"
                      fullWidth
                      className="deleteAccountTextField"
                      error={
                        Boolean(errors.emailOTP) && Boolean(touched.emailOTP)
                      }
                      helperText={Boolean(touched.emailOTP) && errors.emailOTP}
                      //   helperText={<ErrorMessage name="emailOTP" />}
                      //   error={!!ErrorMessage}
                    />
                    <Button
                      type="submit"
                      className="deleteAccountButton2"
                      disabled={disableButton || isSubmitting}
                    >
                      {disableButton ? "Processing..." : "Verify"}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className="deleteAccountError">
                      OTP is sent successfully to email id, Please check your
                      email.
                    </Typography>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
      </Box>
    </Box>
  );
}
