import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { Box } from '@mui/material';
import regular from '../../assets/images/table-check.png';
import onCall from '../../assets/images/table-call.png';
import sleepover from '../../assets/images/sleep-icon.png';


export default function SelectSmall() {

  return (
    <FormControl
      component="fieldset"
      variant="standard"
      className='w-100 '
    >
      <FormGroup className="formgroupsty justify-content-end">


        <FormControlLabel
          className="checkbox-label"

          control={<Box className="d-flex align-items-center">

            <Checkbox 
              className="checkbox-color"

            /> <img className='checkbox-img' src={regular} alt='regular' />
          </Box>}
          label="Regular"
        />

        <FormControlLabel
          className="checkbox-label"

          control={<Box className="d-flex align-items-center">

            <Checkbox 
              className="checkbox-color"

            /> <img className='checkbox-img' src={onCall} alt='on call' />
          </Box>}
          label="On Call"
        />

        <FormControlLabel
          className="checkbox-label"

          control={<Box className="d-flex align-items-center">

            <Checkbox 
              className="checkbox-color"

            /> <img className='checkbox-img' src={sleepover} alt='sleepover' />
          </Box>}
          label="Sleepover"
        />

        <FormControlLabel
          className="checkbox-label"

          control={
            <Checkbox 
              className="checkbox-color"

            />}
          label="Select All"
        />

      </FormGroup>
    </FormControl>

  );
}
