import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

export default function QuestionAccordion() {
  return (
    <div className='accordion-main-wrapper'>
      <Accordion sx={{mb:2}} defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className='question-title'
        >
          Lorem ipsum dolor sit amet?
        </AccordionSummary>
        <AccordionDetails>
        Quisque rutrum. Aenean imperdi. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget.
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{mb:2}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          className='question-title'
        >
          Lorem ipsum dolor sit amet?
        </AccordionSummary>
        <AccordionDetails>
        Quisque rutrum. Aenean imperdi. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget.
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{mb:2}} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
          className='question-title'
        >
         Lorem ipsum dolor sit amet?
        </AccordionSummary>
        <AccordionDetails>
        Quisque rutrum. Aenean imperdi. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget.
        </AccordionDetails>
        
      </Accordion>
      <Accordion sx={{mb:2}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4-content"
          id="panel4-header"
          className='question-title'
        >
         Lorem ipsum dolor sit amet?
        </AccordionSummary>
        <AccordionDetails>
        Quisque rutrum. Aenean imperdi. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget.
        </AccordionDetails>
        
      </Accordion>
    </div>
  );
}