import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import BannerSlider from "../../components/ui/BannerSlider";
import { useState, useEffect } from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { forgotpassword, getUserTypeList } from "../../api";
import { useNavigate } from "react-router-dom";
import WebHeader from "../../Website/Web-Header";

export default function SignInSide() {
  const [disableButton, setDisableButton] = useState(false);
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState([]);
  const [errorMessage, setErrorMessage] = useState();
  const initialValues = {
    email: "",
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
  });

  const fieldLogout = () => {
    localStorage.removeItem("userData");
    navigate("/sign-in");
  };
  const currUrl = window.location.href;
  const currUrlpathname = window.location.pathname;
  const new_str = currUrl.replace(currUrlpathname, "");

  return (
    <Box id="personal-info" width="100%" height="100%">
      <Box>
        <WebHeader />
      </Box>
      <Grid container>
        <Grid item xs={12} sm={12} md={7}>
          <Box>
            <BannerSlider />
          </Box>
        </Grid>

        <Grid item xs={12} md={5}>
          <Box className="w-100 h-100 d-flex align-items-center justify-content-center">
            <Box className="form-main-box">
              <Box>
                <h2 mb={3} className="sign-title">
                  Forgot Password?
                </h2>
              </Box>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  const postData = {
                    email: values.email,
                    currentUrl: new_str,
                  };
                  setDisableButton(true);
                  axios
                    .post(forgotpassword, postData)
                    .then((result) => {
                      const res = result.data;

                      if (res.success) {
                        setDisableButton(false);
                        toast.success(
                          "Reset Password link Send to Registered Email"
                        );
                        setTimeout(() => {
                          navigate("/sign-in");
                        }, 3000);
                      } else {
                        setDisableButton(false);
                        toast.error(res.message);
                      }
                    })
                    .catch((error) => {
                      toast.error("Something went wrong");
                    });
                }}
              >
                {({
                  setFieldValue,
                  errors,
                  touched,
                  values,
                  isValid,
                  dirty,
                }) => (
                  <Form>
                    <Box>
                      <label>Email<span className="red-color">*</span> </label>

                      <Field
                        name="email"
                        type="email"
                        as={TextField}
                        margin="normal"
                        size="small"
                        className="form-input"
                        placeholder="Email"
                        value={
                          values?.email.toLocaleLowerCase()
                        }
                        error={Boolean(errors.email) && Boolean(touched.email)}
                        helperText={Boolean(touched.email) && errors.email}
                      />

                      <Button
                        className="signupbutton"
                        type="submit"
                        fullWidth
                        sx={{ mt: 3, mb: 2 }}
                        disabled={disableButton}
                      >
                        {disableButton === true
                          ? "Processing..."
                          : "Email me a link to reset password"}
                      </Button>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {
                        <>
                          <Typography color="#929495">
                            Back to{" "}
                            <Link
                              onClick={fieldLogout}
                              variant="body2"
                              style={{ cursor: "pointer" }}
                            >
                              Login
                            </Link>
                          </Typography>
                        </>
                      }
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
