import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import WebHeader from "../../../Website/Web-Header";
import WebFooter from "../../../Website/Web-footer";
import { Link } from "@mui/material";
import BookDemo from "../../../Website/HomeUI/BookDemo";
import GuidesFilter from "./Guides-Filter"

const Guides = () => {
  return (
    <Box id="GuidesBlogs">
      <Box className="web-topBanner-bg  blog-main-banner">
        <WebHeader />

        <Box className="webContainer">
          <Box className="W-100 ">
            <Box className="home-content-main space-lg">
              <Box className="text-center">
                <h1>Guides</h1>
                <Typography>
                  Uncover a wealth of meticulously crafted resources in our
                  guides <br /> section. Elevate your operations and deliver superior
                  care with precision <br /> and professionalism at your fingertips.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="blurBg blurRed"> </Box>
        {/* <Box className="blurBg blurPurple"> </Box> */}
        <Box className="blurBg blurYellow"></Box>
      </Box>

      <Box className="space-pt-80">
        <Box className="webContainer">
          <GuidesFilter />
        </Box>
      </Box>

      {/* book demo section  */}

      <Box className="space-m-80">
        <Box className="webContainer">
          <BookDemo />
        </Box>
      </Box>

      <WebFooter />
    </Box>
  );
};

export default Guides;
