import {
  getJobTitleList,
  getJobTypeList,
  getLevelList,
  getState,
  getCountry,
  getCountryDetails,
  getStateDetails,
  getStateList,
  getLevelDetails,
  getJobTypeDetails,
  getJobTitleDetails,
  AustraliaId,
  addGoogleCount,
  updateGoogleCount,
  getIndividualCountDetails,
  SAcreatedBy,
  googleCountLimit,
} from "../../../../api";
import React, { useEffect, useState } from "react";
import moment from "moment";
// import axios from "axios";
import axios from "../../../../api/axios";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MailIcon from "@mui/icons-material/Mail";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import CallIcon from "@mui/icons-material/Call";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import { toast } from "react-toastify";
import { getAllBeforeOnboarding } from "../../../../helpers/commonHelper";
import HCStore from "../HCStore";
import "../../../../pages/client/form/stakeholder.css";

const PersonalInfo = (props) => {
  const { formik } = props;
  const [birthDate, setBirthDate] = useState(null);
  const [apiCount, setApiCount] = useState(0);
  let countId = "6491bb0d50032ab6cada7d3e";
  const [jobTitleList, setJobTitleList] = useState([]);
  const [jobTitleName, setJobTitleName] = useState();
  const [jobTypeList, setJobTypeList] = useState([]);
  const [jobTypeName, setJobTypeName] = useState();
  const [levelList, setLevelList] = useState([]);
  const [levelName, setLevelName] = useState();

  const [inputValue, setnewInputValue] = React.useState(null);
  const [inputStateValue, setInputStateValue] = React.useState(null);
  const [stateTypeVal, setStateTypeVal] = useState([]);
  const [countryVal, setCountryVal] = useState();
  const [countryName, setCountryName] = useState();
  const [stateName, setStateName] = useState();
  const [stateData, setStateData] = useState();
  const [checkBoxVisible, setCheckBoxVisible] = useState(false);
  const [valueDobData, setDobData] = useState();
  const [valueDobDataTwo, setDobDataTwo] = useState();
  const [dobError, setDobError] = useState("");
  const [visiblePostAddresss, setVisiblePostAddresss] = useState(false);
  const [latLng, setLatLng] = useState({ lat: null, lng: null });
  const { resetFields, setField, buttonClickedPinfo } = HCStore();

  let createdBy = getAllBeforeOnboarding();

  if (createdBy === "superAdmin") {
    createdBy = SAcreatedBy;
  }

  // const gender = [
  //   { label: "Male", id: "M" },
  //   { label: "Female", id: "F" },
  //   { label: "Other", id: "T" },
  // ];

  const gender = [
    { label: "Male", id: "Male" },
    { label: "Female", id: "Female" },
    { label: "Other", id: "Other" },
  ];

  const titles = [
    {
      label: "Ms",
      id: "Ms",
    },
    { label: "Mr", id: "Mr" },
    { label: "Mrs", id: "Mrs" },
  ];

  const [residentAddressVisible, setResidentAddressVisible] = useState(false);

  let jobTitleIdVal = props?.formik?.values?.userData?.jobTitleId;
  let jobTypeIdVal = props?.formik?.values?.userData?.jobTypeId;
  let levelIdVal = props?.formik?.values?.userData?.levelId;
  const postalAddressVissiable = (values) => {
    if (values) {
      formik.setFieldValue("isPostaladdressSame", values.target.checked);
      //write a code for postal addres is same as registence address
      if (values.target.checked) {
        // formik.setField("sameAsResidentialAddresss", values.target.checked);
        // formik.setFieldValue("postalAddressLine1", formik.values.addressLine1);
        formik.setFieldValue(
          "postalAddress.postalAddressLine1",
          formik.values.address.addressLine1
        );
        // formik.setFieldValue("postalAddressLine2", formik.values.addressLine2);
        formik.setFieldValue(
          "postalAddress.postalAddressLine2",
          formik.values.address.addressLine2
        );
        // formik.setFieldValue("postalCountryId", formik.values.country);
        formik.setFieldValue(
          "postalAddress.postalCountryId",
          formik.values.address.country
        );
        // formik.setFieldValue("postalStateId", formik.values.state);
        formik.setFieldValue(
          "postalAddress.postalStateId",
          formik.values.address.state
        );
        // formik.setFieldValue("postalCity", formik.values.city);
        formik.setFieldValue(
          "postalAddress.postalCity",
          formik.values.address.city
        );
        // formik.setFieldValue("postalPinCode", formik.values.postalCode);
        formik.setFieldValue(
          "postalAddress.postalPinCode",
          formik.values.address.postalCode
        );
        formik.setFieldValue(
          "postalAddress.postalLat",
          formik.values.address.lat
        );
        formik.setFieldValue(
          "postalAddress.postalLng",
          formik.values.address.lng
        );

        // formik.setFieldValue("postalAddress", formik.values.address);
      } else {
        formik.setFieldValue("postalAddress.postalAddressLine1", "");
        formik.setFieldValue("postalAddress.postalAddressLine2", "");
        formik.setFieldValue("postalAddress.postalCountryId", null);
        formik.setFieldValue("postalAddress.postalStateId", null);
        formik.setFieldValue("postalAddress.postalCity", "");
        formik.setFieldValue("postalAddress.postalPinCode", "");
        formik.setFieldValue("postalCity", "");
        // formik.setFieldValue("postalAddress", "");
      }
    }
  };
  useEffect(() => {
    axios
      .get(`${getJobTitleList}?createdBy=${createdBy}`)
      .then((res) => {
        let jobTitleList = res?.data?.data;
        let data = [];
        jobTitleList &&
          jobTitleList.map((d) => {
            data.push({
              label: `${d.name}`,
              id: d._id,
            });
          });
        setJobTitleList(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setResidentAddressVisible]);

  useEffect(() => {
    if (
      jobTitleIdVal !== undefined &&
      jobTitleIdVal !== "" &&
      jobTitleIdVal !== null
    ) {
      jobTitleNameData(jobTitleIdVal);
    }
  }, [jobTitleIdVal !== undefined]);

  const jobTitleNameData = (jobTitleIdVal) => {
    axios
      .get(`${getJobTitleDetails}/${jobTitleIdVal}?createdBy=${createdBy}`)
      .then(function (res) {
        if (res) {
          setJobTitleName(res?.data?.data?.name);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    axios
      .get(`${getJobTypeList}?createdBy=${createdBy}`)
      .then((res) => {
        let jobTypeList = res?.data?.data;
        let data = [];
        jobTypeList &&
          jobTypeList.map((d) => {
            data.push({
              label: `${d.name}`,
              id: d._id,
            });
          });
        setJobTypeList(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (
      jobTypeIdVal !== undefined &&
      jobTypeIdVal !== "" &&
      jobTypeIdVal !== null
    ) {
      jobTypeNameData(jobTypeIdVal);
    }
  }, [jobTypeIdVal !== undefined]);

  const jobTypeNameData = (jobTypeIdVal) => {
    axios
      .get(`${getJobTypeDetails}/${jobTypeIdVal}?createdBy=${createdBy}`)
      .then(function (res) {
        if (res) {
          setJobTypeName(res?.data?.data?.name);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    axios
      .get(`${getLevelList}?createdBy=${createdBy}`)
      .then((res) => {
        let levelList = res?.data?.data;
        let data = [];
        levelList &&
          levelList.map((d) => {
            data.push({
              label: `${d.name}`,
              id: d._id,
            });
          });
        setLevelList(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (levelIdVal !== undefined && levelIdVal !== "" && levelIdVal !== null) {
      levelNameData(levelIdVal);
    }
  }, [levelIdVal !== undefined]);

  const levelNameData = (levelIdVal) => {
    axios
      .get(`${getLevelDetails}/${levelIdVal}?createdBy=${createdBy}`)
      .then(function (res) {
        if (res) {
          setLevelName(res?.data?.data?.name);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleDobData = (newValue) => {
    setDobData(newValue.target.value);
  };

  useEffect(() => {
    countryList();
    if (
      formik.values.address.addressLine1 ||
      formik.values.address.addressLine2 ||
      formik.values.address.city ||
      formik.values.address.state
    ) {
      setCheckBoxVisible(true);
    }

    if (
      formik.values.postalAddress.postalAddressLine1 ||
      formik.values.postalAddress.postalAddressLine2 ||
      formik.values.postalAddress.postalCity ||
      formik.values.postalAddress.postalStateId
    ) {
      setVisiblePostAddresss(true);
    }
  }, []);
  const countryList = () => {
    axios
      .get(getCountry)
      .then((res) => {
        let countryData = res?.data?.data;
        let data = [];
        countryData &&
          countryData.map((country) => {
            data.push({
              label: `${country.name}`,
              id: country._id,
            });
          });
        setCountryVal(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let countryNmd = props?.formik?.values?.address?.country;

  useEffect(() => {
    const apiUrl = `${getState}/${AustraliaId}?createdBy=${createdBy}`;
    stategetFunction(apiUrl);
  }, []);

  useEffect(() => {
    if (countId !== undefined && countId !== "") {
      getCountDetails(countId);
    }
  }, [countId]);

  const getCountDetails = (countId) => {
    axios
      .get(`${getIndividualCountDetails}/${countId}?createdBy=${createdBy}`)
      .then(function (res) {
        if (res) {
          setApiCount(res?.data?.data?.count);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getCountryAddFunction = (z) => {
    if (z) {
      setStateName("");
      setnewInputValue(z);
      countryNameData(z);
      const apiUrl = `${getState}/${z}`;
      stategetFunction(apiUrl);
    }
  };

  setTimeout(() => {
    setResidentAddressVisible(formik.values.isResidentialAddress);
  }, 1000);

  function stategetFunction(apiUrl) {
    axios
      .get(apiUrl)
      .then((res) => {
        let stateData = res?.data?.data;
        let data = [];
        stateData &&
          stateData.map((d) => {
            data.push({
              label: `${d.StateName}`,
              id: d._id,
            });
          });
        setStateTypeVal(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const getStateIdFunction = (y) => {
    if (y) {
      stateNameData(y);
      setInputStateValue(y);
    }
  };

  useEffect(() => {
    if (countryNmd !== undefined && countryNmd !== null) {
      countryNameData(countryNmd);
    }
  }, [countryNmd !== undefined]);

  const countryNameData = (countryNmd) => {
    axios
      .get(`${getCountryDetails}/${countryNmd}`)
      .then(function (res) {
        if (res) {
          setCountryName(res?.data?.data?.name);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  let stateUnd = props?.formik?.values?.address?.state;

  useEffect(() => {
    if (stateUnd !== undefined && stateUnd !== "" && stateUnd !== null) {
      stateNameData(stateUnd);
    }
  }, [stateUnd !== undefined]);

  const stateNameData = (stateUnd) => {
    axios
      .get(`${getStateDetails}/${stateUnd}?createdBy=${createdBy}`)
      .then(function (res) {
        if (res) {
          setStateName(res?.data?.data?.name);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    stateList();
  }, []);
  const stateList = () => {
    axios
      .get(`${getStateList}?createdBy=${createdBy}`)
      .then((res) => {
        let stateData = res?.data?.data;
        let data = [];
        stateData &&
          stateData.map((state) => {
            data.push({
              label: `${state.name}`,
              id: state._id,
            });
          });
        setStateData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const residentalAddress = (e) => {
    setResidentAddressVisible(e.target.checked);
  };

  let dateOfBirth = null;

  if (formik.values.dob !== undefined && formik.values.dob !== null) {
    dateOfBirth = moment(formik.values.dob).format("YYYY-MM-DD");
  }

  const HandleDateEvent = (e, docName) => {
    const name = docName;
    if (new Date(e) == "Invalid Date") {
      setDobError("Please select a valid date.");
    } else {
      formik.setFieldValue(name, new Date(e));
      setDobError("");
    }
  };

  const setPostalAddressStateFunction = async (state, setFieldValue) => {
    if (state) {
      await setFieldValue("postalAddress.postalStateId", state.id);
      await setFieldValue("postalStateId", state.id);
    }
  };
  const [open, setOpen] = React.useState(false);

  const getStateIdByName = (stateName) => {
    const selectedState = stateData.find((state) => state.label === stateName);
    return selectedState ? selectedState.id : "";
  };

  /** Google Map Api */
  const [options, setOptions] = useState([]);

  const fetchAddressSuggestions = async (input) => {
    try {
      let updatedApiCount = apiCount + 1; // Increment the apiCount

      let postData = {
        count: updatedApiCount,
      };
      if (updatedApiCount <= googleCountLimit) {
        axios
          .put(`${updateGoogleCount}/${countId}`, postData)
          .then(function (res) {
            if (res.data.success === true) {
              setApiCount(res?.data?.data?.count);
              const service =
                new window.google.maps.places.AutocompleteService();
              const request = {
                input: input,
                types: ["address"],
                componentRestrictions: { country: "au" }, // Restrict to Australia
              };

              service.getPlacePredictions(request, (predictions, status) => {
                if (
                  status === window.google.maps.places.PlacesServiceStatus.OK
                ) {
                  const placeIds = predictions.map(
                    (prediction) => prediction.place_id
                  );
                  const placesService =
                    new window.google.maps.places.PlacesService(
                      document.createElement("div")
                    );

                  const placeDetailsPromises = placeIds.map(
                    (placeId) =>
                      new Promise((resolve, reject) => {
                        placesService.getDetails(
                          { placeId },
                          (place, status) => {
                            if (
                              status ===
                              window.google.maps.places.PlacesServiceStatus.OK
                            ) {
                              const addressLine1 = place.name || "";
                              // const addressLine2 =
                              //   place.formatted_address.split(",")[0] || "";
                              const addressLine2 = "";
                              const city =
                                getAddressComponent(place, "locality") || "";
                              const state =
                                getAddressComponent(
                                  place,
                                  "administrative_area_level_1"
                                ) || "";
                              const postalCode =
                                getAddressComponent(place, "postal_code") || "";
                              const country =
                                getAddressComponent(place, "country") || "";

                              const fullAddress = [
                                addressLine1,
                                addressLine2,
                                city,
                                state,
                                postalCode,
                                country,
                              ]
                                .filter(Boolean)
                                .join(", ");

                              resolve({
                                placeId: place.place_id,
                                description: fullAddress,
                                addressLine1: addressLine1,
                                addressLine2: addressLine2,
                                city: city,
                                state: state,
                                postalCode: postalCode,
                                country: country,
                                // Add latitude and longitude here
                                latitude: place.geometry.location.lat(),
                                longitude: place.geometry.location.lng(),
                              });
                            } else {
                              reject(status);
                            }
                          }
                        );
                      })
                  );

                  Promise.all(placeDetailsPromises)
                    .then((places) => {
                      setOptions(places);
                      if (places.length > 0) {
                        const selectedPlace = places[0];
                        const selectedStateName = selectedPlace?.state;
                        if (selectedStateName) {
                          const selectedStateId =
                            getStateIdByName(selectedStateName);
                          formik.setFieldValue(
                            "address.state",
                            selectedStateId
                          );
                        }
                        formik.setFieldValue(
                          "address.addressType",
                          "residential"
                        );
                        formik.setFieldValue(
                          "address.addressLine1",
                          selectedPlace?.addressLine1
                        );
                        formik.setFieldValue(
                          "address.addressLine2",
                          selectedPlace?.addressLine2
                        );
                        formik.setFieldValue(
                          "address.city",
                          selectedPlace?.city
                        );
                        formik.setFieldValue(
                          "address.postalCode",
                          selectedPlace?.postalCode
                        );

                        // Set the latitude and longitude in your formik fields or state
                        formik.setFieldValue(
                          "address.lat",
                          selectedPlace?.latitude
                        );
                        formik.setFieldValue(
                          "address.lng",
                          selectedPlace?.longitude
                        );
                      }
                    })
                    .catch((error) => {
                      console.error(error);
                    });
                }
              });
            }
            if (res.data.success === false) {
              toast.error(res?.data?.message);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        toast.error("Limit Has Been Exceeded");
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getAddressComponent = (place, componentType) => {
    const addressComponent = place.address_components.find((component) =>
      component.types.includes(componentType)
    );

    return addressComponent ? addressComponent.long_name : "";
  };

  function areAddressFieldsFilled(address) {
    return (
      address?.addressLine1 &&
      address?.city &&
      address?.state &&
      address?.postalCode
    );
  }

  const fetchLatLng = (address) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results, status) => {
      if (status === "OK" && results[0]?.geometry) {
        const location = results[0].geometry.location;
        setLatLng({ lat: location.lat(), lng: location.lng() });
        // You can use latLng.lat and latLng.lng as needed

        formik.setFieldValue("address.lat", location.lat());
        formik.setFieldValue("address.lng", location.lng());
      } else {
        console.error(
          "Geocode was not successful for the following reason: " + status
        );
      }
    });
  };
  return (
    <Box id="personal-info" className="account-form">
      <Box className="d-flex align-items-center justify-content-between">
        <Typography className="step-count">Step 2/4</Typography>
      </Box>
      <Box className="personal-info-main">
        <Box className="form-heading-section">
          <Typography className="form-heading form-heading-align">
            Residential Address
          </Typography>
        </Box>

        <Box className="personal-info">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} sm={12} className="input pt-0">
              <label>
                Residential Address <span className="red-color">*</span>
              </label>
              <Autocomplete
                freeSolo
                options={options}
                getOptionLabel={(option) => option.description}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="residentialAddress"
                    sx={{ mt: 2, mb: 2 }}
                    className="form-input"
                    placeholder="Residential Address"
                    type="Name"
                    size="small"

                    // onChange={handleResidentialAddressChange}
                  />
                )}
                onInputChange={(event, value) => fetchAddressSuggestions(value)}
              />
            </Grid>

            <Grid item xs={12} md={12} sm={12} className="input pt-0">
              <Box>
                <FormControlLabel
                  className="input pt-0"
                  sx={{ mt: 2 }}
                  control={
                    <Checkbox
                      className={`form-checkbox ${
                        formik?.values?.isResidentialAddress
                          ? "checked-checkbox-stepper"
                          : "unchecked-checkbox-stepper"
                      }`}
                      checked={formik?.values?.isResidentialAddress}
                      onClick={(e) => {
                        const newValue = !formik.values.isResidentialAddress;
                        setCheckBoxVisible(!checkBoxVisible);
                        formik.setFieldValue("isResidentialAddress", newValue);
                        if (!newValue) {
                          formik.setFieldValue("address", {
                            country: null,
                            state: null,
                            city: "",
                            postalCode: "",
                            addressLine1: "",
                            addressLine2: "",
                          });
                        } else {
                          formik.setFieldValue("address", {
                            ...formik.values.address,
                            addressLine1: "",
                            country: null,
                            state: null,
                            city: "",
                            postalCode: "",
                            addressLine2: "",
                          });
                        }
                      }}
                    />
                  }
                  label="Enter Residential Address manually"
                />
              </Box>
            </Grid>

            <Grid item xs={6} className="input pt-0">
              <label>
                Address Line 1<span className="red-color">*</span>
              </label>
              <TextField
                sx={{ mt: 2, mb: 2 }}
                className="form-input"
                type="Name"
                size="small"
                name="address.addressLine1"
                placeholder="Enter your address line 1"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik?.values?.address?.addressLine1}
                error={
                  formik?.touched?.address?.addressLine1 &&
                  Boolean(formik?.errors?.address?.addressLine1)
                }
                helperText={
                  formik?.touched?.address?.addressLine1 &&
                  formik?.errors?.address?.addressLine1
                }
              />
            </Grid>

            <Grid item xs={6} className="input pt-0">
              <label>Address Line 2</label>
              <TextField
                sx={{ mt: 2, mb: 2 }}
                className="form-input"
                type="Name"
                size="small"
                name="address.addressLine2"
                placeholder="Enter your address line 2"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.address.addressLine2}
                error={
                  formik?.touched?.address?.addressLine2 &&
                  Boolean(formik?.errors?.address?.addressLine2)
                }
                helperText={
                  formik?.touched?.address?.addressLine2 &&
                  formik?.errors?.address?.addressLine2
                }
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} className="input pt-0">
              <label>
                Province/State <span className="red-color">*</span>
              </label>
              {stateData !== undefined ? (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  sx={{ mt: 1, mb: 2 }}
                  size="small"
                  options={stateTypeVal}
                  value={
                    stateTypeVal && formik.values.address?.state
                      ? stateData.find(
                          (option) => option.id === formik.values.address.state
                        )
                      : ""
                  }
                  onChange={(event, newInputValue) => {
                    if (newInputValue) {
                      getStateIdFunction(newInputValue?.id);
                      props.formik.setFieldValue(
                        "address.state",
                        newInputValue?.id
                      );
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="address.state"
                      onBlur={formik.handleBlur}
                      error={
                        buttonClickedPinfo === true &&
                        Boolean(formik?.errors?.address?.state)
                      }
                      helperText={
                        buttonClickedPinfo === true &&
                        formik?.errors?.address?.state
                      }
                      placeholder={"Select"}
                    />
                  )}
                />
              ) : (
                ""
              )}
            </Grid>

            <Grid item xs={12} sm={4} className="pt-0">
              <label>
                City/Town<span className="red-color">*</span>
              </label>
              <TextField
                sx={{ mt: 2, mb: 2 }}
                className="form-input"
                type="text"
                size="small"
                name="address.city"
                placeholder="Enter your city"
                onBlur={formik.handleBlur}
                value={formik.values.address.city}
                onChange={formik.handleChange}
                error={
                  formik?.touched?.address?.city &&
                  Boolean(formik?.errors?.address?.city)
                }
                helperText={
                  formik?.touched?.address?.city &&
                  formik?.errors?.address?.city
                }
              />
            </Grid>

            <Grid item xs={12} sm={4} className="pt-0">
              <label>
                Postal Code<span className="red-color">*</span>
              </label>
              <TextField
                sx={{ mt: 2, mb: 2 }}
                className="form-input"
                type="number"
                size="small"
                name="address.postalCode"
                placeholder="Enter your postal code"
                // onBlur={formik.handleBlur}
                onBlur={(e) => {
                  formik.handleBlur(e);
                  if (
                    formik.values.isResidentialAddress &&
                    areAddressFieldsFilled(formik.values.address)
                  ) {
                    const fullAddress = `${formik.values.address?.addressLine1}, ${formik.values.address?.city}, ${formik.values.address?.state}, ${formik.values.address?.postalCode}`;
                    fetchLatLng(fullAddress);
                  }
                }}
                onChange={formik.handleChange}
                value={formik.values.address.postalCode}
                error={
                  formik?.touched?.address?.postalCode &&
                  Boolean(formik?.errors?.address?.postalCode)
                }
                helperText={
                  formik?.touched?.address?.postalCode &&
                  formik?.errors?.address?.postalCode
                }
              />
            </Grid>

            <Grid item xs={12} md={12} sm={12} className="input pt-0">
              <Box>
                <FormControlLabel
                  className="input pt-0"
                  sx={{ mt: 2 }}
                  control={
                    <Checkbox
                      className={`form-checkbox ${
                        formik?.values?.visiblePostAddresss
                          ? "checked-checkbox-stepper"
                          : "unchecked-checkbox-stepper"
                      }`}
                      checked={formik?.values?.visiblePostAddresss}
                      onClick={(t) => {
                        formik.setFieldValue(
                          "visiblePostAddresss",
                          t.target.checked
                        );
                        setVisiblePostAddresss(t.target.checked);
                        if (t.target.checked === false) {
                          formik.setFieldValue("postalAddress", {
                            postalCountryId: null,
                            postalStateId: null,
                            postalCity: "",
                            postalPinCode: "",
                            postalAddressLine1: "",
                            postalAddressLine2: "",
                          });
                        }
                      }}
                    />
                  }
                  label="Postal Address"
                />
              </Box>
            </Grid>

            <Grid
              container
              spacing={2}
              sx={{
                marginLeft: "3px",
                display: formik.values.postalAddressLine1
                  ? "visible"
                  : visiblePostAddresss
                  ? "visible"
                  : "none",
              }}
            >
              <Grid item xs={12} md={12} sm={12}>
                <FormControlLabel
                  className="input pt-0"
                  // sx={{ mt: 2 }}
                  control={
                    <Checkbox
                      className={`form-checkbox ${
                        formik.values.isPostaladdressSame
                          ? "checked-checkbox-stepper"
                          : "unchecked-checkbox-stepper"
                      }`}
                      checked={formik.values.isPostaladdressSame}
                      onClick={(s) => postalAddressVissiable(s)}
                    />
                  }
                  label="Postal Address is same as Residential Address"
                />
              </Grid>

              <Grid item xs={6} className="input pt-0">
                <label>
                  Address Line 1 <span className="red-color">*</span>
                </label>
                <TextField
                  sx={{ mt: 2, mb: 2 }}
                  className="form-input"
                  type="Name"
                  size="small"
                  placeholder="Enter your address line 1"
                  name="postalAddress.postalAddressLine1"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik?.values?.postalAddress?.postalAddressLine1}
                  error={
                    formik?.touched?.postalAddress?.postalAddressLine1 &&
                    Boolean(formik?.errors?.postalAddress?.postalAddressLine1)
                  }
                  helperText={
                    formik?.touched?.postalAddress?.postalAddressLine1 &&
                    formik?.errors?.postalAddress?.postalAddressLine1
                  }
                />
              </Grid>

              <Grid item xs={6} className="input pt-0">
                <label>Address Line 2</label>
                <TextField
                  sx={{ mt: 2, mb: 2 }}
                  className="form-input"
                  type="Name"
                  size="small"
                  name="postalAddress.postalAddressLine2"
                  placeholder="Enter your address line 2"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik?.values?.postalAddress?.postalAddressLine2}
                  error={
                    formik?.touched?.postalAddress?.postalAddressLine2 &&
                    Boolean(formik?.errors?.postalAddress?.postalAddressLine2)
                  }
                  helperText={
                    formik?.touched?.postalAddress?.postalAddressLine2 &&
                    formik?.errors?.postalAddress?.postalAddressLine2
                  }
                />
              </Grid>

              <Grid item xs={12} sm={4} className="pt-0">
                <label>
                  Province/State <span className="red-color">*</span>
                </label>
                {stateData !== undefined ? (
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    name="postalAddress.postalStateId"
                    sx={{ mt: 1, mb: 2 }}
                    options={stateTypeVal}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          buttonClickedPinfo === true &&
                          Boolean(formik?.errors?.postalAddress?.postalStateId)
                        }
                        helperText={
                          buttonClickedPinfo === true &&
                          formik?.errors?.postalAddress?.postalStateId
                        }
                        placeholder={"Select"}
                      />
                    )}
                    size="small"
                    onChange={(event, newInputValue) => {
                      // setField('inputValue', newInputValue)
                      setPostalAddressStateFunction(
                        newInputValue,
                        formik.setFieldValue
                      );
                    }}
                    value={
                      formik?.values?.postalAddress?.postalStateId !== undefined
                        ? stateData?.find(
                            (option) =>
                              option.id ===
                              formik?.values?.postalAddress?.postalStateId
                          )
                        : ""
                    }
                  />
                ) : (
                  ""
                )}
              </Grid>

              <Grid item xs={12} sm={4} className="pt-0">
                <label>
                  City/Town <span className="red-color">*</span>
                </label>
                <TextField
                  sx={{ mt: 2, mb: 2 }}
                  className="form-input"
                  type="text"
                  size="small"
                  name="postalAddress.postalCity"
                  placeholder="Enter your city"
                  onBlur={formik.handleBlur}
                  value={formik?.values?.postalAddress?.postalCity}
                  onChange={formik.handleChange}
                  error={
                    formik?.touched?.postalAddress?.postalCity &&
                    Boolean(formik?.errors?.postalAddress?.postalCity)
                  }
                  helperText={
                    formik?.touched?.postalAddress?.postalCity &&
                    formik?.errors?.postalAddress?.postalCity
                  }
                />
              </Grid>

              <Grid item xs={12} sm={4} className="pt-0">
                <label>
                  Postal Code <span className="red-color">*</span>
                </label>
                <TextField
                  sx={{ mt: 2, mb: 2 }}
                  className="form-input"
                  type="text"
                  size="small"
                  name="postalAddress.postalPinCode"
                  placeholder="Enter your postal code"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik?.values?.postalAddress?.postalPinCode}
                  error={
                    formik?.touched?.postalAddress?.postalPinCode &&
                    Boolean(formik?.errors?.postalAddress?.postalPinCode)
                  }
                  helperText={
                    formik?.touched?.postalAddress?.postalPinCode &&
                    formik?.errors?.postalAddress?.postalPinCode
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default PersonalInfo;
