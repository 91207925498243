import React from "react";
import { Button } from "@material-ui/core";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "../../../../assets/css/thankyou.css";
import { Link, Navigate } from "react-router-dom";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { redirectionAfterSuscriptionPlan } from "../../../../helpers/commonHelper";

const Thankyou = (props) => {
  const goToPage = (e) => {
    // e.preventDefault();
    // redirectionAfterSuscriptionPlan();
    Navigate("/get-support");
  };
  return (
    <>
      <Box id="thankyou">
        <Card className="thankyou-card">
          <Box className="thankyou-box">
            <CardHeader
              action={
                <IconButton aria-label="settings">
                  <CheckCircleIcon className="check-circle-icon" />
                </IconButton>
              }
            />
          </Box>

          <CardContent>
            <Typography variant="h6" color="#248740" textAlign="center">
              Your request is sent successfully
            </Typography>

            <Typography
              mt={1}
              fontWeight="bold"
              variant="h6"
              color="#248740"
              textAlign="center"
            >
              Admin will connect with you shortly
            </Typography>
          </CardContent>

          <Box mt={2} mb={2} className="thankyou-box">
            <CardActions>
              <Button
                className="buttonsty"
                type="button"
                variant="contained"
                size="large"
                id="next-btn"
              >
                <Link to="/get-support">Okay</Link>
              </Button>
            </CardActions>
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default Thankyou;
