import * as React from "react";
import FormControl from "@mui/material/FormControl";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { Box } from "@mui/material";
import regular from "../../../assets/images/table-check.png";
import onCall from "../../../assets/images/table-call.png";
import sleepover from "../../../assets/images/sleep-icon.png";
import { useState, useEffect } from "react";
import { getAllShift, getAllLocation } from "../../../api";
// import axios from "axios";
import axios from "../../../api/axios";

import FilterStore from "../../availability/store/FilterStore";
import Button from "@mui/material/Button";
import {
  getCreatedBy,
  getAllBeforeOnboarding,
} from "../../../helpers/commonHelper";

export default function ShiftTypeFilter(props) {
  const [shifttype, setShiftType] = useState([]);
  const { resetFields, setField, selectedCategoryId, selectedShift, tabIndex } =
    FilterStore();
  let exploreMarketData = JSON.parse(localStorage.getItem("exploreMarket"));
  let userData = JSON.parse(localStorage.getItem("userData"));

  let createdBy;
  if (userData !== "" && userData !== undefined && userData !== null) {
    createdBy = getCreatedBy();
    // createdBy = exploreMarketData?.createdBy;
  } else {
    // createdBy = exploreMarketData?.createdBy;
    createdBy = getAllBeforeOnboarding();
  }

  const getAppointment = () => {
    axios
      .get(`${getAllShift}?createdBy=${createdBy}`)
      .then(async (res) => {
        setShiftType(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAppointment();
  }, []);

  const selectAllStatus = (stake) => {
    if (stake.target.checked === true) {
      let stakeArr = [];
      shifttype.map((val) => {
        stakeArr.push(val._id);
      });
      setField("selectedShift", stakeArr);
    } else {
      setField("selectedShift", []);
    }
  };

  const selectCheckboxHandle = async (event, values) => {
    const index = selectedShift.indexOf(values);
    if (index === -1) {
      setField("selectedShift", [...selectedShift, values]);
    } else {
      setField(
        "selectedShift",
        selectedShift.filter((val) => val !== values)
      );
    }
  };

  const clearFilterFunction = () => {
    resetFields();
  };

  return (
    <Box mt={1} mb={2} className="d-flex justify-content-start">
      <FormControl component="fieldset" variant="standard">
        <FormGroup className="formgroupsty">
          {shifttype !== undefined &&
            shifttype.length > 0 &&
            shifttype.map((val) => {
              return (
                <FormControlLabel
                  className="checkbox-label"
                  control={
                    <Box className="d-flex align-items-center">
                      <Checkbox
                        defaultChecked
                        className="checkbox-color"
                        onChange={(event) =>
                          selectCheckboxHandle(event, val?._id)
                        }
                        checked={selectedShift?.includes(val?._id)}
                      />
                      {/* <img
                                    className="checkbox-img"
                                    src={val?.icon}
                                    alt={val?.label}
                                    /> */}
                    </Box>
                  }
                  label={val?.name}
                />
              );
            })}

          <FormControlLabel
            className="checkbox-label"
            control={
              <Checkbox
                defaultChecked
                className="checkbox-color"
                onChange={selectAllStatus}
                checked={
                  selectedShift?.length === shifttype.length ? true : false
                }
              />
            }
            label="All"
          />

          <Button
            variant="contained"
            className="btn-primary appointment-clear-button"
            onClick={clearFilterFunction}
          >
            Clear
          </Button>
        </FormGroup>
      </FormControl>
    </Box>
  );
}
